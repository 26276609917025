import { FormItemProps as AntFormItemProps } from 'antd';
import { Icon } from 'common/ui/_new';
import { IFormItemProps, IGetTooltip, isTelRule } from './types';

import { TFunction } from 'i18next';

export const getAntFormItemRules = (rules: IFormItemProps['rules'], t: TFunction<'common', 'ui.form-item'>): AntFormItemProps['rules'] =>
  rules?.map((rule) => {
    if (isTelRule(rule)) {
      return {
        pattern: /^\+\d{1,3}\s{1}\(\d{2,3}\)\s{1}\d{2,4}\-\d{2,4}(\-\d{2})?$/,
        message: t('rules.tel.message')
      };
    }
    return rule;
  });

export const getTooltip: IGetTooltip = (tooltip) => {
  if (typeof tooltip === 'object' && tooltip !== null) {
    return {
      icon: <Icon type='info' />,
      ...tooltip
    };
  }

  return tooltip;
};
