import { Key } from 'react';
import { Flex } from 'antd';
import { IButtonProps, Icon, IDropdownItem } from 'common/ui/_new';
import { validateRole } from 'common/helpers';
import { IROLE, ROLES } from 'types/roles';
import { instructorPaths } from 'types/routes/instructor';
import { studentPaths } from 'types/routes/student';
import { ProfileNavItem } from './ProfileNavItem/ProfileNavItem';
import { ApplicationsCount } from './ApplicationsCount';
import { NavigationItem } from './NavigationItem';
import { Notifications } from './Notifications';
import { IHeaderNavigation, NavigationItemStatus, NavigationItemStatusMap } from './types';
import styleVar from 'common/styles/vars.scss';

import { TFunction } from 'i18next';

const INSTRUCTOR_WITH_NEWS_PRESET: typeof ROLES.INSTRUCTOR = {
  role: ROLES.INSTRUCTOR.role,
  permissions: {
    ...ROLES.INSTRUCTOR.permissions,
    NewsFeedSettings: true
  }
};

const getStudentNavigation = (t: TFunction<'Instructors' | 'Guest' | 'Students' | 'Root', 'common.navigation'>): IHeaderNavigation[] => [
  {
    key: 'education',
    label: t('education.label'),
    children: [
      {
        key: 'my-courses',
        label: <NavigationItem label={t('education.children.my-courses')} to={studentPaths.myCourses} />
      },
      {
        key: 'achievements',
        label: <NavigationItem label={t('education.children.achievements')} to={studentPaths.achievements} />
      },
      {
        key: 'competencies',
        label: <NavigationItem label={t('education.children.competencies')} to={studentPaths.competencies} />
      },
      {
        key: 'student-catalog',
        label: <NavigationItem label={t('education.children.catalog')} to={studentPaths.catalog} />
      },
      {
        key: 'thesaurus',
        label: <NavigationItem label={t('education.children.thesaurus')} to={studentPaths.thesaurus} />
      },
      {
        key: 'expulsion',
        label: <NavigationItem label={t('education.children.expulsion-requests')} to={studentPaths.expulsion} />
      }
    ]
  },
  {
    key: 'profileData',
    label: t('account.label'),
    children: [
      {
        key: 'profile',
        label: <ProfileNavItem label={t('account.children.profile-data')} to={studentPaths.profile} />
      },
      {
        key: 'accounts',
        label: <NavigationItem label={t('account.children.linked-accounts')} to={studentPaths.accountRelation} />
      }
    ]
  },
  {
    key: 'student-notifications',
    label: t('info.label'),
    children: [
      {
        key: 'notifications',
        label: (
          <>
            <NavigationItem label={t('info.children.notifications')} to={studentPaths.notifications} />
            <Notifications />
          </>
        )
      },
      {
        key: 'news',
        label: <NavigationItem label={t('info.children.news')} to={studentPaths.news} />
      }
    ]
  }
];

const getInstructorNavigation = (permissions: IROLE.RoleObject['permissions']): IHeaderNavigation[] => [
  {
    key: 'education-settings',
    label: 'Настройки обучения',
    children: [
      {
        key: 'courses',
        label: <NavigationItem label='Курсы' to={instructorPaths.courses} />
      },
      {
        key: 'programs',
        label: <NavigationItem label='Программы' to={instructorPaths.programs} />,
        isPermitted: Boolean(permissions.TrainingSettings || permissions.OnlyEditSettings)
      },
      {
        key: 'resources',
        label: <NavigationItem label='Ресурсы' to={instructorPaths.resources} />
      },
      {
        key: 'instructor-catalog',
        label: <NavigationItem label='Каталог' to={instructorPaths.catalog} />
      }
    ]
  },
  {
    key: 'profileData',
    label: 'Учетная запись',
    children: [
      {
        key: 'profile',
        label: <ProfileNavItem label='Данные для обучения' to={studentPaths.profile} />
      },
      {
        key: 'accounts',
        label: <NavigationItem label='Привязанные аккаунты' to={studentPaths.accountRelation} />
      }
    ]
  },
  {
    key: 'course-applications',
    label: 'Заявки по курсам',
    children: [
      {
        key: 'applications-expulsion',
        label: <NavigationItem label='Заявки на отчисление' to={instructorPaths.expulsion} />
      },
      {
        key: 'applications-enrollment',
        label: (
          <Flex justify='space-between'>
            <NavigationItem label='Заявки на зачисление' to={instructorPaths.enrollment} />
            <ApplicationsCount />
          </Flex>
        )
      }
    ]
  },
  {
    key: 'news',
    label: 'Новости',
    button: <NavigationItem label='Новости' to={instructorPaths.news} isDropDownItem={false} />,
    access: INSTRUCTOR_WITH_NEWS_PRESET
  }
];

const administratorNavigation: IHeaderNavigation[] = [
  {
    key: 'education-settings',
    label: 'Настройки обучения',
    children: [
      {
        key: 'courses',
        label: <NavigationItem label='Курсы' to={instructorPaths.courses} />
      },
      {
        key: 'programs',
        label: <NavigationItem label='Программы' to={instructorPaths.programs} />
      },
      {
        key: 'resources',
        label: <NavigationItem label='Ресурсы' to={instructorPaths.resources} />
      },
      {
        key: 'student-catalog',
        label: <NavigationItem label='Каталог' to={studentPaths.catalog} />
      }
    ]
  },
  {
    key: 'profileData',
    label: 'Учетная запись',
    children: [
      {
        key: 'profile',
        label: <ProfileNavItem label='Данные для обучения' to={studentPaths.profile} />
      },
      {
        key: 'accounts',
        label: <NavigationItem label='Привязанные аккаунты' to={studentPaths.accountRelation} />
      }
    ]
  },
  {
    key: 'course-applications',
    label: 'Заявки по курсам',
    children: [
      {
        key: 'applications-expulsion',
        label: <NavigationItem label='Заявки на отчисление' to={instructorPaths.expulsion} />
      },
      {
        key: 'applications-enrollment',
        label: <NavigationItem label='Заявки на зачисление' to={instructorPaths.enrollment} />
      }
    ]
  },
  {
    key: 'news',
    label: 'Новости',
    button: <NavigationItem label='Новости' to={instructorPaths.news} isDropDownItem={false} />
  },
  {
    key: 'system',
    label: 'Управление',
    button: (
      <NavigationItem
        label='Управление'
        to={`${process.env.REACT_APP_OLD}/Sys`}
        tooltip='Панель администратора (старый дизайн)'
        isExternalRef
        isDropDownItem={false}
      />
    )
  }
];

export const getMainNavigationByRole = (
  user: IROLE.RoleObject,
  t: TFunction<'Instructors' | 'Guest' | 'Students' | 'Root', 'common.navigation'>
) => {
  if (user.role === 'Students') {
    return getStudentNavigation(t);
  }

  if (user.role === 'Instructors') {
    return getInstructorNavigation(user.permissions)
      .filter((link) => {
        if (link.access) {
          return validateRole([link.access], user);
        }
        return true;
      })
      .map(({ children, ...rest }) => ({ children: children?.filter(({ isPermitted }) => isPermitted !== false), ...rest }));
  }

  if (user.role === 'Root') {
    return administratorNavigation;
  }
};

export const navigationItemStatusMap: NavigationItemStatusMap = {};

export const getNavigationItemPropsByStatus = (status?: NavigationItemStatus): IButtonProps | undefined => {
  if (status === 'warning') {
    return {
      suffixIcon: <Icon type='warning' style={{ color: styleVar.colorYellow100 }} />
    };
  }
};

export const getChildKeys = (children: IDropdownItem[]) => {
  return children.map((child) => child.key || '');
};

export const checkPathMatch = (pathname: string, segmentsToMatch: Key[]) => {
  const firstPathSegment = pathname.match(/\/([^\/]+)/)?.[1];
  return segmentsToMatch.includes(firstPathSegment as Key);
};

// todo: add i18n
