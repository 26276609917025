import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'common/ui/_new';
import { useRedirectPath } from 'common/hooks';
import { ErrorResultPage, ErrorResultPageProps } from '../common';

export const CourseOrExerciseNotFoundErrorPage: FC<ErrorResultPageProps> = (props) => {
  const redirectPath = useRedirectPath();
  const { t } = useTranslation('common', { keyPrefix: 'components.error-page.course-or-exercise-not-found' });

  return (
    <ErrorResultPage
      subTitle={t('sub-title')}
      extra={
        <Link to={redirectPath.studentsCatalog()} type='primary'>
          {t('link')}
        </Link>
      }
      {...props}
    />
  );
};
