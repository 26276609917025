import { createApi } from '@reduxjs/toolkit/query/react';
import { getBaseQuery } from 'common/helpers';
import type { ISaveScorm } from 'types/requests/instructor/scorm.api';

export const scormApi = createApi({
  reducerPath: 'scormApi',
  baseQuery: getBaseQuery(),
  endpoints: ({ mutation }) => ({
    // API Добавление SCORM пакета в урок.
    saveScorm: mutation<ISaveScorm.Response, ISaveScorm.Params>({
      query: ({ courseId, lessonId, body, ...params }) => ({
        url: `/api/instructors/Courses/${courseId}/Lessons/${lessonId}/Exercises/Create/SCORM`,
        method: 'POST',
        params,
        body
      })
    })
  })
});

export const { useSaveScormMutation } = scormApi;
