import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { getBaseQuery } from 'common/helpers';
import type { IExercisesResults, ITrainingsResultsOptions } from 'types/requests/instructor/results.api';

export const instructorResultsApi = createApi({
  reducerPath: 'instructorResultsApi',
  baseQuery: getBaseQuery(),
  tagTypes: ['InstructorTrainingsResultsList'],
  endpoints: ({ query }) => ({
    // Получение списка курсов с ожидающими проверку упражнениями
    getTrainingsResultsOptions: query<ITrainingsResultsOptions.Response, ITrainingsResultsOptions.Params>({
      query: () => ({
        url: '/api/instructors/trainings/results/verify/trainings'
      })
    }),
    // Получение списка упражнений требующих оценки
    getExercisesResults: query<IExercisesResults.Response, IExercisesResults.Params>({
      query: ({ trainingIdQuery, searchQuery }) => {
        return {
          url: '/api/instructors/trainings/results/verify',
          method: 'GET',
          params: { training: trainingIdQuery, search: searchQuery }
        };
      }
    })
  })
});

export const { useGetTrainingsResultsOptionsQuery, useGetExercisesResultsQuery } = instructorResultsApi;
