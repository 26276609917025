import { FC } from 'react';
import { Input, Switch } from 'common/ui/_new';
import { DictionarySelect, HierarchySelect } from 'common/components/_new';
import { useNotification } from 'common/hooks';
import { AttributeTypeEnum } from 'types/entities';
import { AttributeValueInputComponentProps } from './types';

export const AttributeValueInputComponent: FC<AttributeValueInputComponentProps> = ({ type, details, ...restProps }) => {
  const notification = useNotification();
  switch (type) {
    case AttributeTypeEnum.String:
      const { value, onChange } = restProps;
      return <Input value={value} onChange={(e) => onChange?.(e.target.value)} />;
    case AttributeTypeEnum.Bool:
      return <Switch {...restProps} />;
    case AttributeTypeEnum.Reference:
      if (details) {
        return <DictionarySelect dictionaryId={details} {...restProps} />;
      }
      break;
    case AttributeTypeEnum.Set:
      if (details) {
        return <DictionarySelect mode='multiple' dictionaryId={details} {...restProps} />;
      }
      break;
    case AttributeTypeEnum.Hierarchy:
      if (details) {
        return <HierarchySelect attributeReferenceTypeId={details} lastLevelNodeId={restProps.value} {...restProps} />;
      }
      break;
    default:
      notification.onError();
  }
};
