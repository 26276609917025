import { HeaderRender } from 'antd/es/calendar/generateCalendar';
import dayjs, { Dayjs } from 'dayjs';
import { Button, Title } from 'common/ui/_new';
import { getLocalizedDate } from 'common/helpers';
import styles from './styles.module.scss';

export const renderHeader = ({ value, onChange }: Parameters<HeaderRender<Dayjs>>[0], lang: string) => {
  const currentDate = getLocalizedDate(value, 'MMMM YYYY', lang);

  return (
    <div className={styles.header}>
      <Title level={5} className={styles.title}>
        {currentDate}
      </Title>
      <div className={styles.buttons}>
        <Button onClick={() => onChange(value.clone().add(-1, 'M'))} type='icon-only' icon='arrow-chevron-left' className={styles.button} />
        <Button onClick={() => onChange(dayjs())} type='icon-only' icon='dot' className={styles.button} />
        <Button onClick={() => onChange(value.clone().add(1, 'M'))} type='icon-only' icon='arrow-chevron-right' className={styles.button} />
      </div>
    </div>
  );
};
