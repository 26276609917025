import { FC } from 'react';
import { FormItemProps as AntFormItemProps } from 'antd';
import { Rule } from 'antd/es/form';

export type ITelRule = Omit<Rule, 'type'> & {
  type?: 'tel';
};

export type IFormItemProps = Omit<AntFormItemProps, 'rules'> & {
  rules?: (Rule | ITelRule)[];
};

export type IInternalFormItem = FC<IFormItemProps>;

export const isTelRule = (rule: Rule | ITelRule): rule is ITelRule => 'type' in rule && rule.type === 'tel';

export type IGetTooltip = (tooltip: IFormItemProps['tooltip']) => AntFormItemProps['tooltip'];
