import { IGetFileTypeIcon } from './types';

export const getFileTypeIcon: IGetFileTypeIcon = (filetype) => {
  if (!filetype) {
    return 'attach';
  }

  const [type, subtype] = filetype?.split('/');

  if (type === 'application') {
    if (subtype === 'pdf') {
      return 'file-doc';
    }
    if (subtype === 'zip') {
      return 'file-archive';
    }
  }

  if (type === 'image') {
    return 'file-img';
  }

  if (type === 'audio') {
    return 'file-audio';
  }

  if (type === 'video') {
    return 'file-video';
  }

  return 'attach';
};
