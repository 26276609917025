import { Children } from 'react';
import { Flex } from 'antd';
import { ISiders } from './types';
import styles from './styles.module.scss';

export const Siders: ISiders = (props) => {
  const { children } = props;

  return (
    <Flex vertical gap={32}>
      {Children.map(children, (child, i) => {
        if (child) {
          return (
            <aside key={i} className={styles.aside}>
              {child}
            </aside>
          );
        }
      })}
    </Flex>
  );
};

/* 
todo: заменить Children API
*/
