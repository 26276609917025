import cn from 'classnames';
import { InputMaskType } from './types';

import { Props as ReactInputMaskProps } from 'react-input-mask';

export const InputMask: InputMaskType = (props) => {
  const { value = '', className, ...restProps } = props;

  const innerProps: ReactInputMaskProps = {
    //* overridable:
    //* outer:
    ...restProps,
    //* unoverridable:
    value,
    className: cn('ant-input', className)
  };

  return <InputMask {...innerProps} />;
};

//! не работает: нужно заменить дочерний компонент InputMask на ReactInputMask
//todo: привести стили в соответствие с Input
