import { ISelectProps } from 'common/ui/_new';

import { LANGUAGES } from 'i18n/constants';

export const options: ISelectProps['options'] = [
  {
    label: 'Русский',
    optionLabel: 'RU',
    value: LANGUAGES.ru
  },
  {
    label: 'English',
    optionLabel: 'EN',
    value: LANGUAGES.en
  }
];
