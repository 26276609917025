import { useCallback, useEffect, useRef, useState } from 'react';
import dayjs, { extend } from 'dayjs';
import durationPlugin from 'dayjs/plugin/duration';
extend(durationPlugin);

export const useFormatTimer = (totalSeconds: number | undefined): [boolean, boolean, string, () => void] => {
  const [timer, setTimer] = useState(0);
  const [hasTimer, setHasTimer] = useState(false);
  const intervalId = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    if (typeof totalSeconds === 'number') {
      setHasTimer(true);
      const seconds = Math.floor(totalSeconds);
      setTimer(seconds);
    }
  }, [totalSeconds]);

  useEffect(() => {
    if (!hasTimer) return;
    const interval = setTimeout(() => {
      if (timer === 0) return false;
      setTimer(timer - 1);
    }, 1000);

    intervalId.current = interval;

    return () => clearTimeout(interval);
  }, [timer, hasTimer]);

  const stop = useCallback(() => {
    if (intervalId.current) {
      clearInterval(intervalId.current);
    }
  }, []);

  return [hasTimer, timer === 0, dayjs.duration(timer, 'seconds').format('mm:ss'), stop];
};
