import { FC } from 'react';
import cn from 'classnames';
import { useDefaultMediaQueries } from 'common/hooks';
import { getPresetProps } from './helper';
import { PageHeadingTitle } from './PageHeadingTitle';
import { PageHeadingProps } from './types';
import styles from './styles.module.scss';

export const PageHeading: FC<PageHeadingProps> = (props) => {
  const { isMobile } = useDefaultMediaQueries();

  if (isMobile) {
    return <MobilePageHeading {...props} />;
  }

  const { title, footer, preset, image, className, children } = props;
  const { imgSrc } = getPresetProps({ preset, image });

  return (
    <section className={cn(styles.pageHeading, className)}>
      <div className={styles.main}>
        <PageHeadingTitle>{title}</PageHeadingTitle>
        <div>{children}</div>
        {footer && <div className={styles.footer}>{footer}</div>}
      </div>
      {imgSrc && <img className={styles.image} src={imgSrc} alt='' />}
    </section>
  );
};

const MobilePageHeading: FC<PageHeadingProps> = ({ title, endGroup, className }) => {
  return (
    <section className={cn(styles.pageHeading_mobile, className)}>
      <div className={styles.main}>
        <PageHeadingTitle className={styles.title}>{title}</PageHeadingTitle>
        {endGroup && <div>{endGroup}</div>}
      </div>
    </section>
  );
};
