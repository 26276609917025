import { useMemo } from 'react';
import { Search } from 'react-router-dom';
import { setPathIds } from 'common/helpers';
import { useAppSelector } from 'common/hooks';
import { fallbacks } from 'common/constants';
import { profileSelectors } from 'api/student/profile/profile.selectors';
import { IRedirect } from 'types/common';
import { guestPaths } from 'types/routes/guest';
import { instructorPaths } from 'types/routes/instructor';
import { studentPaths } from 'types/routes/student';

export const useRedirectPath = (): IRedirect<string> => {
  const role = useAppSelector(profileSelectors.role);

  return useMemo(
    () => ({
      // Fallback
      fallback: redirect(fallbacks[role.role]),
      // Guest
      welcome: redirect(guestPaths.welcome),
      invite: redirect(guestPaths.invite),
      coursePreview: redirectWithIds(guestPaths.coursePreview),
      notFound: redirect(guestPaths.notFound),
      authLoader: redirect(guestPaths.authLoader),
      // Student
      myCourses: redirect(studentPaths.myCourses),
      myStudiedCourses: redirect(studentPaths.myStudiedCourses),
      courseFolder: redirectWithIds(studentPaths.courseFolder),
      course: redirectWithIds(studentPaths.course),
      studentsCatalog: redirect(studentPaths.catalog),
      profile: redirect(studentPaths.profile),
      profileAttributeGroup: redirectWithIds(studentPaths.profileAttributeGroup),
      achievements: redirect(studentPaths.achievements),
      competencies: redirect(studentPaths.competencies),
      password: redirect(studentPaths.password),
      education: redirectWithIds(studentPaths.education),
      main: redirect(studentPaths.main),
      academy: redirect(studentPaths.academy),
      expulsion: redirect(studentPaths.expulsion),
      notifications: redirect(studentPaths.notifications),
      accountRelation: redirect(studentPaths.accountRelation),
      news: redirect(studentPaths.news),
      // Instructor
      programs: redirect(instructorPaths.programs),
      programView: redirectWithIds(instructorPaths.programView),
      programCreate: redirect(instructorPaths.programCreate),
      programEdit: redirectWithIds(instructorPaths.programEdit),
      programSort: redirectWithIds(instructorPaths.programSort),
      programAuthors: redirectWithIds(instructorPaths.programAuthors),
      courseResponsesUpload: redirectWithIds(instructorPaths.courseResponsesUpload),
      exerciseResponsesUpload: redirectWithIds(instructorPaths.exerciseResponsesUpload),
      lessonResponsesUpload: redirectWithIds(instructorPaths.lessonResponsesUpload),
      lessonCreate: redirectWithIds(instructorPaths.lessonCreate),
      lessonEdit: redirectWithIds(instructorPaths.lessonEdit),
      lessonConstraints: redirectWithIds(instructorPaths.lessonConstraints),
      exerciseCreate: redirectWithIds(instructorPaths.exerciseCreate),
      exerciseEdit: redirectWithIds(instructorPaths.exerciseEdit),
      scormCreate: redirectWithIds(instructorPaths.scormCreate),
      exerciseConstraints: redirectWithIds(instructorPaths.exerciseConstraints),
      exerciseCompetencies: redirectWithIds(instructorPaths.exerciseCompetencies),
      exerciseFiles: redirectWithIds(instructorPaths.exerciseFiles),
      exerciseSlides: redirectWithIds(instructorPaths.exerciseSlides),
      exerciseSlidesCreate: redirectWithIds(instructorPaths.exerciseSlidesCreate),
      exerciseSlidesEdit: redirectWithIds(instructorPaths.exerciseSlidesEdit),
      instructorsNews: redirect(instructorPaths.news),
      newsCreate: redirect(instructorPaths.newsCreate),
      newsEdit: redirectWithIds(instructorPaths.newsEdit),
      courseGroup: redirectWithIds(instructorPaths.courseGroup),
      courseGroupChildrenSort: redirectWithIds(instructorPaths.courseGroupChildrenSort),
      coursesGroupInstructorsAdd: redirectWithIds(instructorPaths.coursesGroupInstructorsAdd),
      coursesGroupInstructorsRemove: redirectWithIds(instructorPaths.coursesGroupInstructorsRemove),
      coursesGroupInstructorsRemoveTaskQueue: redirectWithIds(instructorPaths.coursesGroupInstructorsRemoveTaskQueue),
      courses: redirect(instructorPaths.courses),
      coursesUsers: redirect(instructorPaths.coursesUsers),
      coursesList: redirect(instructorPaths.coursesList),
      courseInstructors: redirectWithIds(instructorPaths.courseInstructors),
      courseInstructorsEdit: redirectWithIds(instructorPaths.courseInstructorsEdit),
      courseInstructorsCreate: redirectWithIds(instructorPaths.courseInstructorsCreate),
      instructorEstimatesExportTaskQueue: redirectWithIds(instructorPaths.instructorEstimatesExportTaskQueue),
      studentsJournal: redirectWithIds(instructorPaths.studentsJournal),
      studentsJournalDownload: redirectWithIds(instructorPaths.studentsJournalDownload),
      visitJournal: redirectWithIds(instructorPaths.visitJournal),
      visitJournalDownload: redirectWithIds(instructorPaths.visitJournalDownload),
      studentsJournalExportTaskQueue: redirectWithIds(instructorPaths.studentsJournalExportTaskQueue),
      resources: redirect(instructorPaths.closedQuestions),
      closedQuestions: redirect(instructorPaths.closedQuestions),
      closedQuestionCreate: redirect(instructorPaths.closedQuestionCreate),
      closedQuestionEdit: redirectWithIds(instructorPaths.closedQuestionEdit),
      freeQuestions: redirect(instructorPaths.freeQuestions),
      freeQuestionCreate: redirect(instructorPaths.freeQuestionCreate),
      freeQuestionEdit: redirectWithIds(instructorPaths.freeQuestionEdit),
      links: redirect(instructorPaths.links),
      templates: redirect(instructorPaths.templates),
      programCopy: redirectWithIds(instructorPaths.programCopy),
      profileRights: redirectWithIds(instructorPaths.profileRights),
      instructorCourse: redirectWithIds(instructorPaths.course),
      courseCreate: redirect(instructorPaths.courseCreate),
      coursesGroupCreate: redirect(instructorPaths.coursesGroupCreate),
      coursesGroupEdit: redirectWithIds(instructorPaths.coursesGroupEdit),
      coursesStudentManagement: redirectWithIds(instructorPaths.coursesStudentManagement),
      viewStudentCourses: redirectWithIds(instructorPaths.viewStudentCourses),
      studentsJournalCheck: redirectWithIds(instructorPaths.studentsJournalCheck),
      coursesEdit: redirectWithIds(instructorPaths.coursesEdit),
      courseManualNotifications: redirectWithIds(instructorPaths.courseManualNotifications),
      courseAutoNotifications: redirectWithIds(instructorPaths.courseAutoNotifications),
      courseAutoNotificationCreate: redirectWithIds(instructorPaths.courseAutoNotificationCreate),
      courseAutoNotificationEdit: redirectWithIds(instructorPaths.courseAutoNotificationEdit),
      courseManualNotification: redirectWithIds(instructorPaths.courseManualNotification),
      courseManualNotificationCreate: redirectWithIds(instructorPaths.courseManualNotificationCreate),
      courseManualNotificationEdit: redirectWithIds(instructorPaths.courseManualNotificationEdit),
      courseManualNotificationSend: redirectWithIds(instructorPaths.courseManualNotificationSend),
      auditJournal: redirect(instructorPaths.auditJournal),
      coursesStudentsChanges: redirectWithIds(instructorPaths.coursesStudentsChanges),
      coursesStudentsGroups: redirectWithIds(instructorPaths.coursesStudentsGroups),
      coursesStudentsImport: redirectWithIds(instructorPaths.coursesStudentsImport),
      visitJournalExportTaskQueue: redirectWithIds(instructorPaths.visitJournalExportTaskQueue),
      courseLinks: redirectWithIds(instructorPaths.courseLinks),
      certificates: redirectWithIds(instructorPaths.certificates),
      certificateCreate: redirectWithIds(instructorPaths.certificateCreate),
      // Move
      move: ({ number }) => number.toString()
    }),
    [role]
  );
};

function redirect(path: string): () => string {
  return (options?: { search?: Search }): string => `${path}${options?.search ?? ''}`;
}

function redirectWithIds(path: string): (options: object & { search?: Search }) => string {
  return ({ search, ...ids }): string => {
    return `${setPathIds(path, ids)}${search || ''}`;
  };
}
