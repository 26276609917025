import { FC, useContext } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import cn from 'classnames';
import { Button, ILinkProps, Tooltip } from 'common/ui/_new';
import { ExpandedPanelContext } from '../../Layout';
import { checkPathMatch } from './helpers';
import styles from './styles.module.scss';

type NavigationItemProps = {
  label: string;
  to: ILinkProps['to'];
  tooltip?: string;
  isExternalRef?: boolean;
  isDropDownItem?: boolean;
};

export const NavigationItem: FC<NavigationItemProps> = ({ label, to, tooltip, isExternalRef = false, isDropDownItem = true }) => {
  const { pathname } = useLocation();
  const { setIsExpanded } = useContext(ExpandedPanelContext);

  return (
    <Tooltip title={tooltip}>
      {isDropDownItem ? (
        <NavLink to={to} onClick={() => setIsExpanded(false)} className={styles.dropdownLink} reloadDocument={isExternalRef}>
          {label}
        </NavLink>
      ) : (
        <NavLink to={to} onClick={() => setIsExpanded(false)} className={styles.dropdownLink} reloadDocument={isExternalRef}>
          <Button type='text' className={cn(styles.text, { [styles.active]: checkPathMatch(pathname, to.toString()) })}>
            {label}
          </Button>
        </NavLink>
      )}
    </Tooltip>
  );
};
/* 
todo: добавить стили для isActive, isPending
 */
