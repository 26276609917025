import { Flex } from 'antd';
import cn from 'classnames';
import { IModalProps, Title } from 'common/ui/_new';
import { Header } from './Header';
import { AccumulatedProps, ExpressModalProps } from './types';
import styles from './styles.module.scss';

import { TFunction } from 'i18next';

const initialStaticProps: AccumulatedProps = {
  width: 516,
  okButtonProps: {
    size: 'large',
    block: true
  },
  className: styles.expressModal
};

const getNewPropsByType = (
  accProps: AccumulatedProps,
  outerProps: Pick<ExpressModalProps, 'type'>,
  t: TFunction<'common', 'ui.express-modal'>
): AccumulatedProps => {
  if (outerProps.type) {
    const propsByTypeMap: Record<NonNullable<ExpressModalProps['type']>, ExpressModalProps> = {
      error: {
        title: t('error-type.title'),
        icon: 'decor-error'
      },
      delete: {
        title: t('delete-type.title'),
        subtitle: t('delete-type.subtitle'),
        icon: 'decor-trash',
        okText: t('delete-type.ok-button.label'),
        okButtonProps: {
          ...accProps.okButtonProps,
          danger: true
        }
      }
    };

    return {
      ...accProps,
      ...propsByTypeMap[outerProps.type]
    };
  }

  return {
    ...accProps,
    icon: 'decor-info',
    okText: t('default-type.ok-button.label')
  };
};

const getNewPropsByOkButtonProps = (
  { okButtonProps, onOk, ...restAccProps }: AccumulatedProps,
  outerProps: Pick<ExpressModalProps, 'okButtonProps' | 'onOk'>
): AccumulatedProps => {
  const hasOkButton = outerProps.okButtonProps || outerProps.onOk;

  if (hasOkButton) {
    return {
      ...restAccProps,
      okButtonProps: {
        ...okButtonProps,
        ...outerProps.okButtonProps
      },
      onOk: outerProps.onOk ?? onOk
    };
  }

  return restAccProps;
};

const getNewPropsByChildrenType = (accProps: AccumulatedProps, outerProps: Pick<ExpressModalProps, 'children'>): AccumulatedProps => {
  if (typeof outerProps.children === 'string') {
    return {
      ...accProps,
      children: (
        <Title level={5} style={{ textAlign: 'center' }}>
          {outerProps.children}
        </Title>
      )
    };
  }

  return {
    ...accProps,
    children: outerProps.children
  };
};

const getNewPropsByMediaQuery = (
  { icon, ...restAccProps }: AccumulatedProps,
  { isMobile }: Record<'isMobile', boolean>
): AccumulatedProps => {
  if (isMobile) {
    return {
      ...restAccProps,
      closable: false,
      closeIcon: null,
      cancelButtonProps: { type: 'link', size: 'large', className: styles.cancelBtn }
    };
  }

  return {
    ...restAccProps,
    icon
  };
};

const getNewPropsByClassName = (accProps: AccumulatedProps, outerProps: Pick<ExpressModalProps, 'className'>): AccumulatedProps => ({
  ...accProps,
  className: cn(accProps.className, outerProps.className)
});

const buildProps = (accProps: AccumulatedProps, outerProps: ExpressModalProps): IModalProps => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { type, title, subtitle, icon, ...restProps } = {
    ...outerProps,
    ...accProps,
    // todo: make consistent
    title: outerProps.title ?? accProps.title,
    subtitle: outerProps.subtitle ?? accProps.subtitle,
    okText: outerProps.okText ?? accProps.okText
  };

  return {
    ...restProps,
    title: <Header title={title} subtitle={subtitle} icon={icon} />,
    footer:
      accProps.okButtonProps || accProps.onOk
        ? (_, { OkBtn, CancelBtn }) => (
            <Flex vertical gap={8}>
              <OkBtn />
              {accProps.cancelButtonProps && <CancelBtn />}
            </Flex>
          )
        : undefined
  };
};

export const propsBuilder: {
  data: ExpressModalProps;
  init: () => typeof propsBuilder;
  modifyByType: (outerProps: Pick<ExpressModalProps, 'type'>, t: TFunction<'common', 'ui.express-modal'>) => typeof propsBuilder;
  modifyByOkButtonProps: (outerProps: Pick<ExpressModalProps, 'okButtonProps' | 'onOk'>) => typeof propsBuilder;
  modifyByChildrenType: (outerProps: Pick<ExpressModalProps, 'children'>) => typeof propsBuilder;
  modifyByClassName: (outerProps: Pick<ExpressModalProps, 'className'>) => typeof propsBuilder;
  modifyByMediaQuery: (queries: Record<'isMobile', boolean>) => typeof propsBuilder;
  build: (outerProps: ExpressModalProps) => IModalProps;
} = {
  data: {},
  init: function () {
    this.data = initialStaticProps;
    return this;
  },
  modifyByType: function (outerProps, t) {
    this.data = getNewPropsByType(this.data, outerProps, t);
    return this;
  },
  modifyByOkButtonProps: function (outerProps) {
    this.data = getNewPropsByOkButtonProps(this.data, outerProps);
    return this;
  },
  modifyByChildrenType: function (outerProps) {
    this.data = getNewPropsByChildrenType(this.data, outerProps);
    return this;
  },
  modifyByMediaQuery: function (queries) {
    this.data = getNewPropsByMediaQuery(this.data, queries);
    return this;
  },
  modifyByClassName: function (outerProps) {
    this.data = getNewPropsByClassName(this.data, outerProps);
    return this;
  },
  build: function (outerProps) {
    return buildProps(this.data, outerProps);
  }
};
