import dayjs, { extend } from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';

extend(customParseFormat);

const getParseFormat = (date: string) => {
  if (/(^0[1-9]|[12][0-9]|3[01])\/(0[1-9]|1[0-2])\/(\d{4}$)/.test(date)) {
    return 'MM/DD/YYYY';
  }
  if (/(^0[1-9]|[12][0-9]|3[01])\.(0[1-9]|1[0-2])\.(\d{4}$)/.test(date)) {
    return 'DD.MM.YYYY';
  }
};

export const formatInvitationDate = (date: string, lang: string) => {
  const parseFormat = getParseFormat(date);
  return dayjs(date, parseFormat).locale(lang).format('LL');
};
