import { FC } from 'react';
import { EditableCourseCard } from './Course';
import { EditableCourseGroupCard } from './CourseGroup';
import { EditableGroupsAndCoursesListProps, isCourseCardProps, isCourseGroupCardProps } from './types';
import styles from './styles.module.scss';

export const EditableGroupsAndCoursesList: FC<EditableGroupsAndCoursesListProps> = ({ items, showActions, isEditable }) => {
  return (
    <div className={styles.container}>
      <div className={styles.cards}>
        {items.map(({ type, ...props }) => {
          if (isCourseCardProps(type, props)) {
            return <EditableCourseCard key={props.id} {...props} showActions={showActions} isEditable={isEditable} />;
          }

          if (isCourseGroupCardProps(type, props)) {
            return <EditableCourseGroupCard key={props.id} {...props} isEditable={isEditable} />;
          }

          return null;
        })}
      </div>
    </div>
  );
};
