import { FC, HTMLAttributes } from 'react';
import cn from 'classnames';
import { sanitize } from 'common/helpers';
import { Ellipsis } from './Ellipsis';
import { isEllipsisProps } from './helper';
import { HtmlContentProps } from './types';
import styles from './styles.module.scss';

export const HtmlContent: FC<HtmlContentProps> = ({ content, className, ellipsis, ...restProps }) => {
  const innerProps: HTMLAttributes<HTMLDivElement> = {
    //* overridable:
    //* outer:
    ...restProps,
    //* unoverridable:
    dangerouslySetInnerHTML: content
      ? {
          __html: String(sanitize(content))
        }
      : undefined
  };

  if (ellipsis) {
    return (
      <Ellipsis {...(isEllipsisProps(ellipsis) && ellipsis)} className={className}>
        <div {...innerProps} className={styles.htmlContent} />
      </Ellipsis>
    );
  }

  return <div {...innerProps} className={cn(styles.htmlContent, 'html-content', className)} />;
};

//! <p><br></p> makes the component render anyway
