import { ComponentProps } from 'react';
import { Progress as AntProgress } from 'antd';
import cn from 'classnames';
import { IProgress } from './types';
import styles from './styles.module.scss';
import styleVar from 'common/styles/vars.scss';

const staticOverridableProps: ComponentProps<typeof AntProgress> = {
  strokeColor: styleVar.colorAccentGreen100
};

export const Progress: IProgress = ({ className, ...outerProps }) => {
  const overridableProps: ComponentProps<typeof AntProgress> = {
    ...staticOverridableProps
  };

  const unoverridableProps: ComponentProps<typeof AntProgress> = {
    className: cn(styles.progress, className)
  };

  return <AntProgress {...overridableProps} {...outerProps} {...unoverridableProps} />;
};
