import { lazy, ReactElement, Suspense, useEffect } from 'react';
import { createBrowserRouter, Navigate, Outlet, RouterProvider } from 'react-router-dom';
import { adminRoutes, guestRoutes, instructorRoutes, studentRoutes } from 'router';
import { ErrorBoundary } from 'containers';
import { useNotification } from 'common/ui/_new';
import { BackToTopButton, Header, Loader } from 'common/components';
import { checkError, isFeatureAvailable, validateRole } from 'common/helpers';
import { useAppSelector, useRedirectPath } from 'common/hooks';
import { useGetProfileQuery } from 'api/student/profile/profile.api';
import { profileSelectors } from 'api/student/profile/profile.selectors';
import { authErrorSelectors } from 'api/guest/authError/auth-error.selector';
import { useLazyGetAuthTypeQuery } from 'api/guest/authType/auth-type.api';
import { oneIdSelectors } from 'api/guest/oneId/one-id.selector';
import { IRedirect } from 'types/common';
import { IROLE } from 'types/roles';
import { guestPaths } from 'types/routes/guest';

import { i18n } from 'i18n/config';
import { LANGUAGES } from 'i18n/constants';

const NoAccess = lazy(() => import('pages/guest/NoAccess'));
const CoursePreviewByInvite = lazy(() => import('pages/guest/CoursePreviewByInvite'));
const CoursePreview = lazy(() => import('pages/guest/CoursePreview'));
const NotFound = lazy(() => import('pages/guest/NotFound'));

const ProtectedRoute = ({ hasAccess, children }: { hasAccess: boolean; children: ReactElement }) => {
  if (hasAccess) {
    return children;
  }

  return <NoAccess />;
};

const getProtectedRoutes = ({ user }: { user: IROLE.RoleObject }) =>
  [...adminRoutes, ...instructorRoutes, ...studentRoutes].map(({ path, roles, element }) => ({
    path,
    element: <ProtectedRoute hasAccess={validateRole(roles, user)}>{element}</ProtectedRoute>
  }));

const AuthorizedRoute = ({ isAuthorized }: { isAuthorized: boolean }) => {
  const notify = useNotification();

  const { error: oneIdError } = useAppSelector(oneIdSelectors.value);
  if (oneIdError) {
    throw new Error(oneIdError?.Description);
  }

  const { authError } = useAppSelector(authErrorSelectors.value);
  const redirectPath = useRedirectPath();

  if (checkError({ error: authError, status: 555 })) {
    return <Navigate replace to={redirectPath.authLoader()} />;
  }

  const { title = '', description = '' } =
    !!authError && 'status' in authError ? ((authError?.data ?? {}) as { title?: string; description?: string }) : {};
  if (checkError({ error: authError, status: 401 })) {
    if (title && description) {
      notify.error({ message: title, description });
      return null;
    }

    return <Navigate replace to={redirectPath.welcome()} />;
  }

  if (isAuthorized) {
    return (
      <>
        <Header />
        <ErrorBoundary>
          <Suspense fallback={<Loader />}>
            <Outlet />
          </Suspense>
        </ErrorBoundary>
      </>
    );
  }

  return <Navigate replace to={redirectPath.welcome()} />;
};

const getAuthorizedRoutes = ({
  user,
  isAuthorized,
  redirectPath
}: {
  isAuthorized: boolean;
  user: IROLE.RoleObject;
  redirectPath: IRedirect<string>;
}) => [
  {
    element: <AuthorizedRoute isAuthorized={isAuthorized} />,
    children: [
      ...getProtectedRoutes({ user }),
      ...(user.role === 'Guest'
        ? []
        : [
            {
              index: true,
              path: guestPaths.invite,
              element: <CoursePreviewByInvite />
            },
            {
              index: true,
              path: guestPaths.coursePreview,
              element: <CoursePreview />
            }
          ]),
      {
        index: true,
        path: guestPaths.notFound,
        element: <NotFound />
      }
    ]
  },
  ...guestRoutes,
  {
    index: true,
    element: <Navigate replace to={redirectPath.fallback()} />
  }
];

export const App = () => {
  const getProfileQuery = useGetProfileQuery();
  const { role: user, accountId } = useAppSelector(profileSelectors.profile);
  const isAuthorized = Boolean(accountId);

  const [getAuthType] = useLazyGetAuthTypeQuery();
  useEffect(() => {
    getAuthType();
    if (process.env.NODE_ENV === 'development') {
      console.log(user);
    }

    if (!isFeatureAvailable('LOCALIZATION', user)) {
      i18n.changeLanguage(LANGUAGES.ru);
    }
  }, [getAuthType, user]);

  const redirectPath = useRedirectPath();

  if (!(getProfileQuery.isSuccess || getProfileQuery.isError)) {
    return <Loader />;
  }

  const router = createBrowserRouter([
    {
      path: '/',
      element: (
        <ErrorBoundary>
          <Suspense fallback={<Loader />}>
            <Outlet />
          </Suspense>
          <BackToTopButton />
        </ErrorBoundary>
      ),
      ErrorBoundary,
      children: getAuthorizedRoutes({ isAuthorized, user, redirectPath })
    }
  ]);

  return <RouterProvider router={router} fallbackElement={<Loader />} />;
};
