import { FC, useContext } from 'react';
import { ColorScheme, ColorSchemeContext } from 'containers/_new';
import { Switch } from 'common/ui/_new';

export const ThemeToggle: FC = () => {
  const { colorScheme, setColorScheme } = useContext(ColorSchemeContext);

  return (
    <Switch
      defaultChecked={colorScheme === ColorScheme.DARK}
      onChange={(checked) => {
        setColorScheme(checked ? ColorScheme.DARK : ColorScheme.LIGHT);
      }}
    />
  );
};
