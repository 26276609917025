import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Flex } from 'antd';
import { Icon, Link } from 'common/ui/_new';
import styles from './styles.module.scss';

export const SupportContacts: FC = () => {
  const { t } = useTranslation('common', { keyPrefix: 'components.support-contacts' });

  return (
    <Flex vertical gap={16} align='flex-start'>
      <Link
        to='https://t.me/learniu_bot'
        target='_blank'
        icon={<Icon type='sm-telegram' className={styles.icon} />}
        className={styles.link}
      >
        https://t.me/learniu_bot
      </Link>
      <Link to='mailto:lms.support@innopolis.ru' icon={<Icon type='mail' className={styles.icon} />} className={styles.link}>
        lms.support@innopolis.ru
      </Link>
      <Link to='tel:+78005503171' icon={<Icon type='phone' className={styles.icon} />} className={styles.link}>
        8 800 550 31 71
      </Link>
      <Link
        to='https://innopolis.teamly.ru/space/a85f27dc-7f34-446e-838c-61796c567b3f/article/7c3ad86b-15bc-4200-9515-79f875bd4d53'
        target='_blank'
        icon={<Icon type='book-open' className={styles.icon} />}
        className={styles.link}
      >
        {t('user-guide.label')}
      </Link>
    </Flex>
  );
};
