import { ComponentProps } from 'react';
import { Flex, Tree as AntTree } from 'antd';
import cn from 'classnames';
import { Spin } from '../Spin';
import { getSwitcherIcon } from './helpers';
import { ITree } from './types';
import styles from './styles.module.scss';

export const Tree: ITree = (props) => {
  const { loading, className, ...restProps } = props;

  const innerProps: ComponentProps<typeof AntTree> = {
    //* overridable:
    checkable: true,
    selectable: false,
    switcherIcon: getSwitcherIcon,
    //* outer:
    ...restProps,
    //* unoverridable:
    className: cn(styles.Tree, className)
  };
  if (loading) {
    return (
      <Flex justify='center'>
        <Spin />;
      </Flex>
    );
  }
  return <AntTree {...innerProps} />;
};
