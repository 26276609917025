import type { IEditEvaluationSlide, IEvaluationSlide, ISaveEvaluationSlide } from 'types/requests/instructor/slides.slide_evaluation.api';
import { slidesApi } from '../slides.api';

const slideEvaluationApi = slidesApi.injectEndpoints({
  endpoints: ({ query, mutation }) => ({
    // API Создание страницы типа Evaluation.
    saveEvaluationSlide: mutation<ISaveEvaluationSlide.Response, ISaveEvaluationSlide.Params>({
      query: ({ exerciseId, body }) => ({
        url: `/api/instructors/Exercises/${exerciseId}/Pages/Evaluation`,
        method: 'POST',
        body
      }),
      invalidatesTags: ['Slides', 'Evaluation']
    }),
    // API получения страницы типа Evaluation.
    getEvaluationSlide: query<IEvaluationSlide.Response, IEvaluationSlide.Params>({
      query: ({ exerciseId, pageId }) => `/api/instructors/Exercises/${exerciseId}/Pages/${pageId}/Evaluation`,
      providesTags: ['Evaluation']
    }),
    // API Редактирование страницы типа Evaluation.
    editEvaluationSlide: mutation<IEditEvaluationSlide.Response, IEditEvaluationSlide.Params>({
      query: ({ exerciseId, pageId, body }) => ({
        url: `/api/instructors/Exercises/${exerciseId}/Pages/${pageId}/Evaluation`,
        method: 'PUT',
        body
      }),
      invalidatesTags: ['Slides', 'Evaluation']
    })
  })
});

export const { useEditEvaluationSlideMutation, useGetEvaluationSlideQuery, useSaveEvaluationSlideMutation } = slideEvaluationApi;
