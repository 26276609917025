import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import dayjs, { extend } from 'dayjs';
import toArray from 'dayjs/plugin/toArray';
import { DateTime } from 'types/common';

import { LANGUAGES } from 'i18n/constants';

extend(toArray);

enum MatchLevel {
  NONE,
  YEAR,
  MONTH,
  DATE,
  HOUR,
  MINUTE
}

type Language = keyof typeof LANGUAGES | string;
type TimeFormat = string; // e.g.: 'h:mm A'
type DateAndTimeSeparator = string;
type DateFormat = string; // e.g.: 'MMMM D, YYYY'
type DateTimeTemplate = string; // e.g.: 'MMMM D, YYYY h:mm A'
type DateTimeValue = string; // e.g.: 'January 1, 2001 3:00 AM'
type DateTimeRangeValue = string; // e.g.: 'January 1, 2001 3:00 AM - 3:01 AM'

const timeFormatsMap: Record<Language, TimeFormat> = {
  en: 'HH:mm',
  ru: 'HH:mm'
};

const dateTimeSeparatorsMap: Record<Language, DateAndTimeSeparator> = {
  en: ' ',
  ru: ', '
};

const dateFormatsMap: Record<Language, DateFormat> = {
  en: 'MMMM D, YYYY',
  ru: 'D MMMM YYYY'
};

const dateShortFormatsMap: Record<Language, DateFormat> = {
  en: 'DD.MM.YYYY',
  ru: 'DD.MM.YYYY'
};

const dateRangeFormatsMap: Record<Language, Record<MatchLevel.YEAR | MatchLevel.MONTH, Record<'begin' | 'end', DateFormat>>> = {
  en: {
    [MatchLevel.YEAR]: {
      begin: 'MMMM D',
      end: 'MMMM D, YYYY'
    },
    [MatchLevel.MONTH]: {
      begin: 'MMMM D',
      end: 'D, YYYY'
    }
  },
  ru: {
    [MatchLevel.YEAR]: {
      begin: 'D MMMM',
      end: 'D MMMM YYYY'
    },
    [MatchLevel.MONTH]: {
      begin: 'D',
      end: 'D MMMM YYYY'
    }
  }
};

const dateRangeShortFormatsMap: Record<Language, Record<MatchLevel.YEAR | MatchLevel.MONTH, Record<'begin' | 'end', DateFormat>>> = {
  en: {
    [MatchLevel.YEAR]: {
      begin: 'DD.MM',
      end: 'DD.MM.YYYY'
    },
    [MatchLevel.MONTH]: {
      begin: 'DD',
      end: 'DD.MM.YYYY'
    }
  },
  ru: {
    [MatchLevel.YEAR]: {
      begin: 'DD.MM',
      end: 'DD.MM.YYYY'
    },
    [MatchLevel.MONTH]: {
      begin: 'DD',
      end: 'DD.MM.YYYY'
    }
  }
};

const compareDateTimeValues = (dateTime1: DateTime, dateTime2: DateTime, maxLevel: MatchLevel): MatchLevel => {
  const dateTime1Array = dayjs(dateTime1).toArray();
  const dateTime2Array = dayjs(dateTime2).toArray();

  let level = MatchLevel.NONE;
  while (
    level < dateTime1Array.length &&
    level < dateTime2Array.length &&
    level < maxLevel &&
    dateTime1Array[level] === dateTime2Array[level]
  ) {
    level++;
  }

  return level;
};

const getLocalDateTimeFormat = (
  language: Language,
  dateCurrentFormatsMap: Record<Language, DateFormat>,
  timeFormatsMap: Record<Language, TimeFormat>,
  dateTimeSeparatorsMap: Record<Language, DateAndTimeSeparator>
) => {
  return [dateCurrentFormatsMap[language], dateTimeSeparatorsMap[language], timeFormatsMap[language]].join('');
};

const formatDateTime = (language: Language, dateTime: DateTime, localDateTimeFormat: DateTimeTemplate): DateTimeValue => {
  return dayjs(dateTime).locale(language).format(localDateTimeFormat);
};

const joinDateTimeValues = (dateTime1: DateTimeValue, dateTime2: DateTimeValue): DateTimeRangeValue => {
  return [dateTime1, dateTime2].join(' - ');
};

const getFormattedTimeRange = (
  language: Language,
  beginDateTime: DateTime,
  endDateTime: DateTime,
  localTimeFormat: TimeFormat
): DateTimeRangeValue => {
  const formattedBeginTime = formatDateTime(language, beginDateTime, localTimeFormat);
  const formattedEndTime = formatDateTime(language, endDateTime, localTimeFormat);

  return joinDateTimeValues(formattedBeginTime, formattedEndTime);
};

const getFormattedDateRange = (
  language: Language,
  beginDateTime: DateTime,
  endDateTime: DateTime,
  localDateRangeFormatsMap: Record<'begin' | 'end', DateTimeTemplate>
): DateTimeRangeValue => {
  const formattedBeginDate = formatDateTime(language, beginDateTime, localDateRangeFormatsMap.begin);
  const formattedEndDate = formatDateTime(language, endDateTime, localDateRangeFormatsMap.end);

  return joinDateTimeValues(formattedBeginDate, formattedEndDate);
};

const joinDateWithTime = (date: DateTimeValue, time: DateTimeRangeValue, separator: DateAndTimeSeparator) => {
  return [date, time].join(separator);
};

export const formatDateTimeRangeByLocale = (
  language: Language,
  beginDateTime: DateTime | undefined | null,
  endDateTime?: DateTime | undefined | null,
  showTime?: boolean,
  shortFormat?: boolean
) => {
  const { dateRangeCurrentFormatsMap, dateCurrentFormatsMap } = shortFormat
    ? {
        dateRangeCurrentFormatsMap: dateRangeShortFormatsMap,
        dateCurrentFormatsMap: dateShortFormatsMap
      }
    : {
        dateRangeCurrentFormatsMap: dateRangeFormatsMap,
        dateCurrentFormatsMap: dateFormatsMap
      };

  if (beginDateTime) {
    if (endDateTime) {
      if (showTime) {
        const maxMatchLevel = MatchLevel.MINUTE;
        const matchLevel = compareDateTimeValues(beginDateTime, endDateTime, maxMatchLevel);

        if (matchLevel === maxMatchLevel) {
          const dateTimeFormat = getLocalDateTimeFormat(language, dateCurrentFormatsMap, timeFormatsMap, dateTimeSeparatorsMap);

          return formatDateTime(language, beginDateTime, dateTimeFormat);
        }

        if (matchLevel >= MatchLevel.DATE) {
          const timeRange = getFormattedTimeRange(language, beginDateTime, endDateTime, timeFormatsMap[language]);
          const date = formatDateTime(language, beginDateTime, dateCurrentFormatsMap[language]);

          return joinDateWithTime(date, timeRange, dateTimeSeparatorsMap[language]);
        }

        const dateTimeFormat = getLocalDateTimeFormat(language, dateCurrentFormatsMap, timeFormatsMap, dateTimeSeparatorsMap);
        const localDateRangeFormatsMap = { begin: dateTimeFormat, end: dateTimeFormat };

        return getFormattedDateRange(language, beginDateTime, endDateTime, localDateRangeFormatsMap);
      }

      const maxMatchLevel = MatchLevel.DATE;
      const matchLevel = compareDateTimeValues(beginDateTime, endDateTime, maxMatchLevel);

      if (matchLevel === maxMatchLevel) {
        return formatDateTime(language, beginDateTime, dateCurrentFormatsMap[language]);
      }

      const localDateRangeFormatsMap =
        matchLevel === MatchLevel.YEAR || matchLevel === MatchLevel.MONTH
          ? dateRangeCurrentFormatsMap[language][matchLevel]
          : {
              begin: dateCurrentFormatsMap[language],
              end: dateCurrentFormatsMap[language]
            };

      return getFormattedDateRange(language, beginDateTime, endDateTime, localDateRangeFormatsMap);
    }

    const dateTimeFormat = showTime
      ? getLocalDateTimeFormat(language, dateCurrentFormatsMap, timeFormatsMap, dateTimeSeparatorsMap)
      : dateCurrentFormatsMap[language];

    return formatDateTime(language, beginDateTime, dateTimeFormat);
  }

  return;
};

export const useFormatDateTimeRange = (
  beginDateTime: DateTime | undefined | null,
  endDateTime?: DateTime | undefined | null,
  showTime?: boolean,
  shortFormat?: boolean
) => {
  const {
    i18n: { language }
  } = useTranslation();

  return useMemo(
    () => formatDateTimeRangeByLocale(language, beginDateTime, endDateTime, showTime, shortFormat),
    [beginDateTime, endDateTime, language, shortFormat, showTime]
  );
};
