import { FC, HTMLAttributes, ReactNode } from 'react';
import cn from 'classnames';
import { LogOutButton } from './LogOutButton';
import { Username } from './Username';
import styles from './styles.module.scss';

type UserProps = {
  iconButton?: ReactNode;
} & Pick<HTMLAttributes<HTMLDivElement>, 'className'>;

export const User: FC<UserProps> = ({ iconButton, className }) => {
  return (
    <div className={cn(styles.container, className)}>
      <div className={styles.usernameWrapper}>
        <Username />
      </div>
      {iconButton && <div className={styles.settingsButtonWrapper}>{iconButton}</div>}
      <div className={styles.logOutButtonWrapper}>
        <LogOutButton />
      </div>
    </div>
  );
};
