import { FC } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { QueryResponseBoundary } from 'containers/_new';
import { DocViewer } from 'common/ui/_new';
import { useGetPptxSlideQuery } from 'api/instructor/slides/endpoints/slide_pptx.endpoint';
import { InstructorParams } from 'types/routes/instructor';

export const PptxPreviewModal: FC = () => {
  const { exerciseId = '' } = useParams<InstructorParams.Exercise>();
  const [searchParams] = useSearchParams();

  const pptxQuery = useGetPptxSlideQuery({ exerciseId, pageId: searchParams.get('slideId') || '' });

  return (
    <QueryResponseBoundary query={pptxQuery}>
      <DocViewer url={pptxQuery.data?.address} />
    </QueryResponseBoundary>
  );
};
