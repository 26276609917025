import { ComponentProps, ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { InputNumber as AntInputNumber } from 'antd';
import cn from 'classnames';
import { ClearButton } from 'common/ui/_new';
import { IInputNumberProps } from './types';
import styles from './styles.module.scss';

import type { ValueType } from 'rc-input-number';

export const InputNumber = <T extends ValueType = ValueType>(props: IInputNumberProps<T>): ReactElement => {
  const { t } = useTranslation('common', { keyPrefix: 'ui.input-number' });
  const { allowClear, ...restProps } = props;

  const innerProps: ComponentProps<typeof AntInputNumber<T>> = {
    //* overridable:
    placeholder: t('placeholder'),
    //* outer:
    ...restProps,
    //* unoverridable:
    className: cn(styles.inputNumber, props.className)
  };

  if (!props.readOnly && allowClear) {
    return (
      <div className={styles.inputNumberWrapperWithClearBtn}>
        <AntInputNumber {...innerProps} />
        <ClearButton onInputChange={props.onChange} disabled={props.value === undefined || props.value === null} size={props.size} />
      </div>
    );
  }

  return <AntInputNumber {...innerProps} />;
};
