import type { IEditPictureSlide, IPictureSlide, ISavePictureSlide } from 'types/requests/instructor/slides.slide_picture.api';
import { slidesApi } from '../slides.api';

const slidePictureApi = slidesApi.injectEndpoints({
  endpoints: ({ query, mutation }) => ({
    // API Создание страницы типа Picture.
    savePictureSlide: mutation<ISavePictureSlide.Response, ISavePictureSlide.Params>({
      query: ({ exerciseId, body }) => ({
        url: `/api/instructors/Exercises/${exerciseId}/Pages/Picture`,
        method: 'POST',
        body
      }),
      invalidatesTags: ['Slides', 'Picture']
    }),
    // API получения страницы типа Picture.
    getPictureSlide: query<IPictureSlide.Response, IPictureSlide.Params>({
      query: ({ exerciseId, pageId }) => `/api/instructors/Exercises/${exerciseId}/Pages/${pageId}/Picture`,
      providesTags: ['Picture']
    }),
    // API Редактирование страницы типа Picture.
    editPictureSlide: mutation<IEditPictureSlide.Response, IEditPictureSlide.Params>({
      query: ({ exerciseId, pageId, body }) => ({
        url: `/api/instructors/Exercises/${exerciseId}/Pages/${pageId}/Picture`,
        method: 'PUT',
        body
      }),
      invalidatesTags: ['Slides', 'Picture']
    })
  })
});

export const { useEditPictureSlideMutation, useGetPictureSlideQuery, useSavePictureSlideMutation } = slidePictureApi;
