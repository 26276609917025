import { Account, ExerciseId, IExercise, ITraining, LessonId } from 'types/entities';

export interface ISearchUser {
  id: string;
  sid: string;
  initials: string;
  email: string;
  phone: string;
  registrationDate: string;
  iian: string;
  organisation: string;
  role: string;
  type: number;
  hasLoginAccess: boolean;
}

export interface ISearchUserData {
  draw: number;
  recordsTotal: number;
  recordsFiltered: number;
  data: ISearchUser[];
}

//* Lms.Entities.Entities.Training.Students.TrainingStudentFilter
export enum TrainingStudentFiltersEnum {
  ALL = 0,
  AVAILABLE = 1,
  NOT_AVAILABLE = 2,
  EMPTY = 3,
  COMPLETED = 4
}

export interface TrainingStudentFilterOption {
  id: TrainingStudentFiltersEnum;
  label?: string;
}

//* Lms.UseCases.Interfaces.Trainings.Students.GetStudentTrainingsUseCase.ProfileStudent
export interface StudentTrainingProfile {
  id: Account['id'];
  firstname?: Account['firstname'];
  surname?: Account['surname'];
  patronymic?: Account['patronymic'];
  phone?: Account['phone'];
  email?: Account['eMail'];
  picture?: Account['icon'];
}

//* Lms.UseCases.Interfaces.Trainings.Students.GetStudentTrainingsUseCase.CurrentStudentTraining
export interface StudentTraining {
  id: ITraining['id'];
  percent: number;
  trainingLabel?: ITraining['label'];
  trainingGroup?: ITraining['group'];
  begin: ITraining['begin']; // Дата начала обучения (ISO формат даты)
  end: ITraining['end']; // Дата окончания обучения (ISO формат даты)
  activeLessonId?: LessonId; // Id активного урока (по которому идет обучение в данный момент, или следующего по порядку)
  activeLessonLabel?: string; // Имя активного урока (по которому идет обучение в данный момент, или следующего по порядку)
  activeExerciseId?: ExerciseId; // Id активного упражнения (по которому идет обучение в данный момент, или следующего по порядку)
  activeExerciseLabel?: IExercise['label']; // Имя активного упражнения (по которому идет обучение в данный момент, или следующего по порядку)
  isEmptyTraining: boolean; // Является ли обучение пустым (не имеет ни одного слайда)
  isAvailableTraining: boolean; // Доступно ли обучение для студента (галка Training.Available + по датам Open и Closed)
  isFinishedTraining: boolean; // Является ли обучение завершенным (статус завершенности у всех доступных упражнений)
}
