import { createApi } from '@reduxjs/toolkit/query/react';
import { getBaseQuery } from 'common/helpers';
import { IAuthType } from 'types/requests/guest/auth-type.api';
import { authTypeSlice } from './auth-type.slice';

export const authTypeApi = createApi({
  reducerPath: 'authTypeApi',
  baseQuery: getBaseQuery(),
  endpoints: ({ query }) => ({
    getAuthType: query<IAuthType.Response, IAuthType.Params>({
      query: () => '/api/Login/AuthType',
      onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
        try {
          const { data } = await queryFulfilled;
          dispatch(authTypeSlice.actions.setAuthType(data.authType));
        } catch (e) {
          console.log(e);
        }
      }
    }),
    checkExistAccount: query<void, void>({
      query: () => '/api/account/CheckExistAccount'
    })
  })
});
export const { useLazyGetAuthTypeQuery, useCheckExistAccountQuery } = authTypeApi;
