import { FC } from 'react';
import { Button } from 'common/ui/_new';
import { useExitAccountMutation } from 'api/student/profile/profile.api';

export const LogOutButton: FC = () => {
  const [exitAccount] = useExitAccountMutation();

  const exit = () => {
    exitAccount()
      .unwrap()
      .then(() => window.location.reload());
  };

  return <Button icon='log-out' type='icon-only' onClick={exit} />;
};
