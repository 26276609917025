import { FC } from 'react';
import { Icon, ImgCrop, ImgCropProps, Upload } from 'common/ui/_new';

import Quill from 'quill/core';

type ImageInsertButtonProps = {
  quill: Quill;
};

export const ImageInsertButton: FC<ImageInsertButtonProps> = ({ quill }) => {
  const insertToEditor: ImgCropProps['onModalOk'] = (file) => {
    quill.focus();
    const range = quill.getSelection();
    const reader = new FileReader();
    reader.readAsDataURL(file as Blob);
    reader.onload = () => {
      if (range) quill.insertEmbed(range.index, 'image', reader.result);
    };
  };

  return (
    <ImgCrop onModalOk={insertToEditor}>
      <Upload maxCount={1} showUploadList={false} customRequest={() => null} className='upload'>
        <Icon type='editor-img' className='img' />
      </Upload>
    </ImgCrop>
  );
};
