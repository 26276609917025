import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ExerciseId, LessonId } from 'types/entities';

export type IVisitJournalState = {
  lessonId: LessonId[];
  exerciseId: ExerciseId[];
};

const initialState: IVisitJournalState = {
  lessonId: [],
  exerciseId: []
};

export const visitJournalSlice = createSlice({
  name: 'visitJournal',
  initialState,
  reducers: {
    setLessonId: (state, action: PayloadAction<LessonId>) => {
      if (action.payload) {
        state.lessonId = [action.payload];
      } else {
        state.lessonId = [];
      }
    },
    setExerciseId: (state, action: PayloadAction<ExerciseId>) => {
      if (action.payload) {
        state.exerciseId = [action.payload];
      } else {
        state.exerciseId = [];
      }
    }
  }
});

export const { setLessonId, setExerciseId } = visitJournalSlice.actions;
export default visitJournalSlice.reducer;
