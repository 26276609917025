import { AttributeFilter } from 'common/components';
import { Option, StudentSearchData } from 'types/entities';

const getSearchAttributesValues = (
  attributeValue: boolean | string | Option['id'] | undefined | NonNullable<Option['id']>[]
): string[] | undefined => {
  if (Array.isArray(attributeValue)) {
    return attributeValue;
  }

  if (typeof attributeValue === 'string') {
    return [attributeValue];
  }

  if (typeof attributeValue === 'boolean') {
    return [String(attributeValue)];
  }

  return;
};

export const convertAttributesFiltersToStudentSearchData = (attributesFilters: AttributeFilter[] | undefined): StudentSearchData => {
  return {
    attributes: attributesFilters?.map(({ attribute, attributeValue }) => ({
      id: attribute,
      values: getSearchAttributesValues(attributeValue)
    }))
  };
};

const getAttributeFilterValue = (values: string[] | undefined): string[] | string | boolean | null | undefined => {
  if (values === undefined || values === null) {
    return;
  }

  if (values.length > 1) {
    return values;
  }

  const value = values[0];
  if (value === 'true') {
    return true;
  }
  if (value === 'false') {
    return false;
  }

  return value;
};

export const convertStudentSearchDataToAttributesFiltersValues = (
  searchAtributes: StudentSearchData | undefined
): AttributeFilter[] | undefined => {
  return searchAtributes?.attributes?.map(({ id, values }) => ({
    attribute: id,
    attributeValue: getAttributeFilterValue(values)
  }));
};
