import { IDictionaryRecord } from 'types/entities';

import EnCommon from 'i18n/en/common.json';

// temporal - BEGIN
export const timeZoneDictionaryId = '312E93BC-5B7C-4707-B44C-715788F1AB1D';

export const isTimeZonesDictionary = (dictionaryId: string) => dictionaryId === timeZoneDictionaryId;

const devTimeZoneCodeByIdMap: Record<IDictionaryRecord['id'], keyof (typeof EnCommon)['components']['time-zones-select']['options']> = {
  '2212fb34-3916-47f7-9fc0-51fcbf56bd9e': 'utc',
  'bf04b3c5-d818-4bb6-b9e8-837b077c693a': 'dateline-standard-time',
  '28dffea0-b538-4bee-b800-89a8181685f6': 'utc-11',
  '987be935-032e-405f-9007-cda0e6b0f045': 'hawaiian-standard-time',
  'ec9477ab-4d72-4adb-9f10-3a4446df5294': 'aleutian-standard-time',
  '4ea86df1-62cb-4ba4-aedf-8ea21a41d0b6': 'marquesas-standard-time',
  '78238451-d518-4be3-9ac6-e70079518ee2': 'utc-09',
  '22d9ed4a-dc4c-4229-a5d6-a1e4432b67f3': 'alaskan-standard-time',
  '8f32c26d-3d6d-4b55-822b-aadf222e21ff': 'pacific-standard-time',
  'a4c5832d-6312-4409-adba-9a7f25ea4068': 'pacific-standard-time-(mexico)',
  'd7ff05c8-8a73-4792-a071-b82691509583': 'utc-08',
  '9f4b2274-fee6-458a-8ccd-39b64c053e21': 'yukon-standard-time',
  '2f28cfaa-9a5c-4961-a0d6-504f97b305d6': 'mountain-standard-time-(mexico)',
  'b9829149-450e-44b4-8a0b-9d321264d5e7': 'mountain-standard-time',
  '522795cb-e1c3-4dc2-8f82-8988f55b14f3': 'us-mountain-standard-time',
  '97e7fb13-329e-4384-ab61-d10648457c5e': 'central-standard-time',
  'df398ccb-108f-4a70-94fa-42203fd86a1b': 'central-america-standard-time',
  '867bb954-b002-4a44-ada2-e5f776863cbb': 'canada-central-standard-time',
  '3f69e119-cdfc-409e-9e4d-86570aa07248': 'easter-island-standard-time',
  '06a105c8-1d71-4f9d-b7e5-d9d882409cd0': 'central-standard-time-(mexico)',
  '821cee93-7bc0-40da-96f2-e13b26719ed9': 'eastern-standard-time-(mexico)',
  '2f38c635-30d7-4346-a56e-331ff19f0702': 'turks-and-caicos-standard-time',
  '87706e92-d695-4509-871d-1937c55092d8': 'us-eastern-standard-time',
  '4ae81f7f-7990-4497-b2a2-6a315838a120': 'haiti-standard-time',
  'd415ee5c-6765-437c-b542-88f68f5f733a': 'cuba-standard-time',
  '0ab88522-d911-4a70-94bf-0c844928d3c8': 'eastern-standard-time',
  '3f7de81f-f32f-4f97-af87-21556735af43': 'sa-pacific-standard-time',
  '3f18dbdb-db29-4835-a376-41d9675cd101': 'pacific-sa-standard-time',
  '9745acf5-a596-4140-b4b8-b9e9e6b3fc14': 'central-brazilian-standard-time',
  '4b53ad8d-5e59-4f3d-8f4f-f7d9a550af2a': 'venezuela-standard-time',
  '26470329-5133-42fa-8db4-2ddc75976f0a': 'sa-western-standard-time',
  '910ea3e3-2cd5-4f3a-970f-23fc4575522f': 'atlantic-standard-time',
  'f42fe422-9d39-4f7f-8075-d317d901f66e': 'paraguay-standard-time',
  '5328e64a-f8cf-4270-b534-7dacf69bea6b': 'newfoundland-standard-time',
  '4f86599d-f09b-4429-a41d-35b09254873f': 'saint-pierre-standard-time',
  '927926ef-27b1-4365-9f48-74b0eec41346': 'bahia-standard-time',
  '680fb222-c033-4ae3-937b-f4e667229186': 'magallanes-standard-time',
  '70dde414-9fe3-42af-8005-33b79356db01': 'montevideo-standard-time',
  'cb616c87-e69a-46bf-a734-92d522726bd0': 'sa-eastern-standard-time',
  '955001f7-29f3-4878-9dc7-e0d21993ce3f': 'greenland-standard-time',
  'b7114b92-35b1-4958-a5cb-a6e1be5e4f67': 'argentina-standard-time',
  '90fd564a-63b8-487d-a6e1-5b8616b3d2af': 'e.-south-america-standard-time',
  'c334e326-97f2-4aa4-bd45-9dfd7e9a9b80': 'tocantins-standard-time',
  '01ba0685-1a0f-457b-be0d-c7cb4bcfe2fb': 'utc-02',
  'd8cdc4e0-48a5-406f-be48-99e81bb1bd8a': 'cape-verde-standard-time',
  '308c2f1d-aab8-423b-b2a5-4ef5295b4b96': 'azores-standard-time',
  'aa21127b-bb74-4cee-8c97-dc91a2a203f7': 'gmt-standard-time',
  '9437ec30-8c6c-4ad4-8884-8f33fca017c9': 'greenwich-standard-time',
  'd51d9c40-f6e2-49b1-a5d7-c73dcd226b92': 'sao-tome-standard-time',
  '362cb59a-0da9-46cc-9480-20d1104b0e56': 'w.-europe-standard-time',
  '911d5df6-153c-4c63-ad43-632a2e8a036e': 'central-europe-standard-time',
  '10ac9baa-f363-43e3-90fa-7d5b8271578c': 'romance-standard-time',
  '54c68835-f72f-4dd8-82c3-f27dc40c195a': 'central-european-standard-time',
  'f6808a0e-7442-445f-b672-a208be780bb6': 'w.-central-africa-standard-time',
  'c009b4aa-495d-4341-92c7-f69a53cdee45': 'morocco-standard-time',
  '244932d2-d022-43ae-aeaf-32f0b99499a5': 'jordan-standard-time',
  '566a7dd4-b788-45d1-b878-afa95cabfc8f': 'gtb-standard-time',
  '56de9afc-a606-412b-ad03-bfe45418c407': 'middle-east-standard-time',
  '1218ede1-e1f2-487a-94e5-c274f04ab479': 'fle-standard-time',
  '2861dc86-9ce0-4f98-bb29-61a1402c680a': 'namibia-standard-time',
  '5ed1a272-926d-4c4c-b01c-714431c8f5c6': 'syria-standard-time',
  '781b4fe5-73f8-46b4-99f2-2587439f1764': 'israel-standard-time',
  '7360196d-c435-4650-baa5-fd1825cf2f8b': 'egypt-standard-time',
  'b828d07c-4b09-4cc3-88cb-a4dc7270e0ea': 'kaliningrad-standard-time',
  'd38ed0fa-c1ca-4bf3-8c07-bec67b268e1e': 'e.-europe-standard-time',
  'fa215b98-9596-4b34-87f2-8accfe4ffde8': 'west-bank-standard-time',
  '9aba5f8f-707f-4ac0-93df-096a8739bc5d': 'libya-standard-time',
  '6e9b49ba-672a-4f5f-a3d0-af997c517b82': 'south-africa-standard-time',
  '1b37a49e-cf23-4e68-be41-1d0dd2160d97': 'sudan-standard-time',
  'ebcd7bb3-a971-437d-a558-3369b10b61ec': 'arabic-standard-time',
  '83590a2a-9d80-438a-a977-7a5df087e9c1': 'arab-standard-time',
  '4633cccc-7ed7-4d78-89e0-7ad72729cdd9': 'belarus-standard-time',
  'd375c973-6084-4ed9-b378-be7bde5cf9fa': 'russian-standard-time',
  '1ba7beb8-b136-4129-a187-bd4131e7bac2': 'e.-africa-standard-time',
  'd9f60941-4e31-436b-bdbd-6d3fbd5d486f': 'turkey-standard-time',
  'ff089cbe-e063-43a3-bd24-58e83ad7c48f': 'iran-standard-time',
  '39605c41-d399-49d4-be7f-70dd2bc496fa': 'arabian-standard-time',
  'e695c9e9-26ac-49bf-8320-7eee28e624e0': 'astrakhan-standard-time',
  '742438ea-49d6-4668-b4cc-b1e5fdc453b7': 'azerbaijan-standard-time',
  '1b934bb8-c9d3-43ae-8bf4-dc6edddcea70': 'volgograd-standard-time',
  '326dc09a-8e8b-411a-86f2-d5f28b74f5ae': 'caucasus-standard-time',
  '994d121c-77fa-44a0-8023-1ba9ae343230': 'russia-time-zone-3',
  '30bde9ae-e24e-4572-a421-823e53ba79fc': 'mauritius-standard-time',
  '39d9a748-aacc-4b52-820a-5fcc13c67393': 'saratov-standard-time',
  '60eb66a0-4fab-4612-9ca8-9c699d0f5047': 'georgian-standard-time',
  'a3719f51-efd7-48d9-838a-49ebf74a788a': 'afghanistan-standard-time',
  'cb4e91d7-b20b-4d0f-a062-2f14c5790997': 'west-asia-standard-time',
  '25815303-3b93-4c70-83c6-10fb552d6a0f': 'ekaterinburg-standard-time',
  '7ca9ef65-c25f-4697-808b-c13a28bbb7bc': 'pakistan-standard-time',
  'c6744219-4874-4c8f-ac5f-2df8bd0eebbe': 'qyzylorda-standard-time',
  'e0af8b78-cee1-4c59-81de-5eb009e26d58': 'india-standard-time',
  'd3e8ca53-62c1-4407-8cda-c68a023b506d': 'sri-lanka-standard-time',
  'd62254f8-7fa9-46a3-a4f1-003f33fa1724': 'nepal-standard-time',
  '1ba89512-8e29-4582-aa89-95d79c232a45': 'central-asia-standard-time',
  'a314f1a9-ea65-4f1c-8ec7-ca1736bd31ea': 'bangladesh-standard-time',
  '80aaa6b1-d752-48e5-81a4-66260e39aae4': 'omsk-standard-time',
  '8f43afe0-fa14-4e3b-a8ca-4e11ef92a283': 'myanmar-standard-time',
  '14c60109-5965-4570-9d47-5df8607df453': 'se-asia-standard-time',
  '0548d7e5-2f40-46ee-a790-9c15faaa3693': 'altai-standard-time',
  'c1c21615-2cfd-4741-94a1-5ee2b6d4f70d': 'north-asia-standard-time',
  'c8ea01f6-3dcb-452e-b45a-dd9d52bc24ac': 'n.-central-asia-standard-time',
  '74e88b24-d398-4178-b212-b1aaed2537cf': 'tomsk-standard-time',
  'a46c55d7-1634-4d30-ba5d-0e59b13197f6': 'w.-mongolia-standard-time',
  '77fdfd82-4280-4387-b18a-e6055d19d912': 'china-standard-time',
  '13e420de-df85-4ac8-bd4c-dcb994e2494c': 'north-asia-east-standard-time',
  '2a187431-1b1b-4fd7-965c-a9206f00ce74': 'singapore-standard-time',
  '675193de-9f24-4901-84b5-3cdf04f0ceff': 'w.-australia-standard-time',
  '7eea3d0a-c7d1-4deb-893b-4e87b4746f54': 'taipei-standard-time',
  '4508385d-8f63-40dd-8a99-a5532995fdc0': 'ulaanbaatar-standard-time',
  'ddee3996-160f-4775-9542-fdccdeab826f': 'aus-central-w.-standard-time',
  '6bb135f2-7ed1-4c77-afce-ffdbea11f3c0': 'tokyo-standard-time',
  '0068a678-cada-4f32-8c24-0ff4bdec524c': 'north-korea-standard-time',
  'bc7e19e1-65ac-4cb3-be5a-29dfaa242ac9': 'korea-standard-time',
  '52f832ca-231b-46bb-a00f-8091df88f7ea': 'transbaikal-standard-time',
  '0affe156-54f9-48b1-bc5b-db740e6ccdcd': 'yakutsk-standard-time',
  '27e3d059-ca59-40be-93a1-debf4575f1be': 'cen.-australia-standard-time',
  '20f8cb57-cc4c-408f-8ab4-d67994a1792f': 'aus-central-standard-time',
  'c0f4a784-1271-4cd1-a16b-e5e87e5f4679': 'e.-australia-standard-time',
  'ad8a7016-547a-45df-805e-99056c0e863a': 'vladivostok-standard-time',
  'd82b3cba-34dc-40cb-925d-95e9d980a387': 'west-pacific-standard-time',
  'f621cd9f-9b95-4934-9ec5-ba049fc37920': 'aus-eastern-standard-time',
  'b50f85e3-7475-48a9-8557-86de4ceba511': 'tasmania-standard-time',
  '41a8c5fb-a835-4e28-ad35-1a03092ad6fb': 'lord-howe-standard-time',
  '42f5e0b3-7bba-455f-945c-642b926bf388': 'magadan-standard-time',
  '42fe238d-85f0-4c4f-9c9a-a5d412e0552f': 'bougainville-standard-time',
  'b4abc13e-b981-4976-8f5f-c8e1a0e7887d': 'norfolk-standard-time',
  'b0ae3ef8-6e4e-4059-aaa6-31e60bd6c15a': 'sakhalin-standard-time',
  'c71b731d-5a87-40f0-9a9d-0c95a2ad79d6': 'central-pacific-standard-time',
  '486e933e-9796-4861-9dcb-255c9fdd963d': 'russia-time-zone-10',
  'bc4ce9c1-9a34-451a-a137-5436839c6ea1': 'russia-time-zone-11',
  '48e062ba-d526-4672-a8ee-39c6f32b7252': 'new-zealand-standard-time',
  '5846c611-a1f1-400b-b661-23c6de8340a8': 'utc+12',
  '88a19343-ac2e-4cdc-a6ac-457280fdaaf1': 'fiji-standard-time',
  '3534c875-6582-4533-81c3-e36264ec987b': 'chatham-islands-standard-time',
  '59a1160b-d84e-4ce6-ae79-1a0f7eb3006f': 'utc+13',
  '0feb3891-992d-47da-971c-d76e82991b77': 'tonga-standard-time',
  '0f7497b8-b424-42d6-a749-645da6bd3f9f': 'samoa-standard-time',
  'cdceebf8-7dcf-4abf-b8ea-babc499daee2': 'line-islands-standard-time'
};

const prodTimeZoneCodeByIdMap: Record<IDictionaryRecord['id'], keyof (typeof EnCommon)['components']['time-zones-select']['options']> = {
  '6431e0bb-344e-4c4f-b63d-cad1a511dbe1': 'utc',
  '0c666ad1-b118-46c5-8ca0-c507597a8cfb': 'dateline-standard-time',
  '7c51a9c4-5e3f-422c-b031-f7997cf085f3': 'utc-11',
  'dd26732e-222e-4d25-b57d-96c7fc016544': 'hawaiian-standard-time',
  '81e17eab-4642-4263-bc52-cd2dc79013fb': 'aleutian-standard-time',
  '9c45868c-5e41-436f-a6e3-145d70702dca': 'marquesas-standard-time',
  '0f9d1962-e413-4f9a-93fa-1f7c5398d683': 'utc-09',
  'b3bf2b34-dd5c-4314-8ae8-0eb46846cc9c': 'alaskan-standard-time',
  '6e37b40e-10f3-4f74-9c2e-2717a65a090d': 'pacific-standard-time',
  '8af2dd89-bc0f-495c-90aa-8c65649ff195': 'pacific-standard-time-(mexico)',
  '80a64418-3fd9-48b4-900e-5cf0050505b4': 'utc-08',
  'e2aef2d1-2beb-4b14-a104-ec0309771e58': 'yukon-standard-time',
  'f5542feb-a5cd-406c-92c1-ad6f66714358': 'mountain-standard-time-(mexico)',
  'e9fef45d-4407-43a4-b930-c731f5d6ccba': 'mountain-standard-time',
  '91176b2b-37c4-44ac-87be-71fe59d51a62': 'us-mountain-standard-time',
  'ffd9f281-679e-4ff9-8aee-019acb553b10': 'central-standard-time',
  '6b6b87fb-ea91-475b-b847-888c87ebe9c5': 'central-america-standard-time',
  'a8ac70c9-834d-4e1a-8b05-1960fc931e2c': 'canada-central-standard-time',
  '1daa2e53-b1ba-4326-a2b1-6ada85a36da9': 'easter-island-standard-time',
  'ac8c3ed5-1004-44d7-8b20-3953bae990fc': 'central-standard-time-(mexico)',
  '1f947b5f-1d04-48ae-99f3-363a4703fa27': 'eastern-standard-time-(mexico)',
  'b8915430-1e76-41db-a900-1d11d966549b': 'turks-and-caicos-standard-time',
  '559f0fe9-4367-46fb-8237-04acfde5d18a': 'us-eastern-standard-time',
  'f1dcb916-8f2e-4789-b803-862f6a83546d': 'haiti-standard-time',
  'af2a323e-a3d8-4542-9858-4d03631c163a': 'cuba-standard-time',
  '595efeab-d5ea-4d91-8b2a-84b590c3a1db': 'eastern-standard-time',
  '2cd66fb0-3afb-4364-9196-45c93fee0e02': 'sa-pacific-standard-time',
  'd20c1b39-493a-4bd2-99ab-447eedcf29f4': 'pacific-sa-standard-time',
  '417ea739-a32c-41c1-84f6-17d9cf9221ea': 'central-brazilian-standard-time',
  'a640b087-02a6-48c7-88b8-875f2dd21de0': 'venezuela-standard-time',
  '02afa4ac-f20c-4474-9b91-d417b5b90c7c': 'sa-western-standard-time',
  '75855e48-b5e5-4d0d-9ffe-3ebd7b21569c': 'atlantic-standard-time',
  '147afac7-8d84-4b49-b3f7-5e86b812c97e': 'paraguay-standard-time',
  '8721b6f4-3a81-4e28-ab11-a047f42eee03': 'newfoundland-standard-time',
  'a542f4e3-0f7e-4392-bdb0-31c85228dac6': 'saint-pierre-standard-time',
  'efa95c05-70a4-404a-985b-e72fc820a979': 'bahia-standard-time',
  'e09cae44-8bca-462e-93e2-3c1c1ea8b8be': 'magallanes-standard-time',
  'e671393e-847a-40b0-8daf-3c7cf1accb49': 'montevideo-standard-time',
  'b9245752-192c-4257-b129-86c903c2893f': 'sa-eastern-standard-time',
  '7301a05e-a07b-456e-a253-8a55b3ca7e08': 'greenland-standard-time',
  '174b2a4a-543c-47a1-8b82-d32feafb0a85': 'argentina-standard-time',
  '9500c759-4866-4900-ba91-38758c93a7a8': 'e.-south-america-standard-time',
  '033b114e-b7d2-4aee-823f-09b245866596': 'tocantins-standard-time',
  'c4b99690-0089-4474-a803-e34c08bf2385': 'utc-02',
  '2be3733e-b4e1-41eb-af42-3573c39781bc': 'cape-verde-standard-time',
  '7f6fc824-31f4-4217-8cd0-a7c70e425472': 'azores-standard-time',
  '2cb529c6-abad-461d-be09-18789f22d68d': 'gmt-standard-time',
  '30a260ca-c9bc-4529-8258-2915742ba47d': 'greenwich-standard-time',
  'a57e84b5-a6a7-4cdb-bf53-74776146f602': 'sao-tome-standard-time',
  'f50a6579-9a4e-4ea4-8e1e-2f6e785c1966': 'w.-europe-standard-time',
  '86825bb7-97de-4d3d-a185-b140d0c06521': 'central-europe-standard-time',
  'd900e722-c3cc-4dfa-8586-d30a8a697d1f': 'romance-standard-time',
  '370f7d47-ac81-4fd6-9c37-5bc18e778e41': 'central-european-standard-time',
  '4c9e06ae-dea7-489b-85fc-cbf04a91a9c3': 'w.-central-africa-standard-time',
  '0de76972-81e0-4967-8866-fffe13ab864b': 'morocco-standard-time',
  '3b65ff3d-8cf3-4fd8-8736-4183d74a7829': 'jordan-standard-time',
  '93bbcd4e-9ab0-4aca-ab75-7995abd62c4f': 'gtb-standard-time',
  'aafc366c-3e6d-4498-a7e8-361814c95097': 'middle-east-standard-time',
  '19f3f13f-a77f-4372-a3e7-707b859efb18': 'fle-standard-time',
  '58b22ce6-b769-44b4-a5f8-e60b2595f87d': 'namibia-standard-time',
  'c0bb34aa-e646-4769-9412-63ede5793133': 'syria-standard-time',
  '9d16d430-7f3c-44e0-b7b5-0058108fdf64': 'israel-standard-time',
  'a2397b4b-aea1-445e-a449-c88a1bd990a3': 'egypt-standard-time',
  '6d473f53-f781-4f38-917b-e80c29a5f45d': 'kaliningrad-standard-time',
  '784e8bd4-e43e-4f8b-852b-8ed6ffd96c7d': 'e.-europe-standard-time',
  'f0dafbed-d4ff-49b5-87f0-44878481e29f': 'west-bank-standard-time',
  '4d9e2830-3813-4780-b405-fb6ebfafcd0e': 'libya-standard-time',
  'f2c1bc4e-f70b-46bc-a549-46d87b4f9df0': 'south-africa-standard-time',
  'f210a10d-b1e2-4a59-8e13-cc0bb0e2e190': 'sudan-standard-time',
  '6cf39dac-0bf4-4526-ab68-105b1619c8d2': 'arabic-standard-time',
  '091d23f1-234f-4ee1-93a9-7fb6df2a5db0': 'arab-standard-time',
  '0340f43a-2581-45fa-bce8-b4755c7685fa': 'belarus-standard-time',
  '283bcaa9-3456-47cd-99db-7a1ee37d0389': 'russian-standard-time',
  '56a374a7-ca37-49cf-95e8-f597328ba5c0': 'e.-africa-standard-time',
  'f3d62150-555b-48eb-bf5c-2b6b8c9412fb': 'turkey-standard-time',
  'd24dc214-e8fe-41c6-979a-a0efbd7e3063': 'iran-standard-time',
  'ab50ea73-f1e6-4324-93bd-e02c9d49ef6b': 'arabian-standard-time',
  '293ebfd8-7a6d-4d7a-99cb-173c0829c97c': 'astrakhan-standard-time',
  '61101ad5-ee60-44cf-8732-d1a9d0fe9932': 'azerbaijan-standard-time',
  '94a48335-b73d-4f93-b85d-50d1f2a2bd72': 'volgograd-standard-time',
  'b6fada03-fe1d-4044-a02e-ee0ef04a6911': 'caucasus-standard-time',
  '3da5485e-c0ba-4f9b-bc38-bf74a218b512': 'russia-time-zone-3',
  'bb45f7d0-9114-4eeb-8760-b98d44681a2b': 'mauritius-standard-time',
  '017f000e-a9e3-488e-b6d8-785c33f75f60': 'saratov-standard-time',
  '5821ef95-7321-4c9a-ae45-9069a620a5d1': 'georgian-standard-time',
  '1f269744-79a4-4455-88c2-b21c9fef314b': 'afghanistan-standard-time',
  '7d652e5d-fb51-472e-a3df-e362ac6c2a29': 'west-asia-standard-time',
  '07c2e33b-c224-46bc-a8f5-c709a713c23a': 'ekaterinburg-standard-time',
  '2d3b6c52-5d5e-4e42-8819-0241b4373c75': 'pakistan-standard-time',
  '6e3e8ba7-a5b3-4c61-9e7e-daba60595e2e': 'qyzylorda-standard-time',
  '0926c2b4-1eb5-4df8-b444-b6b7522b029e': 'india-standard-time',
  '2e59c8d9-b336-45a6-ac12-52ea710e06a0': 'sri-lanka-standard-time',
  '3836531d-c3e8-4c44-8848-649844330181': 'nepal-standard-time',
  'dc1f6aba-2c96-40c1-a4a8-6948b950a4db': 'central-asia-standard-time',
  '4fff55c6-ca17-46b5-9916-270a4078720f': 'bangladesh-standard-time',
  '74fcefb7-bdce-4297-b80d-19bc0a7617cc': 'omsk-standard-time',
  'c71d75f3-5939-4fd1-a4bc-e0c16de0065d': 'myanmar-standard-time',
  '1d168635-aad6-4c26-b1af-1cc44c823da5': 'se-asia-standard-time',
  '3ff69fcf-7c0c-4de1-b7ee-fc0c1ce43ac7': 'altai-standard-time',
  '6804e1cc-0ab6-41c1-8647-035bc61d0213': 'north-asia-standard-time',
  'ab3e328a-2230-46a9-b718-90737b2e4505': 'n.-central-asia-standard-time',
  '7dfb9f64-9a82-4571-8343-6bdcdae4d6b1': 'tomsk-standard-time',
  '7e254ff7-5e73-4a94-aa6a-3c24470892e0': 'w.-mongolia-standard-time',
  '78e499c4-979c-42df-a3e5-6a5aceeb7726': 'china-standard-time',
  '98a5339f-9498-41b3-bf93-f6515d7a1bee': 'north-asia-east-standard-time',
  '5a289ef1-8e0e-44a5-8312-2a26fccc8a4a': 'singapore-standard-time',
  '5c028d74-c242-4acd-949d-cc1480e3ae6e': 'w.-australia-standard-time',
  'd59cd28e-cfb9-4563-bd5c-ed549cdc2700': 'taipei-standard-time',
  'e691b0c0-330b-4b46-805d-78082d7a7008': 'ulaanbaatar-standard-time',
  'c2eb8161-e011-4e36-89b5-1d9a416338f1': 'aus-central-w.-standard-time',
  '2a76f9b7-7112-406c-8c16-c5da2d9b431a': 'tokyo-standard-time',
  'c87cb399-e4e8-4435-b973-98f648d4ad15': 'north-korea-standard-time',
  '411cfc65-7821-459c-9e51-00af346717d1': 'korea-standard-time',
  '41dd7bba-e00e-492b-8e72-143d97c0a4ad': 'transbaikal-standard-time',
  'ca4f4916-e2bb-4192-b6eb-8fa68a93834b': 'yakutsk-standard-time',
  'e7d95d0a-fb1f-4b3b-a01e-f7b862f61f7a': 'cen.-australia-standard-time',
  'eb3c0ba4-dc4c-40f6-a0c3-64289fa17b90': 'aus-central-standard-time',
  'b0cda28f-8598-4a93-9b8b-609d2f5ff4da': 'e.-australia-standard-time',
  '972fe905-7db8-4573-aae5-412a21b1baf0': 'vladivostok-standard-time',
  '6a325c4a-3af4-4742-8599-71c87c497c7a': 'west-pacific-standard-time',
  'a113b4e9-6d2e-4fef-871a-b8efd8284bc1': 'aus-eastern-standard-time',
  'bd534191-972a-46b8-8f86-aa79c778f7c4': 'tasmania-standard-time',
  '2bd06c53-dd56-409a-8681-a9597ef0450b': 'lord-howe-standard-time',
  '66d5bd5a-8922-4ca0-98c1-0faec83a0255': 'magadan-standard-time',
  '444254b3-9c33-4fc1-b07e-6b06361a6ae3': 'bougainville-standard-time',
  'd48d8969-94f7-4d89-af6d-5ec8e24f1c93': 'norfolk-standard-time',
  '9b228906-01b3-44d1-9948-b190b113e022': 'sakhalin-standard-time',
  '91be706e-def1-42a3-b720-b73a451b7f45': 'central-pacific-standard-time',
  'e45f32f1-32c6-4026-9373-0e42fd8839fa': 'russia-time-zone-10',
  '53020134-ce39-4f67-87e6-12752cedcb4c': 'russia-time-zone-11',
  '03ee735c-c5dd-49ba-9502-e18066e1c0e5': 'new-zealand-standard-time',
  '68cfc134-0184-410d-88b9-7cc42061d4bd': 'utc+12',
  '637127e8-2a53-4b5e-b633-4e91a722192c': 'fiji-standard-time',
  'f4a91a1d-b1ec-465b-b581-63a4c03dd23a': 'chatham-islands-standard-time',
  'ba1232d8-26f3-4321-a9a1-8eaf06aa2184': 'utc+13',
  '7528c13d-c539-410a-9235-19de4f0bea24': 'tonga-standard-time',
  'c1001818-314e-44ce-b6bd-5acb279c8fb9': 'samoa-standard-time',
  'b94eb340-5830-4133-bfea-c027f123db28': 'line-islands-standard-time'
};

export const timeZoneCodeByIdMap =
  process.env.REACT_APP_OLD === 'https://ooc-lms-app-test.geocode.tech' ? devTimeZoneCodeByIdMap : prodTimeZoneCodeByIdMap;

export const sortByZone = (a: string, b: string) => {
  const [ahh, amm = 0] = a.split('UTC')[1].split(')')[0].split(':');
  const [bhh, bmm = 0] = b.split('UTC')[1].split(')')[0].split(':');
  const atime = Number(ahh) * 60 + Math.sign(Number(ahh)) * Number(amm);
  const btime = Number(bhh) * 60 + Math.sign(Number(bhh)) * Number(bmm);
  return atime - btime;
};
// temporal - END
