import type { IEditExternalSlide, IExternalSlide, ISaveExternalSlide } from 'types/requests/instructor/slides.slide_external-question.api';
import { slidesApi } from '../slides.api';

const slideExternalApi = slidesApi.injectEndpoints({
  endpoints: ({ query, mutation }) => ({
    // API Создание страницы типа External.
    saveExternalSlide: mutation<ISaveExternalSlide.Response, ISaveExternalSlide.Params>({
      query: ({ exerciseId, body }) => ({
        url: `/api/instructors/Exercises/${exerciseId}/Pages/External`,
        method: 'POST',
        body
      }),
      invalidatesTags: ['Slides', 'External-Question']
    }),
    // API получения страницы типа External.
    getExternalSlide: query<IExternalSlide.Response, IExternalSlide.Params>({
      query: ({ exerciseId, pageId }) => `/api/instructors/Exercises/${exerciseId}/Pages/${pageId}/External`,
      providesTags: ['External-Question']
    }),
    // API Редактирование страницы типа External.
    editExternalSlide: mutation<IEditExternalSlide.Response, IEditExternalSlide.Params>({
      query: ({ exerciseId, pageId, body }) => ({
        url: `/api/instructors/Exercises/${exerciseId}/Pages/${pageId}/External`,
        method: 'PUT',
        body
      }),
      invalidatesTags: ['Slides', 'External-Question']
    })
  })
});

export const { useEditExternalSlideMutation, useGetExternalSlideQuery, useSaveExternalSlideMutation } = slideExternalApi;
