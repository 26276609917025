import { ISlideObject } from 'types/common';

import { i18n } from 'i18n/config';

export const SLIDES_CONFIG: ISlideObject[] = [
  {
    id: '0',
    label: i18n.t('common:constants.slides-config.content'),
    type: 'content'
  },
  {
    id: '1',
    label: i18n.t('common:constants.slides-config.answers'),
    type: 'answers'
  },
  {
    id: '2',
    label: i18n.t('common:constants.slides-config.free'),
    type: 'free'
  },
  {
    id: '3',
    label: i18n.t('common:constants.slides-config.editable_answers'),
    type: 'editable_answers'
  },
  {
    id: '4',
    label: i18n.t('common:constants.slides-config.editable_free'),
    type: 'editable_free'
  },
  {
    id: '5',
    label: i18n.t('common:constants.slides-config.attributes'),
    type: 'attributes'
  },
  {
    id: '6',
    label: i18n.t('common:constants.slides-config.audio'),
    type: 'audio'
  },
  {
    id: '7',
    label: i18n.t('common:constants.slides-config.video'),
    type: 'video'
  },
  {
    id: '8',
    label: i18n.t('common:constants.slides-config.kinescope'),
    type: 'kinescope'
  },
  {
    id: '9',
    label: i18n.t('common:constants.slides-config.web'),
    type: 'web'
  },
  {
    id: '10',
    label: i18n.t('common:constants.slides-config.file'),
    type: 'file'
  },
  {
    id: '11',
    label: i18n.t('common:constants.slides-config.picture'),
    type: 'picture'
  },
  {
    id: '12',
    label: i18n.t('common:constants.slides-config.external'),
    type: 'external'
  },
  {
    id: '13',
    label: i18n.t('common:constants.slides-config.evaluation'),
    type: 'evaluation'
  },
  {
    id: '14',
    label: i18n.t('common:constants.slides-config.pdf'),
    type: 'pdf'
  },
  {
    id: '15',
    label: i18n.t('common:constants.slides-config.pptx'),
    type: 'pptx'
  }
];
