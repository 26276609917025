import { forwardRef } from 'react';
import { Form as AntForm } from 'antd';
import cn from 'classnames';
import { Item } from './Item';
import { IForm, IInternalForm } from './types';
import styles from './styles.module.scss';

const staticOverridableProps: React.ComponentProps<typeof AntForm> = {
  layout: 'vertical',
  scrollToFirstError: true
};

export const InternalForm: IInternalForm = forwardRef(function InternalForm({ className, children, ...outerProps }, ref) {
  const overridableProps: React.ComponentProps<typeof AntForm> = {
    ...staticOverridableProps
  };

  const unoverridableProps: React.ComponentPropsWithRef<typeof AntForm> = {
    ref,
    className: cn(styles.form, className)
  };

  return (
    <AntForm {...overridableProps} {...outerProps} {...unoverridableProps}>
      {children}
    </AntForm>
  );
});

export const Form: IForm = Object.assign(InternalForm, {
  useForm: AntForm.useForm,
  useFormInstance: AntForm.useFormInstance,
  useWatch: AntForm.useWatch,
  Item,
  List: AntForm.List,
  ErrorList: AntForm.ErrorList,
  Provider: AntForm.Provider
});
