import { FC, HTMLAttributes, PropsWithChildren } from 'react';
import cn from 'classnames';
import styles from './styles.module.scss';

export type SettingItemLayoutProps = {
  label: string;
} & PropsWithChildren &
  Pick<HTMLAttributes<HTMLDivElement>, 'className'>;

export const SettingItemLayout: FC<SettingItemLayoutProps> = ({ label, className, children }) => {
  return (
    <div className={cn(styles.item, className)}>
      <span className={styles.label}>{label}</span>
      {children}
    </div>
  );
};
