import { FC, useState } from 'react';
import { IInternalFormProps, Spin } from 'common/ui/_new';
import { useAppSelector } from 'common/hooks';
import { useCheckAttributeForChainResultsMutation } from 'api/student/attributes/attributes.api';
import { profileSelectors } from 'api/student/profile/profile.selectors';
import { ChainConditionAttributeId, ChainConditionTriggerValue } from 'types/entities';
import {
  addResultAttributesToChainInMap,
  deleteResultAttributesFromChainInMap,
  extendResultAttributesWithChainProps,
  updateCurrentAttributes,
  useGetChainConditionAttributes
} from './helper';
import { ChainsMap, UnfilledAttributesFormProps } from './types';

export const UnfilledAttributesForm: FC<UnfilledAttributesFormProps> = ({
  AttributesForm,
  form,
  initialAttributes,
  currentAttributes,
  setCurrentAttributes,
  children,
  ...restProps
}) => {
  const { accountId = '' } = useAppSelector(profileSelectors.profile);
  const { data: chainConditionAttributesMap } = useGetChainConditionAttributes(accountId);

  const [checkAttributeForChainResults, checkAttributeForChainResultsMutation] = useCheckAttributeForChainResultsMutation();

  const [chainsMap, setChainsMap] = useState<ChainsMap>({});

  const onValuesChange: IInternalFormProps['onValuesChange'] = (changed) => {
    Object.entries(changed).forEach((entry) => {
      const [conditionAttributeId, conditionTriggerValue] = entry as [ChainConditionAttributeId, ChainConditionTriggerValue];

      const hasChainResultAttribute = chainConditionAttributesMap[conditionAttributeId]?.[conditionTriggerValue];
      if (hasChainResultAttribute) {
        checkAttributeForChainResults({
          accountId,
          attributeId: conditionAttributeId,
          value: conditionTriggerValue
        })
          .unwrap()
          .then((chainResultAttributes) => {
            const extendedChainResultAttributes = extendResultAttributesWithChainProps(
              chainResultAttributes,
              chainConditionAttributesMap[conditionAttributeId][conditionTriggerValue]
            );

            const newChainsMap: ChainsMap = addResultAttributesToChainInMap(chainsMap, conditionAttributeId, extendedChainResultAttributes);
            setChainsMap(newChainsMap);

            setCurrentAttributes(updateCurrentAttributes(newChainsMap, initialAttributes));
          });
      } else {
        const newChainsMap: ChainsMap = deleteResultAttributesFromChainInMap(chainsMap, conditionAttributeId);
        setChainsMap(newChainsMap);

        setCurrentAttributes(updateCurrentAttributes(newChainsMap, initialAttributes));
      }
    });
  };

  if (currentAttributes === undefined || currentAttributes.length === 0) {
    return null;
  }

  return (
    <Spin spinning={checkAttributeForChainResultsMutation.isLoading}>
      <AttributesForm form={form} attributes={currentAttributes} name='unfilled-attributes' onValuesChange={onValuesChange} {...restProps}>
        {children}
      </AttributesForm>
    </Spin>
  );
};
