import { createApi } from '@reduxjs/toolkit/query/react';
import { getBaseQuery } from 'common/helpers';
import type { ISaveExerciseAsFinished } from 'types/requests/student/trace.api';

export const traceApi = createApi({
  reducerPath: 'traceApi',
  tagTypes: ['Training_Trace', 'Exercise_Trace'],
  baseQuery: getBaseQuery(),
  endpoints: ({ mutation }) => ({
    // Сохранение урока как завершенного для дальнейшего заполнения цифрового следа
    saveExerciseAsFinished: mutation<ISaveExerciseAsFinished.Response, ISaveExerciseAsFinished.Params>({
      query: ({ courseId, exerciseId }) => ({
        url: `/api/Trainings/${courseId}/Exercises/${exerciseId}/Trace/Absent`,
        method: 'POST'
      }),
      invalidatesTags: ['Exercise_Trace']
    })
  })
});

export const { useSaveExerciseAsFinishedMutation } = traceApi;
