import { BaseQueryFn, FetchArgs, fetchBaseQuery, FetchBaseQueryError } from '@reduxjs/toolkit/query/react';
import dayjs from 'dayjs';
import { oneIdSlice } from 'api/guest/oneId/one-id.slice';

/**
 * This is for fetchBaseQuery
 * @returns function
 */
type IGetBaseQueryProps = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  paramsSerializer?: (params: Record<string, any>) => string;
};

export const getBaseQuery =
  (getBaseQueryProps?: IGetBaseQueryProps): BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> =>
  async (args, api, extraOptions) => {
    const { paramsSerializer } = getBaseQueryProps || {};
    const fetchQuery = fetchBaseQuery({
      baseUrl: process.env.REACT_APP_API,
      credentials: 'include',
      prepareHeaders: (headers) => {
        headers.set('accept', 'application/json');
        return headers;
      },
      paramsSerializer
    });
    const result = await fetchQuery(args, api, extraOptions);
    const fetchArgs = args as FetchArgs;
    const newData = {
      request: {
        url: result.meta?.request.url.substring(process.env.REACT_APP_API?.length as number),
        method: result.meta?.request.method,
        body: fetchArgs.body,
        date: dayjs().format('DD/MM/YYYY HH:mm:ss:ms')
      },
      response: {
        body: result.data,
        date: dayjs().format('DD/MM/YYYY HH:mm:ss:ms'),
        status: result.meta?.response?.status
      }
    };

    if (result.error) {
      const { data, status } = result.error;
      if (status === 401 && data !== null && typeof data === 'object' && 'Title' in data && 'Description' in data) {
        const { Title, Description } = data;
        if (typeof Title === 'string' && typeof Description === 'string') {
          api.dispatch(oneIdSlice.actions.setOneIdError({ Title, Description }));
        }
      }
    }

    let text = `
    &nbsp;&nbsp;&nbsp;${newData.request.method}:[${newData.request.date}] - ${newData.request.url} ${
      newData.request.body ? `-- ${JSON.stringify(newData.request.body)}` : ''
    }<br/>
    &nbsp;&nbsp;&nbsp;${newData.response.status}:[${newData.response.date}] ${
      newData.response.body ? `-- ${JSON.stringify(newData.response.body)}` : ''
    }<br/><br/>
  `;

    const existingRequests = JSON.parse(`${sessionStorage.getItem('requests')}`) || '';
    text += existingRequests;
    sessionStorage.setItem('requests', JSON.stringify(text));
    return result;
  };
