import { FC } from 'react';
import { Col, ColProps } from 'antd';

export const AttritbuteFormItemCol: FC<ColProps> = ({ children, ...restProps }) => {
  return (
    <Col xs={24} sm={24} xl={12} {...restProps}>
      {children}
    </Col>
  );
};
