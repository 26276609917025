import { useLayoutEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setUnfilled } from 'api/student/profile/profile.slice';
import { AttributesField } from 'types/entities';

export const useUnfilledProfileDispatch = (attributes?: AttributesField[]) => {
  const dispatch = useDispatch();
  useLayoutEffect(() => {
    if (!!attributes?.length) {
      dispatch(setUnfilled(true));
    } else {
      dispatch(setUnfilled(false));
    }
  }, [dispatch, attributes?.length]);
};
