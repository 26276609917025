import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { ContentLayout, ContentPageLayout, Header, QueryResponseBoundary } from 'containers/_new';
import { Button, Table } from 'common/ui/_new';
import { useModal } from 'common/hooks';
import {
  useGetAccountsQuery,
  useGetCurrentStateQuery,
  useLoginAccountMutation,
  useLoginToMainMutation
} from 'api/student/accountRelation/accountRelation.api';
import { IAccountRelation } from 'types/entities';
import { AddAccountModal } from './AddAccountModal';
import { getAccountsTableData, getColumns } from './helper';
import styles from './styles.module.scss';

export const AccountRelation: FC = () => {
  const { t } = useTranslation('Students', { keyPrefix: 'pages.account-relation' });

  const currentStateQuery = useGetCurrentStateQuery();

  const addModal = useModal();

  const accountsQuery = useGetAccountsQuery();

  const [loginAccount] = useLoginAccountMutation();
  const onLoginClick = (id: IAccountRelation['id']) => {
    loginAccount({ id })
      .unwrap()
      .then(() => window.location.reload());
  };

  const [loginToMain] = useLoginToMainMutation();
  const onLoginToMain = () => {
    loginToMain()
      .unwrap()
      .then(() => window.location.reload());
  };

  const columns = getColumns(
    {
      actions: {
        'log-in-as': (record) => ({
          onClick: () => onLoginClick(record.id)
        })
      }
    },
    t
  );

  return (
    <>
      <ContentPageLayout centered showBreadcrumbs={false}>
        <ContentLayout header={<Header className={styles.title}>{t('title')}</Header>} tile>
          <div className={styles.panelContainer}>
            <div className={styles.panel}>
              <QueryResponseBoundary query={currentStateQuery} size='small'>
                {!currentStateQuery.data?.isMainAccount && <Button onClick={onLoginToMain}>{t('button-login-to-main')}</Button>}
                <Button onClick={addModal.open} type='primary'>
                  {t('button-login')}
                </Button>
              </QueryResponseBoundary>
            </div>
          </div>
          <Table
            dataSource={getAccountsTableData(accountsQuery.data)}
            columns={columns}
            loading={accountsQuery.isFetching}
            scroll={{ y: '50vh' }}
          />
        </ContentLayout>
      </ContentPageLayout>

      {addModal.isOpen && <AddAccountModal open onCancel={addModal.close} onClose={addModal.close} />}
    </>
  );
};
