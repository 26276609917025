import { createApi } from '@reduxjs/toolkit/query/react';
import { getBaseQuery } from 'common/helpers';
import { GetCourseInstructorsDescriptionForNonStudent } from 'types/requests/guest/courses.api';

export const guestCoursesApi = createApi({
  reducerPath: 'guestCoursesApi',
  baseQuery: getBaseQuery(),
  endpoints: ({ query }) => ({
    // Получить список преподавателей для анонимного пользователя
    getCourseInstructorsDescriptionForNonStudent: query<
      GetCourseInstructorsDescriptionForNonStudent.Response,
      GetCourseInstructorsDescriptionForNonStudent.Params
    >({
      query: ({ courseId }) => `/api/trainings/${courseId}/instructors/description`
    })
  })
});

export const { useGetCourseInstructorsDescriptionForNonStudentQuery, useLazyGetCourseInstructorsDescriptionForNonStudentQuery } =
  guestCoursesApi;
