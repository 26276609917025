import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'common/ui/_new';
import { useRedirect, useRedirectPath } from 'common/hooks';
import { GuestParams } from 'types/routes/guest';

export type RedirectToCourseJoinPageButtonProps = {
  courseId: GuestParams.Course['courseId'];
};

export const RedirectToCourseJoinPageButton: FC<RedirectToCourseJoinPageButtonProps> = ({ courseId }) => {
  const redirect = useRedirect();
  const redirectPath = useRedirectPath();

  const { t } = useTranslation('common', { keyPrefix: 'components.redirect-to-course-join-page-button' });

  const redirectToCoursePageForJoin = () => {
    redirect.welcome({ state: { redirectPath: redirectPath.coursePreview({ courseId }) } });
  };

  return (
    <Button onClick={() => redirectToCoursePageForJoin()} type='primary'>
      {t('label')}
    </Button>
  );
};
