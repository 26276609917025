import { createApi } from '@reduxjs/toolkit/query/react';
import { getBaseQuery } from 'common/helpers';
import { IGetLinks } from 'types/requests/student/artefacts.api';

export const linksApi = createApi({
  reducerPath: 'linksApi',
  baseQuery: getBaseQuery(),
  endpoints: ({ query }) => ({
    getLinks: query<IGetLinks.Response, IGetLinks.Params>({
      query: () => `/api/students/trainings/links`
    })
  })
});

export const { useGetLinksQuery } = linksApi;
