import type { IRemoveSlide, ISavePreviewSlide } from 'types/requests/instructor/slides.slide.api';
import { slidesApi } from '../slides.api';

const slideApi = slidesApi.injectEndpoints({
  endpoints: ({ mutation }) => ({
    // Удаление страницы
    removeSlide: mutation<IRemoveSlide.Response, IRemoveSlide.Params>({
      query: ({ exerciseId, pageId }) => ({
        url: `/api/instructors/Exercises/${exerciseId}/Pages/${pageId}/Drop`,
        method: 'DELETE'
      }),
      invalidatesTags: ['Slides']
    }),
    // Предпросмотр слайда
    savePreviewSlide: mutation<ISavePreviewSlide.Response, ISavePreviewSlide.Params>({
      query: ({ exerciseId, pageId }) => ({
        url: `/api/instructors/Exercises/${exerciseId}/Pages/${pageId}/PreviewPage`,
        method: 'POST'
      })
    })
  })
});

export const { useRemoveSlideMutation, useSavePreviewSlideMutation } = slideApi;
