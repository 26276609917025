import { useEffect, useMemo, useState } from 'react';
import styleVar from 'common/styles/responsive.scss';

export type MediaQuery = Parameters<typeof window.matchMedia>[0]; // ex: '(90px <= width < 1220px)'

const getMediaQueryListMatches = (mediaQueryLists: MediaQueryList[]) => mediaQueryLists.map((queryList) => queryList.matches);

export const useMediaQuery = (queries: MediaQuery[]): boolean[] => {
  const mediaQueryLists = useMemo(() => queries.map((query) => window.matchMedia(query)), [queries]);

  const [, setMatches] = useState(getMediaQueryListMatches(mediaQueryLists));

  useEffect(() => {
    const onQueryChange = () => setMatches(getMediaQueryListMatches(mediaQueryLists));

    mediaQueryLists.forEach((mediaQueryList) => {
      mediaQueryList.addEventListener('change', onQueryChange);
    });

    return () => {
      mediaQueryLists.forEach((mediaQueryList) => {
        mediaQueryList.removeEventListener('change', onQueryChange);
      });
    };
  }, [mediaQueryLists]);

  return getMediaQueryListMatches(mediaQueryLists);
};

const { defaultMobileMediaQuery, defaultTabletMediaQuery, defaultLaptopMediaQuery, defaultDesktopMediaQuery } = styleVar;

export const useDefaultMediaQueries = () => {
  const [isMobile, isTablet, isLaptop, isDesktop] = useMediaQuery([
    defaultMobileMediaQuery,
    defaultTabletMediaQuery,
    defaultLaptopMediaQuery,
    defaultDesktopMediaQuery
  ]);

  return { isMobile, isTablet, isLaptop, isDesktop };
};
