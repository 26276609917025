import { AntTreeNodeProps } from 'antd/lib/tree';
import { MinusSquareOutlined, PlusSquareOutlined } from '@ant-design/icons';
import { ITreeProps } from './types';
import styles from './styles.module.scss';

export const getSwitcherIcon: ITreeProps['switcherIcon'] = ({ expanded }: AntTreeNodeProps) => {
  if (expanded) {
    return <MinusSquareOutlined className={styles.treeSwitchIcon} />;
  }
  return <PlusSquareOutlined className={styles.treeSwitchIcon} />;
};
