import { createSlice } from '@reduxjs/toolkit';
import type { ICalendarChosenDateAction, ISidebarState } from 'types/store/student/calendar.slice';

const initialState: ISidebarState = {
  chosenDate: ''
};

export const sidebarSlice = createSlice({
  name: 'sidebar',
  initialState,
  reducers: {
    setChosenDate: (state, action: ICalendarChosenDateAction) => {
      state.chosenDate = action.payload;
      return state;
    }
  }
});

export const { actions: sidebarActions } = sidebarSlice;
