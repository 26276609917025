import { IIconType } from 'common/ui/_new';
import { ICheckErrorArgs, IDetailedCourse, IStatusMap } from 'types/entities';

/**
 * To create map fro array where key is id and value is lesson.delivering
 * @returns IStatusMap
 */
export const createLessonStatusMap = (data: IDetailedCourse): IStatusMap => {
  if (!data) return {};
  return data?.modules.reduce((acc, module): IStatusMap => {
    const key = module[0].id as keyof IStatusMap;
    // @ts-ignore
    acc[key] = getLessonStatus(module[0].lesson.delivering);
    return acc;
  }, {});
};

/**
 * This is to know if exercise is finished, in progress or unknown
 * @returns string
 */
export const moduleIconMap: Record<string, IIconType> = {
  progress: 'in-progress',
  done: 'done-solid'
};

export const getLessonStatus = (delivering: 0 | 1 | 2): 'none' | 'progress' | 'done' => {
  if (delivering === 1) return 'progress';
  if (delivering === 2) return 'done';
  return 'none';
};

/**
 * to check if error body has code
 * @props
 * error :FetchBaseQueryError | SerializedError | undefined
 * status :number
 * code :string
 * @returns boolean
 * */
export const checkError = (args: ICheckErrorArgs): boolean => {
  const { error, status, code } = args;
  let result = false;
  // @ts-ignore
  if (error) result = error?.status === status;
  // @ts-ignore
  if (code) result = result && (error?.data?.ErrorCode || [])[0] === code;

  return result;
};
