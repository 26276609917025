import { ColumnsType } from 'antd/es/table';
import { ActionsPanel, ActionsPanelItemStaticProps } from 'common/components/_new';
import { IAccountRelation } from 'types/entities';
import { AccountTable, Actions, ActionType, GetColumnsFn, TFunctionAccountRelation } from './types';

export const getAccountsTableData = (accounts?: IAccountRelation[]) =>
  accounts?.map(({ id, email, firstname, surname, patronymic }) => ({
    key: id,
    name: `${surname} ${firstname} ${patronymic || ''}`,
    email,
    id
  }));

const actionsStaticProps = (t: TFunctionAccountRelation): ActionsPanelItemStaticProps<ActionType>[] => {
  return [
    {
      type: 'log-in-as',
      tooltip: t('table.log-in-as'),
      icon: 'log-in-as'
    }
  ];
};

const getActionsPanelItems = (actions: Actions, record: AccountTable, t: TFunctionAccountRelation) =>
  actionsStaticProps(t).map(({ type, ...staticProps }) => ({
    ...staticProps,
    ...actions[type]?.(record)
  }));

export const getColumns: GetColumnsFn = ({ actions }, t) => {
  const columns: ColumnsType<AccountTable> = [
    {
      title: t('table.columns-name'),
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: t('table.columns-email'),
      dataIndex: 'email',
      key: 'email'
    },
    {
      key: 'actions',
      render: (record) => <ActionsPanel items={getActionsPanelItems(actions, record, t)} />,
      align: 'center',
      width: 1
    }
  ];
  return columns;
};
