import { Id } from 'types/common';
import {
  AttributesField,
  AttributeTypeEnum,
  CourseId,
  ExerciseFinishType,
  ExerciseId,
  LessonId,
  PageContentType,
  QuestionId,
  QuestionResponseType,
  QuestionTypeEnum
} from 'types/entities';

export enum SLIDES_ENUM {
  HTML = '1',
  EXTERNAL_TESTING = '2.101',
  CHECKBOX = '2.2.0',
  RADIO = '2.2.1',
  INPUT_DATE = '2.5.1',
  INPUT_TEXT = '2.5.2',
  INPUT_INTEGER = '2.5.3',
  INPUT_FLOAT = '2.5.4',
  INPUT_BOOLEAN = '2.5.5',
  INPUT_TEXTAREA = '2.5.10',
  ATTACHING_FILE = '2.7',
  TEXT = '2.8',
  ATTRIBUTES = '2.9',
  SCORM = '2.10',
  VIDEO = '6',
  IFRAME = '7',
  IFRAME_VIDEO = '7.6',
  IFRAME_PDF = '7.11',
  PICTURE = '8',
  AUDIO = '9',
  EXTERNAL_IFRAME = '10',
  PDF = '11',
  DOC = '12',
  KINESCOPE = '13'
}

//* Lms.Entities.Entities.Page.Questions.Free.QxFreeResponseType
export enum FreeResponseTypeEnum {
  Undefined, // Не определено
  Date, // Дата
  String, // Строка
  Integer, // Целое число
  Float, // Дробное число
  Boolean, // Логическое значение
  Email, // EMail
  Address, // Адрес
  Country, // Страна
  City, // Город
  Text // Текст
}

//* Robocode.Areas.Questions.ViewModels.Free.FreeResponseViewModel
export type FreeResponseViewModel = {
  value?: string;
  responseType?: FreeResponseTypeEnum;
};

//* Robocode.Areas.Questions.ViewModels.Answers.AnswersResponseViewModel
export type AnswersResponseViewModel = {
  options?: Id[];
};

export declare module ISlides {
  // Содержимое ограниченной страницы для пользователя
  export interface Common {
    contentType: number;
    type?: number;
    responseType?: number;
  }

  // HTML
  export interface HTML extends Common {
    content: string;
  }

  interface Options {
    id: string;
    text: string;
    explanation: string;
    select: boolean;
    correct: boolean;
  }

  interface Question {
    id: string;
    text: string;
    label: string;
    answered: boolean;
    options: Options[];
  }

  // CHECKBOX & RADIO
  export interface Choiceable extends Required<Common> {
    isReadonly: boolean;
    remainingTime: string;
    question: Question;
  }

  // INPUT_DATE
  // INPUT_TEXT
  // INPUT_NUMBER
  // INPUT_FLOAT
  // INPUT_BOOLEAN
  // INPUT_TEXTAREA
  export interface FreeQuestion extends Required<Common> {
    text: string;
    value: string;
    isReadonly: boolean;
    remainingTime: string;
    isRequired: boolean;
  }

  // ATTACHING_FILE
  export interface AttachingFile extends Common {
    text: string;
    value: string;
    isReadonly: boolean;
    remainingTime: string;
  }

  // TEXT
  export interface Text extends Common {
    text: string;
  }

  export type Attribute<T extends AttributeTypeEnum = AttributeTypeEnum> = AttributesField<T>;

  // ATTRIBUTES
  export interface Attributes extends Common {
    text?: string;
    attributes: Attribute[];
  }

  interface Downloadable {
    downloadPermission: boolean;
  }

  // SCORM
  export interface Scorm extends Common {
    scormParams: {
      source: string;
      args?: string;
      training: string;
      exercise: string;
    };
    scormUrl: string;
  }

  // VIDEO
  export interface Video extends Common, Downloadable {
    play: boolean;
    url: string;
    player: number;
  }

  // IFRAME
  export interface IFrame extends Common {
    url: string;
  }

  // PICTURE
  export interface Picture extends Common {
    url: string;
  }

  // AUDIO
  export interface Audio extends Common {
    content: string;
    url: string;
  }

  // EXTERNAL_IFRAME
  export interface External extends Common {
    url: string;
  }

  // EXTERNAL_TESTING

  export interface Testing extends Common {
    url: string;
    isDone: boolean;
    results: string;
  }

  // PDF
  export interface PDF extends Common, Downloadable {
    url: string;
  }
  // DOX/DOCX
  export interface DOC extends Common {
    url: string;
  }

  export interface IKinescopeLink {
    quality: string;
    link: string;
    fileSize: string;
  }

  export interface Kinescope extends Common {
    link: string;
    downloadLinks?: IKinescopeLink[];
  }
}

// Список типов вопросов со свободными ответами
export interface IQuestionsFreeType {
  // Идентификатор типа
  id: number;
  // Название типа
  label: string;
  data?: {
    [key: string]: string;
  };
}

//* Robocode.ViewModels.Trainings.TrainingFinishFeedbackViewModel
// Тип с информацией об обратной связи
type TrainingFinishFeedbackViewModel = {
  rating?: number;
  value?: string;
  on: boolean;
};

//* Robocode.ViewModels.Trainings.TrainingFinishSkillViewModel
// Информация о владении компетенцией
export type TrainingFinishSkillViewModel = {
  text?: string;
  value: number; // double
};

//* Robocode.ViewModels.Trainings.TrainingFinishQuestionSkillViewModel
// Оценка по компетенции
type TrainingFinishQuestionSkillViewModel = {
  label?: string;
  mark?: string;
};

//* Robocode.ViewModels.Trainings.TrainingFinishQuestionViewModel
// Информация о вопросе
export type TrainingFinishQuestionViewModel = {
  id: QuestionId;
  label?: string;
  value: number; // double
  mark?: string;
  contentType: PageContentType;
  type: QuestionTypeEnum;
  responseType?: QuestionResponseType;
  skills?: TrainingFinishQuestionSkillViewModel[];
};

//* Robocode.ViewModels.Trainings.TrainingFinishViewModel
// Данные по прохождению
export type TrainingFinishViewModel = {
  finishPageType: ExerciseFinishType;
  exerciseLabel?: string;
  isRetakeAvailable: boolean;
  courseId: CourseId;
  trainingImage?: string;
  time?: string;
  feedback: TrainingFinishFeedbackViewModel;
  skills?: TrainingFinishSkillViewModel[];
  questions?: TrainingFinishQuestionViewModel[];
  validate: boolean;
  result?: number;
  average?: number;
  isShowCorrectAnswer: boolean;
  isShowQuestions: boolean;
  numberOfCorrectAnswers?: number;
};

//* Lms.UseCases.Interfaces.Common.UnitQueries.GetNextAvailableExerciseUnitQueryResult
// Результат следующего упражнения для студента
export type GetNextAvailableExerciseUnitQueryResult = {
  id: ExerciseId;
  label?: string;
  lessonId: LessonId;
};

//* Robocode.ViewModels.Pages.ContentViewModels.BasePageViewModel
export type BasePageViewModel = {
  contentType: PageContentType;
};

interface IExerciseSlidesDuration {
  ticks: number;
  days: number;
  hours: number;
  milliseconds: number;
  minutes: number;
  seconds: number;
  totalDays: number;
  totalHours: number;
  totalMilliseconds: number;
  totalMinutes: number;
  totalSeconds: number;
}

// Список слайдов упражнения
interface IExerciseSlidesType {
  // Идентификатор слайда
  id: string;
  // Отступ
  indent: number;
  // Продолжительность
  duration: IExerciseSlidesDuration;
  // Активна ли страница
  isActivePage: boolean;
}

interface ExerciseSlides<T> {
  exerciseLabel: string;
  exerciseDescription: string;
  pages: T[];
  isShowCorrectAnswerInTest: boolean;
  picture: string;
}

export type IExerciseSlidesBody = ExerciseSlides<Omit<IExerciseSlidesType, 'duration' | 'indent'>>;
export type IExerciseSlideResponse = ExerciseSlides<IExerciseSlidesType>;
