import { guestPaths } from 'types/routes/guest';
import { instructorPaths } from 'types/routes/instructor';
import { studentPaths } from 'types/routes/student';

export const fallbacks = {
  Guest: guestPaths.welcome,
  Instructors: instructorPaths.courses,
  Students: studentPaths.myCourses,
  Root: instructorPaths.courses
};
