import { FC } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { Button, Dropdown } from 'common/ui/_new';
import { useAppSelector } from 'common/hooks';
import { profileSelectors } from 'api/student/profile/profile.selectors';
import {
  checkPathMatch,
  getChildKeys,
  getMainNavigationByRole,
  getNavigationItemPropsByStatus,
  navigationItemStatusMap
} from './Navigation.helper';
import { NavigationProps } from './types';
import styles from './styles.module.scss';

export const Navigation: FC<NavigationProps> = ({ className }) => {
  const user = useAppSelector(profileSelectors.role);
  const { t } = useTranslation(user.role, { keyPrefix: 'common.navigation' });
  const items = getMainNavigationByRole(user, t);

  const unfilled = useAppSelector(profileSelectors.unfilled);
  navigationItemStatusMap.profileData = unfilled ? 'warning' : undefined;
  const { pathname } = useLocation();

  return (
    <div className={cn(styles.container, className)}>
      <div className={styles.mainNav}>
        {items?.map(({ key, children, label, button }) => (
          <div key={key}>
            {children ? (
              <Dropdown
                key={key}
                menu={{ items: children }}
                placement='bottom'
                dropdownRender={(item) => <div className={styles.dropdownMenu}>{item}</div>}
              >
                <Button
                  type='text'
                  {...getNavigationItemPropsByStatus(navigationItemStatusMap[key])}
                  className={cn(styles.text, {
                    [styles.active]: checkPathMatch(pathname, getChildKeys(children))
                  })}
                >
                  {label}
                </Button>
              </Dropdown>
            ) : (
              button
            )}
          </div>
        ))}
      </div>
    </div>
  );
};
