import { ComponentProps, FC, useContext, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Trans } from 'react-i18next';
import { Row } from 'antd';
import { AttritbuteFormItemCol, ProfileAttributesForm } from 'pages/student/Profile/common';
import { useGetVisibleUnfilledAttributeListQuery } from 'pages/student/Profile/UnfilledForm/helper';
import { PageErrorHandlerContext } from 'containers';
import { ContentLayout, ContentPageLayout, Header } from 'containers/_new';
import { Alert, Form, Spin } from 'common/ui/_new';
import { AttributeFormItem, UnfilledAttributesForm } from 'common/components/_new';
import { usePrompt, useUnfilledProfileDispatch } from 'common/hooks';
import styles from './styles.module.scss';

export const UnfilledRequiredProfileFieldsErrorPage: FC = () => {
  const getVisibleUnfilledAttributeListQuery = useGetVisibleUnfilledAttributeListQuery();
  const { initialAttributes, currentAttributes, setCurrentAttributes, isLoading, isFetching } = getVisibleUnfilledAttributeListQuery;

  useUnfilledProfileDispatch(initialAttributes);

  const [form] = Form.useForm();
  const [isFormDirty, setIsFormDirty] = useState<boolean>(false);

  const onFormChange: ComponentProps<typeof Form.Provider>['onFormChange'] = () => {
    setIsFormDirty(true);
  };

  usePrompt({ isDirty: isFormDirty });

  const { setPageErrorNode } = useContext(PageErrorHandlerContext);

  const { state: { courseLabel } = {} } = useLocation();

  if (!initialAttributes?.length) {
    return null;
  }

  return (
    <ContentPageLayout centered showBreadcrumbs={false}>
      <ContentLayout tile header={courseLabel && <Header>{courseLabel}</Header>}>
        <Alert
          type='warning-secondary'
          description={
            <Trans ns='common' i18nKey='components.error-page.unfilled-mandatory-profile-fields.alert'>
              <span className={styles.asterisk} />
            </Trans>
          }
        />
        <Form.Provider onFormChange={onFormChange}>
          <UnfilledAttributesForm
            AttributesForm={ProfileAttributesForm}
            form={form}
            initialAttributes={initialAttributes}
            currentAttributes={currentAttributes}
            setCurrentAttributes={setCurrentAttributes}
            onFinish={() => {
              setIsFormDirty(false);
              setPageErrorNode(null);
            }}
          >
            <Spin spinning={isLoading || isFetching}>
              <Row gutter={[32, 0]}>
                {currentAttributes?.map((attributeProps) => {
                  if (attributeProps.hidden) {
                    return;
                  }

                  return (
                    <AttritbuteFormItemCol key={attributeProps.id}>
                      <AttributeFormItem {...attributeProps} />
                    </AttritbuteFormItemCol>
                  );
                })}
              </Row>
            </Spin>
          </UnfilledAttributesForm>
        </Form.Provider>
      </ContentLayout>
    </ContentPageLayout>
  );
};
