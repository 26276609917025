import { Alert, IAlertProps } from 'common/ui/_new';
import { ILinkAlert } from './types';
import styles from './styles.module.scss';

export const LinkAlert: ILinkAlert = ({ ...outerProps }) => {
  const unoverridableProps: IAlertProps = {
    className: styles.linkAlert
  };

  return <Alert {...outerProps} {...unoverridableProps} />;
};
