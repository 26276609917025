import { FC } from 'react';
import { Avatar, Link, Text, Title } from 'common/ui/_new';
import { UserContactCardProps } from './types';
import styles from './styles.module.scss';

export const UserContactCard: FC<UserContactCardProps> = ({ avatarSrc, name, status, phone, email, social = {} }) => {
  return (
    <div className={styles.card}>
      <Avatar src={avatarSrc} alt='' className={styles.avatar} />
      <div className={styles.content}>
        {name && (
          <Title level={5} ellipsis={{ tooltip: name, rows: 1 }} className={styles.title}>
            {name}
          </Title>
        )}
        {status && <div className={styles.status}>{status}</div>}
        {phone && (
          <Link to={`tel:${phone}`} className={styles.phone}>
            {phone}
          </Link>
        )}
        {email && (
          <Link to={`mailto:${email}`}>
            <Text ellipsis={{ tooltip: email }}>{email}</Text>
          </Link>
        )}
        {Object.keys(social).length > 0 && (
          <div className={styles.social}>
            {social.email && (
              <Link
                to={`mailto:${social.email}`}
                target='_blank'
                rel='noreferrer'
                type='link'
                icon='mail-solid'
                className={styles.socialBtn}
              />
            )}
            {social.telegram && (
              <Link to={social.telegram} target='_blank' rel='noreferrer' type='link' icon='sm-telegram' className={styles.socialBtn} />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

// todo: move to UserContactCardsList folder
