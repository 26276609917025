import styleVar from './styles.module.scss';

const clickableElementsSelector = styleVar.interactionableElementsSelector.replace(/^"(.*)"$/, '$1');

export const detectClickOutsideLinkAndButtonChildren = (event: Event, handler: () => void) => {
  const selection = window.getSelection()?.toString() ?? '';
  const isSomeTextSelected = selection.length > 0;

  if (isSomeTextSelected) {
    return;
  }

  const target = event.target as Element;

  if (target.matches(clickableElementsSelector)) {
    handler();
  }
};

export const createEventListener = (element: HTMLElement, handler: () => void) => {
  const event = 'click';
  const listener: EventListener = (e) => detectClickOutsideLinkAndButtonChildren(e, handler);
  element.addEventListener(event, listener);

  return () => {
    element.removeEventListener(event, listener);
  };
};
