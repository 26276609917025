import { FC } from 'react';
import { Breadcrumb, BreadcrumbProps, Col, Row, Skeleton } from 'antd';
import cn from 'classnames';
import { useBreadcrumbs } from 'common/hooks';
import { IColSize } from 'types/common';
import styles from './styles.module.scss';

interface IBreadcrumbsProps extends BreadcrumbProps {
  size?: IColSize;
}

export const Breadcrumbs: FC<IBreadcrumbsProps> = ({ size, className }) => {
  const { breadcrumbsList, isLoading, pathLength } = useBreadcrumbs(false);

  if (!isLoading && breadcrumbsList.length === 0) {
    return null;
  }

  const items = isLoading
    ? Array(pathLength).map(() => ({
        title: <Skeleton.Input active size='small' />
      }))
    : breadcrumbsList.map(({ url, label }) => ({
        title: label,
        href: url
      }));

  return (
    <Row justify='center' className={cn(styles.breadcrumbs, className)}>
      <Col span={24} {...size}>
        <Breadcrumb separator='>' items={items} />
      </Col>
    </Row>
  );
};
