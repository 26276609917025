import { FC } from 'react';
import { Col, Row } from 'antd';
import { Layout as LayoutContainer } from 'containers';
import { Contents } from '../components';
import { IContentPageLayoutProps } from './types';

export const ContentPageLayout: FC<IContentPageLayoutProps> = (props) => {
  const { header, showBreadcrumbs = true, children, ...restProps } = props;

  return (
    <LayoutContainer showBreadcrumbs={showBreadcrumbs} {...restProps}>
      <Row
        gutter={[
          { xs: 24, sm: 24, lg: 24, xl: 32 },
          { xs: 24, sm: 24, lg: 24, xl: 32 }
        ]}
      >
        {header && <Col span={24}>{header}</Col>}
        <Col span={24}>
          <Contents>{children}</Contents>
        </Col>
      </Row>
    </LayoutContainer>
  );
};
