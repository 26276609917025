import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Layout } from 'containers';
import { ResultPage } from 'common/components/_new';
import { useAppSelector } from 'common/hooks';
import { profileSelectors } from 'api/student/profile/profile.selectors';
import { ErrorResultPageProps } from './types';

export const ErrorResultPage: FC<ErrorResultPageProps> = ({ title, ...restProps }) => {
  const { role } = useAppSelector(profileSelectors.role);
  const { t } = useTranslation('common', { keyPrefix: 'components.response-error-page' });

  return (
    <Layout showBreadcrumbs={['Students', 'Instructors', 'Root'].includes(role)} centered style={{ justifyContent: 'center' }}>
      <ResultPage title={title ?? t('title.default')} {...restProps} />
    </Layout>
  );
};
