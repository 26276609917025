import { createApi } from '@reduxjs/toolkit/query/react';
import { getBaseQuery } from 'common/helpers';
import {
  ICreateExerciseScheduleRequest,
  ICreateLessonScheduleRequest,
  IDeleteExerciseScheduleRequest,
  IDeleteLessonScheduleRequest,
  IEditExerciseScheduleRequest,
  IEditLessonScheduleRequest,
  IExerciseScheduleRequest,
  ILessonScheduleRequest
} from 'types/requests/instructor/schedule.api';
import { programCoursesApi } from '../courses/courses.api';

export const scheduleApi = createApi({
  reducerPath: 'scheduleApi',
  baseQuery: getBaseQuery(),
  tagTypes: ['ExerciseSchedule', 'CourseSchedule'],
  endpoints: ({ query, mutation }) => ({
    getExerciseSchedule: query<IExerciseScheduleRequest.Response, IExerciseScheduleRequest.Params>({
      query: ({ courseId, exerciseId }) => ({
        url: `/api/schedule/trainings/${courseId}/exercises/${exerciseId}/schedule`
      })
    }),
    createExerciseSchedule: mutation<ICreateExerciseScheduleRequest.Response, ICreateExerciseScheduleRequest.Params>({
      query: ({ courseId, exerciseId, scheduleBody }) => ({
        url: `/api/schedule/trainings/${courseId}/exercises/${exerciseId}/schedule/create`,
        method: 'POST',
        body: scheduleBody
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        queryFulfilled.finally(() => {
          dispatch(programCoursesApi.util.invalidateTags(['Lessons', 'Exercises']));
        });
      }
    }),
    editExerciseSchedule: mutation<IEditExerciseScheduleRequest.Response, IEditExerciseScheduleRequest.Params>({
      query: ({ courseId, exerciseId, scheduleBody }) => ({
        url: `/api/schedule/trainings/${courseId}/exercises/${exerciseId}/schedule/edit`,
        method: 'PUT',
        body: scheduleBody
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        queryFulfilled.finally(() => {
          dispatch(programCoursesApi.util.invalidateTags(['Lessons', 'Exercises']));
        });
      }
    }),
    deleteExerciseSchedule: mutation<IDeleteExerciseScheduleRequest.Response, IDeleteExerciseScheduleRequest.Params>({
      query: ({ courseId, exerciseId }) => ({
        url: `/api/schedule/trainings/${courseId}/exercises/${exerciseId}/schedule/delete`,
        method: 'DELETE'
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        queryFulfilled.finally(() => {
          dispatch(programCoursesApi.util.invalidateTags(['Lessons', 'Exercises']));
        });
      }
    }),
    getLessonSchedule: query<ILessonScheduleRequest.Response, ILessonScheduleRequest.Params>({
      query: ({ courseId, lessonId }) => `/api/schedule/trainings/${courseId}/lessons/${lessonId}/schedule`
    }),
    createLessonSchedule: mutation<ICreateLessonScheduleRequest.Response, ICreateLessonScheduleRequest.Params>({
      query: ({ courseId, lessonId, scheduleBody }) => ({
        url: `/api/schedule/trainings/${courseId}/lessons/${lessonId}/schedule/create`,
        method: 'POST',
        body: scheduleBody
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        queryFulfilled.finally(() => {
          dispatch(programCoursesApi.util.invalidateTags(['Lessons', 'Exercises']));
        });
      }
    }),
    editLessonSchedule: mutation<IEditLessonScheduleRequest.Response, IEditLessonScheduleRequest.Params>({
      query: ({ courseId, lessonId, scheduleBody }) => ({
        url: `/api/schedule/trainings/${courseId}/lessons/${lessonId}/schedule/edit`,
        method: 'PUT',
        body: scheduleBody
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        queryFulfilled.finally(() => {
          dispatch(programCoursesApi.util.invalidateTags(['Lessons', 'Exercises']));
        });
      }
    }),
    deleteLessonSchedule: mutation<IDeleteLessonScheduleRequest.Response, IDeleteLessonScheduleRequest.Params>({
      query: ({ courseId, lessonId }) => ({
        url: `/api/schedule/trainings/${courseId}/lessons/${lessonId}/schedule/delete`,
        method: 'DELETE'
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        queryFulfilled.finally(() => {
          dispatch(programCoursesApi.util.invalidateTags(['Lessons', 'Exercises']));
        });
      }
    })
  })
});

export const {
  useGetExerciseScheduleQuery,
  useCreateExerciseScheduleMutation,
  useEditExerciseScheduleMutation,
  useGetLessonScheduleQuery,
  useDeleteExerciseScheduleMutation,
  useCreateLessonScheduleMutation,
  useEditLessonScheduleMutation,
  useDeleteLessonScheduleMutation
} = scheduleApi;
