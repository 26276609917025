import { ComponentProps, FC } from 'react';
import { Menu as AntMenu } from 'antd';
import cn from 'classnames';
import { IMenuProps } from './types';
import styles from './styles.module.scss';

export const Menu: FC<IMenuProps> = ({ items, className, ...restProps }) => {
  const innerProps: ComponentProps<typeof AntMenu> = {
    //* overridable:
    //* outer:
    ...restProps,
    //* unoverridable:
    items,
    className: cn(styles.menu, className)
  };

  return <AntMenu {...innerProps} />;
};
