import { createApi } from '@reduxjs/toolkit/query/react';
import { enforceResponseFileDownload, getBaseQuery } from 'common/helpers';
import {
  IGetExportTypes,
  IMaterialDownloadCourse,
  IMaterialDownloadExercise,
  IMaterialDownloadLesson
} from 'types/requests/instructor/materialDownload';

export const materialDownload = createApi({
  reducerPath: 'materialDownload',
  baseQuery: getBaseQuery(),
  tagTypes: [],
  endpoints: ({ query }) => ({
    getExportTypes: query<IGetExportTypes.Response, IGetExportTypes.Params>({
      query: () => `/api/instructors/analytics/questions/export/types`
    }),
    materialDownloadCourse: query<IMaterialDownloadCourse.Response, IMaterialDownloadCourse.Params>({
      query: ({ courseId, types, begin, end }) => {
        const params = new URLSearchParams();
        types?.forEach((type) => {
          params.append('types', `${type}`);
        });
        begin && params.append('begin', `${begin}`);
        end && params.append('end', `${end}`);
        return {
          url: `/api/instructors/analytics/questions/export/trainings/${courseId}`,
          method: 'GET',
          params: params,
          responseHandler: enforceResponseFileDownload,
          responseType: 'blob',
          cache: 'no-cache'
        };
      }
    }),
    materialDownloadLesson: query<IMaterialDownloadLesson.Response, IMaterialDownloadLesson.Params>({
      query: ({ courseId, lessonId, types, begin, end }) => {
        const params = new URLSearchParams();
        types?.forEach((type) => {
          params.append('types', `${type}`);
        });
        begin && params.append('begin', `${begin}`);
        end && params.append('end', `${end}`);
        return {
          url: `/api/instructors/analytics/questions/export/trainings/${courseId}/lesson/${lessonId}`,
          method: 'GET',
          params: params,
          responseHandler: enforceResponseFileDownload,
          cache: 'no-cache'
        };
      }
    }),
    materialDownloadExercise: query<IMaterialDownloadExercise.Response, IMaterialDownloadExercise.Params>({
      query: ({ exerciseId, lessonId, courseId, types, begin, end }) => {
        const params = new URLSearchParams();
        types?.forEach((type) => {
          params.append('types', `${type}`);
        });
        begin && params.append('begin', `${begin}`);
        end && params.append('end', `${end}`);
        return {
          url: `/api/instructors/analytics/questions/export/trainings/${courseId}/lesson/${lessonId}/exercise/${exerciseId}`,
          method: 'GET',
          params: params,
          responseHandler: enforceResponseFileDownload,
          cache: 'no-cache'
        };
      }
    })
  })
});

export const {
  useGetExportTypesQuery,
  useLazyMaterialDownloadCourseQuery,
  useLazyMaterialDownloadLessonQuery,
  useLazyMaterialDownloadExerciseQuery
} = materialDownload;
