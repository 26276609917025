import { FC } from 'react';
import { Link, Text } from 'common/ui/_new';
import { useFormatDates, useRedirectPath } from 'common/hooks';
import { InstructorParams } from 'types/routes/instructor';
import { CoverCard, CoverCardProps } from '../CoverCard';
import styles from './styles.module.scss';

export type EditableCourseGroupCardProps = {
  id: InstructorParams.CourseGroup['courseGroupId'];
  label?: CoverCardProps['title'];
  begin?: Parameters<typeof useFormatDates>[0];
  end?: Parameters<typeof useFormatDates>[1];
  picture?: CoverCardProps['cover'];
  isEditable?: boolean;
};

export const EditableCourseGroupCard: FC<EditableCourseGroupCardProps> = ({ id, label, begin, end, picture, isEditable }) => {
  const redirectPath = useRedirectPath();
  const formattedDate = useFormatDates(begin, end);

  return (
    <CoverCard
      title={label}
      to={redirectPath.courseGroup({ courseGroupId: id })}
      cover={picture}
      action={
        isEditable && (
          <Link to={redirectPath.coursesGroupEdit({ courseGroupId: id })} type='stroke-secondary-square' size='small' icon='edit' />
        )
      }
    >
      <div className={styles.stats}>
        <Text icon='calendar'>{formattedDate}</Text>
      </div>
    </CoverCard>
  );
};
