import { ComponentProps, FC } from 'react';
import cn from 'classnames';
import { Progress } from 'common/ui/_new';
import { useDefaultMediaQueries } from 'common/hooks';
import { CourseProgressProps } from './types';
import styles from './styles.module.scss';
import styleVar from 'common/styles/vars.scss';

export const CourseProgress: FC<CourseProgressProps> = ({ disabled, className, ...restProps }) => {
  const { isMobile } = useDefaultMediaQueries();

  const innerProps: ComponentProps<typeof Progress> = {
    //* overridable:
    type: 'circle',
    trailColor: styleVar.colorAccentGreen10,
    status: 'normal',
    size: isMobile ? 96 : 122,
    //* outer:
    ...restProps,
    //* unoverridable:
    className: cn(styles.courseProgress, { [styles.disabled]: disabled }, className)
  };

  return <Progress {...innerProps} />;
};
