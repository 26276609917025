import { FC } from 'react';
import { Tag } from 'antd';
import { Text } from 'common/ui/_new';
import { CourseTopicsListProps } from './types';
import styles from './styles.module.scss';

export const CourseTopicsList: FC<CourseTopicsListProps> = ({ items }) => {
  return (
    <div className={styles.tags}>
      {items.map(({ label }, i) => (
        <Tag key={i} className={styles.tag}>
          <Text ellipsis={{ tooltip: label }}>{label}</Text>
        </Tag>
      ))}
    </div>
  );
};
