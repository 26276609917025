import { Dayjs, extend } from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { AddressName, AttributeTypeEnum, CompanyInfo, DictionaryRecordId } from 'types/entities';
import { FieldValue, FileValue, FormItemValue } from './types';

extend(utc);

export const isFieldValue = (type: AttributeTypeEnum, value: FormItemValue): value is FieldValue =>
  [
    AttributeTypeEnum.String,
    AttributeTypeEnum.Bool,
    AttributeTypeEnum.Int,
    AttributeTypeEnum.Float,
    AttributeTypeEnum.Date,
    AttributeTypeEnum.Address,
    AttributeTypeEnum.Company,
    AttributeTypeEnum.Reference,
    AttributeTypeEnum.Set,
    AttributeTypeEnum.Hierarchy
  ].includes(type);

export const isFileValue = (type: AttributeTypeEnum, value: FormItemValue): value is FileValue =>
  [AttributeTypeEnum.File, AttributeTypeEnum.Pattern].includes(type);

export const isString = (type: AttributeTypeEnum, value: FieldValue): value is string => type === AttributeTypeEnum.String;

export const isDate = (type: AttributeTypeEnum, value: FieldValue): value is Dayjs => type === AttributeTypeEnum.Date;

export const isAddress = (type: AttributeTypeEnum, value: FieldValue): value is AddressName => type === AttributeTypeEnum.Address;

export const isCompany = (type: AttributeTypeEnum, value: FieldValue): value is CompanyInfo => type === AttributeTypeEnum.Company;

export const isSet = (type: AttributeTypeEnum, value: FieldValue): value is DictionaryRecordId[] => type === AttributeTypeEnum.Set;
