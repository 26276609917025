import { useParams, useSearchParams } from 'react-router-dom';
import { QueryResponseBoundary } from 'containers/_new';
import { useGetExternalSlideQuery } from 'api/instructor/slides/endpoints/slide_external-question.endpoint';
import { InstructorParams } from 'types/routes/instructor';
import styles from './styles.module.scss';

export const ExternalPreviewModal = () => {
  const { exerciseId = '' } = useParams<InstructorParams.Exercise>();
  const [searchParams] = useSearchParams();

  const query = useGetExternalSlideQuery({ exerciseId, pageId: searchParams.get('slideId') || '' });

  return (
    <QueryResponseBoundary query={query}>
      <iframe className={styles.iframe} src={query.data?.label}></iframe>
    </QueryResponseBoundary>
  );
};
