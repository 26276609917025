import { Icon } from 'common/ui/_new';
import { BellSvg, ErrorSvg, NoAccessSvg } from './assets';
import { IGetPropsByStatus } from './types';
import styles from './styles.module.scss';

export const getPropsByStatus: IGetPropsByStatus = (status) => {
  switch (status) {
    case 'success':
      return { icon: <Icon type='decor-accept' />, innerStatus: status };
    case 'warning':
    case 'error':
      return { icon: <ErrorSvg />, innerStatus: status };
    case 'no-access':
      return { icon: <NoAccessSvg />, innerStatus: 'error' };
    case 'destructive':
      return { icon: <Icon type='decor-trash' />, innerStatus: 'warning', statusClassName: styles.result_destructive };
    case 'info':
      return { icon: <Icon type='decor-info' />, innerStatus: status };
    case 'no-data':
      return { icon: <BellSvg />, innerStatus: 'info' };
    default:
      return {};
  }
};
