import { FC } from 'react';
import { Link, Text, TextProps } from 'common/ui/_new';
import { useFormatDates, useRedirectPath } from 'common/hooks';
import { InstructorParams } from 'types/routes/instructor';
import { CoverCard, CoverCardProps } from '../CoverCard';
import { Actions, ActionsProps } from './Actions';
import styles from './styles.module.scss';

export type EditableCourseCardProps = {
  id: InstructorParams.CourseGroup['courseGroupId'];
  label?: CoverCardProps['title'];
  begin?: Parameters<typeof useFormatDates>[0];
  end?: Parameters<typeof useFormatDates>[1];
  picture?: CoverCardProps['cover'];
  courseId?: ActionsProps['programId'];
  group?: TextProps['children'];
  showActions?: boolean;
  isEditable?: boolean;
};

export const EditableCourseCard: FC<EditableCourseCardProps> = ({
  id,
  label,
  begin,
  end,
  picture,
  courseId,
  group,
  showActions,
  isEditable
}) => {
  const formattedDate = useFormatDates(begin, end);
  const redirectPath = useRedirectPath();

  return (
    <CoverCard
      title={label}
      to={redirectPath.instructorCourse({ courseId: id })}
      cover={picture}
      action={
        isEditable && <Link to={redirectPath.coursesEdit({ courseId: id })} type='stroke-secondary-square' size='small' icon='edit' />
      }
      footer={showActions && <Actions courseId={id} programId={courseId} />}
    >
      <div className={styles.stats}>
        <Text icon='calendar'>{formattedDate}</Text>
        <Text icon='students'>{group}</Text>
      </div>
    </CoverCard>
  );
};
