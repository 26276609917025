import { FC, ReactNode, useMemo } from 'react';
import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { Layout } from 'containers';
import { Link } from 'common/ui/_new';
import { IResultPageProps, ResultPage } from 'common/components/_new';
import { useRedirectPath } from 'common/hooks';

interface IResponseBoundaryProps {
  error: FetchBaseQueryError | SerializedError | undefined;
  children: ReactNode;
  responseConfig?: {
    [key: number]: IResultPageProps;
  };
}

export const ResponseBoundary: FC<IResponseBoundaryProps> = ({ error, children, responseConfig }) => {
  const redirectPath = useRedirectPath();
  const defaultProps = useMemo<IResultPageProps>(
    () => ({
      status: 'warning',
      title: 'Произошла ошибка',
      extra: (
        <Link type='primary' to={redirectPath.fallback()}>
          На главную
        </Link>
      )
    }),
    [redirectPath]
  );
  const resultProps = useMemo<IResultPageProps>(() => {
    // @ts-ignore
    return responseConfig ? responseConfig[error?.status] || defaultProps : defaultProps;
  }, [error, responseConfig, defaultProps]);

  if (!error) {
    return <>{children}</>;
  }
  return (
    <Layout centered>
      <ResultPage {...resultProps} />
    </Layout>
  );
};
