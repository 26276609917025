import type { IAudioSlide, IEditAudioSlide, ISaveAudioSlide } from 'types/requests/instructor/slides.slide_audio.api';
import { slidesApi } from '../slides.api';

const slideAudioApi = slidesApi.injectEndpoints({
  endpoints: ({ query, mutation }) => ({
    // API Создание страницы типа Audio.
    saveAudioSlide: mutation<ISaveAudioSlide.Response, ISaveAudioSlide.Params>({
      query: ({ exerciseId, body }) => ({
        url: `/api/instructors/Exercises/${exerciseId}/Pages/Audio`,
        method: 'POST',
        body
      }),
      invalidatesTags: ['Slides', 'Audio']
    }),
    // API информация страницы типа Audio.
    getAudioSlide: query<IAudioSlide.Response, IAudioSlide.Params>({
      query: ({ exerciseId, pageId }) => `/api/instructors/Exercises/${exerciseId}/Pages/${pageId}/Audio`,
      providesTags: ['Audio']
    }),
    // API Редактирование страницы типа Audio.
    editAudioSlide: mutation<IEditAudioSlide.Response, IEditAudioSlide.Params>({
      query: ({ exerciseId, pageId, body }) => ({
        url: `/api/instructors/Exercises/${exerciseId}/Pages/${pageId}/Audio`,
        method: 'PUT',
        body
      }),
      invalidatesTags: ['Slides', 'Audio']
    })
  })
});

export const { useGetAudioSlideQuery, useEditAudioSlideMutation, useSaveAudioSlideMutation } = slideAudioApi;
