import { ISelectProps, Tooltip } from 'common/ui/_new';
import { IHierarchyItemValue } from 'types/entities';

export const getSelectedNodes = (nodes: IHierarchyItemValue[]) => {
  const flatArray: ISelectProps[] = [];

  const flattenArray = (nestedArray: IHierarchyItemValue[]) => {
    for (let i = 0; i < nestedArray.length; i++) {
      const { id, text, nodes, isSelected } = nestedArray[i];

      if (isSelected) {
        flatArray.push({ value: { label: <Tooltip title={text}>{text}</Tooltip>, value: id } });

        if (nodes && nodes.length > 0) {
          flattenArray(nodes);
        }
      }
    }
  };

  flattenArray(nodes);

  return flatArray;
};
