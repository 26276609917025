import { ChangeEvent, useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Flex } from 'antd';
import { ContentLayout, QueryResponseBoundary } from 'containers/_new';
import { CoverCard } from 'common/ui';
import { Button, Search, Text } from 'common/ui/_new';
import { getDateLocale } from 'common/helpers';
import { useDebouncedState, useRedirectPath } from 'common/hooks';
import { useGetCoursesByCatalogFiltersQuery } from 'api/guest/catalog/catalog.api';
import { IFormattedCourse } from 'types/entities';
import styles from './styles.module.scss';

const DEFAULT_TAKE = 5;

export const List = () => {
  const { t, i18n } = useTranslation('common', { keyPrefix: 'components.news' });
  const [skip, setSkip] = useState<number>(0);
  const [visibleCourses, setVisibleCourses] = useState<IFormattedCourse[]>([]);
  const [debouncedValue, setSearchValue, prevValue] = useDebouncedState();
  const isNewList = useRef<boolean>(false);
  const redirectPath = useRedirectPath();

  useEffect(() => {
    if (debouncedValue !== '' || prevValue !== '') {
      setSkip(0);
      isNewList.current = true;
    }
  }, [debouncedValue, prevValue]);
  const coursesQuery = useGetCoursesByCatalogFiltersQuery({ take: DEFAULT_TAKE, skip, body: { search: debouncedValue } });

  const showLoadMoreButton =
    typeof coursesQuery.data !== 'undefined' && coursesQuery.data?.length === DEFAULT_TAKE && visibleCourses.length !== 0;

  useLayoutEffect(() => {
    if (!coursesQuery.data) return;

    setVisibleCourses(isNewList.current ? coursesQuery.data : [...visibleCourses, ...coursesQuery.data]);
    isNewList.current = false;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [coursesQuery.data]);

  const onLoadMoreClick = useCallback(() => {
    setSkip(skip + DEFAULT_TAKE);
    isNewList.current = false;
  }, [skip]);

  const onSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
  };

  return (
    <ContentLayout tile>
      <Flex vertical gap={32}>
        <Search placeholder={t('list.search')} onChange={onSearchChange} loading={coursesQuery.isLoading || coursesQuery.isFetching} />
        <QueryResponseBoundary query={coursesQuery} empty={{ description: t('list.empty') }}>
          <div className={styles.courses}>
            {visibleCourses.map((course) => {
              return (
                <CoverCard
                  key={course.id}
                  title={course.label}
                  to={redirectPath.coursePreview({ courseId: course.id })}
                  cover={course.picture}
                >
                  <div className={styles.dates}>
                    <Text icon='calendar'>
                      {t('card.start-date')}: {getDateLocale(new Date(course.begin), i18n, 'LL')}
                    </Text>
                    <Text icon='clock'>
                      {t('card.end-date')}: {getDateLocale(new Date(course.end), i18n, 'LL')}
                    </Text>
                  </div>
                </CoverCard>
              );
            })}
          </div>
        </QueryResponseBoundary>
        {showLoadMoreButton && (
          <Button loading={coursesQuery.isFetching} onClick={onLoadMoreClick} className={styles.showMoreBtn}>
            {t('list.show-more')}
          </Button>
        )}
      </Flex>
    </ContentLayout>
  );
};

//! bug: redundant items render after clearing of the search query
