import { UseTranslationResponse } from 'react-i18next';
import { ISlideTypes } from 'types/common';
import { IExerciseSlide } from 'types/entities';

export const getSlideTypeByLabel = (
  { type, isHiddenAsResourse: isEditable }: IExerciseSlide,
  { t }: Pick<UseTranslationResponse<'common', 'helpers.get-slide-type-by-label'>, 't'>
): ISlideTypes | null => {
  const map = new Map<string, ISlideTypes>([
    [t('evaluation'), 'evaluation'],
    [t('content'), 'content'],
    [t('picture'), 'picture'],
    [t('file'), 'file'],
    [t('free.string'), isEditable ? 'editable_free' : 'free'],
    [t('answers'), isEditable ? 'editable_answers' : 'answers'],
    [t('web'), 'web'],
    [t('pdf'), 'pdf'],
    [t('audio'), 'audio'],
    [t('video'), 'video'],
    [t('external'), 'external'],
    [t('attributes'), 'attributes'],
    [t('pptx'), 'pptx'],
    [t('free.date'), isEditable ? 'editable_free' : 'free'],
    [t('free.number'), isEditable ? 'editable_free' : 'free'],
    [t('free.float'), isEditable ? 'editable_free' : 'free'],
    [t('free.boolean'), isEditable ? 'editable_free' : 'free'],
    [t('free.text'), isEditable ? 'editable_free' : 'free'],
    [t('kinescope'), 'kinescope']
  ]);
  return map.get(type) || null;
};
