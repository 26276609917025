export const flattenParamsWithArray = (params: object): URLSearchParams => {
  const flatParams = new URLSearchParams();
  Object.entries(params).forEach(([key, value]) => {
    if (value !== undefined && value !== null) {
      if (Array.isArray(value)) {
        value.forEach((v) => {
          flatParams.append(key, String(v));
        });
      } else {
        flatParams.append(key, String(value));
      }
    }
  });

  return flatParams;
};
