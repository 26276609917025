import { InternalNamePath } from 'antd/lib/form/interface';
import dayjs from 'dayjs';
import { optionalInputBlocked } from 'common/ui/_new';
import {
  FieldValue,
  FileValue,
  FormItemValue,
  isAddress,
  isCompany,
  isDate,
  isFieldValue,
  isFileValue,
  isString
} from 'common/components/_new';
import { AttributesField, AttributeTypeEnum, IProfileField, IProfileFiles } from 'types/entities';

import { TFunction } from 'i18next';

export const convertFormFieldValue = (
  key: string,
  value: FieldValue,
  type: AttributeTypeEnum,
  isHasNoneAttribute?: AttributesField['isHasNoneAttribute']
): IProfileField => {
  if (isString(type, value)) {
    if (isHasNoneAttribute && value === optionalInputBlocked) {
      return {
        attributeId: key,
        isHasNoneValue: true
      };
    }

    return {
      attributeId: key,
      value
    };
  }

  if (isDate(type, value)) {
    return {
      attributeId: key,
      value: dayjs(value).utc().format()
    };
  }

  if (isAddress(type, value)) {
    return {
      attributeId: key,
      address: {
        addressName: value
      }
    };
  }

  if (isCompany(type, value)) {
    const { companyName, addressName, inn, kpp } = value;

    return {
      attributeId: key,
      company: {
        companyName,
        addressName,
        inn,
        kpp
      }
    };
  }

  return {
    attributeId: key,
    value: value.toString()
  };
};

export const getProfileQueryBodyData = (
  values: Record<string, FieldValue | FileValue>,
  attributes: AttributesField[],
  t: TFunction<'Students', 'pages.profile.profile-attributes-form'>
) => {
  let filesIndex = 0;

  return Object.keys(values).reduce<{ fieldsData: IProfileField[]; filesData: IProfileFiles }>(
    (result, key) => {
      const value: FormItemValue = values[key];
      if (value === undefined) {
        return result;
      }

      const attribute = attributes.find(({ id }) => id === key);
      if (!attribute) {
        return result;
      }

      if (isFieldValue(attribute.type, value)) {
        const { type, isHasNoneAttribute } = attribute;
        result.fieldsData.push(convertFormFieldValue(key, value, type, isHasNoneAttribute));
        return result;
      }
      if (isFileValue(attribute.type, value)) {
        if (value.size) {
          result.filesData.append(`files[${filesIndex}].attributeId`, key);
          result.filesData.append(`files[${filesIndex}].file`, value);
          result.filesData.append(`files[${filesIndex}].isSaved`, 'true');
          filesIndex++;
        }
        return result;
      }

      throw new Error(t('get-profile-query-body-data.type-match-error'));
    },
    { fieldsData: [], filesData: new FormData() }
  );
};

export const getValidValues = (
  errorFields: {
    name: InternalNamePath;
    errors: string[];
  }[],
  values: Record<AttributesField['id'], FieldValue>
) => {
  const validValuesArray = Object.keys(values).filter((attributeId) => !errorFields.some(({ name }) => name?.includes(attributeId)));

  const validValuesObject = validValuesArray.reduce((obj: Record<AttributesField['id'], FieldValue>, key) => {
    obj[key] = values[key];
    return obj;
  }, {});

  return validValuesObject;
};
//! similar functionality to 'getProfileQueryBodyData' in 'pages/student/Education/components/SlidesCarousel/components/ContentPage/components/AttributesSlide/helper.ts'
