import { accountRelationApi } from './accountRelation/accountRelation.api';
import { attributesApi } from './attributes/attributes.api';
import { calendarApi } from './calendar/calendar.api';
import { competenciesApi } from './competencies/competencies.api';
import { coursesApi } from './courses/courses.api';
import { dadataApi } from './dadata/dadata.api';
import { dictionaryApi } from './dictionary/dictionary.api';
import { educationApi } from './education/education.api';
import { expulsionApi } from './expulsion/expulsion.api';
import { hierarchiesApi } from './hierarchies/hierarchies.api';
import { linksApi } from './links/links.api';
import { newsApi } from './news/news.api';
import { notificationApi } from './notifications/notifications.api';
import { passwordApi } from './password/password.api';
import { profileApi } from './profile/profile.api';
import { refineApi } from './refine/refine.api';
import { traceApi } from './trace/trace.api';

export const studentApiReducers = {
  [accountRelationApi.reducerPath]: accountRelationApi.reducer,
  [attributesApi.reducerPath]: attributesApi.reducer,
  [calendarApi.reducerPath]: calendarApi.reducer,
  [competenciesApi.reducerPath]: competenciesApi.reducer,
  [coursesApi.reducerPath]: coursesApi.reducer,
  [dadataApi.reducerPath]: dadataApi.reducer,
  [dictionaryApi.reducerPath]: dictionaryApi.reducer,
  [educationApi.reducerPath]: educationApi.reducer,
  [expulsionApi.reducerPath]: expulsionApi.reducer,
  [hierarchiesApi.reducerPath]: hierarchiesApi.reducer,
  [linksApi.reducerPath]: linksApi.reducer,
  [newsApi.reducerPath]: newsApi.reducer,
  [notificationApi.reducerPath]: notificationApi.reducer,
  [passwordApi.reducerPath]: passwordApi.reducer,
  [profileApi.reducerPath]: profileApi.reducer,
  [refineApi.reducerPath]: refineApi.reducer,
  [traceApi.reducerPath]: traceApi.reducer
};

export const studentApiMiddlewares = [
  accountRelationApi.middleware,
  attributesApi.middleware,
  calendarApi.middleware,
  competenciesApi.middleware,
  coursesApi.middleware,
  dadataApi.middleware,
  dictionaryApi.middleware,
  educationApi.middleware,
  expulsionApi.middleware,
  hierarchiesApi.middleware,
  linksApi.middleware,
  newsApi.middleware,
  notificationApi.middleware,
  passwordApi.middleware,
  profileApi.middleware,
  refineApi.middleware,
  traceApi.middleware
];
