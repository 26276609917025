const decodeFilename = (filename: string) => {
  const startIndex = filename.indexOf(`filename*=UTF-8''`);
  const encodedPart = filename.substring(startIndex + `filename*=UTF-8''`.length);
  return decodeURIComponent(encodedPart);
};

export const enforceResponseFileDownload = async (response: Response) => {
  //Файл не скачается если ответ с ошибкой
  if (response.ok) {
    const fileName = response.headers.get('content-disposition');
    const url = window.URL.createObjectURL(await response.blob());
    const a = document.createElement('a');
    a.href = url;
    a.download = fileName ? decodeFilename(fileName) : 'Файл';
    document.body.appendChild(a);
    a.click();
    a.remove();
  }
};
