import { FC, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuill } from 'react-quilljs';
import DisabledContext from 'antd/es/config-provider/DisabledContext';
import { sanitize } from 'common/helpers';
import { convertToQuillFormat, fontSizes, formats, modules } from './helper';
import { ImageInsertButton } from './ImageInsertButton';
import { TextEditorProps } from './types';
import 'quill/dist/quill.snow.css';
import './TextEditor.scss';

// eslint-disable-next-line import/no-named-as-default
import Quill from 'quill';
import { SizeStyle } from 'quill/formats/size';
const SizeStyleAttributor = Quill.import('attributors/style/size') as typeof SizeStyle;
SizeStyleAttributor.whitelist = fontSizes;
Quill.register(SizeStyleAttributor, true);

export const TextEditor: FC<TextEditorProps> = (props) => {
  const { t } = useTranslation('common', { keyPrefix: 'ui.text-editor' });
  const { value, onChange, disabled: customDisabled, placeholder = t('placeholder') } = props;

  const { quill, quillRef } = useQuill({
    modules,
    formats,
    placeholder
  });

  useEffect(() => {
    if (!quill) {
      return;
    }

    quill.root.innerHTML = value ? String(sanitize(convertToQuillFormat(value))) : '';

    quill.on('text-change', () => {
      onChange?.(quill.root.innerHTML);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [quill]);

  const disabled = useContext(DisabledContext);
  const mergedDisabled = customDisabled !== null && customDisabled !== void 0 ? customDisabled : disabled;

  useEffect(() => {
    if (mergedDisabled) {
      quill?.disable();
    } else {
      quill?.enable();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mergedDisabled]);

  return (
    <div className='quill'>
      <div id='toolbar'>
        <span className='ql-formats'>
          <select className='ql-size' defaultValue='16px'>
            {fontSizes.map((size, i) => (
              <option key={i} value={size}>
                {size}
              </option>
            ))}
          </select>
        </span>
        <span className='ql-formats'>
          <button className='ql-bold' />
          <button className='ql-italic' />
          <button className='ql-underline' />
        </span>
        <span className='ql-formats'>
          <select className='ql-color' />
        </span>
        <span className='ql-formats'>
          <button className='ql-align' value='' />
          <button className='ql-align' value='center' />
          <button className='ql-align' value='right' />
        </span>
        <span className='ql-formats'>
          <button className='ql-list' value='ordered' />
          <button className='ql-list' value='bullet' />
        </span>
        <span className='ql-formats'>
          <button className='ql-link' />
          {quill && <ImageInsertButton quill={quill} />}
          <button className='ql-video' />
        </span>
        <span className='ql-formats'>
          <button className='ql-code-block' />
        </span>
      </div>
      <div ref={quillRef} />
    </div>
  );
};

/* 
!: Quill не поддерживает функционал работы с таблицами
*/
