import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, IButtonProps, useNotification } from 'common/ui/_new';
import { useRedirectPath } from 'common/hooks';
import { useJoinCourseMutation } from 'api/guest/invitations/invitations.api';
import { ApplyForCourseButtonProps } from './types';

export const ApplyForCourseButton: FC<ApplyForCourseButtonProps> = ({ courseId }) => {
  const { t } = useTranslation('common', { keyPrefix: 'components.apply-for-course-button' });
  const notify = useNotification();

  const [joinCourse, { isLoading }] = useJoinCourseMutation();

  const redirect = useRedirectPath();
  const handleApplyForCourseClick: IButtonProps['onClick'] = () => {
    joinCourse({ courseId })
      .unwrap()
      .then(({ isApply, trainingId }) => {
        if (isApply) {
          notify.success({
            description: t('join-course-mutation.is-apply.notification.message')
          });
          return;
        }

        notify.success({
          description: t('join-course-mutation.is-not-apply.notification.message')
        });
        redirect.course({ courseId: trainingId });
      })
      .catch((error) => {
        if ('status' in error) {
          const { status } = error;

          if (status === 400) {
            if ('data' in error) {
              const { data } = error;

              if ('ErrorCode' in data && Array.isArray(data.ErrorCode)) {
                if (data.ErrorCode.includes('1')) {
                  notify.error({
                    description: t('join-course-mutation.error.400-1.notification.message')
                  });
                  redirect.course({ courseId });
                }

                if (data.ErrorCode.includes('3')) {
                  notify.error({
                    description: t('join-course-mutation.error.400-3.notification.message')
                  });
                }

                if (data.ErrorCode.includes('4')) {
                  notify.error({
                    description: t('join-course-mutation.error.400-4.notification.message')
                  });
                }
              }
            }
          }

          if (status === 404) {
            notify.error({
              description: t('join-course-mutation.error.404.notification.message')
            });
          }
        }
      });
  };

  return (
    <Button onClick={handleApplyForCourseClick} loading={isLoading} type='primary'>
      {t('label')}
    </Button>
  );
};
