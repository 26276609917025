import { Trans, useTranslation } from 'react-i18next';
import cn from 'classnames';
import { HTMLVideoPlayerProps, IVideoPlayer } from './types';
import styles from './styles.module.scss';

export const VideoPlayer: IVideoPlayer = (props) => {
  const { t } = useTranslation('common', { keyPrefix: 'ui.video-player' });
  const { src, sources, permissions, className, ...restProps } = props;

  const innerProps: HTMLVideoPlayerProps = {
    //* overirdable:
    controls: true,
    //* outer:
    ...restProps,
    //* unoverirdable:
    ...(!permissions?.download && { controlsList: 'nodownload' }),
    className: cn(styles.videolayer, className)
  };

  return (
    <video {...innerProps}>
      {sources && sources.map((sourceProps, i) => <source key={i} {...sourceProps} />)}
      {t('fallback-message.no-browser-support')}
      {permissions?.download && (
        <>
          {' '}
          <Trans i18nKey='ui.video-player.fallback-message.download-by-link'>
            <a href={src} download />
          </Trans>
        </>
      )}
    </video>
  );
};
