import { useEffect, useState } from 'react';
import { useGetAttributeGroupsQuery, useLazyGetAttributeItemQuery } from 'api/student/attributes/attributes.api';
import { AttributesField, IAttributesGroupItem } from 'types/entities';

export const useGetAttributeList = (): { data?: IAttributesGroupItem[]; isLoading: boolean } => {
  const [getAttributeItem] = useLazyGetAttributeItemQuery();
  const { data } = useGetAttributeGroupsQuery();

  const [result, setResult] = useState<IAttributesGroupItem[] | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    const getItems = async () => {
      try {
        setLoading(true);
        if (!data?.attributeGroups?.length) return;
        const fields: AttributesField[] = (
          await Promise.all(data.attributeGroups.map((group) => getAttributeItem({ id: group.id }).unwrap()))
        ).flat();

        const filteredAttributeGroups = data.attributeGroups.filter((group) => {
          return fields && fields.find((item) => item.attributeGroupId === group.id);
        });
        setResult(filteredAttributeGroups);
        setLoading(false);
      } catch (e) {
        console.log(e);
      }
    };
    getItems();
  }, [data?.attributeGroups, getAttributeItem]);

  return { data: result, isLoading: loading };
};
