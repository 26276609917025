import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'common/ui/_new';
import { useAppSelector, useRedirectPath } from 'common/hooks';
import { profileSelectors } from 'api/student/profile/profile.selectors';
import { ErrorResultPage, ErrorResultPageProps } from '../common';

export const CourseInvitationUnavailableErrorPage: FC<ErrorResultPageProps> = (props) => {
  const redirectPath = useRedirectPath();
  const { t } = useTranslation('common', { keyPrefix: 'components.error-page.course-invitation-unavailable' });
  const { role } = useAppSelector(profileSelectors.role);

  return (
    <ErrorResultPage
      subTitle={t('sub-title')}
      extra={
        <Link
          {...(role === 'Guest'
            ? { to: redirectPath.welcome(), state: { redirectPath: redirectPath.studentsCatalog() } }
            : { to: redirectPath.studentsCatalog() })}
          type='primary'
        >
          {t('link')}
        </Link>
      }
      {...props}
    />
  );
};
