import { FC, Fragment, useMemo } from 'react';
import { Col } from 'antd';
import { Form, Input } from 'common/ui/_new';
import { IFormFieldProps } from 'types/entities';

export const FormField: FC<IFormFieldProps> = (props) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { component: Component = Input, colProps = null, inputProps, children, optionsId, ...formItemProps } = props;

  const extendedInputProps = { ...inputProps };

  const Wrapper = useMemo<typeof Col | typeof Fragment>(() => (colProps ? Col : Fragment), [colProps]);

  if (!Object.keys(props).length) {
    return null;
  }

  return (
    <Wrapper {...colProps}>
      <Form.Item {...formItemProps}>
        <Component {...extendedInputProps}>{children}</Component>
      </Form.Item>
    </Wrapper>
  );
};
