import { FC } from 'react';
import { Col, Row } from 'antd';
import { Layout as LayoutContainer } from 'containers';
import { Contents, Siders } from '../components';
import { ILeftSiderPageLayoutProps } from './types';

export const LeftSiderPageLayout: FC<ILeftSiderPageLayoutProps> = ({ header, siders, children, ...restProps }) => {
  const areSidersProvided = siders.some((sider) => sider);

  return (
    <LayoutContainer showBreadcrumbs {...restProps}>
      <Row
        gutter={[
          { xs: 24, sm: 24, lg: 24, xl: 32 },
          { xs: 24, sm: 24, lg: 24, xl: 32 }
        ]}
      >
        {header && <Col span={24}>{header}</Col>}
        {areSidersProvided && (
          <Col xs={24} lg={6}>
            <Siders>{siders}</Siders>
          </Col>
        )}
        <Col xs={24} {...(areSidersProvided && { lg: 18 })}>
          <Contents>{children}</Contents>
        </Col>
      </Row>
    </LayoutContainer>
  );
};
