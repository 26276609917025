import { useTranslation } from 'react-i18next';
import { Button } from 'common/ui/_new';
import { useRedirect } from 'common/hooks';
import styles from './styles.module.scss';

export const SlideFooter = () => {
  const { t } = useTranslation('common', { keyPrefix: 'components.slide-footer' });
  const redirectTo = useRedirect();

  return (
    <div className={styles.footer}>
      <Button onClick={() => redirectTo.move({ number: -1 })}>{t('cancel-button.label')}</Button>
      <Button htmlType='submit' type='primary'>
        {t('save-button.label')}
      </Button>
    </div>
  );
};
