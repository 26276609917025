import { Id } from 'types/common';
import { ExerciseId } from 'types/entities';

export enum IEditableAnswersEnum {
  Checkbox = 0,
  Radio = 1
}

export interface IEditableAnswer {
  id: Id;
  exerciseId: ExerciseId;
  label: string;
  course?: string;
  response?: IEditableAnswersEnum.Checkbox | IEditableAnswersEnum.Radio;
  text?: string;
  evaluate?: boolean;
  shuffle?: boolean;
  isEditableOnPage?: boolean;
}
