import { FC } from 'react';
import cn from 'classnames';
import { Attachment } from './Item';
import { AttachmentsItem, AttachmentsProps } from './types';
import styles from './styles.module.scss';

export const Attachments: FC<AttachmentsProps> = ({ items, className }) => {
  if (!items?.length) {
    return null;
  }

  return (
    <div className={cn(styles.files, className)}>
      {(items.filter(({ title, address }) => title && address) as Required<AttachmentsItem>[]).map(({ title, address }, i) => (
        <Attachment key={i} to={address.startsWith('/') ? `${process.env.REACT_APP_API}${address}` : address}>
          {title}
        </Attachment>
      ))}
    </div>
  );
};
