import type { IEditFileSlide, IFileSlide, ISaveFileSlide } from 'types/requests/instructor/slides.slide_file.api';
import { slidesApi } from '../slides.api';

const slideFileApi = slidesApi.injectEndpoints({
  endpoints: ({ query, mutation }) => ({
    // API Создание страницы типа File.
    saveFileSlide: mutation<ISaveFileSlide.Response, ISaveFileSlide.Params>({
      query: ({ exerciseId, body }) => ({
        url: `/api/instructors/Exercises/${exerciseId}/Pages/File`,
        method: 'POST',
        body
      }),
      invalidatesTags: ['Slides', 'File']
    }),
    // API получения страницы типа File.
    getFileSlide: query<IFileSlide.Response, IFileSlide.Params>({
      query: ({ exerciseId, pageId }) => `/api/instructors/Exercises/${exerciseId}/Pages/${pageId}/File`,
      providesTags: ['File']
    }),
    // Редактирование страницы типа File.
    editFileSlide: mutation<IEditFileSlide.Response, IEditFileSlide.Params>({
      query: ({ exerciseId, pageId, body }) => ({
        url: `/api/instructors/Exercises/${exerciseId}/Pages/${pageId}/File`,
        method: 'PUT',
        body
      }),
      invalidatesTags: ['Slides', 'File']
    })
  })
});

export const { useEditFileSlideMutation, useGetFileSlideQuery, useSaveFileSlideMutation } = slideFileApi;
