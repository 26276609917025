import { FC } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { IModalProps, Modal } from 'common/ui/_new';
import { ISlideTypes } from 'types/common';
import { SLIDE } from './helper';

export const PreviewSlideModal: FC<IModalProps> = (props) => {
  const { t } = useTranslation('common', { keyPrefix: 'components.preview-slide-modal' });
  const [searchParams] = useSearchParams();
  const type = searchParams.get('type') as ISlideTypes;

  return (
    <Modal title={t('title')} width='1000px' {...props}>
      {SLIDE[type]}
    </Modal>
  );
};

// todo: add i18n
