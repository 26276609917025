import { FC } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { QueryResponseBoundary } from 'containers/_new';
import { HtmlContent } from 'common/ui/_new';
import { useGetEvaluationSlideQuery } from 'api/instructor/slides/endpoints/slide_evaluation.endpoint';
import { InstructorParams } from 'types/routes/instructor';

export const EvaluationPreviewModal: FC = () => {
  const { exerciseId = '' } = useParams<InstructorParams.Exercise>();
  const [searchParams] = useSearchParams();

  const evaluationQuery = useGetEvaluationSlideQuery({ exerciseId, pageId: searchParams.get('slideId') || '' });

  return (
    <QueryResponseBoundary query={evaluationQuery}>
      <HtmlContent content={evaluationQuery.data?.text} />
    </QueryResponseBoundary>
  );
};
