import { FC } from 'react';
import { HtmlContent, Title } from 'common/ui/_new';
import { ExerciseProps } from './types';
import styles from './styles.module.scss';

export const Exercise: FC<ExerciseProps> = ({ label, description }) => {
  return (
    <div className={styles.exercise}>
      <Title
        level={5}
        ellipsis={{
          tooltip: label,
          rows: 2
        }}
        className={styles.title}
      >
        {label}
      </Title>
      {description && <HtmlContent content={description} ellipsis={{ lines: 2 }} />}
    </div>
  );
};
