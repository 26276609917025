import { createApi } from '@reduxjs/toolkit/query/react';
import { getBaseQuery } from 'common/helpers';

export const exercisesApi = createApi({
  reducerPath: 'exercisesApi',
  baseQuery: getBaseQuery(),
  tagTypes: [
    'CoursesConstraints',
    'AttributesConstraints',
    'ExerciseFiles',
    'SortExercises',
    'Exercises',
    'Exercise',
    'ExerciseLocation',
    'ExerciseTrainingTraceAdditionalFields'
  ],
  endpoints: () => ({})
});
