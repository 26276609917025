import { Badge } from 'antd';
import { useGetNotificationsQuery } from 'api/student/notifications/notifications.api';
import { INotification } from 'types/entities';
import styles from './styles.module.scss';

export const Notifications = () => {
  const notificationQuery = useGetNotificationsQuery({ notificationsType: 0, offset: 1, limit: 100 });
  const notifications = notificationQuery.data?.data;
  const unreadCount = notifications?.filter((item: INotification) => !item.read).length;
  return <Badge count={unreadCount} overflowCount={99} className={styles.badge} />;
};
