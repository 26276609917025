import { createApi } from '@reduxjs/toolkit/query/react';
import dayjs from 'dayjs';
import { getBaseQuery } from 'common/helpers';
import type { ICalendarDay, ICalendarDays } from 'types/requests/student/calendar.api';

export const calendarApi = createApi({
  reducerPath: 'calendarApi',
  baseQuery: getBaseQuery(),
  endpoints: ({ query }) => ({
    getCalendarDays: query<ICalendarDays.Response, ICalendarDays.Params>({
      query: ({ currentMonth }) => `/api/students/trainings/${dayjs(currentMonth).format('YYYY-MM')}/highlight`
    }),
    getEventsByDate: query<ICalendarDay.Response, ICalendarDay.Params>({
      query: ({ chosenDate }) => `/api/students/trainings/${dayjs(chosenDate).format('YYYY-MM-DD')}`
    })
  })
});
export const { useGetCalendarDaysQuery, useLazyGetEventsByDateQuery, useGetEventsByDateQuery } = calendarApi;
