import { ComponentProps, FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import AntParagraph from 'antd/es/typography/Paragraph';
import cn from 'classnames';
import { useMediaQuery } from 'common/hooks';
import { getEllipsisProps } from '../helper';
import { ParagraphProps } from './types';
import styles from './styles.module.scss';
import styleVar from 'common/styles/responsive.scss';

export const Paragraph: FC<ParagraphProps> = ({ ellipsis, className, children, ...restProps }) => {
  const { t } = useTranslation('common', { keyPrefix: 'components.typography.common.ellipsis' });

  const [isNoHover] = useMediaQuery([styleVar.noHoverMediaQuery]);
  const [expanded, setExpanded] = useState<boolean>(false);

  const innerProps: ComponentProps<typeof AntParagraph> = {
    //* overridable:
    //* outer:
    ...restProps,
    //* unoverridable:
    ellipsis: getEllipsisProps(ellipsis, expanded, setExpanded, t, isNoHover),
    className: cn(styles.paragraph, className)
  };

  return <AntParagraph {...innerProps}>{children}</AntParagraph>;
};
