import type { IEditPptxSlide, IPptxSlide, ISavePptxSlide } from 'types/requests/instructor/slides.slide_pptx.api';
import { slidesApi } from '../slides.api';

const slidePptxApi = slidesApi.injectEndpoints({
  endpoints: ({ query, mutation }) => ({
    // API Создание страницы типа PPTX.
    savePptxSlide: mutation<ISavePptxSlide.Response, ISavePptxSlide.Params>({
      query: ({ exerciseId, body }) => ({
        url: `/api/instructors/Exercises/${exerciseId}/Pages/PPTX`,
        method: 'POST',
        body
      }),
      invalidatesTags: ['Slides', 'Pptx']
    }),
    // API получение страницы типа PPTX.
    getPptxSlide: query<IPptxSlide.Response, IPptxSlide.Params>({
      query: ({ exerciseId, pageId }) => `/api/instructors/Exercises/${exerciseId}/Pages/${pageId}/PPTX`,
      providesTags: ['Pptx']
    }),
    // API Редактирование страницы типа PPTX.
    editPptxSlide: mutation<IEditPptxSlide.Response, IEditPptxSlide.Params>({
      query: ({ exerciseId, pageId, body }) => ({
        url: `/api/instructors/Exercises/${exerciseId}/Pages/${pageId}/PPTX`,
        method: 'PUT',
        body
      }),
      invalidatesTags: ['Slides', 'Pptx']
    })
  })
});

export const { useEditPptxSlideMutation, useGetPptxSlideQuery, useSavePptxSlideMutation } = slidePptxApi;
