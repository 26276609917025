import { useContext, useLayoutEffect } from 'react';
import { ConfigProvider, notification } from 'antd';
import { ArgsProps } from 'antd/lib/notification';
import { NotificationContext } from 'containers';
import { AntConfigProvider } from 'containers/_new';

import { StyleProvider } from '@ant-design/cssinjs';

const COMMON: Omit<ArgsProps, 'message'> = {
  duration: 3
};
const ERROR_ARGS: ArgsProps = { message: 'Что-то пошло не так', ...COMMON };
const UPDATED_ARGS: ArgsProps = { message: 'Успешно обновлено', ...COMMON };
const CREATED_ARGS: ArgsProps = { message: 'Успешно сохранено', ...COMMON };
const DELETED_ARGS: ArgsProps = { message: 'Успешно удалено', ...COMMON };
const DEVELOPMENT_ARGS: ArgsProps = { message: 'Эта функция находится в разработке', ...COMMON };

export type notificationProps = ArgsProps;

/**
 * @deprecated use useNotification from common/ui
 */
export const useNotification = () => {
  const { locale, theme } = useContext(ConfigProvider.ConfigContext);
  useLayoutEffect(() => {
    ConfigProvider.config({
      holderRender: (children) => (
        <StyleProvider hashPriority='high'>
          <AntConfigProvider>{children}</AntConfigProvider>
        </StyleProvider>
      )
    });
  }, [locale, theme]);

  const { openNotification } = useContext(NotificationContext);
  const notify = (props: notificationProps) => openNotification(props);

  return Object.assign(notify, {
    onError: () => notification.error(ERROR_ARGS),
    onUpdated: () => notification.success(UPDATED_ARGS),
    onCreated: () => notification.success(CREATED_ARGS),
    onDeleted: () => notification.success(DELETED_ARGS),
    inDevelopment: () => notification.info(DEVELOPMENT_ARGS)
  });
};

// todo: remove onError, onUpdated, onCreated, onDeleted, inDevelopment
