import { ItemType as AntItemType } from 'antd/lib/menu/hooks/useItems';
import { IDropdownItem } from './types';

export const getPermittedDropdownItems = (items: IDropdownItem[]): AntItemType[] =>
  items?.reduce((result: AntItemType[], item) => {
    if (item.isPermitted || item.isPermitted === undefined) {
      // eslint-disable-next-line
      const { isPermitted, ...rest } = item;
      result.push(rest);
    }
    return result;
  }, []);
