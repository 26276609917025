import { FC } from 'react';
import cn from 'classnames';
import { Radio } from 'common/ui/_new';
import { IRadioQuestionProps } from './types';
import styles from './styles.module.scss';

export const RadioQuestion: FC<IRadioQuestionProps> = ({ options, isAnswered, size = 'large', className, ...outerProps }) => {
  if (isAnswered) {
    return (
      <Radio.Group
        {...outerProps}
        value={options.find(({ checked }) => checked)?.value}
        disabled={true}
        className={cn(styles.radioQuestion, styles.isAnswered, { [styles.small]: size === 'small' }, className)}
      >
        {options.map(({ id, label, correct, ...rest }, i) => {
          return (
            <Radio
              key={id ?? i}
              className={cn(styles.radio, { [styles.correct]: correct === true, [styles.inCorrect]: correct === false })}
              {...rest}
            >
              {label}
            </Radio>
          );
        })}
      </Radio.Group>
    );
  }

  return (
    <Radio.Group {...outerProps} options={options} className={cn(styles.radioQuestion, { [styles.small]: size === 'small' }, className)} />
  );
};
