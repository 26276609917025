import AntIcon from '@ant-design/icons';
import cn from 'classnames';
import { getInitialProps } from './helper';
import { IIconProps } from './types';
import styles from './styles.module.scss';

export const Icon: React.FC<IIconProps> = ({ type, className, ...outerProps }) => {
  const { component, initialTypeStyleClassNames } = getInitialProps({ type, styles });

  const overridableProps: React.ComponentProps<typeof AntIcon> = {
    component
  };

  const unoverridableProps: React.ComponentProps<typeof AntIcon> = {
    className: cn(...initialTypeStyleClassNames, className)
  };

  return <AntIcon {...overridableProps} {...outerProps} {...unoverridableProps} />;
};

/*
todo: распределить иконки по использующим их компонентам
todo: убрать явное задание размера и цвета, чтобы использующий компонент переопределял их нативно
*/
