import { FC } from 'react';
import { Lesson } from './Lesson';
import { LessonsProps } from './types';
import styles from './styles.module.scss';

export const Lessons: FC<LessonsProps> = ({ items }) => {
  if (!items?.length) {
    return null;
  }

  return (
    <div className={styles.lessons}>
      {items.map((lessonProps) => (
        <Lesson key={lessonProps.id} {...lessonProps} />
      ))}
    </div>
  );
};
