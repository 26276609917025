import { ComponentProps } from 'react';
import { Upload as AntUpload } from 'antd';
import cn from 'classnames';
import { Dragger } from './Dragger';
import { IInternalUpload, IUpload } from './types';
import styles from './styles.module.scss';

const InternalUpload: IInternalUpload = ({ className, ...outerProps }) => {
  const overridableProps: ComponentProps<typeof AntUpload> = {};

  const unoverridableProps: ComponentProps<typeof AntUpload> = {
    className: cn(styles.upload, className)
  };

  return <AntUpload {...overridableProps} {...outerProps} {...unoverridableProps} />;
};

export const Upload: IUpload = Object.assign(InternalUpload, {
  Dragger,
  LIST_IGNORE: AntUpload.LIST_IGNORE
});
