import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { admin } from './admin';
import { guest } from './guest';
import { instructor } from './instructor';
import { student } from './student';

const rootReducer = combineReducers({
  ...guest.reducers,
  ...guest.slices,
  ...student.reducers,
  ...student.slices,
  ...instructor.reducers,
  ...instructor.slices,
  ...admin.reducers,
  ...admin.slices
});

export const setupStore = () => {
  return configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: false
      }).concat(...guest.middlewares, ...student.middlewares, ...instructor.middlewares, ...admin.middlewares)
  });
};

export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = AppStore['dispatch'];
