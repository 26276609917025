import { UseTranslationResponse } from 'react-i18next';
import { IIconProps, ITooltipProps } from 'common/ui/_new';
import { CourseStatusEnum } from 'types/entities';
import styles from './styles.module.scss';

export const getPropsByStatusMap = (
  status: Exclude<CourseStatusEnum, CourseStatusEnum.NOT_STARTED>,
  t: UseTranslationResponse<'Students', 'pages.course.lessons.lesson.exercises.exercise.status'>['t']
): {
  tooltipProps: ITooltipProps;
  iconProps: IIconProps;
} => {
  switch (status) {
    case CourseStatusEnum.IN_PROGRESS:
      return {
        tooltipProps: {
          title: t('in-progress')
        },
        iconProps: {
          type: 'on-review',
          className: styles.inProgress
        }
      };
    case CourseStatusEnum.EVALUATION:
      return {
        tooltipProps: {
          title: t('completed-to-verify')
        },
        iconProps: {
          type: 'clock',
          className: styles.toVerify
        }
      };
    case CourseStatusEnum.COMPLETED:
    case CourseStatusEnum.COMPLETED_WITHOUT_VERIFICATION:
      return {
        tooltipProps: {
          title: t('completed-not-to-verify')
        },
        iconProps: {
          type: 'done-solid',
          className: styles.noToVerify
        }
      };
  }
};
