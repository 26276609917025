import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { ILinkProps, Link } from 'common/ui/_new';
import styles from './styles.module.scss';

type OldVersionLink = Omit<ILinkProps, 'to'>;

export const OldVersionLink: FC<OldVersionLink> = ({ className }) => {
  const { t, i18n } = useTranslation('common', { keyPrefix: 'components.header.old-design-link' });

  const link = `${process.env.REACT_APP_OLD}/Profile/Language/${i18n.language}?back=%2FStudents`;

  return (
    <Link to={link} className={cn(styles.link, className)}>
      {t('label')}
    </Link>
  );
};
