import { createApi } from '@reduxjs/toolkit/query/react';
import { getBaseQuery } from 'common/helpers';
import type { ICreateNews, IEditNews, IInstructorNewsList, IInstructorSpecificNews } from 'types/requests/instructor/news.api';

export const instructorNewsApi = createApi({
  reducerPath: 'instructorNewsApi',
  baseQuery: getBaseQuery(),
  tagTypes: ['NewsList'],
  endpoints: ({ query, mutation }) => ({
    getInstructorNews: query<IInstructorNewsList.Response, IInstructorNewsList.Params>({
      query: ({ take, skip }) => ({
        url: '/api/instructors/NewsFeed',
        params: {
          take,
          skip
        }
      }),
      providesTags: ['NewsList']
    }),
    getInstructorNewsItem: query<IInstructorSpecificNews.Response, IInstructorSpecificNews.Params>({
      query: (id) => `/api/instructors/NewsFeed/${id}`
    }),
    createInstructorNewsItem: mutation<ICreateNews.Response, ICreateNews.Params>({
      query: (body) => ({
        url: `/api/instructors/NewsFeed/create`,
        method: 'POST',
        body
      }),
      invalidatesTags: ['NewsList']
    }),
    editInstructorNewsItem: mutation<IEditNews.Response, IEditNews.Params>({
      query: ({ id, body }) => ({
        url: `/api/instructors/NewsFeed/edit/${id}`,
        method: 'POST',
        body
      }),
      invalidatesTags: ['NewsList']
    })
  })
});

export const {
  useGetInstructorNewsQuery,
  useGetInstructorNewsItemQuery,
  useCreateInstructorNewsItemMutation,
  useEditInstructorNewsItemMutation
} = instructorNewsApi;
