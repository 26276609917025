import { FC } from 'react';
import { FloatButton } from 'antd';
import { Icon } from 'common/ui/_new';
import styles from './styles.module.scss';

export const BackToTopButton: FC = () => {
  return (
    <FloatButton.BackTop
      icon={<Icon type='arrow-up' className={styles.backToTopButton__icon} />}
      visibilityHeight={500}
      type='primary'
      className={styles.backToTopButton}
    />
  );
};
