import {
  IDropEditableAnswers,
  IEditEditableAnswers,
  IGetEditableAnswers,
  ISaveEditableAnswers
} from 'types/requests/instructor/slides.slide_editable-answers.api';
import { slidesApi } from '../slides.api';

const editableAnswersApi = slidesApi.injectEndpoints({
  endpoints: ({ query, mutation }) => ({
    saveEditableAnswers: mutation<ISaveEditableAnswers.Response, ISaveEditableAnswers.Params>({
      query: ({ body, exerciseId, courseId }) => ({
        url: '/api/Questions/Answers/CreateAttachedExercise',
        method: 'POST',
        body: {
          ...body,
          courseId,
          exerciseId
        }
      }),
      invalidatesTags: ['Slides', 'Editable_answers']
    }),
    getEditableAnswers: query<IGetEditableAnswers.Response, IGetEditableAnswers.Params>({
      query: (id) => `/api/questions/answers/${id}/edit`,
      providesTags: ['Editable_answers']
    }),
    editEditableAnswers: mutation<IEditEditableAnswers.Response, IEditEditableAnswers.Params>({
      query: ({ body, exerciseId, courseId, id }) => ({
        url: '/api/Questions/Answers/EditAttachedExercise',
        method: 'PUT',
        body: {
          ...body,
          id,
          courseId,
          exerciseId
        }
      }),
      invalidatesTags: ['Slides', 'Editable_answers']
    }),
    dropEditableAnswers: mutation<IDropEditableAnswers.Response, IDropEditableAnswers.Params>({
      query: (questionId) => ({
        url: `/api/questions/answers/${questionId}/Drop`,
        method: 'DELETE'
      })
    })
  })
});

export const {
  useSaveEditableAnswersMutation,
  useDropEditableAnswersMutation,
  useGetEditableAnswersQuery,
  useEditEditableAnswersMutation
} = editableAnswersApi;
