import { useCallback, useMemo, useRef, useState } from 'react';

export type useModalReturnType<T = undefined> = {
  open: (...props: T extends undefined ? never[] : [T]) => void;
  props: T | undefined;
  isOpen: boolean;
  close: () => void;
};

export const useModal = <T = undefined>(): useModalReturnType<T> => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const modalPropsRef = useRef<T>();

  const close = useCallback(() => setIsOpen(false), []);

  const open = useCallback((...props: [T] | never[]) => {
    modalPropsRef.current = props[0];
    setIsOpen(true);
  }, []);

  return useMemo(
    () => ({
      open,
      props: modalPropsRef.current,
      isOpen,
      close
    }),
    [close, isOpen, open]
  );
};
