import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useGetCourseByIdQuery } from 'api/instructor/courses/courses.api';
import { LessonView } from 'types/entities';

export const useProgramView = () => {
  const { programId = '' } = useParams();
  const courseQuery = useGetCourseByIdQuery(programId);
  const [lessonsList, setLessonsList] = useState<LessonView[]>([]);

  useEffect(() => {
    setLessonsList(courseQuery.data?.lessons || []);
  }, [courseQuery.data?.lessons]);

  return {
    lessonsList,
    lessonsCount: lessonsList.length,
    courseQuery
  };
};
