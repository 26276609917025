import { ComponentProps } from 'react';
import { Descriptions as AntDescriptions } from 'antd';
import cn from 'classnames';
import { IDescriptions } from './types';
import styles from './styles.module.scss';

export const Descriptions: IDescriptions = ({ className, ...outerProps }) => {
  const overridableProps: ComponentProps<typeof AntDescriptions> = {};

  const unoverridableProps: ComponentProps<typeof AntDescriptions> = {
    className: cn(styles.descriptions, className)
  };

  return <AntDescriptions {...overridableProps} {...outerProps} {...unoverridableProps} />;
};
