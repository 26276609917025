import { createSlice } from '@reduxjs/toolkit';
import { IAuthLinkAction, IAuthLinkSlice } from 'types/store/guest/auth-link.slice';

const initialState: IAuthLinkSlice = {
  authLink: undefined
};

export const authLinkSlice = createSlice({
  name: 'authLink',
  initialState,
  reducers: {
    setAuthLink: (state, action: IAuthLinkAction) => {
      state.authLink = action.payload;
      return state;
    }
  }
});

export const { actions: authLinkActions } = authLinkSlice;
