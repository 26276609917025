import { createApi } from '@reduxjs/toolkit/query/react';
import { getBaseQuery } from 'common/helpers';
import { IMarkAsReadNotification, INotifications } from 'types/requests/student/notifications.api';

export const notificationApi = createApi({
  reducerPath: 'notificationApi',
  baseQuery: getBaseQuery(),
  tagTypes: ['Notification'],
  endpoints: ({ query, mutation }) => ({
    getNotifications: query<INotifications.Response, INotifications.Params>({
      query: ({ notificationsType, offset, limit }) => ({
        url: '/api/notifications',
        params: { notificationsType, 'pagination.Offset': offset, 'pagination.Limit': limit }
      }),
      providesTags: ['Notification']
    }),
    markAsReadNotification: mutation<IMarkAsReadNotification.Response, IMarkAsReadNotification.Params>({
      query: (id) => ({
        url: `/api/notifications/${id}/read`,
        method: 'POST'
      }),
      invalidatesTags: ['Notification']
    })
  })
});

export const { useGetNotificationsQuery, useMarkAsReadNotificationMutation } = notificationApi;
