import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { IIconType } from 'common/ui/_new';
import { useGetCourseByIdQuery } from 'api/instructor/courses/courses.api';

const SummaryInitialObjects: { label: string; iconName: IIconType; count: number }[] = [
  {
    label: 'Самостоятельная работа',
    iconName: 'independent-work',
    count: 0
  },
  {
    label: 'Тестирование',
    iconName: 'testing',
    count: 0
  },
  {
    label: 'Лекции',
    iconName: 'lecture',
    count: 0
  }
];

export const useProgramSummary = () => {
  const { programId = '' } = useParams();
  const courseByIdQuery = useGetCourseByIdQuery(programId);
  const [summaryList, setSummaryList] = useState(SummaryInitialObjects);

  useEffect(() => {
    const summaryCounts = [courseByIdQuery.data?.tasks || 0, courseByIdQuery.data?.tests || 0, courseByIdQuery.data?.lectures || 0];
    setSummaryList(SummaryInitialObjects.map((item, index) => ({ ...item, count: summaryCounts[index] })));
  }, [courseByIdQuery.data?.lectures, courseByIdQuery.data?.lessons, courseByIdQuery.data?.tasks, courseByIdQuery.data?.tests]);

  return {
    query: courseByIdQuery,
    summaryList
  };
};
