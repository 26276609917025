import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { ILinkProps, Link } from 'common/ui/_new';
import { LogoIUen, LogoIUru } from './assets';
import styles from './styles.module.scss';

import { LANGUAGES } from 'i18n/constants';

type LogoProps = Omit<ILinkProps, 'to'>;

export const Logo: FC<LogoProps> = ({ className }) => {
  const { i18n } = useTranslation();

  const LogoSvg = i18n.language === LANGUAGES.ru ? LogoIUru : LogoIUen;

  return (
    <Link type='link' to='/' className={cn(styles.logo, className)}>
      <LogoSvg className={styles.logo__svg} />
    </Link>
  );
};
