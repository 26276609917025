import { createSlice } from '@reduxjs/toolkit';
import { IAuthErrorAction, IAuthErrorSlice } from 'types/store/guest/auth-error.slice';

const initialState: IAuthErrorSlice = {
  authError: undefined
};

export const authErrorSlice = createSlice({
  name: 'authError',
  initialState,
  reducers: {
    setAuthError: (state, action: IAuthErrorAction) => {
      state.authError = action.payload;
      return state;
    }
  }
});

export const { actions: authErrorActions } = authErrorSlice;
