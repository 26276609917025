import { FC } from 'react';
import { Col, Row } from 'antd';
import { NamePath } from 'antd/es/form/interface';
import { Button, Form } from 'common/ui/_new';
import { AttributeSelect } from './AttributeSelect';
import { AttributeFiltersFormListProps } from './types';

export const AttributeFiltersFormList: FC<AttributeFiltersFormListProps> = ({
  form,
  name,
  attributeFormItemLabel,
  attributeValueFormItemLabel
}) => {
  const clearFieldValue = (namePath: NamePath) => {
    form.setFieldValue([name, ...namePath], undefined);
  };

  return (
    <Form.List name={name}>
      {(fields, { add, remove }) => (
        <div style={{ marginBottom: 24 }}>
          {fields.map((field) => {
            return (
              <Row key={field.key} gutter={16}>
                <Col flex='auto'>
                  <AttributeSelect
                    clearFieldValue={clearFieldValue}
                    attributeFieldProps={{
                      name: [field.name, 'attribute'],
                      label: attributeFormItemLabel,
                      initialValue: form.getFieldValue([name, field.name, 'attribute']),
                      rules: [{ required: true }]
                    }}
                    attributeValueFieldProps={{
                      name: [field.name, 'attributeValue'],
                      label: attributeValueFormItemLabel,
                      rules: [{ required: true }]
                    }}
                  />
                </Col>
                <Col>
                  <Button
                    onClick={() => {
                      remove(field.name);
                    }}
                    type='stroke-secondary-square'
                    icon='delete'
                    style={{ marginTop: 24 }}
                  />
                </Col>
              </Row>
            );
          })}
          <Button onClick={() => add()} type='primary'>
            Добавить фильтрацию
          </Button>
        </div>
      )}
    </Form.List>
  );
};
