import { ColumnsType } from 'antd/es/table';
import { Paragraph } from 'common/ui/_new';
import { IItemChart } from 'types/entities';
import styles from './styles.module.scss';

import { i18n } from 'i18n/config';
export type GetColumnsFn = () => ColumnsType<IItemChart>;

export const roundCompetenciesValues = (data: IItemChart[] | undefined) => {
  return data?.map((competence) => ({
    key: competence.key,
    value: Number(competence.value.toFixed(2))
  }));
};

export const getColumns: GetColumnsFn = () => {
  const columns: ColumnsType<IItemChart> = [
    {
      title: i18n.t('Students:pages.competencies.table.index'),
      dataIndex: 'index',
      key: 'index',
      render: (_, __, index) => {
        ++index;
        return index;
      }
    },
    {
      title: i18n.t('Students:pages.competencies.table.competencies'),
      dataIndex: 'key',
      render: (competence) => (
        <Paragraph ellipsis={{ rows: 3, tooltip: competence }} className={styles.competence}>
          {competence}
        </Paragraph>
      ),
      key: 'key'
    },
    {
      title: i18n.t('Students:pages.competencies.table.evaluation'),
      dataIndex: 'value',
      key: 'value'
    }
  ];
  return columns;
};
