import { createApi } from '@reduxjs/toolkit/query/react';
import { getBaseQuery } from 'common/helpers';
import type { IChangePassword, ICreatePassword, IPasswordStatus } from 'types/requests/student/password.api';

export const passwordApi = createApi({
  reducerPath: 'passwordApi',
  baseQuery: getBaseQuery(),
  endpoints: ({ query, mutation }) => ({
    getUserPasswordStatus: query<IPasswordStatus.Response, IPasswordStatus.Params>({
      query: () => '/api/Password/HasPassword'
    }),
    changePassword: mutation<IChangePassword.Response, IChangePassword.Params>({
      query: (body) => ({
        url: '/api/Password/Change',
        method: 'POST',
        body
      })
    }),
    createPassword: mutation<ICreatePassword.Response, ICreatePassword.Params>({
      query: ({ value, repeat }) => ({
        url: `/api/Password/Set?Value=${value}&Repeat=${repeat}`,
        method: 'POST'
      })
    })
  })
});

export const { useGetUserPasswordStatusQuery, useChangePasswordMutation, useCreatePasswordMutation } = passwordApi;
