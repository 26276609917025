import { ComponentProps } from 'react';
import { FormInstance, Rule } from 'antd/lib/form';
import dayjs from 'dayjs';
import { DatePicker } from 'common/ui/_new';

type IDatePickerFormItemProps = {
  name: string;
  dependencies: string[];
  rules: Rule[];
};

type ReturnData = {
  formItemFromProps: IDatePickerFormItemProps;
  formItemToProps: IDatePickerFormItemProps;
  datePickerFromProps: ComponentProps<typeof DatePicker>;
  datePickerToProps: ComponentProps<typeof DatePicker>;
};

type IOptions = {
  limitEndDateToCurrent: boolean;
};

/**
 * Хук для указания ограничения выбора диапазона дат.
 * Условия:
 * - dateFrom и dateTo не могут быть взяты будущим числом
 * - dateFrom не может быть младше dateTo
 *
 * @param nameDateFrom - имя поля, которое будет указывать на начало диапазона
 * @param nameDateTo - имя поля, которое будет указывать на конец диапазона
 * @param form - форма, результат такой операции: const [form] = useForm();
 * @returns
 */
export const useDateRangeLimitationProps: <T, K extends keyof T, P extends keyof T>(
  nameDateFrom: K,
  nameDateTo: P,
  form: FormInstance<T>,
  options?: IOptions
) => ReturnData = (nameDateFromDenormalized, nameDateToDenormalized, form, options = { limitEndDateToCurrent: true }) => {
  const nameDateFrom = nameDateFromDenormalized.toString();
  const nameDateTo = nameDateToDenormalized.toString();

  const datePickerFromProps: ComponentProps<typeof DatePicker> = {
    disabledDate: (current) => {
      let compareWith = form.getFieldValue(nameDateTo);
      if (!compareWith) {
        if (!options.limitEndDateToCurrent) {
          return false;
        }
        compareWith = new Date();
      }
      return current.isAfter(new Date(compareWith));
    }
  };
  const formItemFromProps: IDatePickerFormItemProps = {
    name: nameDateFrom,
    dependencies: [nameDateTo],
    rules: [
      {
        validator: async (_, field) => {
          if (!!datePickerFromProps.disabledDate && datePickerFromProps.disabledDate(dayjs(field), { type: 'date' })) {
            return Promise.reject('Неверно задан диапазон дат');
          }
        }
      }
    ]
  };

  const datePickerToProps: ComponentProps<typeof DatePicker> = {
    disabledDate: (current) => {
      const compareWith = form.getFieldValue(nameDateFrom);
      return (
        (!compareWith ? false : current.isBefore(new Date(compareWith))) || (options.limitEndDateToCurrent && current.isAfter(new Date()))
      );
    }
  };
  const formItemToProps: IDatePickerFormItemProps = {
    name: nameDateTo,
    dependencies: [nameDateFrom],
    rules: [
      {
        validator: async (_, field) => {
          if (field) {
            if (!!datePickerToProps.disabledDate && datePickerToProps.disabledDate(dayjs(field), { type: 'date' })) {
              return Promise.reject('Неверно задан диапазон дат');
            }
          }
        }
      }
    ]
  };

  return {
    formItemFromProps,
    formItemToProps,
    datePickerFromProps,
    datePickerToProps
  };
};
