import { createApi } from '@reduxjs/toolkit/query/react';
import { getBaseQuery } from 'common/helpers';
import { ISearchUser } from 'types/requests/instructor/coursesUsers.api';

export const coursesUsersApi = createApi({
  reducerPath: 'supportApi',
  baseQuery: getBaseQuery(),
  tagTypes: [],
  endpoints: ({ query }) => ({
    searchUser: query<ISearchUser.Response, ISearchUser.Params>({
      query: (params) => ({
        url: `/api/instructors/support/users`,
        params
      })
    })
  })
});

export const { useSearchUserQuery } = coursesUsersApi;
