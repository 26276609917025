import { createApi } from '@reduxjs/toolkit/query/react';
import { getBaseQuery } from 'common/helpers';
import { IAddAccount, ICurrentState, IGetAccounts, ILoginAccount, ILoginToMain } from 'types/requests/student/accountRelation.api';

export const accountRelationApi = createApi({
  reducerPath: 'accountRelationApi',
  baseQuery: getBaseQuery(),
  tagTypes: ['Accounts'],
  endpoints: ({ query, mutation }) => ({
    getAccounts: query<IGetAccounts.Response, IGetAccounts.Params>({
      query: () => `/api/AccountRelation`,
      providesTags: ['Accounts']
    }),
    loginAccount: mutation<ILoginAccount.Response, ILoginAccount.Params>({
      query: ({ id }) => ({
        url: `/api/AccountRelation/Login/${id}`,
        method: 'POST'
      })
    }),
    getCurrentState: query<ICurrentState.Response, ICurrentState.Params>({
      query: () => `/api/AccountRelation/CurrentState`
    }),
    addAccount: mutation<IAddAccount.Response, IAddAccount.Params>({
      query: ({ body }) => ({
        url: `/api/AccountRelation`,
        method: 'POST',
        body
      }),
      invalidatesTags: ['Accounts']
    }),
    loginToMain: mutation<ILoginToMain.Response, ILoginToMain.Params>({
      query: () => ({
        url: `/api/AccountRelation/LoginToMain`,
        method: 'POST'
      })
    })
  })
});

export const { useGetAccountsQuery, useLoginAccountMutation, useGetCurrentStateQuery, useAddAccountMutation, useLoginToMainMutation } =
  accountRelationApi;
