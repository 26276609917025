import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { App } from './app/App';
import { AcceptCookie } from 'containers';
import { AntConfigProvider } from 'containers/_new';
import { ConfirmProvider } from 'common/helpers';
import { setupStore } from './api';
import './i18n/config';
import './common/styles/styles.scss';

import { Wrapper } from '@unione-pro/unione.commons.webpack';

const root = createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <AntConfigProvider>
      <ConfirmProvider>
        <AcceptCookie>
          <Provider store={setupStore()}>
            <Wrapper>
              <App />
            </Wrapper>
          </Provider>
        </AcceptCookie>
      </ConfirmProvider>
    </AntConfigProvider>
  </React.StrictMode>
);
