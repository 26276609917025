import { Id } from 'types/common';

export type SlideId = Id;

//* Lms.Entities.Entities.Page.PageContentType
// Типы содержимого страниц
export enum PageContentType {
  UNKNOWN = 0,
  CONTENT = 1,
  TASK = 2,
  TEST = 3,
  PRESENTATION_BEGIN = 4,
  PRESENTATION_END = 5,
  VIDEO = 6,
  WEB = 7,
  IMAGE = 8,
  AUDIO = 9,
  EXTERNAL = 10,
  PDF = 11,
  DOC = 12,
  KINESCOPE = 13,
  TRACE = 41
}
