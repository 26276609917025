import { ComponentProps, FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Empty, Link, Result, Spin } from 'common/ui/_new';
import { ResultPage } from 'common/components/_new';
import { useRedirectPath } from 'common/hooks';
import { QueryResponseBoundaryProps } from './types';
import styles from './styles.module.scss';

export const QueryResponseBoundary: FC<QueryResponseBoundaryProps> = ({
  query,
  responseConfig,
  isEmpty,
  size = 'default',
  empty,
  children
}) => {
  const { t } = useTranslation('common', { keyPrefix: 'containers.query-response-boundary' });
  const redirectPath = useRedirectPath();

  if (!query || query.isUninitialized) {
    return <>{children}</>;
  }

  if (query.isLoading) {
    return (
      <div className={styles.container}>
        <Spin size={size} />
      </div>
    );
  }

  if (query.isFetching) {
    if (children === undefined || (Array.isArray(children) && children.length === 0)) {
      return (
        <div className={styles.container}>
          <Spin size={size} />
        </div>
      );
    }

    return <Spin size={size}>{children}</Spin>;
  }

  if (query.isSuccess) {
    if (isEmpty ?? Object.keys(query.data ?? {}).length === 0) {
      return <Empty description={t('success.empty.description')} size={size} {...empty} />;
    }

    return <>{children}</>;
  }

  if (query.isError) {
    const defaultErrorResultProps: ComponentProps<typeof Result> = {
      status: 'warning',
      title: t('error.result.title'),
      extra: (
        <Link type='primary' to={redirectPath.fallback()}>
          {t('error.result.extra-link.label')}
        </Link>
      )
    };
    const resultProps: ComponentProps<typeof Result> =
      (query.error?.status && responseConfig?.[query.error.status]) || defaultErrorResultProps;

    return <ResultPage {...resultProps} />;
  }

  return <>{children}</>;
};
