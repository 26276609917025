import { createContext, Dispatch, SetStateAction } from 'react';
import { NotificationInstance } from 'antd/es/notification/interface';
import { ColorScheme } from './color-theme';

export const ColorSchemeContext = createContext<{
  colorScheme?: ColorScheme;
  setColorScheme: Dispatch<SetStateAction<ColorScheme>>;
}>({
  setColorScheme: () => undefined
});

export const colorSchemesMap = {
  [ColorScheme.DARK]: 'dark',
  [ColorScheme.LIGHT]: 'light'
};

export const NotificationContext = createContext<{
  notificationApi: NotificationInstance;
}>({
  notificationApi: {
    destroy: () => undefined,
    error: () => undefined,
    info: () => undefined,
    open: () => undefined,
    success: () => undefined,
    warning: () => undefined
  }
});
