import { FC } from 'react';
import { Image } from 'common/ui/_new';
import { CoverDescriptionLayoutProps } from './types';
import styles from './styles.module.scss';

export const CoverDescriptionLayout: FC<CoverDescriptionLayoutProps> = ({ cover, header, footer, children }) => {
  return (
    <div className={styles.layout}>
      <Image src={cover} alt='' className={styles.cover} />
      <div className={styles.content}>
        <div className={styles.header}>{header}</div>
        <div className={styles.main}>{children}</div>
        {footer && <div className={styles.footer}>{footer}</div>}
      </div>
    </div>
  );
};
