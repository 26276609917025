import { ThemeConfig } from 'antd';
import { ComponentTokenMap } from 'antd/es/theme/interface';
import { ColorScheme, themeConfigsMapByColorScheme } from './color-theme';
import styleVar from 'common/styles/vars.scss';

const staticThemeConfig: ThemeConfig = {
  token: {
    borderRadius: 4,
    controlHeightSM: 32,
    controlHeight: 40,
    controlHeightLG: 44,
    controlPaddingHorizontal: 16,
    paddingContentHorizontal: 16,
    fontFamily: styleVar.mainTextFont,
    fontSize: +styleVar['main-text-font-size'],
    fontSizeHeading1: +styleVar['heading1-font-size'],
    fontSizeHeading2: +styleVar['heading2-font-size'],
    fontSizeHeading3: +styleVar['heading3-font-size'],
    fontSizeHeading4: +styleVar['heading4-font-size'],
    fontSizeHeading5: +styleVar['heading5-font-size'],
    lineHeight: +styleVar['main-text-line-height'],
    lineHeightHeading1: +styleVar['heading1-line-height'],
    lineHeightHeading2: +styleVar['heading2-line-height'],
    lineHeightHeading3: +styleVar['heading3-line-height'],
    lineHeightHeading4: +styleVar['heading4-line-height'],
    lineHeightHeading5: +styleVar['heading5-line-height']
  },
  components: {
    Alert: {
      lineWidth: 0,
      borderRadiusLG: 10,
      defaultPadding: '16px 20px',
      withDescriptionPadding: '16px 20px'
    },
    Avatar: {
      controlHeightLG: 88,
      textFontSizeLG: 50
    },
    Button: {
      paddingInlineLG: 24,
      paddingInline: 16,
      contentFontSize: 16,
      contentFontSizeLG: 18
    },
    Checkbox: {
      borderRadiusSM: 4,
      controlInteractiveSize: 24,
      paddingXS: 14
    },
    DatePicker: {
      cellHeight: 24,
      cellWidth: 30,
      paddingInline: 15
    },
    Dropdown: {
      borderRadiusLG: 10,
      paddingBlock: 8,
      fontSize: 14,
      lineHeight: 20 / 14,
      paddingXXS: 8
    },
    Form: {
      verticalLabelPadding: '0 0 4px',
      fontSize: 14,
      lineHeight: 20 / 14
    },
    Input: {
      paddingInline: 15
    },
    InputNumber: {
      paddingInline: 15
    },
    Menu: {
      borderRadiusLG: 8,
      iconMarginInlineEnd: 0,
      collapsedIconSize: 24
    },
    Modal: {
      borderRadiusLG: 10,
      titleFontSize: 24,
      titleLineHeight: 28 / 24
    },
    Notification: {
      controlHeightLG: 24,
      fontSizeLG: 20,
      lineHeightLG: 24 / 20,
      marginSM: 8,
      marginXS: 8,
      paddingContentHorizontalLG: 16,
      paddingLG: 16,
      paddingMD: 16,
      borderRadiusLG: 8
    },
    Pagination: {
      borderRadius: 6,
      itemSize: 26,
      fontSize: 14,
      fontWeightStrong: 400
    },
    Progress: {
      fontSize: 20,
      lineHeight: 24 / 20,
      circleTextFontSize: '20px',
      fontFamily: styleVar.headingFont
    },
    Radio: {
      dotSize: 12,
      radioSize: 16
    },
    Select: {
      paddingSM: 16
    },
    Switch: {
      handleSize: 14,
      trackHeight: 16,
      trackPadding: 1,
      trackMinWidth: 32
    },
    Tabs: {
      horizontalItemPaddingLG: '16px 16px 12px',
      horizontalMargin: '0 0 32px 0',
      horizontalItemGutter: 11,
      lineWidth: 2,
      lineHeight: 24 / 18
    },
    Table: {
      cellPaddingBlock: 4,
      cellPaddingInline: 19,
      cellFontSize: 16,
      fontWeightStrong: 400
    },
    Tooltip: {
      borderRadius: 8,
      controlHeight: 20,
      fontSize: 16,
      lineHeight: 20 / 16,
      paddingSM: 12,
      sizePopupArrow: 20
    },
    Typography: {}
  }
};

export const getGeneralThemeTokenConfig = ({ colorScheme = ColorScheme.LIGHT }: { colorScheme: ColorScheme }): ThemeConfig['token'] => ({
  ...staticThemeConfig.token,
  ...themeConfigsMapByColorScheme[colorScheme]?.token
});

type ComponentsConfig = NonNullable<ThemeConfig['components']>;

const combineComponentsConfigs = (componentsKeys: (keyof ComponentTokenMap)[], componentsConfigs: ComponentsConfig[]) =>
  componentsKeys.reduce((componentsConfig: ComponentsConfig, componentKey) => {
    componentsConfig[componentKey] = componentsConfigs.reduce((componentConfig, componentsConfig) => {
      if (componentKey in componentsConfig) {
        componentConfig = { ...componentConfig, ...componentsConfig[componentKey] };
      }
      return componentConfig;
    }, {});
    return componentsConfig;
  }, {});

export const getThemeConfig = ({ colorScheme = ColorScheme.LIGHT }: { colorScheme?: ColorScheme }): ThemeConfig => {
  const staticThemeComponentsConfig = staticThemeConfig.components ?? {};
  const colorSchemeComponentsConfig = themeConfigsMapByColorScheme[colorScheme].components ?? {};

  const componentsKeys = Array.from(
    new Set([...Object.keys(staticThemeComponentsConfig), ...Object.keys(colorSchemeComponentsConfig)])
  ) as (keyof ComponentTokenMap)[];

  return {
    algorithm: themeConfigsMapByColorScheme[colorScheme].algorithm,
    token: getGeneralThemeTokenConfig({ colorScheme }),
    components: combineComponentsConfigs(componentsKeys, [staticThemeComponentsConfig, colorSchemeComponentsConfig])
  };
};
