import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';

//* Lms.Entities.Entities.Course.Lesson.Work.Exercise.ExerciseWorkKind
export enum ExerciseWorkKind {
  ANY = 0,
  LECTURE = 10,
  TEST = 20,
  TASK = 30
}

export interface IStatusMap {
  [key: string]: string;
}

export interface ICheckErrorArgs {
  error: FetchBaseQueryError | SerializedError | undefined;
  status: number;
  code?: string;
}
