import { useLayoutEffect, useState } from 'react';
import { QueryStatus } from '@reduxjs/toolkit/dist/query';
import { useLazyGetUnfilledAttributeListQuery } from 'api/student/attributes/attributes.api';
import { AttributesField } from 'types/entities';
import { FieldsInvisibility } from '../common';

export const unfilledAttributesFormName = 'unfilled';

export const useGetVisibleUnfilledAttributeListQuery = (fieldsInvisibility?: FieldsInvisibility) => {
  const [getUnfilledAttributeList, unfilledAttributeListQuery] = useLazyGetUnfilledAttributeListQuery();
  const { data: unfilledAttributes, isLoading, isFetching, status } = unfilledAttributeListQuery;

  useLayoutEffect(() => {
    getUnfilledAttributeList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const visibleUnfilledAttributes: AttributesField[] | undefined = fieldsInvisibility
    ? unfilledAttributes?.filter(({ id }) => !fieldsInvisibility[id])
    : unfilledAttributes;

  const [currentAttributes, setCurrentAttributes] = useState<AttributesField[] | undefined>();
  useLayoutEffect(() => {
    setCurrentAttributes(visibleUnfilledAttributes);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status === QueryStatus.fulfilled]);

  return {
    initialAttributes: visibleUnfilledAttributes,
    currentAttributes,
    setCurrentAttributes,
    isLoading,
    isFetching
  };
};
