import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { UserContactCard } from 'common/components/_new';
import { localizePersonName } from 'common/helpers';
import { UserContactCardsListProps } from './types';
import styles from './styles.module.scss';

export const UserContactCardsList: FC<UserContactCardsListProps> = ({ items }) => {
  const { i18n } = useTranslation();

  return (
    <div className={styles.instructorsList}>
      {items.map(({ firstName, lastName, picture, role }, i) => (
        <UserContactCard key={i} name={localizePersonName({ firstName, lastName }, i18n.language)} avatarSrc={picture} status={role} />
      ))}
    </div>
  );
};
