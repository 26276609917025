import type { PermissionsUnionType } from './permissions';
import type { UserRoleUnionType } from './userRoles';

export module IROLE {
  export type UserRole = UserRoleUnionType;
  export type Permissions = PermissionsUnionType;
  export type RoleObject = {
    role: UserRole;
    permissions: Partial<Record<Permissions, boolean>>;
  };
  export type IRoles = {
    GUEST: RoleObject;
    STUDENT: RoleObject;
    INSTRUCTOR: RoleObject;
    ADMIN: RoleObject;
  };
}

export const ROLES: IROLE.IRoles = {
  GUEST: { role: 'Guest', permissions: {} },
  STUDENT: { role: 'Students', permissions: {} },
  INSTRUCTOR: { role: 'Instructors', permissions: {} },
  ADMIN: { role: 'Root', permissions: {} }
};
