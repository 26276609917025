import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { IResultProps, Result } from 'common/ui/_new';
import { useAppSelector } from 'common/hooks';
import { profileSelectors } from 'api/student/profile/profile.selectors';
import { getPropsByStatus } from './helper';
import { IResultPageProps } from './types';

export const ResultPage: FC<IResultPageProps> = ({ status, ...restProps }) => {
  const { role } = useAppSelector(profileSelectors.role);
  const { t } = useTranslation('common', { keyPrefix: 'components.result-page' });

  const propsByStatus = getPropsByStatus(status, role, t);

  const innerProps: IResultProps = {
    //* overridable:
    ...propsByStatus,
    //* outer:
    ...restProps,
    //* unoverridable:
    status: 'info'
  };

  return <Result {...innerProps} />;
};
