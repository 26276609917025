import { createApi } from '@reduxjs/toolkit/query/react';
import { DefaultOptionType } from 'common/ui/_new';
import { getBaseQuery } from 'common/helpers';

export const instructorExpulsionApi = createApi({
  reducerPath: 'instructorExpulsionApi',
  baseQuery: getBaseQuery(),
  tagTypes: ['Expulsions'],
  endpoints: ({ query }) => ({
    getExpulsions: query<DefaultOptionType[], void>({
      query: () => `/api/instructors/expulsions`,
      transformResponse: (data: { id: string; label: string }[]): DefaultOptionType[] => {
        return data.map(({ id, label }) => ({
          value: id,
          label
        }));
      }
    })
  })
});

export const { useGetExpulsionsQuery } = instructorExpulsionApi;
