import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { ConfigProvider, notification } from 'antd';
import { ConfigProviderProps } from 'antd/es/config-provider';
import enUS from 'antd/locale/en_US';
import ruRu from 'antd/locale/ru_RU';
import { Empty } from 'common/ui/_new';
import { useColorScheme } from 'common/hooks';
import { ColorSchemeContext, colorSchemesMap, NotificationContext } from './helper';
import { getThemeConfig } from './theme';

import { LANGUAGES } from 'i18n/constants';

export const AntConfigProvider: FC<ConfigProviderProps> = ({ children }) => {
  const { i18n } = useTranslation();

  const [colorScheme, setColorScheme] = useColorScheme();
  useEffect(() => {
    if (colorScheme !== undefined) {
      document.documentElement.style.setProperty('--color-scheme', colorSchemesMap[colorScheme]);
    }
  }, [colorScheme]);

  const innerProps: ConfigProviderProps = {
    locale: i18n.resolvedLanguage === LANGUAGES.ru ? ruRu : enUS,
    renderEmpty: () => <Empty />,
    theme: getThemeConfig({ colorScheme })
  };

  const [notificationApi, notificationContextHolder] = notification.useNotification();

  return (
    <ColorSchemeContext.Provider value={{ colorScheme, setColorScheme }}>
      <ConfigProvider {...innerProps}>
        <NotificationContext.Provider value={{ notificationApi }}>
          {notificationContextHolder}
          {children}
        </NotificationContext.Provider>
      </ConfigProvider>
    </ColorSchemeContext.Provider>
  );
};
