import {
  CourseGroupId,
  CoursesItemTypeEnum,
  FinishedCoursesAndGroupsListItem,
  IModuleProgress,
  UnfinishedCoursesAndGroupsListItem
} from 'types/entities';

export const getCourseGroupsIds = (coursesAndGroups: UnfinishedCoursesAndGroupsListItem[] | FinishedCoursesAndGroupsListItem[]) =>
  coursesAndGroups.reduce((acc: CourseGroupId[], { type, id }) => {
    if (type === CoursesItemTypeEnum.COURSE_GROUP) {
      acc.push(id);
    }
    return acc;
  }, []);

export const addModulesProgressInfoToGroups = (
  coursesAndGroups: UnfinishedCoursesAndGroupsListItem[] | FinishedCoursesAndGroupsListItem[],
  modulesProgressItems: IModuleProgress[]
) =>
  coursesAndGroups.map(({ id, type, label, begin, end, picture }) => {
    if (type === CoursesItemTypeEnum.COURSE_GROUP) {
      const { modulesAmountCalc = 0, maxModulesAmountCalc } =
        modulesProgressItems.find((modulesProgressItem) => modulesProgressItem.id === id) ?? {};
      return {
        id,
        type,
        label,
        begin,
        end,
        picture,
        modulesAmountCalc,
        maxModulesAmountCalc
      };
    }

    return {
      id,
      type,
      label,
      begin,
      end,
      picture
    };
  });
