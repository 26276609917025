import { createApi } from '@reduxjs/toolkit/query/react';
import { flattenParamsWithArray, getBaseQuery } from 'common/helpers';
import {
  CopyExercisesToPredicate,
  CreateCourseApplicationPredicate,
  CreateCourseDocumentGenerationPredicate,
  CreateCourseStatePredicate,
  DeletePredicate,
  EditCourseApplicationPredicate,
  EditCourseDocumentGenerationPredicate,
  EditCoursePredicatesSequence,
  EditCourseStatePredicate,
  EditExerciseInEvaluation,
  ExcludeExerciseFromEvaluation,
  ExecuteCoursePredicates,
  GetApplicationCourses,
  GetApplicationExercises,
  GetCoursePredicateInfo,
  GetCoursePredicatesDescription,
  GetPredicateTemplates,
  IncludeExerciseInEvaluation
} from 'types/requests/instructor/predicate.api';

export const predicateApi = createApi({
  reducerPath: 'predicateApi',
  baseQuery: getBaseQuery(),
  tagTypes: [
    'Predicates',
    'PredicatesSequence',
    'EvaluatedExercises',
    'ApplicationPredicate',
    'StatePredicate',
    'DocumentGenerationPredicate'
  ],
  endpoints: ({ query, mutation }) => ({
    getCoursePredicatesDescription: query<GetCoursePredicatesDescription.Response, GetCoursePredicatesDescription.Params>({
      query: ({ courseId, predicateEventType }) => ({
        url: `/api/instructors/trainings/${courseId}/predicates/description`,
        params: { predicateEventType }
      }),
      providesTags: [
        'Predicates',
        'EvaluatedExercises',
        'PredicatesSequence',
        'ApplicationPredicate',
        'StatePredicate',
        'DocumentGenerationPredicate'
      ]
    }),
    includeExerciseInEvaluation: mutation<IncludeExerciseInEvaluation.Response, IncludeExerciseInEvaluation.Params>({
      query: ({ courseId, predicateId, exerciseId }) => ({
        url: `/api/instructors/trainings/${courseId}/predicate/${predicateId}/exercises/include`,
        method: 'POST',
        params: {
          exerciseId
        }
      }),
      invalidatesTags: (_, error) => (error ? [] : ['EvaluatedExercises'])
    }),
    editExerciseInEvaluation: mutation<EditExerciseInEvaluation.Response, EditExerciseInEvaluation.Params>({
      query: ({ courseId, predicateId, link, exerciseId }) => ({
        url: `/api/instructors/trainings/${courseId}/predicate/${predicateId}/exercise/${exerciseId}/edit`,
        method: 'POST',
        params: {
          predicateExerciseId: link
        }
      }),
      invalidatesTags: (_, error) => (error ? [] : ['EvaluatedExercises'])
    }),
    excludeExerciseFromEvaluation: mutation<ExcludeExerciseFromEvaluation.Response, ExcludeExerciseFromEvaluation.Params>({
      query: ({ predicateId, link }) => ({
        url: `/api/instructors/trainings/predicate/${predicateId}/exercises/${link}/exclude`,
        method: 'DELETE'
      }),
      invalidatesTags: (_, error) => (error ? [] : ['EvaluatedExercises'])
    }),
    getApplicationExercises: query<GetApplicationExercises.Response, GetApplicationExercises.Params>({
      query: ({ courseId, applicationCourseId }) => ({
        url: `/api/instructors/trainings/${courseId}/predicate/application/trainings/${applicationCourseId}/exercises`
      })
    }),
    executeCoursePredicates: mutation<ExecuteCoursePredicates.Response, ExecuteCoursePredicates.Params>({
      query: ({ courseId, predicateId }) => ({
        url: `/api/instructors/trainings/${courseId}/predicate/${predicateId}/execute`,
        method: 'POST'
      })
    }),
    editCoursePredicatesSequence: mutation<EditCoursePredicatesSequence.Response, EditCoursePredicatesSequence.Params>({
      query: ({ courseId, predicateId, sequence }) => ({
        url: `/api/instructors/trainings/${courseId}/predicate/${predicateId}/sequence/${sequence}`,
        method: 'POST'
      }),
      invalidatesTags: (_, error) => (error ? [] : ['PredicatesSequence'])
    }),
    getCoursePredicateInfo: query<GetCoursePredicateInfo.Response, GetCoursePredicateInfo.Params>({
      query: ({ predicateId }) => ({
        url: `/api/instructors/predicate/${predicateId}/info`
      }),
      providesTags: ['ApplicationPredicate', 'StatePredicate', 'DocumentGenerationPredicate']
    }),
    createCourseApplicationPredicate: mutation<CreateCourseApplicationPredicate.Response, CreateCourseApplicationPredicate.Params>({
      query: ({ courseId, body, ...params }) => ({
        url: `/api/instructors/trainings/${courseId}/predicate/application/create`,
        method: 'POST',
        body,
        params: flattenParamsWithArray(params)
      }),
      invalidatesTags: (_, error) => (error ? [] : ['ApplicationPredicate'])
    }),
    editCourseApplicationPredicate: mutation<EditCourseApplicationPredicate.Response, EditCourseApplicationPredicate.Params>({
      query: ({ courseId, predicateId, body, ...params }) => ({
        url: `/api/instructors/trainings/${courseId}/predicate/${predicateId}/application/edit`,
        method: 'POST',
        body,
        params: flattenParamsWithArray(params)
      }),
      invalidatesTags: (_, error) => (error ? [] : ['ApplicationPredicate'])
    }),
    getApplicationCourses: query<GetApplicationCourses.Response, GetApplicationCourses.Params>({
      query: ({ courseId }) => ({
        url: `/api/instructors/trainings/${courseId}/predicate/application/trainings`
      })
    }),
    createCourseStatePredicate: mutation<CreateCourseStatePredicate.Response, CreateCourseStatePredicate.Params>({
      query: ({ courseId, body, ...params }) => ({
        url: `/api/instructors/trainings/${courseId}/predicate/state/create`,
        method: 'POST',
        body,
        params: flattenParamsWithArray(params)
      }),
      invalidatesTags: (_, error) => (error ? [] : ['StatePredicate'])
    }),
    editCourseStatePredicate: mutation<EditCourseStatePredicate.Response, EditCourseStatePredicate.Params>({
      query: ({ courseId, predicateId, body, ...params }) => ({
        url: `/api/instructors/trainings/${courseId}/predicate/${predicateId}/state/edit`,
        method: 'POST',
        body,
        params: flattenParamsWithArray(params)
      }),
      invalidatesTags: (_, error) => (error ? [] : ['StatePredicate'])
    }),
    deletePredicate: mutation<DeletePredicate.Response, DeletePredicate.Params>({
      query: ({ courseId, predicateId }) => ({
        url: `/api/instructors/trainings/${courseId}/predicate/${predicateId}`,
        method: 'POST'
      }),
      invalidatesTags: (_, error) => (error ? [] : ['Predicates'])
    }),
    copyExercisesToPredicate: mutation<CopyExercisesToPredicate.Response, CopyExercisesToPredicate.Params>({
      query: ({ predicateFromId, predicateToId }) => ({
        url: `/api/instructors/predicate/exercises/copy/${predicateFromId}/${predicateToId}`,
        method: 'POST'
      }),
      invalidatesTags: (_, error) => (error ? [] : ['Predicates'])
    }),
    createCourseDocumentGenerationPredicate: mutation<
      CreateCourseDocumentGenerationPredicate.Response,
      CreateCourseDocumentGenerationPredicate.Params
    >({
      query: ({ courseId, body, ...params }) => ({
        url: `/api/instructors/trainings/${courseId}/predicate/gendoc/create`,
        method: 'POST',
        body,
        params: flattenParamsWithArray(params)
      }),
      invalidatesTags: (_, error) => (error ? [] : ['DocumentGenerationPredicate'])
    }),
    editCourseDocumentGenerationPredicate: mutation<
      EditCourseDocumentGenerationPredicate.Response,
      EditCourseDocumentGenerationPredicate.Params
    >({
      query: ({ courseId, predicateId, body, ...params }) => ({
        url: `/api/instructors/trainings/${courseId}/predicate/${predicateId}/gendoc/edit`,
        method: 'POST',
        body,
        params: flattenParamsWithArray(params)
      }),
      invalidatesTags: (_, error) => (error ? [] : ['DocumentGenerationPredicate'])
    }),
    getPredicateTemplates: query<GetPredicateTemplates.Response, GetPredicateTemplates.Params>({
      query: ({ courseId }) => ({
        url: `/api/instructors/trainings/${courseId}/predicate/templates`
      })
    })
  })
});

export const {
  useCopyExercisesToPredicateMutation,
  useCreateCourseApplicationPredicateMutation,
  useCreateCourseDocumentGenerationPredicateMutation,
  useCreateCourseStatePredicateMutation,
  useDeletePredicateMutation,
  useEditCourseApplicationPredicateMutation,
  useEditCourseDocumentGenerationPredicateMutation,
  useEditCoursePredicatesSequenceMutation,
  useEditCourseStatePredicateMutation,
  useEditExerciseInEvaluationMutation,
  useExcludeExerciseFromEvaluationMutation,
  useExecuteCoursePredicatesMutation,
  useGetApplicationCoursesQuery,
  useGetCoursePredicateInfoQuery,
  useGetCoursePredicatesDescriptionQuery,
  useGetPredicateTemplatesQuery,
  useIncludeExerciseInEvaluationMutation,
  useLazyGetApplicationExercisesQuery
} = predicateApi;
