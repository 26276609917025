import { createApi } from '@reduxjs/toolkit/query/react';
import { getBaseQuery } from 'common/helpers';

export const slidesApi = createApi({
  reducerPath: 'slidesApi',
  baseQuery: getBaseQuery(),
  tagTypes: [
    'Audio',
    'Answers',
    'Attributes',
    'Content',
    'Evaluation',
    'External-Question',
    'File',
    'Free',
    'Pdf',
    'Picture',
    'Pptx',
    'Video',
    'Web-page',
    'Slides',
    'Editable_free',
    'Editable_answers',
    'Kinescope'
  ],
  endpoints: () => ({})
});
