import { ComponentProps, FC, forwardRef, PropsWithRef } from 'react';
import { Collapse as AntCollapse } from 'antd';
import cn from 'classnames';
import { ICollapseProps } from './types';
import styles from './styles.module.scss';

export const Collapse: FC<PropsWithRef<ICollapseProps>> = forwardRef(function Collapse({ className, ...restProps }, ref) {
  const innerProps: ComponentProps<typeof AntCollapse> = {
    //* overridable:
    //* outer:
    ...restProps,
    //* unoverridable:
    ref,
    className: cn(styles.collapse, className)
  };

  return <AntCollapse {...innerProps} />;
});
