import { createSlice } from '@reduxjs/toolkit';
import { IDictionariesState } from 'types/store/student/dictionary.slice';
import { dictionaryApi } from './dictionary.api';

const initialState: IDictionariesState = {};

export const dictionarySlice = createSlice({
  name: 'dictionary',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(dictionaryApi.endpoints.getDictionary.matchFulfilled, (state, action) => {
      return {
        ...state,
        ...{ [action.meta.arg.originalArgs]: action.payload.map((item) => ({ value: item.id, label: item.label ?? '' })) }
      };
    });
  }
});

export const { actions: dictionaryActions, reducer: dictionary } = dictionarySlice;
