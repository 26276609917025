import { IDefaultTabs, IGetDefaultKey, IHandleTabsChange, IStorageKey } from './types';

const DEFAULT_TABS_STORAGE_KEY = 'DEFAULT_TABS';

const getParsedStorageValue = (key: IStorageKey) => {
  try {
    const valueJSON = sessionStorage.getItem(key);

    if (valueJSON !== null) {
      try {
        return JSON.parse(valueJSON);
      } catch (e) {
        sessionStorage.removeItem(key);
        console.error(e);
      }
    }
  } catch (e) {
    sessionStorage.removeItem(key);
    console.error(e);
  }
};

const setStringifiedStorageValue = (key: IStorageKey, value: Record<string, string>) => {
  try {
    const stringifiedValue = JSON.stringify(value);

    try {
      sessionStorage.setItem(key, stringifiedValue);
    } catch (e) {
      console.error(e);
    }
  } catch (e) {
    console.error(e);
  }
};

export const getDefaultKey: IGetDefaultKey = (props) => {
  const { id } = props;
  if (id !== undefined) {
    const defaultTabs: IDefaultTabs = getParsedStorageValue(DEFAULT_TABS_STORAGE_KEY);
    const storageDefaultActiveKey = defaultTabs?.[id];

    if (storageDefaultActiveKey) {
      return storageDefaultActiveKey;
    }
  }

  const { defaultActiveKey, items } = props;

  return defaultActiveKey ?? items?.[0].key;
};

export const handleTabsChange: IHandleTabsChange = ({ activeKey, onChange, id }) => {
  if (activeKey) {
    if (id) {
      const defaultTabs: IDefaultTabs = getParsedStorageValue(DEFAULT_TABS_STORAGE_KEY);

      setStringifiedStorageValue(DEFAULT_TABS_STORAGE_KEY, {
        ...defaultTabs,
        [id]: activeKey
      });
    }
    onChange?.(activeKey);
  }
};
