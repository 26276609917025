import { ComponentProps, FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import AntTitle from 'antd/es/typography/Title';
import cn from 'classnames';
import { useMediaQuery } from 'common/hooks';
import { TypographyIcon } from '../components/TypographyIcon';
import { getEllipsisProps } from '../helper';
import { ITitleProps } from './types';
import styles from './styles.module.scss';
import styleVar from 'common/styles/responsive.scss';

export const Title: FC<ITitleProps> = ({ icon, suffixIcon, ellipsis, className, children, ...restProps }) => {
  const { t } = useTranslation('common', { keyPrefix: 'components.typography.common.ellipsis' });

  const [isNoHover] = useMediaQuery([styleVar.noHoverMediaQuery]);
  const [expanded, setExpanded] = useState<boolean>(false);

  const innerProps: ComponentProps<typeof AntTitle> = {
    //* overridable:
    //* outer:
    ...restProps,
    //* unoverridable:
    ellipsis: getEllipsisProps(ellipsis, expanded, setExpanded, t, isNoHover),
    className: cn(styles.title, className)
  };

  return (
    <AntTitle {...innerProps}>
      {icon && <TypographyIcon icon={icon} />}
      {children}
      {suffixIcon && <TypographyIcon icon={suffixIcon} />}
    </AntTitle>
  );
};
