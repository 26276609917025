import { ApplicationMode, FeatureFlagRule } from 'types/common';
import { ROLES } from 'types/roles';

export type FeatureFlagName = 'LOCALIZATION';

export const FEATURE_FLAGS: Record<FeatureFlagName, FeatureFlagRule[] | boolean> = {
  LOCALIZATION: [
    {
      users: [ROLES.GUEST, ROLES.STUDENT],
      modes: [ApplicationMode.DEV, ApplicationMode.PROD]
    }
  ]
};
