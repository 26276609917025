import { FC } from 'react';
import { Title } from 'common/ui/_new';
import { isTitle } from './helper';
import { IHeaderProps } from './types';
import styles from './styles.module.scss';

export const Header: FC<IHeaderProps> = (props) => {
  const { afterGroup, endGroup, children, ...restProps } = props;

  return (
    <div className={styles.header}>
      <div className={styles.titleGroup}>
        {isTitle(props) ? (
          <Title ellipsis={{ tooltip: children, rows: 3 }} {...restProps}>
            {children}
          </Title>
        ) : (
          children
        )}
        {afterGroup && <div>{afterGroup}</div>}
      </div>
      {endGroup && <div>{endGroup}</div>}
    </div>
  );
};
