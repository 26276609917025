import { ComponentProps, FC, useContext } from 'react';
import { Drawer as AntDrawer } from 'antd';
import { Drawer, DrawerProps } from 'common/ui/_new';
import { MobileDrawerContext } from './helper';
import styles from './styles.module.scss';

export const MobileDrawer: FC<DrawerProps> = ({ children, ...restProps }) => {
  const { isMobileDrawerOpen, setIsMobileDrawerOpen } = useContext(MobileDrawerContext);

  const innerProps: ComponentProps<typeof AntDrawer> = {
    //* overridable:
    open: isMobileDrawerOpen,
    onClose: () => setIsMobileDrawerOpen(false),
    //* outer:
    ...restProps,
    //* unoverridable:
    className: styles.drawer
  };

  return <Drawer {...innerProps}>{children}</Drawer>;
};
