import { FC } from 'react';
import { Tooltip, TooltipProps } from 'antd';
import { Icon, IIconType } from 'common/ui/_new';
import styles from './styles.module.scss';

type IProps = TooltipProps & {
  text: string;
  title?: string;
  iconType?: IIconType;
};

export const TextWithTooltipIcon: FC<IProps> = ({ text, title, iconType = 'info', ...tooltipProps }) => {
  if (!title) {
    return <>{text}</>;
  }
  return (
    <div className={styles.container}>
      {text}
      <Tooltip title={title} {...tooltipProps}>
        <span>
          <Icon type={iconType} />
        </span>
      </Tooltip>
    </div>
  );
};
