import { createApi } from '@reduxjs/toolkit/query/react';
import { getBaseQuery } from 'common/helpers';
import { IPicture } from 'types/requests/instructor/storage.api';

export const storageApi = createApi({
  reducerPath: 'storageApi',
  baseQuery: getBaseQuery(),
  endpoints: ({ mutation }) => ({
    // Загрузить изображение (/uploads/pictures/)
    uploadPicture: mutation<IPicture.Response, IPicture.Params>({
      query: (body) => ({
        url: '/api/Instructors/Storage/Picture/Upload',
        method: 'POST',
        body
      })
    }),
    uploadFile: mutation<IPicture.Response, IPicture.Params>({
      query: (body) => ({
        url: '/api/Instructors/Storage/Upload',
        method: 'POST',
        body
      })
    })
  })
});

export const { useUploadPictureMutation, useUploadFileMutation } = storageApi;
