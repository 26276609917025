import { ArgsProps } from 'antd/es/notification';
import cn from 'classnames';
import { Icon } from 'common/ui/_new';
import { ArgsPropsWithOptionalMessage } from './types';
import styles from './styles.module.scss';

import { TFunction } from 'i18next';

const closeIcon = <Icon type='cross-small' className={styles.closeIcon} />;

export const getPropsByType = <T extends NonNullable<ArgsProps['type']>>(
  {
    type,
    props
  }: T extends 'success' | 'error'
    ? {
        type: T;
        props?: ArgsPropsWithOptionalMessage | undefined;
      }
    : {
        type: T;
        props: ArgsProps;
      },
  t?: TFunction<'common', 'ui.notification'>
): ArgsProps => {
  if (type === 'success') {
    const { message = t?.('success.default.message'), className, ...restProps } = props ?? {};
    const innerClassName = cn(styles.notification, className);

    return {
      //* overridable:
      icon: <Icon type='check' className={styles.icon} />,
      message,
      //* outer:
      ...restProps,
      //* unoverridable:
      closeIcon,
      className: innerClassName
    };
  }

  if (type === 'error') {
    const { message = t?.('error.default.message'), className, ...restProps } = props ?? {};
    const innerClassName = cn(styles.notification, className);

    return {
      //* overridable:
      icon: <Icon type='close' className={styles.icon} />,
      message,
      //* outer:
      ...restProps,
      //* unoverridable:
      closeIcon,
      className: innerClassName
    };
  }

  const { className, ...restProps } = props;
  const innerClassName = cn(styles.notification, className);

  switch (type) {
    case 'info':
      return {
        //* overridable:
        icon: <Icon type='info' className={styles.icon} />,
        //* outer:
        ...restProps,
        //* unoverridable:
        closeIcon,
        className: innerClassName
      };
    case 'warning':
      return {
        //* overridable:
        icon: <Icon type='warning' className={styles.icon} />,
        //* outer:
        ...restProps,
        //* unoverridable:
        closeIcon,
        className: innerClassName
      };
    default:
      throw new Error('Unsupported notification type!');
  }
};
