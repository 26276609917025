import type { IEditSlideContent, ISaveSlideContent, ISlideContent } from 'types/requests/instructor/slides.slide_content.api';
import { slidesApi } from '../slides.api';

const slideContentApi = slidesApi.injectEndpoints({
  endpoints: ({ query, mutation }) => ({
    // API Создание страницы типа Content.
    saveSlideContent: mutation<ISaveSlideContent.Response, ISaveSlideContent.Params>({
      query: ({ exerciseId, body }) => ({
        url: `/api/instructors/Exercises/${exerciseId}/Pages/Content`,
        method: 'POST',
        body
      }),
      invalidatesTags: ['Slides', 'Content']
    }),
    // API Информации о странице типа Content.
    getSlideContent: query<ISlideContent.Response, ISlideContent.Params>({
      query: ({ exerciseId, pageId }) => `/api/instructors/Exercises/${exerciseId}/Pages/${pageId}/Content`,
      providesTags: ['Content']
    }),
    // API Редактирование страницы типа Content.
    editSlideContent: mutation<IEditSlideContent.Response, IEditSlideContent.Params>({
      query: ({ exerciseId, pageId, body }) => ({
        url: `/api/instructors/Exercises/${exerciseId}/Pages/${pageId}/Content`,
        method: 'PUT',
        body
      }),
      invalidatesTags: ['Slides', 'Content']
    })
  })
});

export const { useSaveSlideContentMutation, useEditSlideContentMutation, useGetSlideContentQuery } = slideContentApi;
