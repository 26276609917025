import { ComponentProps } from 'react';
import { Alert as AntAlert } from 'antd';
import { Icon, icons, IIconType } from 'common/ui/_new';
import { IAlertTypeIconsMap, IGetIconByType, IGetPropsByType } from './types';

const isAlertIconType = (icon: ComponentProps<typeof AntAlert>['icon'] | IIconType): icon is IIconType => {
  return typeof icon === 'string' && icon in icons;
};

export const getPropsByType: IGetPropsByType = (type) => {
  switch (type) {
    case 'default':
      return {
        innerType: 'info',
        innerTypeClassName: 'alert-default'
      };
    case 'default-secondary':
      return {
        innerType: 'info',
        innerTypeClassName: 'alert-secondary alert-default-secondary'
      };
    case 'info-secondary':
      return {
        innerType: 'info',
        innerTypeClassName: 'alert-secondary alert-info-secondary'
      };
    case 'success-secondary':
      return {
        innerType: 'success',
        innerTypeClassName: 'alert-secondary alert-success-secondary'
      };
    case 'error-secondary':
      return {
        innerType: 'error',
        innerTypeClassName: 'alert-secondary alert-error-secondary'
      };
    case 'warning-secondary':
      return {
        innerType: 'warning',
        innerTypeClassName: 'alert-secondary alert-warning-secondary'
      };
    default:
      return {
        innerType: type
      };
  }
};

const alertTypeIconsMap: IAlertTypeIconsMap = {
  info: 'info',
  success: 'check',
  error: 'close',
  warning: 'warning'
};

export const getIconByType: IGetIconByType = ({ type = 'info', icon }) => {
  if (icon === undefined) {
    return <Icon type={alertTypeIconsMap[type]} />;
  }

  if (isAlertIconType(icon)) {
    return <Icon type={icon} />;
  }

  return icon;
};
