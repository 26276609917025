import { createSlice } from '@reduxjs/toolkit';
import { IOneIdAction, IOneIdSlice } from 'types/store/guest/one-id.slice';

const initialState: IOneIdSlice = {
  error: undefined
};

export const oneIdSlice = createSlice({
  name: 'oneId',
  initialState,
  reducers: {
    setOneIdError: (state, action: IOneIdAction) => {
      state.error = action.payload;
      return state;
    }
  }
});

export const { actions: oneIdActions } = oneIdSlice;
