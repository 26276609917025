import { createApi } from '@reduxjs/toolkit/query/react';
import { enforceResponseFileDownload, getBaseQuery } from 'common/helpers';
import {
  IDownloadResultFiles,
  IInstructorApi,
  IInstructorCreate,
  IInstructorDelete,
  IInstructorExport,
  IInstructors,
  IInstructorsAttributes,
  IInstructorUpdate
} from 'types/requests/instructor/instructors.api';

export const instructorsApi = createApi({
  reducerPath: 'instructorsApi',
  baseQuery: getBaseQuery(),
  tagTypes: ['Instructors'],
  endpoints: ({ query, mutation }) => ({
    // Список программы обучения
    getInstructors: query<IInstructors.Response, IInstructors.Params>({
      query: ({ courseId, search }) => ({
        url: `/api/instructors/trainings/${courseId}/instructors`,
        params: {
          search
        }
      }),
      providesTags: ['Instructors']
    }),
    getInstructor: query<IInstructorApi.Response, IInstructorApi.Params>({
      query: ({ courseId, instructorId, search }) => ({
        url: `/api/instructors/Trainings/${courseId}/Instructors/${instructorId}/Edit`,
        params: {
          search
        }
      }),
      providesTags: ['Instructors']
    }),
    getInstructorsAttributes: query<IInstructorsAttributes.Response, IInstructorsAttributes.Params>({
      query: ({ courseId }) => ({
        url: `/api/instructors/Trainings/${courseId}/instructors/attributes`
      })
    }),
    createInstructor: mutation<IInstructorCreate.Response, IInstructorCreate.Params>({
      query: ({ courseId, ...body }) => ({
        url: `/api/instructors/Trainings/${courseId}/Instructors/Include`,
        method: 'POST',
        body
      }),
      invalidatesTags: ['Instructors']
    }),
    updateInstructor: mutation<IInstructorUpdate.Response, IInstructorUpdate.Params>({
      query: ({ courseId, instructorId, ...body }) => ({
        url: `/api/instructors/Trainings/${courseId}/Instructors/${instructorId}/Edit`,
        method: 'PUT',
        body
      }),
      invalidatesTags: ['Instructors']
    }),
    deleteInstructor: mutation<IInstructorDelete.Response, IInstructorDelete.Params>({
      query: ({ courseId, instructorId }) => ({
        url: `/api/instructors/Trainings/${courseId}/Instructors/${instructorId}/Exclude`,
        method: 'DELETE'
      }),
      invalidatesTags: ['Instructors']
    }),
    exportInstructors: mutation<IInstructorExport.Response, IInstructorExport.Params>({
      //@ts-ignore
      queryFn: async ({ courseId, attributes, fileName }, api, extraOptions, baseQuery) => {
        const result = await baseQuery({
          url: `/api/instructors/Trainings/${courseId}/Instructors/Export`,
          method: 'POST',
          body: { attributes },
          responseHandler: (response) => response.blob()
        });
        const hiddenElement = document.createElement('a');
        const url = window.URL || window.webkitURL;
        hiddenElement.href = url.createObjectURL(result.data as Blob);
        hiddenElement.target = '_blank';
        hiddenElement.download = `${fileName}.xlsx`;
        hiddenElement.click();
        return { data: null };
      }
    }),
    downloadResultFiles: query<IDownloadResultFiles.Response, IDownloadResultFiles.Params>({
      query: ({ courseId, exerciseId, studentId }) => {
        return {
          url: `/api/instructors/trainings/${courseId}/results/files`,
          method: 'GET',
          params: { exerciseId, studentId },
          responseHandler: enforceResponseFileDownload,
          cache: 'no-cache'
        };
      }
    })
  })
});

export const {
  useGetInstructorsQuery,
  useGetInstructorQuery,
  useGetInstructorsAttributesQuery,
  useCreateInstructorMutation,
  useUpdateInstructorMutation,
  useDeleteInstructorMutation,
  useExportInstructorsMutation,
  useLazyDownloadResultFilesQuery
} = instructorsApi;
