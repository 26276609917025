import { createApi } from '@reduxjs/toolkit/query/react';
import { enforceResponseFileDownload, getBaseQuery } from 'common/helpers';
import {
  AcceptApplication,
  CancelApplication,
  ExportApplicationsList,
  GetApplicationsCount,
  GetApplicationsList
} from 'types/requests/instructor/applications.api';

export const applicationsApi = createApi({
  reducerPath: 'applicationsApi',
  tagTypes: ['ApplicationsCount'],
  baseQuery: getBaseQuery(),
  endpoints: ({ query, mutation }) => ({
    acceptApplication: mutation<AcceptApplication.Response, AcceptApplication.Params>({
      query: ({ applicationId }) => ({
        url: `/api/applications/${applicationId}/allow`,
        method: 'POST'
      })
    }),
    cancelApplication: mutation<CancelApplication.Response, CancelApplication.Params>({
      query: ({ applicationId }) => ({
        url: `/api/applications/${applicationId}/deny`,
        method: 'POST'
      }),
      invalidatesTags: ['ApplicationsCount']
    }),
    exportApplicationsList: mutation<ExportApplicationsList.Response, ExportApplicationsList.Params>({
      query: ({ trainingId, body }) => ({
        url: `/api/applications/trainings/${trainingId}/export`,
        method: 'POST',
        body,
        responseHandler: enforceResponseFileDownload
      }),
      invalidatesTags: ['ApplicationsCount']
    }),
    getApplicationsCount: query<GetApplicationsCount.Response, GetApplicationsCount.Params>({
      query: () => ({
        url: `/api/instructors/trainings/applications/count`
      }),
      providesTags: ['ApplicationsCount']
    }),
    getApplicationsList: mutation<GetApplicationsList.Response, GetApplicationsList.Params>({
      query: ({ trainingId, body }) => {
        const params = new URLSearchParams();
        if (trainingId) {
          params.append('training', `${trainingId}`);
        }
        return {
          url: `/api/instructors/applications`,
          method: 'POST',
          body,
          params
        };
      }
    })
  })
});

export const {
  useAcceptApplicationMutation,
  useCancelApplicationMutation,
  useExportApplicationsListMutation,
  useGetApplicationsCountQuery,
  useGetApplicationsListMutation
} = applicationsApi;
