import { lazy } from 'react';
import type { IRoute } from 'types/common';
import { ROLES } from 'types/roles';
import { getGuestLabels, guestPaths as paths } from 'types/routes/guest';

const AuthLoader = lazy(() => import('pages/guest/AuthLoader'));
const CoursePreviewByInvite = lazy(() => import('pages/guest/CoursePreviewByInvite'));
const CoursePreview = lazy(() => import('pages/guest/CoursePreview'));
const NotFound = lazy(() => import('pages/guest/NotFound'));
const Welcome = lazy(() => import('pages/guest/Welcome'));

const labels = getGuestLabels();

export const guestRoutes: IRoute[] = [
  {
    path: paths.welcome,
    label: labels.welcome,
    roles: [ROLES.GUEST, ROLES.STUDENT, ROLES.INSTRUCTOR, ROLES.ADMIN],
    element: <Welcome />
  },
  {
    path: paths.authLoader,
    label: labels.authLoader,
    roles: [ROLES.GUEST, ROLES.STUDENT, ROLES.INSTRUCTOR, ROLES.ADMIN],
    element: <AuthLoader />
  },
  {
    path: paths.invite,
    label: labels.invite,
    roles: [ROLES.GUEST, ROLES.STUDENT, ROLES.INSTRUCTOR, ROLES.ADMIN],
    element: <CoursePreviewByInvite />
  },
  {
    path: paths.coursePreview,
    label: labels.coursePreview,
    roles: [ROLES.GUEST, ROLES.STUDENT, ROLES.INSTRUCTOR, ROLES.ADMIN],
    element: <CoursePreview />
  },
  {
    path: paths.notFound,
    label: labels.notFound,
    roles: [ROLES.GUEST, ROLES.STUDENT, ROLES.INSTRUCTOR, ROLES.ADMIN],
    element: <NotFound />
  }
];
