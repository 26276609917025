import { createApi } from '@reduxjs/toolkit/query/react';
import { getBaseQuery } from 'common/helpers';
import { IAnswersDownload, IExportTrainings } from 'types/requests/instructor/answersDownload';

export const uploadingResponses = createApi({
  reducerPath: 'uploadingResponses',
  baseQuery: getBaseQuery(),
  tagTypes: [],
  endpoints: ({ query }) => ({
    getAttributes: query<IExportTrainings.Response, IExportTrainings.Params>({
      query: ({ courseId }) => `/api/instructors/trainings/${courseId}/students/attributes`
    }),
    getFile: query<IAnswersDownload.Response, IAnswersDownload.Params>({
      query: ({ courseId, attributeIds, lessonId, exerciseId }) => {
        const attributes = attributeIds.length ? `?attributeIds=${attributeIds.join('&attributeIds=')}` : '';
        const endUrl = lessonId ? `/lesson/${lessonId}` : exerciseId ? `/exercise/${exerciseId}` : '';
        return {
          url: `/api/instructors/analytics/answers/export/training/${courseId}${endUrl}${attributes}`,
          method: 'GET',
          responseHandler: async (response) => response.url
        };
      }
    })
  })
});

export const { useGetAttributesQuery, useLazyGetFileQuery } = uploadingResponses;
