import { createApi } from '@reduxjs/toolkit/query/react';
import { getBaseQuery } from 'common/helpers';

export const reflectionApi = createApi({
  reducerPath: 'reflectionApi',
  baseQuery: getBaseQuery(),
  tagTypes: ['Reflections'],
  endpoints: ({ mutation }) => ({
    deleteQuestion: mutation<void, string>({
      query: (question) => ({
        url: `/api/reflection/questions/${question}`,
        method: 'DELETE'
      }),
      invalidatesTags: ['Reflections']
    })
  })
});

export const { useDeleteQuestionMutation } = reflectionApi;
