import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CourseGroupId, ExerciseOptionsTypeEnum } from 'types/entities';

export type IStudentsJournalState = {
  groupId: CourseGroupId | undefined;
  trainingTypes: ExerciseOptionsTypeEnum[];
};

const initialState: IStudentsJournalState = {
  groupId: undefined,
  trainingTypes: [ExerciseOptionsTypeEnum.Lecture, ExerciseOptionsTypeEnum.Testing, ExerciseOptionsTypeEnum.Independent]
};

export const studentsJournalSlice = createSlice({
  name: 'studentsJournal',
  initialState,
  reducers: {
    setGroupId: (state, action: PayloadAction<CourseGroupId>) => {
      if (action.payload) {
        state.groupId = action.payload;
      } else {
        state.groupId = undefined;
      }
    },
    setTrainingTypes: (state, action: PayloadAction<ExerciseOptionsTypeEnum[]>) => {
      if (action.payload) {
        state.trainingTypes = action.payload;
      } else {
        state.trainingTypes = [];
      }
    }
  }
});

export const { setGroupId, setTrainingTypes } = studentsJournalSlice.actions;
export default studentsJournalSlice.reducer;
