import { ISlideMap } from 'types/common';
import { AnswersPreviewModal } from '../Slides/Answers/AnswersPreviewModal';
import { AudioPreviewModal } from '../Slides/Audio/AudioPreviewModal';
import { ContentPreviewModal } from '../Slides/Content/ContentPreviewModal';
import { EditableAnswersPreviewModal } from '../Slides/EditableAnswers/EditableAnswersPreviewModal';
import { EditableFreePreviewModal } from '../Slides/EditableFree/EditableFreePreviewModal';
import { EvaluationPreviewModal } from '../Slides/Evaluation/EvaluationPreviewModal';
import { ExternalPreviewModal } from '../Slides/External/ExternalPreviewModal';
import { FilePreviewModal } from '../Slides/File/FilePreviewModal';
import { FreePreviewModal } from '../Slides/Free/FreePreviewModal';
import { KinescopePreviewModal } from '../Slides/Kinescope/KinescopePreviewModal';
import { PdfPreviewModal } from '../Slides/PDF/PdfPreviewModal';
import { PicturePreviewModal } from '../Slides/Picture/PicturePreviewModal';
import { PptxPreviewModal } from '../Slides/PPTX/PptxPreviewModal';
import { VideoPreviewModal } from '../Slides/Video/VideoPreviewModal';
import { WebPreviewModal } from '../Slides/Web/WebPreviewModal';

export const SLIDE: ISlideMap = {
  evaluation: <EvaluationPreviewModal />,
  content: <ContentPreviewModal />,
  picture: <PicturePreviewModal />,
  file: <FilePreviewModal />,
  free: <FreePreviewModal />,
  answers: <AnswersPreviewModal />,
  web: <WebPreviewModal />,
  pdf: <PdfPreviewModal />,
  audio: <AudioPreviewModal />,
  video: <VideoPreviewModal />,
  external: <ExternalPreviewModal />,
  attributes: <div />,
  pptx: <PptxPreviewModal />,
  editable_free: <EditableFreePreviewModal />,
  editable_answers: <EditableAnswersPreviewModal />,
  kinescope: <KinescopePreviewModal />
};
