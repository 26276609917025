import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { SettingItemLayout, SettingItemLayoutProps } from '../common';
import { LocaleSelect } from './LocaleSelect';

type LocaleSettingProps = Pick<SettingItemLayoutProps, 'className'>;

export const LocaleSetting: FC<LocaleSettingProps> = ({ className }) => {
  const { t } = useTranslation('common', { keyPrefix: 'components.header.settings-menu' });

  return (
    <SettingItemLayout label={t('lang.label')} className={className}>
      <LocaleSelect />
    </SettingItemLayout>
  );
};
