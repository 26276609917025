import { Icon } from '../../../Icon';
import { IAvatarIconType } from './types';

type IAvatarIconProps = {
  type: IAvatarIconType;
};

export const AvatarIcon: React.FC<IAvatarIconProps> = ({ type }) => {
  return <Icon type={type} />;
};
