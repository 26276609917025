import { createSlice } from '@reduxjs/toolkit';
import { IAuthTypeAction, IAuthTypeSlice } from 'types/store/guest/auth-type.slice';

const initialState: IAuthTypeSlice = {
  authType: undefined
};

export const authTypeSlice = createSlice({
  name: 'authType',
  initialState,
  reducers: {
    setAuthType: (state, action: IAuthTypeAction) => {
      state.authType = action.payload;
      return state;
    }
  }
});

export const { actions: authTypeActions } = authTypeSlice;
