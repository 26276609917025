import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import type { IAddressSuggestions, ICompanySuggestions } from 'types/requests/student/dadata.api';

export const dadataApi = createApi({
  reducerPath: 'dadataApi',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_DADATA_DOMAIN,
    mode: 'cors',
    prepareHeaders: (headers) => {
      headers.set('accept', 'application/json');
      headers.set('Content-Type', 'application/json');
      headers.set('Authorization', `Token ${process.env.REACT_APP_DADATA_API_KEY}`);
      return headers;
    }
  }),
  endpoints: ({ query }) => ({
    getCompanySuggestions: query<ICompanySuggestions.Response, ICompanySuggestions.Params>({
      query: (query) => ({
        url: '/suggestions/api/4_1/rs/suggest/party',
        method: 'POST',
        body: { query }
      })
    }),
    getAddressSuggestions: query<IAddressSuggestions.Response, IAddressSuggestions.Params>({
      query: ({ query, language }) => ({
        url: '/suggestions/api/4_1/rs/suggest/address',
        method: 'POST',
        body: { query, language }
      })
    })
  })
});

export const {
  useLazyGetAddressSuggestionsQuery,
  useLazyGetCompanySuggestionsQuery,
  useGetAddressSuggestionsQuery,
  useGetCompanySuggestionsQuery
} = dadataApi;
