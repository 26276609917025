import { ComponentProps, FC } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { ColorScheme } from 'containers/_new';
import { useColorScheme } from 'common/hooks';
import { IPdfViewerProps } from './types';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import styles from './styles.module.scss';

import { LocalizationMap, Viewer, Worker } from '@react-pdf-viewer/core';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import en_US from '@react-pdf-viewer/locales/lib/en_US.json';
import ru_RU from '@react-pdf-viewer/locales/lib/ru_RU.json';
import type { ToolbarProps, ToolbarSlot, TransformToolbarSlot } from '@react-pdf-viewer/toolbar';
import { LANGUAGES } from 'i18n/constants';

const WORKER_PACKAGE = 'https://unpkg.com/pdfjs-dist@2.16.105/build/pdf.worker.min.js';
const domain = process.env.REACT_APP_API;

export const PdfViewer: FC<IPdfViewerProps> = ({ url, permissions, className, style, ...restProps }) => {
  const { i18n } = useTranslation();

  const transform: TransformToolbarSlot = (slot: ToolbarSlot) => ({
    ...slot,
    ...(!permissions?.download && { Download: () => <></> })
  });
  const renderToolbar = (Toolbar: (props: ToolbarProps) => React.ReactElement) => <Toolbar>{renderDefaultToolbar(transform)}</Toolbar>;
  const defaultLayoutPluginInstance = defaultLayoutPlugin({ renderToolbar });
  const { renderDefaultToolbar } = defaultLayoutPluginInstance.toolbarPluginInstance;

  const [colorScheme] = useColorScheme();

  const innerProps: ComponentProps<typeof Viewer> = {
    //* overridable:
    withCredentials: true,
    localization: (i18n.language === LANGUAGES.ru ? ru_RU : en_US) as unknown as LocalizationMap,
    theme: colorScheme === ColorScheme.DARK ? 'dark' : undefined,
    plugins: [defaultLayoutPluginInstance],
    //* outer:
    ...restProps,
    //* unoverridable:
    fileUrl: url?.startsWith('/') ? `${domain}${url}` : url ?? ''
  };

  return (
    <div className={cn(styles.viewer, className)} style={{ height: '750px', ...style }}>
      <Worker workerUrl={WORKER_PACKAGE}>
        <Viewer {...innerProps} />
      </Worker>
    </div>
  );
};
