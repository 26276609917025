import { createContext, Dispatch, SetStateAction } from 'react';
import { PageError_old } from './types';

/**
 * @deprecated
 */
export const PageErrorHandlerContext_old = createContext<{
  pageError?: PageError_old | undefined;
  setPageError: Dispatch<SetStateAction<PageError_old | undefined>>;
}>({
  setPageError: () => undefined
});
