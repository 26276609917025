import { ComponentProps } from 'react';
import cn from 'classnames';
import { Tooltip } from 'common/ui/_new';
import { IButtonTooltipProps } from './types';
import styles from './styles.module.scss';

export const ButtonTooltip: React.FC<IButtonTooltipProps> = (props) => {
  const { overlayClassName, ...restProps } = props;

  const innerProps: ComponentProps<typeof Tooltip> = {
    //* overridable:
    //* outer:
    ...restProps,
    //* unoverridable:
    overlayClassName: cn(styles.overlay, overlayClassName)
  };

  return <Tooltip {...innerProps} />;
};
