import { Id } from 'types/common';

export type QuestionId = Id;

//*	Lms.Entities.Entities.Page.Questions.QuestionType
// Типы вопросов
export enum QuestionTypeEnum {
  UNKNOWN = 0,
  BOARD_WITH_BLOCKS_AND_COMMANDS = 1,
  CLOSED_QUESTION = 2,
  QUESTION_WITH_CODE = 3,
  QUESTION_WITH_HTML = 4,
  FREE_RESPONSE = 5,
  DRAWING = 6,
  DOCUMENT = 7,
  GRADED_ACTIVITY = 8,
  ATTRIBUTES_TO_FILL = 9,
  SCORM_SLIDE = 10,
  EXTERNAL_QUESTION = 101
}

export enum QuestionResponseType {
  CHECKBOX,
  RADIO
}
