import { FC, PropsWithChildren } from 'react';
import { notification } from 'antd';
import { ArgsProps } from 'antd/es/notification';
import { NotificationContext } from './helper';

type NotificationProviderProps = PropsWithChildren;

export const NotificationProvider: FC<NotificationProviderProps> = ({ children }) => {
  const [api, contextHolder] = notification.useNotification();

  const openNotification = (props?: ArgsProps) => {
    if (props) {
      const { type = 'info', ...restProps } = props;
      api[type](restProps);
    }
  };

  return (
    <NotificationContext.Provider value={{ openNotification }}>
      {contextHolder}
      {children}
    </NotificationContext.Provider>
  );
};
