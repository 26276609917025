import { ErrorResultPageProps } from './common';
import { CourseInvitationInvalidErrorPageProps } from './InvitationInvalid';
import { SessionNotCompletedErrorPageProps } from './SessionNotCompleted';

export const enum PageErrorKey {
  NO_ACCESS,
  COURSE_INVITATION_INVALID,
  COURSE_INVITATION_UNAVAILABLE,
  COURSE_UNAVAILABLE,
  COURSE_OR_EXERCISE_NOT_FOUND,
  LESSON_OR_COURSE_UNAVAILABLE,
  SESSION_NOT_COMPLETED,
  STUDENT_ALREADY_IN_COURSE,
  UNFILLED_REQUIRED_PROFILE_FIELDS
}

export type ErrorPageProps<T = PageErrorKey> = {
  pageErrorKey?: T;
} & (T extends PageErrorKey.COURSE_INVITATION_INVALID
  ? CourseInvitationInvalidErrorPageProps
  : T extends PageErrorKey.SESSION_NOT_COMPLETED
  ? SessionNotCompletedErrorPageProps
  : ErrorResultPageProps);

export const isCourseInvitationInvalidErrorProps = (
  pageErrorKey: PageErrorKey | undefined,
  props: ErrorPageProps
): props is CourseInvitationInvalidErrorPageProps => {
  return pageErrorKey === PageErrorKey.COURSE_INVITATION_INVALID;
};

export const isSessionNotCompletedErrorPageProps = (
  pageErrorKey: PageErrorKey | undefined,
  props: ErrorPageProps
): props is SessionNotCompletedErrorPageProps => {
  return pageErrorKey === PageErrorKey.SESSION_NOT_COMPLETED;
};
