import { createSlice } from '@reduxjs/toolkit';
import { createRoleObject } from 'common/helpers';
import { ROLES } from 'types/roles';
import { IProfileLoadingAction, IProfileState, IProfileStatusAction } from 'types/store/student/profile.slice';
import { profileApi } from './profile.api';

const initialState: IProfileState = {
  eMail: '',
  surname: '',
  firstname: '',
  picture: '',
  isLoading: true,
  role: ROLES.GUEST
};

export const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    setLoading: (state, action: IProfileLoadingAction) => {
      state = { ...state, isLoading: action.payload };
      return state;
    },
    setUnfilled: (state, action: IProfileStatusAction) => {
      state = { ...state, unfilled: action.payload };
      return state;
    }
  },
  extraReducers: (builder) => {
    builder.addMatcher(profileApi.endpoints.getProfile.matchFulfilled, (state, { payload }) => {
      return { ...state, ...payload, role: createRoleObject(payload.roles) };
    });
  }
});

export const { setUnfilled } = profileSlice.actions;
