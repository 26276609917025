import { ComponentProps, FC } from 'react';
import { Result as AntResult } from 'antd';
import cn from 'classnames';
import { getPropsByStatus } from './helper';
import { IResultProps } from './types';
import styles from './styles.module.scss';

export const Result: FC<IResultProps> = ({ status, className, ...restProps }) => {
  const { icon, innerStatus, statusClassName } = getPropsByStatus(status);

  const innerProps: ComponentProps<typeof AntResult> = {
    //* overridable:
    icon,
    //* outer:
    ...restProps,
    //* unoverridable:
    status: innerStatus,
    className: cn(styles.result, statusClassName, className)
  };

  return <AntResult {...innerProps} />;
};
