import { FC } from 'react';
import { Divider } from 'antd';
import cn from 'classnames';
import { IContentLayoutProps } from './types';
import styles from './styles.module.scss';

export const ContentLayout: FC<IContentLayoutProps> = ({
  header,
  headerSeparated,
  gap = 'lg',
  contentClassName,
  tile = false,
  children
}) => {
  return (
    <div className={tile ? styles.tile : undefined}>
      <div className={cn(styles.main, styles[gap], contentClassName)}>
        {header}
        {headerSeparated && <Divider style={{ marginBlock: 0 }} />}
        {children}
      </div>
    </div>
  );
};
