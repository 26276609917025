export type AuthQueryError = {
  error: {
    data: unknown;
    status: number;
  };
};

export const isAuthQueryError = (e: unknown): e is AuthQueryError => {
  return (
    typeof e === 'object' &&
    e !== null &&
    'error' in e &&
    typeof e.error === 'object' &&
    e.error !== null &&
    'data' in e.error &&
    'status' in e.error &&
    typeof e.error.status === 'number'
  );
};
