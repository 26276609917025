import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'common/ui/_new';
import { AttributesForm, AttributesFormProps, FieldValue } from 'common/components/_new';
import { useSetProfileFieldsMutation, useSetProfileFilesMutation } from 'api/student/profile/profile.api';
import { AttributesField } from 'types/entities';
import { getProfileQueryBodyData, getValidValues } from './helper';
import { ProfileAttributesFormProps } from './types';
import styles from './styles.module.scss';

export const ProfileAttributesForm: FC<ProfileAttributesFormProps> = ({ form, attributes, name, readOnly, children, onValuesChange }) => {
  const { t } = useTranslation('Students', { keyPrefix: 'pages.profile.profile-attributes-form' });

  const [setProfileFields, setProfileFieldsMutation] = useSetProfileFieldsMutation();
  const [setProfileFiles, setProfileFilesMutation] = useSetProfileFilesMutation();

  const isDataLoading = setProfileFieldsMutation.isLoading || setProfileFilesMutation.isLoading;

  const saveAttributesFormData = (values: Record<AttributesField['id'], FieldValue>) => {
    const { fieldsData, filesData } = getProfileQueryBodyData(values, attributes, t);

    if (fieldsData.length > 0) {
      setProfileFields(fieldsData);
    }

    if (Array.from(filesData).length > 0) {
      setProfileFiles(filesData);
    }
  };

  const onFinish: AttributesFormProps['onFinish'] = (values: Record<AttributesField['id'], FieldValue>) => {
    saveAttributesFormData(values);
  };

  const onFinishFailed: AttributesFormProps['onFinishFailed'] = ({ errorFields, values }) => {
    const validValues = getValidValues(errorFields, values);
    saveAttributesFormData(validValues);
  };

  useEffect(() => {
    if (!(setProfileFieldsMutation.isError || setProfileFilesMutation.isError)) {
      if (setProfileFieldsMutation.isSuccess || setProfileFilesMutation.isSuccess) {
        form.resetFields(); // for correct operation 'isFieldsTouched'
      }
    }
  }, [
    form,
    setProfileFieldsMutation.isError,
    setProfileFieldsMutation.isSuccess,
    setProfileFilesMutation.isError,
    setProfileFilesMutation.isSuccess
  ]);

  useEffect(() => {
    form.validateFields();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AttributesForm
      form={form}
      name={name}
      onValuesChange={onValuesChange}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      disabled={isDataLoading}
    >
      {children}
      {!readOnly && form.isFieldsTouched() && (
        <div className={styles.footer}>
          <div className={styles.submitContainer}>
            <Button type='primary' onClick={() => form.submit()}>
              {t('save-button.label')}
            </Button>
          </div>
        </div>
      )}
    </AttributesForm>
  );
};

/* 
  todo: add display of validation error from server
  !: form.isFieldsTouched() not responding to OptionalInput toggling
*/
