import { ComponentProps } from 'react';
import cn from 'classnames';
import { IDocViewer } from './types';
import styles from './styles.module.scss';

import { DocumentViewer } from 'react-documents';

const domain = process.env.REACT_APP_API;

export const DocViewer: IDocViewer = (props) => {
  const { url, className, ...restProps } = props;

  const innerProps: ComponentProps<typeof DocumentViewer> = {
    //* overridable:
    viewer: 'office',
    //* outer:
    ...restProps,
    //* unoverridable:
    url: url?.startsWith('/') ? `${domain}${url}` : url,
    className: cn(styles.docViewer, className)
  };

  return <DocumentViewer {...innerProps} />;
};
