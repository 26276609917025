import { FC } from 'react';
import cn from 'classnames';
import { Checkbox } from 'common/ui/_new';
import { ICheckboxQuestionProps } from './types';
import styles from './styles.module.scss';

export const CheckboxQuestion: FC<ICheckboxQuestionProps> = ({ options, isAnswered, size = 'large', className, ...restProps }) => {
  if (isAnswered) {
    return (
      <Checkbox.Group
        {...restProps}
        value={options.filter(({ checked }) => checked).map(({ value }) => value)}
        disabled
        className={cn(styles.checkboxQuestion, styles.isAnswered, { [styles.small]: size === 'small' }, className)}
      >
        {options.map(({ id, label, correct, ...rest }, i) => {
          return (
            <Checkbox
              key={id ?? i}
              className={cn(styles.checkbox, { [styles.correct]: correct === true, [styles.inCorrect]: correct === false })}
              {...rest}
            >
              {label}
            </Checkbox>
          );
        })}
      </Checkbox.Group>
    );
  }

  return (
    <Checkbox.Group
      {...restProps}
      options={options}
      className={cn(styles.checkboxQuestion, { [styles.small]: size === 'small' }, className)}
    />
  );
};
