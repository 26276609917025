import { ComponentProps } from 'react';
import { Radio as AntRadio } from 'antd';
import cn from 'classnames';
import { IGroup } from './types';
import styles from './styles.module.scss';

export const Group: IGroup = ({ className, ...outerProps }) => {
  const overridableProps: ComponentProps<typeof AntRadio.Group> = {};

  const unoverridableProps: ComponentProps<typeof AntRadio.Group> = {
    className: cn(styles.radioGroup, className)
  };

  return <AntRadio.Group {...overridableProps} {...outerProps} {...unoverridableProps} />;
};
