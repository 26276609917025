import dayjs, { extend } from 'dayjs';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';

import { i18n } from 'i18next';

type localeFormat = 'LL' | 'LLL';

export const getDateLocale = (date: Date, i18n: i18n, format: localeFormat): string => {
  extend(LocalizedFormat);
  const dayJSDate = dayjs(date);
  return dayJSDate.locale(i18n.language).format(format);
};
