import { DateTime, Id } from 'types/common';
import { CourseLessonExerciseInfo, CoursePreviewExercise } from 'types/entities';

export type LessonId = Id;

//* Lms.UseCases.Interfaces.Trainings.Students.GetTrainingLessonsQueries.LessonStatusType
// Статусы проведения урока
export const enum LessonStatusType {
  NOT_STARTED = 0,
  IN_PROGRESS = 1,
  COMPLETED = 2
}

//* Lms.UseCases.Interfaces.Trainings.Students.GetTrainingLessonsQueries.GetLessonInfo
// Модель урока
export type LessonInfo = {
  id: LessonId;
  label?: string;
  description?: string;
  orderNumber: number;
  status: LessonStatusType;
  dateBegin?: DateTime;
  dateEnd?: DateTime;
  isAvailableBySchedule?: boolean;
};

//* Lms.UseCases.Interfaces.Trainings.Students.GetLessonExercisesQueries.GetLessonExercisesQueryResult
// Получить список упражнений
export type CourseLessonExercisesDescription = {
  exercises: CourseLessonExerciseInfo[];
};

//* Lms.UseCases.Interfaces.Common.Invite.InviteTrainingLessonDto
export type CoursePreviewLesson = {
  id: LessonId;
  label?: string;
  description?: string;
  exercises?: CoursePreviewExercise[];
};
