import { FC, HTMLAttributes } from 'react';
import cn from 'classnames';
import { ErrorBoundary } from 'containers';
import { IColSize } from 'types/common';
import { Breadcrumbs } from './Breadcrumbs';
import styles from './styles.module.scss';

interface ILayoutProps extends HTMLAttributes<HTMLDivElement> {
  centered?: boolean;
  showBreadcrumbs?: boolean;
  breadCrumbsSize?: IColSize;
  wide?: boolean;
}

export const Layout: FC<ILayoutProps> = ({
  children,
  centered = false,
  showBreadcrumbs = false,
  breadCrumbsSize,
  wide,
  className,
  ...restProps
}) => {
  return (
    <div className={cn(styles.layout, { [styles.centered]: centered }, className)}>
      {showBreadcrumbs && <Breadcrumbs size={breadCrumbsSize} className={styles.breadcrumb} />}
      <ErrorBoundary>
        <section className={cn(styles.content, { [styles.content_wide]: wide })} {...restProps}>
          {children}
        </section>
      </ErrorBoundary>
    </div>
  );
};
