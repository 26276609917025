import { IFormItemProps, optionalInputBlocked } from 'common/ui/_new';
import { AttributesField, AttributeTypeEnum } from 'types/entities';
import { AttributeFormItemProps, I18nPersonalDataAttributeItemWithOptionalTextKey, I18nPersonalDataAttributeKey } from './types';

import I18nEnCommon from 'i18n/en/common.json';
import { TFunction } from 'i18next';

export const isBoolInitialValue = (
  type: AttributeTypeEnum,
  value: AttributesField['value']
): value is AttributesField<AttributeTypeEnum.Bool>['value'] => type === AttributeTypeEnum.Bool;

export const isDateInitialValue = (
  type: AttributeTypeEnum,
  value: AttributesField['value']
): value is AttributesField<AttributeTypeEnum.Date>['value'] => type === AttributeTypeEnum.Date;

export const isFileInitialValue = (
  type: AttributeTypeEnum,
  value: AttributesField['value']
): value is AttributesField<AttributeTypeEnum.File>['value'] => type === AttributeTypeEnum.File || type === AttributeTypeEnum.Pattern;

export const isReferenceAttributeData = (
  type: AttributeTypeEnum,
  props: Partial<AttributesField>
): props is AttributesField<AttributeTypeEnum.Reference> => type === AttributeTypeEnum.Reference;

export const isSetAttributeData = (
  type: AttributeTypeEnum,
  value: Partial<AttributesField>
): value is AttributesField<AttributeTypeEnum.Set> => type === AttributeTypeEnum.Set;

export const isHierarchyAttributeData = (
  type: AttributeTypeEnum,
  value: Partial<AttributesField>
): value is AttributesField<AttributeTypeEnum.Hierarchy> => type === AttributeTypeEnum.Hierarchy;

/* temporary solution - BEGIN */
const personalDataAttributesKeys = I18nEnCommon.components['attribute-form-item']['personal-data-attributes'];

const personalDataAttributesTransKeysMap: Record<NonNullable<AttributeFormItemProps['id']>, I18nPersonalDataAttributeKey> = {
  'f7543446-d8c3-44e4-ae9b-44b03aa6fa13': 'last-name',
  '596c992d-8193-49c7-8bde-7a7730fe1642': 'first-name',
  '4d51bb6d-6add-456c-9769-bf154dcb2fe0': 'middle-name',
  '618525d8-088b-42a3-bee5-0f9483e088a1': 'time-zone',
  'f5ce98c7-dcb9-4166-bd04-6017dc7ff5fc': 'foreign-citizen',
  '01efc2a6-fe20-41ff-8291-ac2efa0f7a2d': 'education-email',
  '79e21752-6c0e-4bb7-9d72-4b88262e5a8f': 'phone-number',
  'fb8c7b17-7c47-444b-9907-60d38d2a4c88': 'about-me'
};

export const isPersonalDataAttribute = (id: NonNullable<AttributeFormItemProps['id']>) =>
  Object.keys(personalDataAttributesTransKeysMap).includes(id);

export const translateAttributeName = (
  id: NonNullable<AttributeFormItemProps['id']>,
  t: TFunction<'common', 'components.attribute-form-item'>
) => {
  const translationKey = personalDataAttributesTransKeysMap[id];
  return t(`personal-data-attributes.${translationKey}.label`);
};

const hasOptionaltext = (
  translationKey: I18nPersonalDataAttributeKey
): translationKey is I18nPersonalDataAttributeItemWithOptionalTextKey => {
  return 'optional-text' in personalDataAttributesKeys[translationKey];
};

export const translateAttributeOptionalText = (
  id: NonNullable<AttributeFormItemProps['id']>,
  t: TFunction<'common', 'components.attribute-form-item'>
) => {
  const translationKey = personalDataAttributesTransKeysMap[id];
  if (hasOptionaltext(translationKey)) {
    return t(`personal-data-attributes.${translationKey}.optional-text`);
  }
};
/* temporary solution - END */

export const getValdidationRules = (
  {
    type,
    required,
    regex,
    isHasNoneAttribute,
    label,
    min,
    max
  }: Pick<AttributesField, 'type' | 'required' | 'regex' | 'isHasNoneAttribute' | 'label' | 'min' | 'max'>,
  t: TFunction<'common', 'components.attribute-form-item'>
) => {
  const rules: IFormItemProps['rules'] = [];

  if (regex) {
    rules.push({ pattern: new RegExp(regex) });
  }

  if (required) {
    if (isHasNoneAttribute) {
      rules.push(() => ({
        validator(_, value) {
          if (value || value === optionalInputBlocked) {
            return Promise.resolve();
          }
          return Promise.reject(new Error(t('rules.optional-input.required', { label })));
        }
      }));
    } else if (type === AttributeTypeEnum.Bool) {
      rules.push({
        validator: (_, value) => {
          if (value) {
            return Promise.resolve();
          }
          return Promise.reject(new Error(t('rules.boolean.required-check')));
        }
      });
    } else {
      rules.push({ required: true });
    }
  }

  if (min) {
    const intMin = parseInt(min);
    if (!isNaN(intMin)) {
      if (type === AttributeTypeEnum.String) {
        rules.push({ min: intMin, message: t('rules.length.min', { count: intMin }) });
      }
      if (type === AttributeTypeEnum.Int || type === AttributeTypeEnum.Float) {
        rules.push({ type: 'number', min: intMin, message: t('rules.number.min', { value: intMin }) });
      }
    }
  }

  if (max) {
    const intMax = parseInt(max);
    if (!isNaN(intMax)) {
      if (type === AttributeTypeEnum.String) {
        rules.push({ max: intMax, message: t('rules.length.max', { count: intMax }) });
      }
      if (type === AttributeTypeEnum.Int || type === AttributeTypeEnum.Float) {
        rules.push({ type: 'number', max: intMax, message: t('rules.number.max', { value: intMax }) });
      }
    }
  }

  return rules;
};

export const getCapitalizeFirstLetter = (value: string) => {
  if (value.length === 0) {
    return value;
  }
  return value.charAt(0).toUpperCase() + value.slice(1);
};
