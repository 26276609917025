import { Trans } from 'react-i18next';
import cn from 'classnames';
import { HTMLAudioPlayerProps, IAudioPlayer } from './types';
import styles from './styles.module.scss';

const domain = process.env.REACT_APP_API;

export const AudioPlayer: IAudioPlayer = (props) => {
  const { src, sources, className, ...restProps } = props;

  const innerSrc = src?.startsWith('http') ? src : `${domain}${src}`;

  const innerProps: HTMLAudioPlayerProps = {
    //* overridable:
    controls: true,
    //* outer:
    ...restProps,
    //* unoverridable:
    src: innerSrc,
    className: cn(styles.audioPlayer, className)
  };

  return (
    <audio {...innerProps}>
      {sources && sources.map((sourceProps, i) => <source key={i} {...sourceProps} />)}
      <Trans i18nKey='ui.audio-player.fallback-message'>
        <a href={innerSrc} download />
      </Trans>
    </audio>
  );
};
