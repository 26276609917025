import { createApi } from '@reduxjs/toolkit/query/react';
import { getBaseQuery } from 'common/helpers';
import { IGetBreadcrumbs } from 'types/requests/guest/breadcrumbs.api';

export const breadcrumbsApi = createApi({
  reducerPath: 'breadcrumbsApi',
  baseQuery: getBaseQuery(),
  endpoints: ({ query }) => ({
    getBreadcrumbs: query<IGetBreadcrumbs.Response, IGetBreadcrumbs.Params>({
      query: (body) => ({
        url: '/api/Breadcrums',
        method: 'POST',
        body
      })
    })
  })
});
export const { useLazyGetBreadcrumbsQuery } = breadcrumbsApi;
