import { createApi } from '@reduxjs/toolkit/query/react';
import { getBaseQuery } from 'common/helpers';
import { IAuditJournal, IAuditTables, IJournalByProfile, IJournalUserRoles } from 'types/requests/instructor/audit.api';

export const auditApi = createApi({
  reducerPath: 'auditApi',
  baseQuery: getBaseQuery(),
  endpoints: ({ query }) => ({
    getAuditTables: query<IAuditTables.Response, IAuditTables.Params>({
      query: () => '/api/instructors/history/audit/tables'
    }),
    getAuditJournal: query<IAuditJournal.Response, IAuditJournal.Params>({
      query: (params) => ({
        url: '/api/instructors/history/audit/view',
        params
      })
    }),
    getJournalByUserProfile: query<IJournalByProfile.Response, IJournalByProfile.Params>({
      query: (params) => ({
        url: '/api/instructors/history/profile/view',
        params
      })
    }),
    getJournalUserRoles: query<IJournalUserRoles.Response, IJournalUserRoles.Params>({
      query: () => '/api/instructors/history/audit/user-roles'
    })
  })
});

export const { useGetAuditTablesQuery, useGetAuditJournalQuery, useGetJournalByUserProfileQuery, useGetJournalUserRolesQuery } = auditApi;
