import type { IEditPdfSlide, IPdfSlide, ISavePdfSlide } from 'types/requests/instructor/slides.slide_pdf.api';
import { slidesApi } from '../slides.api';

const slidePdfApi = slidesApi.injectEndpoints({
  endpoints: ({ query, mutation }) => ({
    // API Создание страницы типа PDF.
    savePdfSlide: mutation<ISavePdfSlide.Response, ISavePdfSlide.Params>({
      query: ({ exerciseId, body }) => ({
        url: `/api/instructors/Exercises/${exerciseId}/Pages/PDF`,
        method: 'POST',
        body
      }),
      invalidatesTags: ['Slides', 'Pdf']
    }),
    // API получения страницы типа PDF.
    getPdfSlide: query<IPdfSlide.Response, IPdfSlide.Params>({
      query: ({ exerciseId, pageId }) => `/api/instructors/Exercises/${exerciseId}/Pages/${pageId}/PDF`,
      providesTags: ['Pdf']
    }),
    // API Редактирование страницы типа PDF.
    editPdfSlide: mutation<IEditPdfSlide.Response, IEditPdfSlide.Params>({
      query: ({ exerciseId, pageId, body }) => ({
        url: `/api/instructors/Exercises/${exerciseId}/Pages/${pageId}/PDF`,
        method: 'PUT',
        body
      }),
      invalidatesTags: ['Slides', 'Pdf']
    })
  })
});

export const { useEditPdfSlideMutation, useGetPdfSlideQuery, useSavePdfSlideMutation } = slidePdfApi;
