import { ComponentProps, FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Calendar as AntCalendar } from 'antd';
import dayjs, { extend } from 'dayjs';
import updateLocale from 'dayjs/plugin/updateLocale';
import cn from 'classnames';
import { renderHeader } from './helper';
import { CalendarProps } from './types';
import styles from './styles.module.scss';

extend(updateLocale);

dayjs.updateLocale('en', {
  weekStart: 1, // Monday
  weekdaysMin: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
});

export const Calendar: FC<CalendarProps> = (props) => {
  const { className, ...restProps } = props;
  const { i18n } = useTranslation();

  const innerProps: ComponentProps<typeof AntCalendar> = {
    //* overridable:
    fullscreen: false,
    //* outer:
    ...restProps,
    //* unoverridable:
    headerRender: (props) => renderHeader(props, i18n.language),
    className: cn(styles.calendar, className)
  };

  return <AntCalendar {...innerProps} />;
};
