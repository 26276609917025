import { createApi } from '@reduxjs/toolkit/query/react';
import { getBaseQuery } from 'common/helpers';
import {
  IGetAllChainAttributesTemplates,
  IGetChainAttributes,
  IGetChainAttributesDictionaries,
  IGetChainAttributesTemplates,
  IGetChainAttributesTraining,
  IGetChainAttributesUsages,
  ISaveChainAttributes
} from 'types/requests/instructor/chainAttributes.api';

export const chainAttributesApi = createApi({
  reducerPath: 'chainAttributesApi',
  baseQuery: getBaseQuery(),
  tagTypes: ['chainAttributes'],
  endpoints: ({ query, mutation }) => ({
    getChainAttributesDictionaries: query<IGetChainAttributesDictionaries.TransformedResponse, IGetChainAttributesDictionaries.Params>({
      query: () => `/api/chain-attributes/attributes/dictionaries`,
      transformResponse: (data: IGetChainAttributesDictionaries.Response): IGetChainAttributesDictionaries.TransformedResponse => {
        return data.map(({ id, label, options }) => ({
          value: id,
          label,
          type: options
        }));
      }
    }),
    getChainAttributes: query<IGetChainAttributes.TransformedResponse, IGetChainAttributes.Params>({
      query: () => `/api/chain-attributes/attributes`,
      transformResponse: (data: IGetChainAttributes.Response): IGetChainAttributes.TransformedResponse => {
        return data.map(({ id, label, options }) => ({
          value: id,
          label,
          type: options
        }));
      }
    }),
    getChainAttributesUsages: query<IGetChainAttributesUsages.TransformedResponse, IGetChainAttributesUsages.Params>({
      query: () => `/api/chain-attributes/usages`,
      transformResponse: (data: IGetChainAttributesUsages.Response): IGetChainAttributesUsages.TransformedResponse => {
        return data.map(({ id, label }) => ({
          value: id,
          label
        }));
      }
    }),
    getChainAttributesTraining: query<IGetChainAttributesTraining.Response, IGetChainAttributesTraining.Params>({
      query: ({ courseId }) => `/api/instructors/chain-attributes/trainings/${courseId}`,
      providesTags: ['chainAttributes']
    }),
    getAllChainAttributesTemplates: query<IGetAllChainAttributesTemplates.Response, IGetAllChainAttributesTemplates.Params>({
      query: () => `/api/instructors/chain-attributes/templates/all`
    }),
    getChainAttributesTemplates: query<IGetChainAttributesTemplates.Response, IGetChainAttributesTemplates.Params>({
      query: ({ templateId }) => `/api/instructors/chain-attributes/templates/${templateId}`
    }),
    saveChainAttributes: mutation<ISaveChainAttributes.Response, ISaveChainAttributes.Params>({
      query: (body) => ({
        url: `/api/instructors/chain-attributes/trainings`,
        method: 'POST',
        body
      }),
      invalidatesTags: ['chainAttributes']
    })
  })
});

export const {
  useGetChainAttributesDictionariesQuery,
  useGetChainAttributesQuery,
  useGetChainAttributesUsagesQuery,
  useGetChainAttributesTrainingQuery,
  useGetAllChainAttributesTemplatesQuery,
  useGetChainAttributesTemplatesQuery,
  useSaveChainAttributesMutation
} = chainAttributesApi;
