import { ReactNode } from 'react';

export enum NotificationTypesEnum {
  Unknown = 0,
  ReceivingCertificate = 1,
  IncludeToCourse = 2,
  ApprovalExerciseResults = 3,
  ExcludeFromCourse = 4,
  Grading = 5
}

//* Lms.UseCases.Interfaces.Notifications.GetNotificationsUseCase.GetNotificationsTypes
// Типы возвращаемых уведомлений
export enum NotificationFilterTypesEnum {
  All = 0,
  ApprovalExerciseResults = 1,
  ExcludeAndInclude = 2,
  Сertificate = 3
}

export type NotificationTypeNames = {
  [key in NotificationTypesEnum]: string;
};

export interface WorkId {
  workId: string;
}

export interface ILinkedFields<T extends NotificationTypesEnum> {
  trainingId?: string;
  lessonId?: string;
  exerciseId?: string;
  workId?: string;
  questionId?: T extends NotificationTypesEnum.Grading ? string : never;
}

export interface INotification {
  id: string;
  title: string;
  text: string;
  link: string;
  read: boolean;
  date: Date;
  type: NotificationTypesEnum;
  linkFields: ILinkedFields<NotificationTypesEnum>;
}

export interface IFormattedNotification {
  id: string;
  key: string;
  label: ReactNode;
  disabled: boolean;
}
