import { buttonTypesMap } from '../Button';
import { ILinkAsAlertProps, ILinkAsButtonProps, ILinkProps } from './types';

export const isLinkAsButton = (props: ILinkProps): props is ILinkAsButtonProps => {
  return props.type !== undefined && props.type in buttonTypesMap;
};

export const isLinkAsAlert = (props: ILinkProps): props is ILinkAsAlertProps => {
  return props.type !== undefined && props.type === 'alert';
};
