import { IButtonTypesMap, IGetInitialProps } from './types';

export const buttonTypesMap: IButtonTypesMap = {
  default: {
    type: 'default'
  },
  'stroke-primary-square': {
    type: 'default',
    typeClassName: 'btn-stroke-primary'
  },
  'stroke-primary-circle': {
    type: 'default',
    shape: 'circle',
    typeClassName: 'btn-stroke-primary'
  },
  'stroke-secondary-square': {
    type: 'default',
    typeClassName: 'btn-stroke-secondary'
  },
  'stroke-secondary-circle': {
    type: 'default',
    shape: 'circle',
    typeClassName: 'btn-stroke-secondary'
  },
  'stroke-rect-small': {
    type: 'default',
    size: 'small',
    typeClassName: 'btn-stroke-rect'
  },
  primary: {
    type: 'primary'
  },
  link: {
    type: 'link'
  },
  destructive: {
    type: 'link',
    typeClassName: 'btn-destructive'
  },
  'icon-only': {
    type: 'link',
    typeClassName: 'btn-icon-only'
  },
  text: {
    type: 'text'
  }
};

export const getInitialProps: IGetInitialProps = ({ type = 'default', styles }) => {
  const initialTypeStyleClassNames = [styles.btn];

  const { typeClassName, ...initialTypeStyleProps } = buttonTypesMap[type];
  if (typeClassName) {
    initialTypeStyleClassNames.push(typeClassName);
  }

  return { initialTypeStyleClassNames, initialTypeStyleProps };
};
