import { IROLE } from 'types/roles';

// Если в массиве ролей и привилегий, мы встретим один из этих
// ключей, то предполагаем, что этот ключ и является ролью.
// Остальные ключи будут проигнорированы
const validRoles: Partial<Record<IROLE.UserRole, boolean>> = {
  Students: true,
  Instructors: true,
  Root: true
};

export const createRoleObject = (roles: string[]): IROLE.RoleObject => {
  const apiRole = roles.find((str) => validRoles[str as IROLE.UserRole]) as IROLE.UserRole;

  const role = apiRole || 'Guest';
  const permissions = roles?.reduce((acc, current) => {
    const isPermission = current.startsWith('Role_');
    return isPermission ? { ...acc, [current.slice(5)]: true } : acc;
  }, {} as Record<string, boolean>);

  return {
    role,
    permissions
  };
};
