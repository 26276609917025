import { ComponentProps, FC } from 'react';
import { Image as AntImage } from 'antd';
import cn from 'classnames';
import { getFallback } from './helper';
import { IImageProps } from './types';
import styles from './styles.module.scss';

const domain = process.env.REACT_APP_API;

export const Image: FC<IImageProps> = (props) => {
  const { src, alt, fallback, className, ...restProps } = props;

  const innerProps: ComponentProps<typeof AntImage> = {
    //* overridable:
    preview: false,
    alt,
    fallback: getFallback(fallback),
    //* outer:
    ...restProps,
    //* unoverridable:
    src: src?.startsWith('/') ? `${domain}${src}` : src,
    rootClassName: cn(styles.image, className)
  };

  return <AntImage {...innerProps} />;
};

//! don't use for static images
