import { FC } from 'react';
import { ILinkProps, Link } from 'common/ui/_new';
import { useRedirectPath } from 'common/hooks';
import { InstructorParams } from 'types/routes/instructor';
import styles from './styles.module.scss';

export type ActionsProps = {
  courseId: InstructorParams.Course['courseId'];
  programId?: InstructorParams.Program['programId'];
};

export const Actions: FC<ActionsProps> = ({ courseId, programId }) => {
  const redirectPath = useRedirectPath();

  const actions: ILinkProps[] = [
    { to: redirectPath.coursesStudentManagement({ courseId }), icon: 'control', children: 'Управление студентами' },
    { to: redirectPath.studentsJournal({ courseId }), icon: 'journal', children: 'Журнал учащихся' },
    { to: redirectPath.visitJournal({ courseId }), icon: 'session-log', children: 'Журнал посещений' },
    { to: redirectPath.courseInstructors({ courseId }), icon: 'user-circled', children: 'Преподаватели' }
  ];

  if (programId) {
    actions.push({ to: redirectPath.programView({ programId }), icon: 'open-book', children: 'Программа обучения' });
  }

  return (
    <div className={styles.actions}>
      {actions.map((props, i) => (
        <Link key={i} type='link' className={styles.action} {...props} />
      ))}
    </div>
  );
};
