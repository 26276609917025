import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'common/ui/_new';
import { useAppSelector, useRedirectPath } from 'common/hooks';
import { profileSelectors } from 'api/student/profile/profile.selectors';
import { DateTime } from 'types/common';
import { ErrorResultPage, ErrorResultPageProps } from '../common';
import { formatInvitationDate } from './helper';

export type CourseInvitationInvalidErrorPageProps = ErrorResultPageProps & {
  invitationDate: DateTime;
};

export const CourseInvitationInvalidErrorPage: FC<CourseInvitationInvalidErrorPageProps> = ({ invitationDate, ...restProps }) => {
  const redirectPath = useRedirectPath();
  const { t, i18n } = useTranslation('common', { keyPrefix: 'components.error-page.course-invitation-invalid' });
  const { role } = useAppSelector(profileSelectors.role);

  return (
    <ErrorResultPage
      subTitle={t('sub-title', {
        date: formatInvitationDate(invitationDate, i18n.language)
      })}
      extra={
        <Link
          {...(role === 'Guest'
            ? { to: redirectPath.welcome(), state: { redirectPath: redirectPath.studentsCatalog() } }
            : { to: redirectPath.studentsCatalog() })}
          type='primary'
        >
          {t('link')}
        </Link>
      }
      {...restProps}
    />
  );
};
