import { FC, useState } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import { Calendar, CalendarProps, Divider } from 'common/ui/_new';
import { useAppSelector } from 'common/hooks';
import { useGetCalendarDaysQuery } from 'api/student/calendar/calendar.api';
import { profileSelectors } from 'api/student/profile/profile.selectors';
import { DateCell } from './DateCell';
import { Events } from './Events';

export const EventsCalendar: FC = () => {
  const isLogged = useAppSelector(profileSelectors.isLogged);

  const [chosenDate, setChosenDate] = useState<Dayjs>(dayjs());

  //Требуется передать .format(). Если передавать просто dayjs(), то будет спам секундами в запрос.
  const calendarDaysQuery = useGetCalendarDaysQuery({ currentMonth: chosenDate.format('YYYY-MM') }, { skip: !isLogged });

  const cellRender: CalendarProps<Dayjs>['cellRender'] = (current, info) => {
    if (info.type === 'date') {
      const hasEvents = calendarDaysQuery.data?.some((day) => dayjs(day).format('YYYY-MM-DD') === dayjs(current).format('YYYY-MM-DD'));
      return hasEvents && <DateCell />;
    }

    return info.originNode;
  };

  return (
    <div>
      <Calendar cellRender={cellRender} value={chosenDate} onChange={(date) => setChosenDate?.(date)} />
      <Divider />

      <Events chosenDate={chosenDate} />
    </div>
  );
};
