import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { SettingItemLayout, SettingItemLayoutProps } from '../common';
import { ThemeToggle } from './ThemeToggle';

type ThemeSettingProps = Pick<SettingItemLayoutProps, 'className'>;

export const ThemeSetting: FC<ThemeSettingProps> = ({ className }) => {
  const { t } = useTranslation('common', { keyPrefix: 'components.header.settings-menu' });

  return (
    <SettingItemLayout label={t('theme.label')} className={className}>
      <ThemeToggle />
    </SettingItemLayout>
  );
};
