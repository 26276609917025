import DOMPurify, { Config } from 'dompurify';

const sanitizeConfig: Config = {
  USE_PROFILES: { html: true },
  ADD_TAGS: ['iframe'],
  ADD_ATTR: ['table']
};

const URL_WHITE_LIST: string[] = ['https://www.youtube.com/embed/'];

const isSafeSource = (src: string) => URL_WHITE_LIST.some((url) => src.startsWith(url));

DOMPurify.addHook('uponSanitizeAttribute', function (currentNode) {
  if (currentNode.tagName === 'iframe') {
    const src = currentNode.getAttribute('src') || '';
    if (!isSafeSource(src)) {
      return currentNode.parentNode?.removeChild(currentNode);
    }
  }
  if (currentNode.tagName === 'A') {
    currentNode.setAttribute('target', '_blank');
  }
});

export const sanitize = (source: string | Node) => DOMPurify.sanitize(source, sanitizeConfig);
