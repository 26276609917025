import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { SiderContentLayout } from 'containers/_new';
import { CourseTopicsList } from 'common/components';
import { CourseTopicsListProps } from '../CourseTopicsList/types';

export type CourseTagsSiderProps = {
  items: CourseTopicsListProps['items'];
};

export const CourseTagsSider: FC<CourseTagsSiderProps> = ({ items: courseTags }) => {
  const { t } = useTranslation('common', { keyPrefix: 'components.course-tags' });

  return (
    <SiderContentLayout header={t('title')}>
      <CourseTopicsList items={courseTags} />
    </SiderContentLayout>
  );
};
