import { createContext, Dispatch, FC, HTMLAttributes, ReactNode, SetStateAction, useState } from 'react';
import cn from 'classnames';
import styles from './styles.module.scss';

export const ExpandedPanelContext = createContext<{
  isExpanded: boolean;
  setIsExpanded: Dispatch<SetStateAction<boolean>>;
}>({
  isExpanded: false,
  setIsExpanded: () => null
});

type LayoutProps = {
  collapsedNode?: ReactNode;
} & Pick<HTMLAttributes<HTMLDivElement>, 'className' | 'children'>;

export const Layout: FC<LayoutProps> = ({ collapsedNode, className, children }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <ExpandedPanelContext.Provider value={{ isExpanded, setIsExpanded }}>
      <div className={cn(styles.layout, className)}>
        <div className={styles.main}>{children}</div>
        <div className={cn(styles.collapsable, { [styles.expanded]: isExpanded })}>
          <div className={styles.collapsable__inner}>{collapsedNode}</div>
        </div>
      </div>
    </ExpandedPanelContext.Provider>
  );
};
