import { QuillOptions } from 'quill';

export const modules: QuillOptions['modules'] = {
  toolbar: {
    container: '#toolbar'
  },
  history: {
    delay: 500,
    maxStack: 100,
    userOnly: true
  }
};

export const formats: QuillOptions['formats'] = [
  'size',
  'bold',
  'italic',
  'underline',
  'align',
  'script',
  'blockquote',
  'background',
  'list',
  'bullet',
  'indent',
  'link',
  'image',
  'video',
  'color',
  'code-block'
];

const patterns: [string | RegExp, string][] = [
  [/(<font)(.*)(<\/font>)/g, '<span$2</span>'],
  [/(color=")(.*)(")/g, 'style="color: $2"'],
  [/(?<=<ul>.*)<li>([^<]*)<\/li>(?=.*<\/ul>)/g, '<li data-list="bullet"><span class="ql-ui" contenteditable="false"></span>$1</li>'],
  [/(<li>)(.*?)(<\/li>)/g, '<li data-list="ordered">$2</li>'],
  [/(<ul>)(.*?)(<\/ul>)/g, '<ol>$2</ol>']
];

export const convertToQuillFormat = (value: string) => {
  let formattedValue = value;
  patterns.forEach(([searchValue, replaceValue]) => {
    formattedValue = formattedValue.replaceAll(searchValue, replaceValue);
  });
  return formattedValue;
};

export const fontSizes: string[] = ['8px', '16px', '24px', '36px'];
