import { FC } from 'react';
import cn from 'classnames';
import { useDefaultMediaQueries, useFormatDateTimeRange } from 'common/hooks';
import { TimeSlotProps } from './types';
import styles from './styles.module.scss';

export const TimeSlot: FC<TimeSlotProps> = ({ isAvailable, beginDate, endDate, className }) => {
  const { isMobile } = useDefaultMediaQueries();

  const formattedDateRange = useFormatDateTimeRange(beginDate, endDate, true, isMobile);

  if (beginDate === undefined || beginDate === null) {
    return null;
  }

  return (
    <div className={cn(styles.timeSlot, { [styles.available]: isAvailable, [styles.unavailable]: isAvailable === false }, className)}>
      <span>{formattedDateRange}</span>
    </div>
  );
};
