import { ComponentProps, FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Input as AntInput } from 'antd';
import cn from 'classnames';
import { ISearchProps } from './types';
import styles from './styles.module.scss';

export const Search: FC<ISearchProps> = ({ className, ...restProps }) => {
  const { t } = useTranslation('common', { keyPrefix: 'ui.input-search' });
  const innerProps: ComponentProps<typeof AntInput.Search> = {
    //* overridable:
    placeholder: t('placeholder'),
    //* outer:
    ...restProps,
    //* unoverridable:
    className: cn(styles.search, className)
  };

  return <AntInput.Search {...innerProps} />;
};
