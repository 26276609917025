import { createApi } from '@reduxjs/toolkit/query/react';
import { getBaseQuery } from 'common/helpers';
import type { IAuthorsByCourse, IExcludeAuthors, IIncludeAuthors } from 'types/requests/instructor/authors.api';

export const authorsApi = createApi({
  reducerPath: 'authorsApi',
  baseQuery: getBaseQuery(),
  tagTypes: ['Authors'],
  endpoints: ({ query, mutation }) => ({
    // Список авторов для программы обучения
    getAuthorsByCourse: query<IAuthorsByCourse.Response, IAuthorsByCourse.Params>({
      query: (course) => `/api/instructors/courses/${course}/authors`,
      providesTags: ['Authors']
    }),
    // Включить авторов в курс обучения
    includeAuthors: mutation<IIncludeAuthors.Response, IIncludeAuthors.Params>({
      query: ({ courseId, body }) => ({
        url: `/api/Instructors/Courses/${courseId}/Authors/Include`,
        method: 'POST',
        body
      }),
      invalidatesTags: ['Authors']
    }),
    // Исключить автора из курса обучения
    excludeAuthors: mutation<IExcludeAuthors.Response, IExcludeAuthors.Params>({
      query: ({ courseId, accountId }) => ({
        url: `/api/Instructors/Courses/${courseId}/Authors/${accountId}/Exclude`,
        method: 'POST'
      }),
      invalidatesTags: ['Authors']
    })
  })
});

export const { useGetAuthorsByCourseQuery, useIncludeAuthorsMutation, useExcludeAuthorsMutation } = authorsApi;
