import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { MetaDataList } from 'common/components';
import { getMetaDataItems } from './helper';
import { MetaDataProps } from './types';

export const MetaData: FC<MetaDataProps> = ({ className, ...restProps }) => {
  const { t, i18n } = useTranslation('common', { keyPrefix: 'components.course-description.meta' });

  return <MetaDataList items={getMetaDataItems(restProps, { t, i18n })} className={className} />;
};
