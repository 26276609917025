import { createApi } from '@reduxjs/toolkit/query/react';
import { getBaseQuery } from 'common/helpers';
import { ICreateCredential, IDeleteCredentials, IGetCredentialFile, IGetCredentials } from 'types/requests/instructor/credential.api';

export const credentialApi = createApi({
  reducerPath: 'credentialApi',
  baseQuery: getBaseQuery(),
  tagTypes: ['credentials', 'credential'],
  endpoints: ({ query, mutation }) => ({
    getCredentials: mutation<IGetCredentials.Response, IGetCredentials.Params>({
      query: ({ courseId, body }) => ({
        url: `/api/Credential/${courseId}`,
        method: 'POST',
        body
      }),
      invalidatesTags: ['credentials']
    }),
    deleteCredential: mutation<IDeleteCredentials.Response, IDeleteCredentials.Params>({
      query: ({ certificateId }) => ({
        url: `/api/Credential/${certificateId}`,
        method: 'DELETE'
      }),
      invalidatesTags: ['credentials']
    }),
    createCredential: mutation<ICreateCredential.Response, ICreateCredential.Params>({
      query: ({ courseId, studentId, title, date, file }) => {
        const bodyFormData = new FormData();
        bodyFormData.append(`file`, file);
        return {
          url: `/api/Credential`,
          method: 'POST',
          params: {
            trainingId: courseId,
            studentId,
            title,
            date
          },
          body: bodyFormData
        };
      }
    }),
    getCredentialFile: query<IGetCredentialFile.Response, IGetCredentialFile.Params>({
      query: ({ courseId, certificateId }) => ({
        url: `/api/Credential/View/${certificateId}`,
        params: { trainingId: courseId }
      })
    })
  })
});

export const { useGetCredentialsMutation, useDeleteCredentialMutation, useCreateCredentialMutation, useGetCredentialFileQuery } =
  credentialApi;
