import { FC } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { QueryResponseBoundary } from 'containers/_new';
import { AudioPlayer, HtmlContent } from 'common/ui/_new';
import { useGetAudioSlideQuery } from 'api/instructor/slides/endpoints/slide_audio.endpoint';
import { InstructorParams } from 'types/routes/instructor';

export const AudioPreviewModal: FC = () => {
  const { exerciseId = '' } = useParams<InstructorParams.Exercise>();
  const [searchParams] = useSearchParams();

  const audioQuery = useGetAudioSlideQuery({ exerciseId, pageId: searchParams.get('slideId') || '' });

  return (
    <QueryResponseBoundary query={audioQuery}>
      <HtmlContent content={audioQuery.data?.value} />
      <AudioPlayer src={audioQuery.data?.address} />
    </QueryResponseBoundary>
  );
};
