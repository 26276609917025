import { ComponentProps } from 'react';
import { useTranslation } from 'react-i18next';
import { Form as AntForm } from 'antd';
import cn from 'classnames';
import { getAntFormItemRules, getTooltip } from './helper';
import { IInternalFormItem } from './types';
import styles from './styles.module.scss';

const InternalFormItem: IInternalFormItem = (props) => {
  const { t } = useTranslation('common', { keyPrefix: 'ui.form-item' });
  const { rules, tooltip, children, className, ...restProps } = props;

  const innerProps: ComponentProps<typeof AntForm.Item> = {
    //* overridable:
    //* outer:
    ...restProps,
    //* unoverridable:
    rules: getAntFormItemRules(rules, t),
    tooltip: getTooltip(tooltip),
    className: cn(styles['form-item'], className)
  };

  return <AntForm.Item {...innerProps}>{children}</AntForm.Item>;
};

export const Item = Object.assign(InternalFormItem, {
  useStatus: AntForm.Item.useStatus
});

/* 
todo: добавить атрибут для выбора preset (например, submit button)
 */
