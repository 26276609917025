import { createApi } from '@reduxjs/toolkit/query/react';
import { getBaseQuery } from 'common/helpers';
import {
  IRemoveTasksQueues,
  IStopTask,
  ITaskInfoQuery,
  ITaskQueueList,
  ITaskStatuses,
  ITaskTypes
} from 'types/requests/instructor/taskQueue.api';

/**
 * Кастомный сериализатор параметров
 * Сделан для того, чтобы преобразовывать параметр a=[1,2,3] не в такой вид a=1,2,3, а в такой a=1&a=2&a=3.
 * В остальном сериализатор работает так же, как дефолтный
 * @param params
 * @returns
 */
type IParamSerializerProps = Record<string, string | number | string[]>;

const customParamSerializer = (params?: IParamSerializerProps) => {
  if (!params) return '';

  const newParams = new URLSearchParams();
  for (const [key, value] of Object.entries(params)) {
    if (Array.isArray(value)) {
      value.forEach((v) => {
        newParams.append(key, String(v));
      });
    } else {
      newParams.append(key, String(value));
    }
  }

  return newParams.toString();
};

export const instructorTaskQueueApi = createApi({
  reducerPath: 'instructorTaskQueueApi',
  baseQuery: getBaseQuery({ paramsSerializer: customParamSerializer }),
  tagTypes: ['Tasks', 'TaskInfo'],
  endpoints: ({ query, mutation }) => ({
    // Получить список задач
    getTasks: query<ITaskQueueList.Response, ITaskQueueList.Params>({
      query: (params) => ({
        url: `/api/instructors/queue/tasks`,
        params
      }),
      providesTags: ['Tasks']
    }),
    // Отправить задачу для удаления очередей
    removeTasksQueues: mutation<IRemoveTasksQueues.Response, IRemoveTasksQueues.Params>({
      query: (params) => ({
        url: `/api/instructors/queue/tasks`,
        method: 'DELETE',
        params
      }),
      invalidatesTags: ['Tasks']
    }),
    // Информация о конкретной задаче
    getTaskInfo: query<ITaskInfoQuery.Response, ITaskInfoQuery.Params>({
      query: ({ taskId }) => ({
        url: `/api/instructors/queue/task/${taskId}`
      }),
      providesTags: ['TaskInfo']
    }),
    // Отправить запрос на остановку задачи
    stopTask: mutation<IStopTask.Response, IStopTask.Params>({
      query: ({ taskId }) => ({
        url: `/api/instructors/queue/task/${taskId}`,
        method: 'DELETE'
      }),
      invalidatesTags: ['TaskInfo', 'Tasks']
    }),
    // Получение типов задач очереди
    getTaskTypes: query<ITaskTypes.TransformedResponse, ITaskTypes.Params>({
      query: () => ({
        url: `/api/instructors/queue/task/types`
      }),
      transformResponse: (data: ITaskTypes.Response) => {
        return data.map(({ id, label }) => ({
          value: id,
          label
        }));
      }
    }),
    // Получение статусов задач очереди
    getTaskStatuses: query<ITaskStatuses.TransformedResponse, ITaskStatuses.Params>({
      query: () => ({
        url: `/api/instructors/queue/task/statuses`
      }),
      transformResponse: (data: ITaskStatuses.Response) => {
        return data.map(({ id, label }) => ({
          value: id,
          label
        }));
      }
    })
  })
});

export const {
  useGetTaskInfoQuery,
  useGetTaskStatusesQuery,
  useGetTaskTypesQuery,
  useGetTasksQuery,
  useRemoveTasksQueuesMutation,
  useStopTaskMutation
} = instructorTaskQueueApi;
