import { FC } from 'react';
import cn from 'classnames';
import { Descriptions, IDescriptionsProps } from 'common/ui/_new';
import { InlineDescriptionsProps } from './types';
import styles from './styles.module.scss';

export const InlineDescriptions: FC<InlineDescriptionsProps> = ({ className, ...restProps }) => {
  const innerProps: IDescriptionsProps = {
    //* overridable
    //* outer
    ...restProps,
    //* unoverridable
    className: cn(styles.inlineDescriptions, className)
  };

  return <Descriptions {...innerProps} />;
};
