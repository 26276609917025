import { Children, Fragment } from 'react';
import { Flex } from 'antd';
import { IContents } from './types';

export const Contents: IContents = (props) => {
  const { children } = props;

  return (
    <main>
      <Flex vertical gap={32}>
        {Children.map(children, (child, i) => {
          if (child) {
            return <Fragment key={i}>{child}</Fragment>;
          }
        })}
      </Flex>
    </main>
  );
};

/* 
todo: заменить Children API
*/
