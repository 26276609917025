import { FC } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { QueryResponseBoundary } from 'containers/_new';
import { VideoPlayer } from 'common/ui/_new';
import { useGetVideoSlideQuery } from 'api/instructor/slides/endpoints/slide_video.endpoint';
import { InstructorParams } from 'types/routes/instructor';

const domain = process.env.REACT_APP_API;

export const VideoPreviewModal: FC = () => {
  const { exerciseId = '' } = useParams<InstructorParams.Exercise>();
  const [searchParams] = useSearchParams();

  const videoQuery = useGetVideoSlideQuery({ exerciseId, pageId: searchParams.get('slideId') || '' });

  const videoUrl = videoQuery.data?.address.startsWith('http') ? videoQuery.data?.address : `${domain}${videoQuery.data?.address}`;

  return (
    <QueryResponseBoundary query={videoQuery}>
      <VideoPlayer
        autoPlay={videoQuery.data?.play}
        sources={[
          {
            src: videoUrl,
            type: 'video/mp4'
          }
        ]}
      />
    </QueryResponseBoundary>
  );
};
