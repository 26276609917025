import { authTypeApi } from './authType/auth-type.api';
import { breadcrumbsApi } from './breadcrumbs/breadcrumbs.api';
import { catalogApi } from './catalog/catalog.api';
import { guestCoursesApi } from './courses/courses.api';
import { invitationsApi } from './invitations/invitations.api';

export const guestApiReducers = {
  [authTypeApi.reducerPath]: authTypeApi.reducer,
  [breadcrumbsApi.reducerPath]: breadcrumbsApi.reducer,
  [guestCoursesApi.reducerPath]: guestCoursesApi.reducer,
  [catalogApi.reducerPath]: catalogApi.reducer,
  [invitationsApi.reducerPath]: invitationsApi.reducer
};

export const guestApiMiddlewares = [
  authTypeApi.middleware,
  breadcrumbsApi.middleware,
  catalogApi.middleware,
  guestCoursesApi.middleware,
  invitationsApi.middleware
];
