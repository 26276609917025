import { useTranslation } from 'react-i18next';
import { Col, Row } from 'antd';
import { Button, Input } from 'common/ui/_new';

export const FreePreviewModal = () => {
  const { t } = useTranslation('common', { keyPrefix: 'components.slides.free.preview-modal' });

  return (
    <Row gutter={[32, 32]}>
      <Col span={24}>
        <Input disabled placeholder={t('input.placeholder')} />
      </Col>
      <Col span={24}>
        <Button type='primary' disabled>
          {t('button.label')}
        </Button>
      </Col>
    </Row>
  );
};
