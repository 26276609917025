import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ExpressModal, Form, IInternalFormProps, IModalProps, Input, Text } from 'common/ui/_new';
import { useAddAccountMutation } from 'api/student/accountRelation/accountRelation.api';
import styles from './styles.module.scss';

type IAccountModalProps = IModalProps & {
  onClose: () => void;
};

export const AddAccountModal: FC<IAccountModalProps> = ({ onClose, ...modalProps }) => {
  const { t } = useTranslation('Students', { keyPrefix: 'pages.account-relation.add-account-modal' });
  const [form] = Form.useForm();
  const [addAccountMutation, addAccount] = useAddAccountMutation();
  const [isErrorStatus404, setIsErrorStatus404] = useState(false);

  const onFinish: IInternalFormProps['onFinish'] = (body) => {
    addAccountMutation({ body })
      .unwrap()
      .then(onClose)
      .catch((error) => {
        switch (error.status) {
          case 404:
            setIsErrorStatus404(true);
            break;
          case 400:
            form.setFields([
              {
                name: 'email',
                errors: [`${t('on-finish-400')}`]
              }
            ]);
            break;
        }
      });
  };

  const formItemStatus = isErrorStatus404 ? 'error' : '';

  return (
    <ExpressModal
      {...modalProps}
      title={t('modal.title')}
      subtitle={t('modal.subtitle')}
      onOk={() => form.submit()}
      okButtonProps={{ loading: addAccount.isLoading }}
    >
      <Form form={form} onFinish={onFinish} disabled={addAccount.isLoading}>
        <Form.Item name='email' label={t('form.email.label')} rules={[{ required: true, message: t('form.email.rules') }]}>
          <Input status={formItemStatus} placeholder={t('form.email.placeholder')} />
        </Form.Item>
        <Form.Item name='password' label={t('form.password.label')} rules={[{ required: true, message: t('form.password.rules') }]}>
          <Input.Password status={formItemStatus} />
        </Form.Item>
        {isErrorStatus404 && (
          <div className={styles.alert}>
            <Text type='danger'>{t('on-finish-404')}</Text>
          </div>
        )}
      </Form>
    </ExpressModal>
  );
};
