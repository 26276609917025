import { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ISelectProps, Select } from 'common/ui/_new';
import { useDebouncedState } from 'common/hooks';
import { useGetCompanySuggestionsQuery } from 'api/student/dadata/dadata.api';
import { Label } from './Label';
import { CompanySelectOption, CompanySelectProps, CompanySelectValue } from './types';

export const CompanySelect: FC<CompanySelectProps> = ({ value, onChange, ...restProps }) => {
  const { t } = useTranslation('common', { keyPrefix: 'components.company-select' });
  const [innerValue, setInnerValue] = useState<CompanySelectValue>(value?.inn);

  const [skip, setSkip] = useState<boolean>(true);
  const [queryValue, setQueryValue] = useDebouncedState();
  const companySuggestionsQuery = useGetCompanySuggestionsQuery(queryValue, { skip });

  const options: ISelectProps<CompanySelectValue, CompanySelectOption>['options'] = useMemo(() => {
    return companySuggestionsQuery.data?.suggestions?.map((suggestion) => {
      const {
        value: companyName,
        data: {
          address: { value: addressName },
          kpp,
          inn
        }
      } = suggestion;

      return {
        label: (
          <Label>
            <div>{companyName}</div>
            <div>{addressName}</div>
            <div>{t('label.tin', { value: inn })}</div>
            <div>{t('label.rrc', { value: kpp })}</div>
          </Label>
        ),
        value: inn,
        addressName,
        companyName,
        kpp
      };
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companySuggestionsQuery.data?.suggestions]);

  const onSearch: ISelectProps<CompanySelectValue, CompanySelectOption>['onSearch'] = (searchValue) => {
    setQueryValue(searchValue);

    if (searchValue !== '') {
      // @ts-ignore
      onChange?.(undefined);
      setSkip(true);
    }
  };

  const onSelect: ISelectProps<CompanySelectValue, CompanySelectOption>['onSelect'] = (_, option) => {
    const newSelectedOption = { inn: option.value, ...option };
    onChange?.(newSelectedOption);
    setInnerValue(option.value);
  };

  useEffect(() => {
    setSkip(false);
  }, [queryValue]);

  return (
    <Select
      {...restProps}
      options={options}
      value={innerValue}
      onChange={(value) => setInnerValue(value)}
      showSearch
      onSearch={onSearch}
      onSelect={onSelect}
      filterOption={false}
      loading={companySuggestionsQuery.isFetching || companySuggestionsQuery.isLoading}
      placeholder={t('placeholder')}
      {...(!queryValue && { notFoundContent: null })}
      allowClear
    />
  );
};
