import { useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'antd';
import { QueryResponseBoundary } from 'containers/_new';
import { Button, HtmlContent } from 'common/ui/_new';
import { useGetEditableAnswersQuery } from 'api/instructor/slides/endpoints/slide_editable-answers.enpoint';

export const EditableAnswersPreviewModal = () => {
  const [searchParams] = useSearchParams();
  const answersQuery = useGetEditableAnswersQuery(searchParams.get('slideId') || '');

  const { t } = useTranslation('common', { keyPrefix: 'components.slides.editable-answers.preview-modal' });

  return (
    <Row gutter={[32, 32]}>
      <Col span={24}>
        <QueryResponseBoundary query={answersQuery}>
          <HtmlContent content={answersQuery.data?.text} />
        </QueryResponseBoundary>
      </Col>
      <Col span={24}>
        <Button type='primary' disabled>
          {t('next-button.label')}
        </Button>
      </Col>
    </Row>
  );
};
