import { FC } from 'react';
import { RadarChart } from 'common/ui';
import { Table } from 'common/ui/_new';
import { useDefaultMediaQueries } from 'common/hooks';
import { IItemChart } from 'types/entities';
import { getColumns, roundCompetenciesValues } from './helpers';
import styles from './styles.module.scss';

export type CompetenciesOverviewProps = {
  competencies: IItemChart[];
};

export const CompetenciesOverview: FC<CompetenciesOverviewProps> = ({ competencies }) => {
  const { isMobile } = useDefaultMediaQueries();
  const formattedCompetencies = roundCompetenciesValues(competencies);
  return (
    <>
      <RadarChart data={formattedCompetencies || []} />
      {isMobile && <Table dataSource={formattedCompetencies} columns={getColumns()} className={styles.table} />}
    </>
  );
};
