import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import style from './styles.module.scss';

type EllipsisSymbolProps = {
  expanded?: boolean;
};

export const EllipsisSymbol: FC<EllipsisSymbolProps> = ({ expanded }) => {
  const { t } = useTranslation('common', { keyPrefix: 'ui.ellipsis-symbol' });
  return <a className={style.linkRouterLink}>{expanded ? t('less') : t('more')}</a>;
};
