import { ComponentProps, FC } from 'react';
import { Avatar as AntAvatar } from 'antd';
import cn from 'classnames';
import { AvatarIcon, isAvatarIconType } from './components';
import { AvatarProps } from './types';
import styles from './styles.module.scss';

export const Avatar: FC<AvatarProps> = ({ src, size = 'large', icon = 'avatar', className, ...restProps }) => {
  const innerProps: ComponentProps<typeof AntAvatar> = {
    //* overridable
    size,
    icon: isAvatarIconType(icon) ? <AvatarIcon type={icon} /> : icon,
    //* outer
    ...restProps,
    //* unoverridable
    src: typeof src === 'string' && src?.startsWith('/') ? `${process.env.REACT_APP_API}${src}` : src,
    className: cn(styles.avatar, className)
  };

  return <AntAvatar {...innerProps} />;
};
