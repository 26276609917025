import { useEffect, useState } from 'react';
import { Col, Row } from 'antd';
import { ISelectProps, Select } from 'common/ui/_new';
import { useLazyGetHierarchyQuery, useLazyGetHierarchyValuesQuery } from 'api/student/hierarchies/hierarchies.api';
import { getSelectedNodes } from './helper';
import { GetNextSelectProps, HandleChange, HandleSelect, HierarchySelectOption, HierarchySelectType, HierarchySelectValue } from './types';

export const HierarchySelect: HierarchySelectType = (props) => {
  const { onChange, attributeReferenceTypeId, lastLevelNodeId, ...restProps } = props;

  const [selectPropsList, setSelectPropsList] = useState<ISelectProps<HierarchySelectValue, HierarchySelectOption>[]>([]);

  const [getHierarchy] = useLazyGetHierarchyQuery();
  const [getHierarchyValues] = useLazyGetHierarchyValuesQuery();

  const handleSelect: HandleSelect = async (index, value, option) => {
    const newSelectPropsList: ISelectProps<HierarchySelectValue, HierarchySelectOption>[] = [];
    if (option.last) {
      onChange?.(value, option);
    } else {
      onChange?.(undefined, { label: undefined });
      const newSelectProps = await getNewSelectProps(index + 1, value);
      newSelectPropsList.push(newSelectProps);
    }

    setSelectPropsList((prevSelectPropsList) => {
      const currentSelectProps = { ...prevSelectPropsList[index], value };
      return [...prevSelectPropsList.slice(0, index), currentSelectProps, ...newSelectPropsList];
    });
  };

  const handleChange: HandleChange = (index, value) => {
    if (value === undefined) {
      onChange?.(undefined, { label: undefined });
    }

    setSelectPropsList((prevSelectPropsList) => {
      const currentSelectProps = { ...prevSelectPropsList[index], value };
      return [...prevSelectPropsList.slice(0, index), currentSelectProps];
    });
  };

  const getNewSelectProps: GetNextSelectProps = async (index, nodeId) => {
    const options = await getHierarchy({ id: attributeReferenceTypeId, node: nodeId }, true)
      .unwrap()
      .then((optionsData) =>
        optionsData.map(({ id, label, last }) => ({
          label,
          value: id,
          last
        }))
      )
      .catch((error) => {
        throw new Error(error);
      });

    return {
      value: undefined,
      options,
      onSelect: (value, option) => handleSelect(index, value, option),
      onChange: (value) => handleChange(index, value),
      allowClear: true
    };
  };

  useEffect(() => {
    if (lastLevelNodeId) {
      getHierarchyValues({ id: attributeReferenceTypeId, value: lastLevelNodeId as string }) // todo: исправить тип AttributePrevValue, чтобы убрать as
        .unwrap()
        .then((nodes) => {
          const nodesList = nodes ? getSelectedNodes(nodes) : [];
          setSelectPropsList(nodesList);
        });
    } else {
      (async () => {
        const newSelectProps = await getNewSelectProps(0);
        setSelectPropsList([newSelectProps]);
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [attributeReferenceTypeId]);

  return (
    <Row gutter={[24, 24]}>
      {selectPropsList.map((selectProps, i) => (
        <Col key={i} span={24}>
          <Select {...restProps} {...selectProps} />
        </Col>
      ))}
    </Row>
  );
};

/* 
todo: apply UI kit styles to label tooltip
todo: fix redundant rerender if parent rerenders
todo: сделать отдельные компоненты для режимов ввода и чтения данных
todo: добавить анимацию загрузки
todo: добавить уникальный id для каждого Select
*/
