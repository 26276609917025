import { ComponentProps, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Pagination as AntPagination } from 'antd';
import cn from 'classnames';
import { InputNumber } from 'common/ui/_new';
import { useDebouncedState } from 'common/hooks';
import { getItemRender } from './helper';
import { IPagination } from './types';
import styles from './styles.module.scss';

export const Pagination: IPagination = (props) => {
  const { t } = useTranslation('common', { keyPrefix: 'ui.pagination' });
  const { type = 'inline', total = 0, current = 1, onShowSizeChange, className, ...restProps } = props;
  const [innerPageSize, setInnerPageSize] = useDebouncedState('10');

  const handleOnShowSizeChange: ComponentProps<typeof InputNumber>['onChange'] = (newPageSize) => {
    if (typeof newPageSize === 'number') {
      setInnerPageSize(String(newPageSize));
    }
  };

  useEffect(() => {
    const lastPage = Math.ceil(total / Number(innerPageSize));
    const newCurrent = lastPage < current ? lastPage : current;
    onShowSizeChange?.(newCurrent, Number(innerPageSize));
  }, [current, innerPageSize, onShowSizeChange, total]);

  const innerProps: ComponentProps<typeof AntPagination> = {
    //* overridable:
    hideOnSinglePage: true,
    showSizeChanger: false,
    locale: {
      items_per_page: ''
    },
    //* outer:
    ...restProps,
    //* unoverridable:
    total,
    current,
    itemRender: getItemRender,
    className: styles.pagination
  };

  return (
    <div className={cn(styles.paginationContainer, { [styles.paginationContainer_horizontal]: type === 'horizontal' }, className)}>
      <span className={styles.totalIndicator}>
        <span className={styles.label}>{t('total-records')}:</span> <span className={styles.value}>{total}</span>
      </span>
      {total > 10 && (
        <span className={styles.sizeChanger}>
          <span className={styles.label}>{t('entries-per-page')}:</span>
          <span>
            <InputNumber
              value={Number(innerPageSize) || props.pageSize || props.defaultPageSize}
              onChange={handleOnShowSizeChange}
              min={10}
              max={100}
              maxLength={3}
              disabled={props.disabled}
              placeholder=''
              className={styles.input}
            />
          </span>
        </span>
      )}
      <AntPagination {...innerProps} />
    </div>
  );
};

/* 
todo: убрать Number, сделать useDebounceValue для типа number
*/
