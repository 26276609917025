import { FC, useEffect, useMemo, useState } from 'react';
import { Col, Row } from 'antd';
import { Form, Select } from 'common/ui/_new';
import { useGetRefinedAttributesQuery } from 'api/student/refine/refine.api';
import { AttributeTypeEnum } from 'types/entities';
import { AttributeOption, AttributeSelectProps } from './types';
import { AttributeValueInputComponent } from './ValueInput';

export const AttributeSelect: FC<AttributeSelectProps> = ({ attributeFieldProps, attributeValueFieldProps, clearFieldValue }) => {
  const getRefinedAttributesQuery = useGetRefinedAttributesQuery();
  const attributesOptions = useMemo(
    () =>
      getRefinedAttributesQuery.data?.map(({ id, options, ...restProps }) => ({
        value: id,
        details: options,
        ...restProps
      })),
    [getRefinedAttributesQuery.data]
  );

  const [attribute, setAttribute] = useState<AttributeOption | undefined>();
  const onAttributeSelect = (attribute: AttributeOption) => {
    setAttribute(attribute);
    clearFieldValue(attributeValueFieldProps?.name);
  };

  useEffect(() => {
    if (attributesOptions && attributeFieldProps?.initialValue !== undefined) {
      const initialAttribute = attributesOptions.find(({ value }) => value === attributeFieldProps.initialValue);
      setAttribute(initialAttribute);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [attributesOptions]);

  return (
    <Row gutter={8}>
      <Col md={attribute ? 12 : 24} xs={24} {...attributeFieldProps?.colProps}>
        <Form.Item
          name={attributeFieldProps?.name}
          label={attributeFieldProps?.label ?? 'Атрибут платформы'}
          rules={attributeFieldProps?.rules}
        >
          <Select
            options={attributesOptions}
            onSelect={(_, option) => onAttributeSelect(option)}
            loading={getRefinedAttributesQuery.isFetching || getRefinedAttributesQuery.isLoading}
          />
        </Form.Item>
      </Col>
      {attribute && (
        <Col md={12} xs={24} {...attributeValueFieldProps?.colProps}>
          {/* //! non-user-friendly message if validation fails */}
          <Form.Item
            name={attributeValueFieldProps?.name}
            label={attributeValueFieldProps?.label ?? 'Значение атрибута'}
            rules={[...(attribute.regex ? [{ pattern: new RegExp(attribute.regex) }] : []), ...(attributeValueFieldProps?.rules ?? [])]}
            {...(attribute.type === AttributeTypeEnum.Bool && { initialValue: false })}
          >
            <AttributeValueInputComponent type={attribute.type} details={attribute.details} />
          </Form.Item>
        </Col>
      )}
    </Row>
  );
};
