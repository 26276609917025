import { FC, memo } from 'react';
import { ErrorResultPage } from './common/Result';
import { LessonCourseUnavailableErrorPage } from './LessonCourseUnavailable/ErrorPage';
import { CourseOrExerciseNotFoundErrorPage } from './CourseOrExerciseNotFound';
import { CourseUnavailableErrorPage } from './CourseUnavailable';
import { CourseInvitationInvalidErrorPage } from './InvitationInvalid';
import { CourseInvitationUnavailableErrorPage } from './InvitationUnavailable';
import { SessionNotCompletedErrorPage } from './SessionNotCompleted';
import { StudentAlreadyInCourseErrorPage } from './StudentAlreadyInCourse';
import { ErrorPageProps, isCourseInvitationInvalidErrorProps, isSessionNotCompletedErrorPageProps, PageErrorKey } from './types';
import { UnfilledRequiredProfileFieldsErrorPage } from './UnfilledRequiredProfileFields';

export const ErrorPage: FC<ErrorPageProps> = memo(function ErrorPage({ pageErrorKey, ...props }) {
  //! ErrorResultPageProps with extra props
  if (isCourseInvitationInvalidErrorProps(pageErrorKey, props)) {
    return <CourseInvitationInvalidErrorPage {...props} />;
  }
  if (isSessionNotCompletedErrorPageProps(pageErrorKey, props)) {
    return <SessionNotCompletedErrorPage {...props} />;
  }

  //! ErrorResultPageProps only
  switch (pageErrorKey) {
    case PageErrorKey.NO_ACCESS:
      return <ErrorResultPage status='no-access' {...props} />;
    case PageErrorKey.COURSE_INVITATION_UNAVAILABLE:
      return <CourseInvitationUnavailableErrorPage {...props} />;
    case PageErrorKey.COURSE_UNAVAILABLE:
      return <CourseUnavailableErrorPage {...props} />;
    case PageErrorKey.COURSE_OR_EXERCISE_NOT_FOUND:
      return <CourseOrExerciseNotFoundErrorPage {...props} />;
    case PageErrorKey.LESSON_OR_COURSE_UNAVAILABLE:
      return <LessonCourseUnavailableErrorPage {...props} />;
    case PageErrorKey.STUDENT_ALREADY_IN_COURSE:
      return <StudentAlreadyInCourseErrorPage {...props} />;
    case PageErrorKey.UNFILLED_REQUIRED_PROFILE_FIELDS:
      return <UnfilledRequiredProfileFieldsErrorPage />;
    default:
      return <ErrorResultPage {...props} />;
  }
});
