import { Id } from 'types/common';

export enum ActivityType {
  Unknown = 0,
  OnModuleFinal = 1,
  FinalCertification = 2
}

export enum ActivityActor {
  Unknown = 0,
  Individual = 1,
  Group = 2
}

//* Robocode.Models.Instructors.Pages.Edit.File
// todo: upd
export interface IFileSlide {
  id: Id;
  duration: number;
  evaluate: boolean;
  label: string;
  text: string;
  activityType: ActivityType;
  activityActor: ActivityActor;
}
