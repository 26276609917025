import { i18n } from 'i18n/config';

export const getGuestLabels = () => ({
  welcome: '',
  root: '',
  invite: i18n.t('Guest:common.types.routes.invite'),
  coursePreview: i18n.t('Guest:common.types.routes.course-preview'),
  notFound: i18n.t('Guest:common.types.routes.not-found'),
  authLoader: i18n.t('Guest:common.types.routes.auth-loader')
});
