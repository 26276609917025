import { FC } from 'react';
import { useHref, useLinkClickHandler } from 'react-router-dom';
import { Button, IButtonProps } from '../../../Button';
import { ILinkButtonProps } from './types';

export const LinkButton: FC<ILinkButtonProps> = (props) => {
  const { to, replace = false, state, target, onClick, ...restProps } = props;

  const href = useHref(to);
  const handleClick = useLinkClickHandler(to, {
    replace,
    state,
    target
  });

  const innerProps: IButtonProps = {
    //* overridable:
    //* outer:
    ...restProps,
    //* unoverridable:
    ...(String(to).startsWith('http')
      ? {
          href: String(to)
        }
      : {
          href,
          onClick: (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
            onClick?.(e);
            if (!e.defaultPrevented) {
              handleClick(e);
            }
          },
          target
        })
  };

  return <Button {...innerProps} />;
};
