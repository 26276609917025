import { FC } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Upload } from 'antd';
import { QueryResponseBoundary } from 'containers/_new';
import { Button, HtmlContent } from 'common/ui/_new';
import { useGetFileSlideQuery } from 'api/instructor/slides/endpoints/slide_file.endpoint';
import { InstructorParams } from 'types/routes/instructor';

export const FilePreviewModal: FC = () => {
  const { exerciseId = '' } = useParams<InstructorParams.Exercise>();
  const [searchParams] = useSearchParams();

  const fileQuery = useGetFileSlideQuery({ exerciseId, pageId: searchParams.get('slideId') || '' });

  const { t } = useTranslation('common', { keyPrefix: 'components.slides.file.preview-modal' });

  return (
    <>
      <QueryResponseBoundary query={fileQuery}>
        <HtmlContent content={fileQuery.data?.text} />
      </QueryResponseBoundary>
      <Upload>
        <Button disabled>{t('upload-button.label')}</Button>
      </Upload>
    </>
  );
};
