import { authErrorSlice } from './authError/auth-error.slice';
import { authLinkSlice } from './authLink/auth-link.slice';
import { authTypeSlice } from './authType/auth-type.slice';
import { oneIdSlice } from './oneId/one-id.slice';

export const guestSlices = {
  [authTypeSlice.name]: authTypeSlice.reducer,
  [authLinkSlice.name]: authLinkSlice.reducer,
  [authErrorSlice.name]: authErrorSlice.reducer,
  [oneIdSlice.name]: oneIdSlice.reducer
};
