import { DateTime, Id } from 'types/common';
import { CourseStatusEnum, FileLink } from 'types/entities';

export type ExerciseId = Id;

//* Lms.Entities.Entities.Course.Lesson.Work.Exercise.ExerciseAvailability
// Типы доступности упражнений
export enum AvailabilityintegerEnum {
  ANY_AGE = 0,
  AVAILABLE_AFTER_ALL_PREVIOUS_EXERCISES = 1,
  AVAILABLE_IMMEDIATELY_IN_WORK = 2,
  AVAILABLE_IMMEDIATELY_IN_LESSON = 3,
  AVAILABLE_AFTER_PREVIOUS_EXERCISE = 4,
  AVAILABLE_ALWAYS = 99
}

//* Lms.Entities.Entities.Course.Lesson.Work.Exercise.ExerciseFinishType
// Тип страницы завершения упражнения
export enum ExerciseFinishType {
  COMPLETED = 10,
  COMPLETED_RESULT = 20,
  COMPLETED_TO_EVALUATE = 30
}

//* Lms.UseCases.Interfaces.Trainings.Students.GetLessonExercisesQueries.GetExerciseInfo
// Модель упражнения
export type CourseLessonExerciseInfo = {
  id: ExerciseId;
  label?: string;
  description?: string | null;
  availabilityType: AvailabilityintegerEnum;
  isAvailable: boolean;
  location?: string | null;
  feedback: boolean;
  notes?: string | null;
  files?: FileLink[] | null;
  status: CourseStatusEnum;
  value?: string | null;
  isRetakeAvailable: boolean;
  dateBegin?: DateTime | null;
  dateEnd?: DateTime | null;
  isAvailableBySchedule?: boolean;
};

//* Lms.UseCases.Interfaces.Common.Invite.InviteTrainingExerciseDto
// Упражнение для страницы приглашения в обучение
export type CoursePreviewExercise = {
  id: ExerciseId;
  label?: string;
  description?: string;
};
