import { FC } from 'react';
import { Flex } from 'antd';
import { Icon, ILinkProps } from 'common/ui/_new';
import { useAppSelector } from 'common/hooks';
import { profileSelectors } from 'api/student/profile/profile.selectors';
import { NavigationItem } from '../NavigationItem';
import styleVar from 'common/styles/vars.scss';

type ProfileNavItemProps = {
  label: string;
  to: ILinkProps['to'];
};

export const ProfileNavItem: FC<ProfileNavItemProps> = ({ label, to }) => {
  const unfilled = useAppSelector(profileSelectors.unfilled);
  return (
    <Flex justify='space-between'>
      <NavigationItem label={label} to={to} />
      {unfilled && <Icon type='warning' style={{ color: styleVar.colorYellow100 }} />}
    </Flex>
  );
};
