import { FC } from 'react';
import { EllipsisSymbol } from 'common/ui/_new';

import ShowMoreText, { ReactShowMoreTextProps } from 'react-show-more-text';

export type EllipsisProps = ReactShowMoreTextProps;

export const Ellipsis: FC<EllipsisProps> = ({ children, ...restProps }) => {
  const innerProps: ReactShowMoreTextProps = {
    //* overridable:
    lines: 3,
    more: <EllipsisSymbol />,
    less: <EllipsisSymbol expanded />,
    truncatedEndingComponent: '... ',
    //* outer:
    ...restProps
    //* unoverridable:
  };

  return <ShowMoreText {...innerProps}>{children}</ShowMoreText>;
};

//! bug: text overflows the number of set lines
//! bug: ellipsis symbol doesn't stay at the end of the last line
