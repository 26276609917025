import { FC } from 'react';
import { Paragraph } from 'common/ui/_new';
import { LabelProps } from './types';
import styles from './styles.module.scss';

export const Label: FC<LabelProps> = ({ children }) => {
  return (
    <Paragraph ellipsis={{ rows: 2, tooltip: children }} className={styles.labelWrapper}>
      {children}
    </Paragraph>
  );
};
