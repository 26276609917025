import {
  ICoursesTypes,
  IEditableFreeSlide,
  IEditEditableFreeSlide,
  IProgramTypes,
  ISaveEditableFreeSlide
} from '../../../../types/requests/instructor/slides.slide_editable-free.api';
import { slidesApi } from '../slides.api';

const slidesEditableFreeApi = slidesApi.injectEndpoints({
  // API Создание страницы типа Editable_free.
  endpoints: ({ query, mutation }) => ({
    saveEditableFreeSlides: mutation<ISaveEditableFreeSlide.Response, ISaveEditableFreeSlide.Params>({
      query: ({ data, courseId, exerciseId }) => ({
        url: `/api/Questions/Free/CreateAttachedExercise`,
        method: 'POST',
        body: {
          ...data,
          courseId,
          exerciseId
        }
      }),
      invalidatesTags: ['Slides', 'Editable_free']
    }),
    // API получить информацию страницы типа Editable_free.
    getEditableFreeSlide: query<IEditableFreeSlide.Response, IEditableFreeSlide.Params>({
      query: ({ exerciseId }) => `/api/questions/free/${exerciseId}/Edit`,
      providesTags: ['Editable_free']
    }),
    // API Редактирование страницы типа Editable_free.
    editEditableFreeSlide: mutation<IEditEditableFreeSlide.Response, IEditEditableFreeSlide.Params>({
      query: ({ data, courseId, exerciseId, id }) => ({
        url: `/api/Questions/Free/EditAttachedExercise`,
        method: 'PUT',
        body: {
          ...data,
          courseId,
          exerciseId,
          id
        }
      }),
      invalidatesTags: ['Slides', 'Editable_free']
    }),
    //  Получение типов ответов
    getProgramTypes: query<IProgramTypes.Response, IProgramTypes.Params>({
      query: () => 'api/questions/free/types'
    }),
    //  Получение программ обучения
    getTypesCourses: query<ICoursesTypes.Response, ICoursesTypes.Params>({
      query: () => 'api/instructors/courses'
    })
  })
});

export const {
  useGetProgramTypesQuery,
  useGetTypesCoursesQuery,
  useGetEditableFreeSlideQuery,
  useEditEditableFreeSlideMutation,
  useSaveEditableFreeSlidesMutation
} = slidesEditableFreeApi;
