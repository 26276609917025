import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { UploadFile } from 'antd/es/upload';
import cn from 'classnames';
import { Button, IButtonProps, IModalProps, Input, InputProps, Modal, Upload, UploadProps } from 'common/ui/_new';
import { useDefaultMediaQueries } from 'common/hooks';
import { FileViewer } from './FileViewer';
import { SingleFileUploadType } from './types';
import styles from './styles.module.scss';

export const SingleFileUpload: SingleFileUploadType = (props) => {
  const { t } = useTranslation('common', { keyPrefix: 'ui.single-file-upload' });
  const {
    className,
    readOnly,
    disabled,
    placeholder = t('placeholder'),
    value = { uid: '', name: '' },
    onChange,
    accept,
    modalTitle = t('preview-modal.title'),
    defaultPreviewFile = '',
    children = t('label')
  } = props;
  const [file, setFile] = useState<UploadFile>(value);
  const [previewFile, setPreviewFile] = useState<string>(defaultPreviewFile);
  const [isPreviewModalOpen, setIsPreviewModalOpen] = useState<IModalProps['open']>(false);

  const { isMobile } = useDefaultMediaQueries();

  const inputProps: InputProps = {
    //* overridable:
    name: 'filename',
    //* unoverridable:
    value: file?.name,
    suffix:
      file?.uid && file?.name ? (
        <Button onClick={() => setIsPreviewModalOpen(true)} type='link' icon='preview' className={styles.previewBtn}>
          {isMobile || t('preview-button.label')}
        </Button>
      ) : undefined,
    readOnly: true,
    placeholder,
    className: 'file-input'
  };

  const uploadProps: UploadProps = {
    //* unoverridable:
    onChange,
    disabled,
    maxCount: 1,
    accept,
    beforeUpload: (file) => {
      setFile(file);
      setPreviewFile(URL.createObjectURL(file));
      return false;
    },
    showUploadList: false,
    defaultFileList: [],
    fileList: [file]
  };

  const buttonProps: IButtonProps = {
    //* overridable:
    children,
    //* unoverridable:
    disabled,
    ...(readOnly && { disabled: true }),
    ...(isMobile && {
      type: 'stroke-secondary-square',
      icon: 'upload',
      children: undefined,
      tooltip: children
    })
  };

  return (
    <>
      <div className={cn(styles.singleFileUpload, className)}>
        <Input {...inputProps} />
        <Upload {...uploadProps}>
          <Button {...buttonProps} />
        </Upload>
      </div>

      <Modal title={modalTitle} open={isPreviewModalOpen} onCancel={() => setIsPreviewModalOpen(false)} width={1000}>
        <FileViewer src={previewFile} name={file.name} mimeType={file.type} />
      </Modal>
    </>
  );
};
