import { IDetailedCourseReturnType } from 'types/entities';
import { ICourse } from 'types/requests/student/courses.course.api';

export const transformCourseResponse = (data: IDetailedCourseReturnType): ICourse.TransformedResponse => {
  const courseId = data?.training;
  const pictureUrl = data?.picture;
  const label = data?.label;
  const date = {
    status: data?.dates,
    begin: data?.begin,
    end: data?.end
  };
  const description = data?.description;
  const exerciseToContinue = data?.exercise
    ? {
        id: data?.exercise?.id,
        label: data?.exercise?.label,
        description: data.exercise?.isHiddenDescription ? '' : data?.exercise?.description,
        kind: data?.exercise?.kind
      }
    : null;
  const hasGroupLabel = !!data?.group?.label;
  const groupLabel = `${data.group.training}${hasGroupLabel ? ` / ${data?.group?.label}` : ''}`;
  const moderator = { hasModerator: !!data?.group?.lead, id: data?.group?.lead?.id, initials: data?.group?.lead?.initials };
  const instructors = data?.instructors
    .filter(({ isHidden }) => !isHidden)
    .map(({ accountId, picture, firstName, lastName, eMail, role, phone }) => ({
      id: accountId,
      picture,
      fullName: `${firstName} ${lastName}`,
      email: eMail,
      role,
      phone
    }));
  const modules = data?.modules;
  const tutor = data?.tutors[0];
  const support = {
    initials: data?.support?.initials,
    hide: data?.support?.hide_Support,
    telegram: data?.support?.telegramAddress,
    email: data?.support?.emailAddress,
    phone: data?.support?.phoneNumber,
    chat: {
      hide: !!data?.support?.isHideChatSupport,
      id: data?.support?.account
    }
  };
  const trace = data?.trace;
  return {
    courseId,
    pictureUrl,
    label,
    date,
    description,
    exerciseToContinue,
    groupLabel,
    moderator,
    instructors,
    modules,
    tutor,
    support,
    trace
  };
};
