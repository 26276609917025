import { Id } from 'types/common';
import { CourseId, ExerciseId } from 'types/entities';

export type PredicateId = Id;

//! переименовать
type ApplicationExerciseNode = {
  id: ExerciseId;
  label: string;
  sequence: number;
};

//? нет в Swagger
export type ApplicationExercise = {
  label: string; // название урока
  sequence: number;
  nodes: ApplicationExerciseNode[];
};

//* Lms.Entities.Entities.Training.Predicate.TrainingPredicateType
export enum TrainingPredicateType {
  NONE = 0,
  NEW_COURSE_APPLICATION = 1,
  ATTRIBUTE_STATUS_SETTING = 2,
  DATA_SYNC = 3,
  USER_PROFILE_DOC_GENERATION = 4,
  FINAL_SCORE_CALCULATION = 5
}

//* Lms.Entities.Entities.Training.Predicate.TrainingPredicateEvent
export enum TrainingPredicateEvent {
  NONE = 0,
  ENROLLING = 1,
  GRADUATE = 2,
  PROFILE_CHANGE = 3
}

//* Lms.Entities.Entities.Training.Predicate.TrainingPredicateCalculation
export enum TrainingPredicateCalculation {
  AVERAGE = 0,
  MAX = 1,
  MIN = 2,
  SUM = 3,
  DONE_PERCENT = 4,
  WITHOUT_DIGIT = 5 // depricated
}

//* CQRS.Predicates.GetPredicateDescriptionQuery.GetPredicateAttributeInfoQueryResult
export type GetPredicateAttributeInfoQueryResult = {
  label?: string;
  value?: string;
};

export type PredicateExerciseId = Id;

//* CQRS.Predicates.GetPredicateDescriptionQuery.GetPredicateExerciseInfoQueryResult
export type GetPredicateExerciseInfoQueryResult = {
  predicateExerciseId: PredicateExerciseId;
  training: CourseId;
  exercise: ExerciseId;
  label?: string;
};

type Sequence = number; //! starting with 1

//! unused
//* CQRS.Predicates.GetPredicateDescriptionQuery.GetBasePredicateDescriptionQueryResult
type GetBasePredicateDescriptionQueryResult = {
  predicateId: PredicateId;
  type: TrainingPredicateType;
  event: TrainingPredicateEvent;
  upperBoundPresentationValue?: string;
  lowerBoundPresentationValue?: string;
  upperBoundRawValue: number; // double
  lowerBoundRawValue: number; // double
  execTrainingId?: CourseId;
  conditionAttributes?: GetPredicateAttributeInfoQueryResult[];
  exercises?: GetPredicateExerciseInfoQueryResult[];
  //! predicateExerciseWorkKinds?:
  calculation?: TrainingPredicateCalculation;
  isEvaluateAllExercises?: boolean;
  sequence: Sequence;
};

//* CQRS.Predicates.GetPredicateDescriptionQuery.GetApplicationPredicateDescriptionQueryResult
type GetApplicationPredicateDescriptionQueryResult = {
  predicateId: PredicateId;
  type: TrainingPredicateType.NEW_COURSE_APPLICATION;
  event: TrainingPredicateEvent;
  upperBoundPresentationValue?: string;
  lowerBoundPresentationValue?: string;
  upperBoundRawValue: number; // double
  lowerBoundRawValue: number; // double
  conditionAttributes?: GetPredicateAttributeInfoQueryResult[];
  exercises?: GetPredicateExerciseInfoQueryResult[];
  trainingId: CourseId;
  trainingLabel?: string;
  trainingGroupName?: string;
  sequence: Sequence;
};

//* CQRS.Predicates.GetPredicateDescriptionQuery.GetStatePredicateDescriptionQueryResult
type GetStatePredicateDescriptionQueryResult = {
  predicateId: PredicateId;
  type: TrainingPredicateType.ATTRIBUTE_STATUS_SETTING;
  event: TrainingPredicateEvent;
  upperBoundPresentationValue?: string;
  lowerBoundPresentationValue?: string;
  upperBoundRawValue: number; // double
  lowerBoundRawValue: number; // double
  conditionAttributes?: GetPredicateAttributeInfoQueryResult[];
  exercises?: GetPredicateExerciseInfoQueryResult[];
  attributeLabel?: string;
  attributeValue?: string;
  sequence: Sequence;
};

//* CQRS.Predicates.GetPredicateDescriptionQuery.GetGenerationDocumentPredicateDescriptionQueryResult
type GetGenerationDocumentPredicateDescriptionQueryResult = {
  predicateId: PredicateId;
  type: TrainingPredicateType.USER_PROFILE_DOC_GENERATION;
  event: TrainingPredicateEvent;
  upperBoundPresentationValue?: string;
  lowerBoundPresentationValue?: string;
  upperBoundRawValue: number; // double
  lowerBoundRawValue: number; // double
  conditionAttributes?: GetPredicateAttributeInfoQueryResult[];
  exercises?: GetPredicateExerciseInfoQueryResult[];
  sequence: Sequence;
};

export type PredicateDescriptionQueryResult<T = TrainingPredicateType> = T extends TrainingPredicateType.NEW_COURSE_APPLICATION
  ? GetApplicationPredicateDescriptionQueryResult
  : T extends TrainingPredicateType.ATTRIBUTE_STATUS_SETTING
  ? GetStatePredicateDescriptionQueryResult
  : T extends TrainingPredicateType.USER_PROFILE_DOC_GENERATION
  ? GetGenerationDocumentPredicateDescriptionQueryResult
  : GetBasePredicateDescriptionQueryResult;

export type PredicateDescriptionQueryResultProfileChange<T = TrainingPredicateType> = Omit<
  PredicateDescriptionQueryResult<T>,
  'upperBoundPresentationValue' | 'lowerBoundPresentationValue' | 'upperBoundRawValue' | 'lowerBoundRawValue'
>;

export const isGetApplicationPredicateDescriptionQueryResult = (
  props: PredicateDescriptionQueryResult
): props is GetApplicationPredicateDescriptionQueryResult => props.type === TrainingPredicateType.NEW_COURSE_APPLICATION;

export const isGetStatePredicateDescriptionQueryResult = (
  props: PredicateDescriptionQueryResult
): props is GetStatePredicateDescriptionQueryResult => props.type === TrainingPredicateType.ATTRIBUTE_STATUS_SETTING;

export const isGetGenerationDocumentPredicateDescriptionQueryResult = (
  props: PredicateDescriptionQueryResult
): props is GetGenerationDocumentPredicateDescriptionQueryResult => props.type === TrainingPredicateType.USER_PROFILE_DOC_GENERATION;
