import { IEditKinescope, IKinescopeSlide, ISaveKinescopeSlide } from 'types/requests/instructor/slides.slide_kinescope.api';
import { slidesApi } from '../slides.api';

const kinescopeApi = slidesApi.injectEndpoints({
  endpoints: ({ query, mutation }) => ({
    // API Создание страницы типа Kinescope.
    saveKinescopeSlide: mutation<ISaveKinescopeSlide.Response, ISaveKinescopeSlide.Params>({
      query: ({ exerciseId, body }) => ({
        url: `/api/instructors/Exercises/${exerciseId}/Pages/Kinescope`,
        method: 'POST',
        body
      }),
      invalidatesTags: ['Slides', 'Kinescope']
    }),
    // API получение страницы типа Kinescope
    getKinescopeSlide: query<IKinescopeSlide.Response, IKinescopeSlide.Params>({
      query: ({ pageId }) => `/api/instructors/Exercises/Pages/${pageId}/Kinescope`,
      providesTags: ['Kinescope']
    }),
    // API Редактирование страницы типа Kinescope.
    editKinescopeSlide: mutation<IEditKinescope.Response, IEditKinescope.Params>({
      query: ({ exerciseId, body, pageId }) => ({
        url: `/api/instructors/Exercises/${exerciseId}/Pages/Kinescope`,
        method: 'PUT',
        body,
        params: {
          id: pageId
        }
      }),
      invalidatesTags: ['Slides', 'Kinescope']
    })
  })
});

export const { useSaveKinescopeSlideMutation, useGetKinescopeSlideQuery, useEditKinescopeSlideMutation } = kinescopeApi;
