import cn from 'classnames';
import { IDraggerProps, Upload } from 'common/ui/_new';
import { IFileUploadDragger } from './types';
import styles from './styles.module.scss';

const staticOverridableProps: IDraggerProps = {
  accept: '*',
  maxCount: 1,
  multiple: false,
  beforeUpload: () => false
};

export const FileUploadDragger: IFileUploadDragger = ({ className, ...outerProps }) => {
  const overridableProps: IDraggerProps = {
    ...staticOverridableProps
  };

  const unoverridableProps: IDraggerProps = {
    className: cn(styles.fileUploadDragger, className)
  };

  return <Upload.Dragger {...overridableProps} {...outerProps} {...unoverridableProps} />;
};
