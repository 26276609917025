import { EducationCategoryItemType } from 'types/entities';
import { EditableCourseCardProps } from './Course';
import { EditableCourseGroupCardProps } from './CourseGroup';

type Item<T = EducationCategoryItemType> = {
  type: T;
} & (T extends EducationCategoryItemType.COURSE
  ? EditableCourseCardProps
  : T extends EducationCategoryItemType.COURSE_GROUP
  ? EditableCourseGroupCardProps
  : unknown);

export type EditableGroupsAndCoursesListProps = {
  items: Item[];
  showActions?: EditableCourseCardProps['showActions'];
  isEditable?: EditableCourseCardProps['isEditable'] & EditableCourseGroupCardProps['isEditable'];
};

export const isCourseCardProps = (
  type: EducationCategoryItemType,
  props: EditableCourseCardProps | EditableCourseGroupCardProps | unknown
): props is EditableCourseCardProps => {
  return type === EducationCategoryItemType.COURSE;
};

export const isCourseGroupCardProps = (
  type: EducationCategoryItemType,
  props: EditableCourseCardProps | EditableCourseGroupCardProps | unknown
): props is EditableCourseGroupCardProps => {
  return type === EducationCategoryItemType.COURSE_GROUP;
};
