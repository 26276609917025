import { IGetInnerPropsByData } from './types';

const PROGRESS_ITEMS_RENDER_LIMIT = 10; // при превышении отрывистая линия превращается в сплошную

export const getInnerPropsByData: IGetInnerPropsByData = ({ complete, total }, t) => {
  const steps = total > PROGRESS_ITEMS_RENDER_LIMIT ? undefined : total;

  if (complete >= total) {
    return {
      percent: 100,
      steps,
      textIndicatorProps: {
        icon: 'check',
        children: t('text-indicator.completed')
      }
    };
  }

  return {
    percent: (complete / total) * 100,
    steps,
    textIndicatorProps: {
      icon: 'check',
      children: t('text-indicator.completed-count', { complete, total })
    }
  };
};
