import { ComponentProps, FC } from 'react';
import { Segmented as AntSegmented } from 'antd';
import cn from 'classnames';
import { SegmentedProps } from './types';
import styles from './styles.module.scss';

export const Segmented: FC<SegmentedProps> = ({ options, className, ...restProps }) => {
  const innerProps: ComponentProps<typeof AntSegmented> = {
    //* overridable:
    //* outer:
    ...restProps,
    //* unoverridable:
    options,
    className: cn(styles.segmented, className)
  };

  return <AntSegmented {...innerProps} />;
};
