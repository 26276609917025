import { ComponentProps } from 'react';
import { Divider as AntDivider } from 'antd';
import cn from 'classnames';
import { IDivider } from './types';
import styles from './styles.module.scss';

export const Divider: IDivider = ({ className, children, ...outerProps }) => {
  const overridableProps: ComponentProps<typeof AntDivider> = {};

  const unoverridableProps: ComponentProps<typeof AntDivider> = {
    className: cn(styles.divider, className)
  };

  return (
    <AntDivider {...overridableProps} {...outerProps} {...unoverridableProps}>
      {children}
    </AntDivider>
  );
};
