import { FC } from 'react';
import { Form } from 'common/ui/_new';
import { AttributesFormProps } from './types';

export const AttributesForm: FC<AttributesFormProps> = ({ children, ...restProps }) => {
  return (
    <Form scrollToFirstError {...restProps}>
      {children}
    </Form>
  );
};
