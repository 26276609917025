import { FC } from 'react';
import { HtmlContent, ICollapseItemType, Title } from 'common/ui/_new';
import { ListedContentCollapse } from 'common/components/_new';
import { Exercises } from './Exercises';
import { LessonProps } from './types';
import styles from './styles.module.scss';

export const Lesson: FC<LessonProps> = ({ id, label, description, exercises }) => {
  const lesson: ICollapseItemType = {
    key: id,
    label: (
      <Title level={3} ellipsis={{ tooltip: label, rows: 2, onExpand: (e) => e.stopPropagation() }} className={styles.title}>
        {label}
      </Title>
    ),
    extra: description && <HtmlContent content={description} ellipsis={{ lines: 2 }} />,
    children: <Exercises items={exercises} />
  };

  return <ListedContentCollapse items={[lesson]} className={styles.collapse} />;
};

//? какие должны быть раскрыты по умолчанию
