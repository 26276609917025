import { createApi } from '@reduxjs/toolkit/query/react';
import { enforceResponseFileDownload, getBaseQuery } from 'common/helpers';
import {
  CourseGroupWithChildrenQuery,
  GetCourseGroupExportFile,
  GetCourseGroupReorderList,
  IChildCourseGroupCreate,
  ICourseGroupCreate,
  ICourseGroupInfoQuery,
  ICourseGroupInstructorsRemove,
  ICourseGroupsForParentOptions,
  ICourseGroupsQuery,
  IDeleteCourseGroup,
  IEditCourseGroup,
  IGetCourseGroupHierarchy,
  PutCourseGroupReorderList
} from 'types/requests/instructor/courseGroup.api';
import { instructorTrainingsApi } from '../trainings/trainings.api';

export const courseGroupApi = createApi({
  reducerPath: 'courseGroupApi',
  tagTypes: ['СourseGroup'],
  baseQuery: getBaseQuery(),
  endpoints: ({ query, mutation }) => ({
    // Группа курсов c вложенными курсами / группами курсов: получить
    getCourseGroupWithChildren: query<CourseGroupWithChildrenQuery.Response, CourseGroupWithChildrenQuery.Params>({
      query: ({ courseGroupId }) => ({
        url: `/api/Instructors/Sets/${courseGroupId}/list`
      }),
      providesTags: ['СourseGroup']
    }),
    // Группы курсов как опции: получить
    getCourseGroups: query<ICourseGroupsQuery.TransformedResponse, ICourseGroupsQuery.Params>({
      query: () => `/api/instructors/sets`,
      transformResponse: (data: ICourseGroupsQuery.Response) => {
        return data.map(({ id, label }) => ({
          value: id,
          label
        }));
      }
    }),
    // Группа курсов: создать
    createCourseGroup: mutation<ICourseGroupCreate.Response, ICourseGroupCreate.Params>({
      query: ({ data }) => ({
        url: '/api/instructors/sets',
        method: 'POST',
        body: data
      }),
      async onQueryStarted(_, { dispatch }) {
        dispatch(instructorTrainingsApi.util.invalidateTags(['Training']));
      }
    }),
    // Подгруппа курсов: создать
    createChildCourseGroup: mutation<IChildCourseGroupCreate.Response, IChildCourseGroupCreate.Params>({
      query: ({ parentCourseGroupId, data }) => ({
        url: `/api/instructors/sets/${parentCourseGroupId}/child`,
        method: 'POST',
        body: data
      }),
      async onQueryStarted(_, { dispatch }) {
        dispatch(instructorTrainingsApi.util.invalidateTags(['Training']));
      }
    }),
    // Группа курсов: получить информацию
    getCourseGroupInfo: query<ICourseGroupInfoQuery.Response, ICourseGroupInfoQuery.Params>({
      query: ({ courseGroupId }) => `/api/instructors/sets/${courseGroupId}`,
      keepUnusedDataFor: 0
    }),
    // Группа курсов: редактировать информацию
    editCourseGroup: mutation<IEditCourseGroup.Response, IEditCourseGroup.Params>({
      query: ({ courseGroupId, data }) => ({
        url: `/api/instructors/sets/${courseGroupId}`,
        method: 'PUT',
        body: data
      }),
      async onQueryStarted(_, { dispatch }) {
        dispatch(instructorTrainingsApi.util.invalidateTags(['Training']));
      }
    }),
    // Группа курсов: удалить
    deleteCourseGroup: mutation<IDeleteCourseGroup.Response, IDeleteCourseGroup.Params>({
      query: ({ courseGroupId }) => ({
        url: `/api/instructors/sets/${courseGroupId}`,
        method: 'DELETE'
      }),
      async onQueryStarted(_, { dispatch }) {
        dispatch(instructorTrainingsApi.util.invalidateTags(['Training']));
      }
    }),
    // Группы курсов для установки в качестве родительской как опции: получить
    getCourseGroupsForParentOptions: query<ICourseGroupsForParentOptions.TransformedResponse, ICourseGroupsForParentOptions.Params>({
      query: ({ courseGroupId }) => `/api/instructors/parent-sets-for/${courseGroupId}`,
      transformResponse: (data: ICourseGroupsForParentOptions.Response) => {
        return data.map(({ id, label }) => ({
          value: id,
          label
        }));
      }
    }),
    getCourseGroupHierarchy: query<IGetCourseGroupHierarchy.Response, IGetCourseGroupHierarchy.Params>({
      query: ({ courseGroupId }) => ({
        url: `/api/instructors/sets/${courseGroupId}/trainings-hierarchy`
      })
    }),
    courseGroupInstructorsRemove: mutation<ICourseGroupInstructorsRemove.Response, ICourseGroupInstructorsRemove.Params>({
      query: (body) => ({
        url: '/api/Instructors/Trainings/Instructors/Remove/by-set',
        method: 'POST',
        body
      })
    }),
    getCourseGroupReorderList: query<GetCourseGroupReorderList.Response, GetCourseGroupReorderList.Params>({
      query: ({ courseGroupId }) => ({
        url: `/api/Sets/${courseGroupId}/Reorder`
      }),
      providesTags: ['СourseGroup']
    }),
    putCourseGroupReorderList: mutation<PutCourseGroupReorderList.Response, PutCourseGroupReorderList.Params>({
      query: ({ courseGroupId, orders }) => ({
        url: `/api/Sets/${courseGroupId}/Reorder`,
        method: 'PUT',
        params: new URLSearchParams(orders.map((id) => ['orders', id]))
      }),
      invalidatesTags: ['СourseGroup']
    }),
    getCourseGroupExportFile: query<GetCourseGroupExportFile.Response, GetCourseGroupExportFile.Params>({
      query: ({ courseGroupId }) => ({
        url: `/api/instructors/trainings/export/sets/${courseGroupId}`,
        responseHandler: enforceResponseFileDownload
      })
    })
  })
});

export const {
  useGetCourseGroupWithChildrenQuery,
  useGetCourseGroupsQuery,
  useCreateCourseGroupMutation,
  useCreateChildCourseGroupMutation,
  useGetCourseGroupInfoQuery,
  useEditCourseGroupMutation,
  useDeleteCourseGroupMutation,
  useGetCourseGroupsForParentOptionsQuery,
  useGetCourseGroupHierarchyQuery,
  useCourseGroupInstructorsRemoveMutation,
  useGetCourseGroupReorderListQuery,
  usePutCourseGroupReorderListMutation,
  useLazyGetCourseGroupExportFileQuery
} = courseGroupApi;
