import { FC, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { PageErrorHandlerContext } from 'containers';
import { Button } from 'common/ui/_new';
import { useRedirect } from 'common/hooks';
import { StudentParams } from 'types/routes/student';
import { ErrorResultPage, ErrorResultPageProps } from '../common';

export type SessionNotCompletedErrorPageProps = ErrorResultPageProps &
  Pick<StudentParams.Education, 'courseId' | 'lessonId' | 'exerciseId'>;

export const SessionNotCompletedErrorPage: FC<SessionNotCompletedErrorPageProps> = ({ courseId, exerciseId, lessonId, ...restProps }) => {
  const redirect = useRedirect();
  const { t } = useTranslation('common', { keyPrefix: 'components.error-page.session-not-completed' });
  const { setPageErrorNode } = useContext(PageErrorHandlerContext);

  return (
    <ErrorResultPage
      title={t('title')}
      subTitle={t('sub-title')}
      extra={
        <Button
          onClick={() => {
            redirect.education({ courseId, exerciseId, lessonId, slideId: 'start' });
            setPageErrorNode(null);
          }}
          type='primary'
        >
          {t('link')}
        </Button>
      }
      {...restProps}
    />
  );
};
