import { Component, ReactNode } from 'react';
import { Layout } from 'containers';
import { Button, Result } from 'common/ui/_new';

interface IProps {
  children?: ReactNode;
}

interface IState {
  hasError: boolean;
  error?: Error;
}

export class ErrorBoundary extends Component<IProps, IState> {
  public state: IState = {
    hasError: false
  };

  public static getDerivedStateFromError(error: Error): IState {
    return { hasError: true, error };
  }

  public render() {
    const { hasError, error } = this.state;
    const { children } = this.props;
    if (hasError) {
      return (
        <Layout centered style={{ justifyContent: 'center', flexGrow: 1 }}>
          <Result
            status='error'
            title='Ошибка'
            subTitle={error?.message || ''}
            extra={[
              <Button
                type='primary'
                size='large'
                key='try'
                onClick={() => {
                  document.location.reload();
                }}
              >
                Попробовать еще раз
              </Button>
            ]}
          />
        </Layout>
      );
    }

    return children;
  }
}
