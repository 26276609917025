import { createApi } from '@reduxjs/toolkit/query/react';
import { getBaseQuery } from 'common/helpers';
import { ICompetencies } from 'types/requests/student/competencies.api';

export const competenciesApi = createApi({
  reducerPath: 'competenciesApi',
  baseQuery: getBaseQuery(),
  endpoints: ({ query }) => ({
    getCompetencies: query<ICompetencies.Response, ICompetencies.Params>({
      query: ({ courseId, exerciseId }) => {
        const params = courseId && exerciseId ? `?trainingId=${courseId}&exerciseId=${exerciseId}` : '';
        return '/api/profile/Skills' + params;
      }
    })
  })
});

export const { useGetCompetenciesQuery } = competenciesApi;
