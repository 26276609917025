import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from 'common/ui/_new';
import { useDefaultMediaQueries } from 'common/hooks';
import { propsBuilder } from './helper';
import { ExpressModalProps } from './types';

export const ExpressModal: FC<ExpressModalProps> = (props) => {
  const { type, okButtonProps, onOk, children, className } = props;
  const { t } = useTranslation('common', { keyPrefix: 'ui.express-modal' });
  const mediaQueries = useDefaultMediaQueries();

  const innerProps = propsBuilder
    .init()
    .modifyByType({ type }, t)
    .modifyByOkButtonProps({ okButtonProps, onOk })
    .modifyByChildrenType({ children })
    .modifyByMediaQuery(mediaQueries)
    .modifyByClassName({ className })
    .build(props);

  return <Modal {...innerProps} />;
};
