import { useTranslation } from 'react-i18next';
import { Segmented, SegmentedProps } from 'common/ui/_new';
import { CourseDateFilterEnum } from 'types/entities';
import { ICoursesFilters, ICoursesFiltersLabeledOption } from './types';

export const CoursesFilters: ICoursesFilters = ({ onChange }) => {
  const { t } = useTranslation('common', { keyPrefix: 'components.course-filters' });

  const filtersOptions: ICoursesFiltersLabeledOption[] = [
    { label: t('all'), value: CourseDateFilterEnum.ALL },
    { label: t('past'), value: CourseDateFilterEnum.PAST },
    { label: t('current'), value: CourseDateFilterEnum.CURRENT },
    { label: t('future'), value: CourseDateFilterEnum.FUTURE }
  ];

  return <Segmented options={filtersOptions} onChange={onChange as SegmentedProps['onChange']} />;
};

/* 
todo: подумать, как избежать as в onChange - пришлось использовать т.к. не удалось сузить тип опции
*/
