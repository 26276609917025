import { Dispatch, SetStateAction, useState } from 'react';
import { ColorScheme } from 'containers/_new';

const THEME_STORAGE_KEY = 'theme';

const getInitialColorScheme = (): ColorScheme => {
  const ColorSchemeStorageValue = localStorage.getItem(THEME_STORAGE_KEY);
  if (ColorSchemeStorageValue !== null) {
    const ColorSchemeStorageNumberValue = Number(ColorSchemeStorageValue);
    if ([ColorScheme.LIGHT, ColorScheme.DARK].includes(ColorSchemeStorageNumberValue)) {
      return ColorSchemeStorageNumberValue;
    }
  }

  if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
    return ColorScheme.DARK;
  }

  return ColorScheme.LIGHT;
};

export const useColorScheme = (): [ColorScheme | undefined, Dispatch<SetStateAction<ColorScheme>>] => {
  const [colorScheme, setColorScheme] = useState<ColorScheme>(getInitialColorScheme());

  if (colorScheme !== undefined) {
    localStorage.setItem(THEME_STORAGE_KEY, String(colorScheme));
  }

  return [colorScheme, setColorScheme];
};
