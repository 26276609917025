import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ISelectProps, Select, Tooltip } from 'common/ui/_new';
import { useDebouncedState } from 'common/hooks';
import { useGetAddressSuggestionsQuery } from 'api/student/dadata/dadata.api';
import { IAddressSelect } from './types';

export const AddressSelect: IAddressSelect = (props) => {
  const { t, i18n } = useTranslation('common', { keyPrefix: 'components.address-select' });
  const { ...restProps } = props;

  const [skip, setSkip] = useState<boolean>(true);
  const [queryValue, setQueryValue] = useDebouncedState();
  const addressSuggestionsQuery = useGetAddressSuggestionsQuery({ query: queryValue, language: i18n.resolvedLanguage }, { skip });

  const options: ISelectProps['options'] = useMemo(() => {
    if (props.readOnly && props.value) {
      return [
        {
          label: <Tooltip title={props.value}>{props.value}</Tooltip>,
          value: props.value
        }
      ];
    }

    return addressSuggestionsQuery.data?.suggestions?.map((suggestion) => ({
      label: <Tooltip title={suggestion.value}>{suggestion.value}</Tooltip>,
      value: suggestion.value
    }));
  }, [addressSuggestionsQuery.data?.suggestions, props.readOnly, props.value]);

  const onFocus: ISelectProps['onFocus'] = () => {
    setSkip(false);
    setQueryValue(props.value);
  };

  const onSearch: ISelectProps['onSearch'] = (value) => {
    if (value === '') {
      setQueryValue(props.value);
    } else {
      setSkip(true);
      setQueryValue(value);
    }
  };

  useEffect(() => {
    setSkip(false);
  }, [queryValue]);

  return (
    <Select
      {...restProps}
      options={options}
      onFocus={onFocus}
      showSearch
      onSearch={onSearch}
      filterOption={false}
      loading={addressSuggestionsQuery.isFetching || addressSuggestionsQuery.isLoading}
      placeholder={t('placeholder')}
      {...(!queryValue && { notFoundContent: null })}
      allowClear
    />
  );
};

/* 
todo: сделать отдельные компоненты для режимов ввода и чтения данных
 */
