import { NavLink } from 'react-router-dom';
import { Button } from 'antd';
import { ResultProps } from 'antd/lib/result';
import { SmileOutlined } from '@ant-design/icons';
import { NoAccessSvg } from 'common/ui/_new/Result/assets';
import { instructorPaths } from 'types/routes/instructor';
import { studentPaths } from 'types/routes/student';

import { i18n } from 'i18n/config';

interface IErrorsInfo {
  [key: string]: ResultProps;
}
const page = i18n?.t('Students:pages.profile.title');
// Варианты обработки плохих ответов с сервера для конкретного курса
export const COURSE_RESPONSE_CONFIG: IErrorsInfo = {
  400: {
    title: i18n?.t('common:constants.response-config.course-response-config.400.title'),
    extra: (
      <Button type='primary'>
        <NavLink to={studentPaths.profile}>
          {i18n?.t('common:constants.response-config.course-response-config.400.button', { page })}
        </NavLink>
      </Button>
    ),
    icon: <SmileOutlined />
  },
  401: {
    title: i18n?.t('common:constants.response-config.course-response-config.401')
  },
  404: {
    title: i18n?.t('common:constants.response-config.course-response-config.404')
  },
  409: {
    title: i18n?.t('common:constants.response-config.course-response-config.409')
  }
};

export const COURSE_FOLDER_RESPONSE_CONFIG: IErrorsInfo = {
  404: {
    title: i18n?.t('common:constants.response-config.course-folder-response-config.404')
  }
};

export const GET_COURSE_PREVIEW: IErrorsInfo = {
  4001: {
    subTitle: i18n.t('common:constants.response-config.get-course-preview.400-1.sub-title')
  },
  404: {
    subTitle: i18n.t('common:constants.response-config.get-course-preview.404.sub-title')
  }
};

export const GET_COURSE_PREVIEW_BY_INVITE_CODE: IErrorsInfo = {
  4001: {
    subTitle: i18n.t('common:constants.response-config.get-course-preview-by-invite-code.400-1.sub-title')
  },
  4002: {
    subTitle: i18n.t('common:constants.response-config.get-course-preview-by-invite-code.400-2.sub-title')
  },
  404: {
    subTitle: i18n.t('common:constants.response-config.get-course-preview-by-invite-code.404.sub-title')
  }
};

export const GET_SLIDE_BY_PAGE_ID_RESPONSE_CONFIG: IErrorsInfo = {
  401: {
    title: i18n?.t('common:constants.response-config.slide-by-page-id-response-config.401')
  },
  404: {
    title: i18n?.t('common:constants.response-config.slide-by-page-id-response-config.404')
  }
};

export const INSTRUCTOR_COURSE_RESPONSE_CONFIG: IErrorsInfo = {
  404: {
    title: i18n?.t('common:constants.response-config.instructor-course-response-config.404'),
    extra: (
      <Button type='primary'>
        <NavLink to={instructorPaths.courses}>Перейти к списку курсов</NavLink>
      </Button>
    )
  },
  403: {
    title: i18n?.t('common:constants.response-config.instructor-course-response-config.403'),
    extra: (
      <Button type='primary'>
        <NavLink to={instructorPaths.courses}>Перейти к списку курсов</NavLink>
      </Button>
    ),
    icon: <NoAccessSvg />
  }
};

export const INSTRUCTOR_PROGRAM_RESPONSE_CONFIG: IErrorsInfo = {
  404: {
    title: i18n?.t('common:constants.response-config.instructor-program-response-config.404'),
    extra: (
      <Button type='primary'>
        <NavLink to={instructorPaths.programs}>Перейти к списку программ</NavLink>
      </Button>
    )
  }
};

/* 
todo: применить новый ui и перенести, т.к. текущая иерархия импортов не позволяет применить новый ui
*/
