import { FC } from 'react';
import { IInputNumberProps, InputNumber } from 'common/ui/_new';
import { MAX_VALUE, MIN_VALUE, scoreFormatter, scoreParser, STEP } from './helper';
import { ScoreInputNumberProps } from './types';

export const ScoreInputNumber: FC<ScoreInputNumberProps> = (props) => {
  const innerProps: IInputNumberProps = {
    //* overridable
    //* outer
    ...props,
    //* unoverridable,
    min: MIN_VALUE,
    max: MAX_VALUE,
    step: STEP,
    parser: scoreParser,
    formatter: scoreFormatter
  };

  return <InputNumber {...innerProps} />;
};
