import { Id } from 'types/common';
import { HierarchyRecordId, IAddress, ISuggestion, LmsFile } from 'types/entities';

export type AttributeId = Id;
export type AttributesGroupId = Id;

//*	Lms.Entities.Auth.Principals.AttributeType
// Типы данных атрибутов
export enum AttributeTypeEnum {
  String = 1, // Строкa
  Bool = 2, // Логическое значение
  Int = 3, // Целое число
  Float = 4, // Дробное число
  Date = 5, // Дата
  Address = 10, // Адрес
  Company = 15, // Организация
  Phone = 20, // Телефон
  File = 30, // Файл
  Reference = 50, // Выбор одного значения из словаря
  Set = 51, // Выбор нескольких значений из словаря
  Hierarchy = 60, // Иерархия
  Pattern = 80 // Шаблон документа для автоматического заполнения данными
}

//* Lms.UseCases.Interfaces.Common.Profile.Attributes.Field
// Описание поля
export type AttributesField<T extends AttributeTypeEnum = AttributeTypeEnum> = {
  fid?: string;
  checked: boolean;
  id: Id;
  label?: string;
  description?: string;
  help?: string;
  required: boolean;
  readonly: boolean;
  filled: boolean;
  type: T;
  regex?: string;
  categoryId?: AttributeCategoryIds;
  categoryName?: string;
  attributeGroupId?: IAttributesGroupItem['id'];
  max?: string;
  min?: string;
  sys: boolean;
  hidden: boolean;
  hideFor?: number[];
  readonlyPreset: boolean;
  isHasNoneAttribute: boolean;
  noneAttributeText?: string;
  isHasNoneValue: boolean;
  readonlyFor?: number[];
  isNotValid: boolean;
} & (T extends AttributeTypeEnum.String | AttributeTypeEnum.Float | AttributeTypeEnum.Date | AttributeTypeEnum.Address
  ? {
      value?: string;
      options?: string;
    }
  : T extends AttributeTypeEnum.Bool
  ? {
      value?: 'true' | 'false';
      options?: string;
    }
  : T extends AttributeTypeEnum.Int
  ? {
      value?: number;
      options?: string;
    }
  : T extends AttributeTypeEnum.Company
  ? {
      value?: {
        free: boolean;
        value: string;
      };
      options?: string;
    }
  : T extends AttributeTypeEnum.Phone
  ? {
      value?: unknown;
      options?: string;
    }
  : T extends AttributeTypeEnum.File
  ? {
      value?: LmsFile;
      options?: string;
    }
  : T extends AttributeTypeEnum.Reference
  ? {
      value?: Id; // todo: заменить на алиас конкретной сущности
      options: string;
    }
  : T extends AttributeTypeEnum.Set
  ? {
      value?: string; // Id, разделенные запятой
      options: string;
    }
  : T extends AttributeTypeEnum.Hierarchy
  ? {
      value: HierarchyRecordId;
      options: string;
    }
  : T extends AttributeTypeEnum.Pattern
  ? {
      value?: unknown;
      options?: string;
    }
  : never);

// todo: delete
export interface IFieldAttribute {
  categoryId: AttributeCategoryIds;
  options: string;
  type: AttributeTypeEnum;
  label: string;
  value: IAttributeFileValue | IAttributeCompanyValue | string;
  fid: string;
  id: string;
  readonly: boolean;
  description: string;
}

// todo: удалить:
export interface IAttributeFileValue {
  account: {
    id: string;
    created: Date;
    type: number;
  };
  account_Id: string;
  created: Date;
  createdBy: {
    id: string;
    created: Date;
    type: number;
  };
  createdBy_Id: string;
  id: string;
  revision: number;
  size: number;
  storage: string;
  type: number;
  value: string;
}

// todo: удалить:
export interface IAttributeCompanyValue {
  value: {
    value: string;
  };
}

export enum AttributeCategoryIds {
  email = 2,
  address = 3,
  registration_address = 4,
  passport_copy = 8,
  passport_number = 10,
  live_region = 16,
  registration_region = 17,
  passport_series = 19,
  identity_card_delivery_method = 21,
  activity_field = 48,
  delivery_address = 24,
  diploma_form_series = 26,
  citizenship = 30,
  diploma_issue_date = 31,
  passport_date = 32,
  birthdate = 33,
  position = 34,
  name = 36,
  passport_issue_place = 41,
  diplom_copy = 43,
  snils_copy = 46,
  diploma_form_number = 50,
  snils = 53,
  company = 57,
  patronymic = 58,
  sex = 59,
  diploma_registration_number = 63,
  surname = 69,
  phone_num = 73,
  structural_division = 74,
  employee_category = 75,
  position_gazprom = 76,
  field77 = 77,
  field78 = 78,
  field79 = 79
}

export type IProfileCategoryNames = {
  [index in AttributeCategoryIds]: string;
};

export const PROFILE_CATEGORY_NAMES: IProfileCategoryNames = {
  // 1, //'Дополнительно'
  2: 'email', //'Электронная почта'
  3: 'address', //'Адрес места жительства / место нахождения (Заказчика)'
  4: 'registration_address', //'Адрес прописки'
  // 6, //'Алиас Телеграм (Имя пользователя / Username в настройках профиля)'
  8: 'passport_copy', // 'Копия паспорт'
  10: 'passport_number', // 'Номер паспорта'
  // 12, // 'Паспорт (Заказчика)'
  //13, // 'Представителем какой образовательной организации вы являетесь?'
  16: 'live_region', // 'Регион проживания/фактической деятельности'
  17: 'registration_region', // 'Регион регистрации'
  //18, // 'Серия и Номер Паспорт (Заказчика)'
  19: 'passport_series', // 'Серия паспорта'
  21: 'identity_card_delivery_method', // 'Способ доставки удостоверения'
  //22: , // 'Сфера деятельности'
  //23, // 'Сфера деятельности'
  24: 'delivery_address', // 'Указать адрес доставки удостоверения'
  //25, // 'Уровень вашего образования'
  26: 'diploma_form_series', // 'Cерия бланка диплома'
  //27, // 'e-mail родителя'
  //28, // 'В каком отделе/секторе Вы работаете?'
  //29, // 'Город'
  30: 'citizenship', // 'Гражданство'
  31: 'diploma_issue_date', // 'Дата выдачи диплома'
  32: 'passport_date', // 'Дата выдачи паспорта'
  33: 'birthdate', // 'Дата рождения'
  34: 'position', // 'Должность'
  //35, // 'Желаемый способ связи'
  36: 'name', // 'Имя'
  //37, // 'Категории организации (ФОИВ, РОИВ, ВУЗ, Компании)'
  //38, // 'Категория граждан'
  //39, // 'Категория должности'
  //40, // 'Категория организации'
  41: 'passport_issue_place', // 'Кем выдан паспорт'
  //42, // 'кем выдан паспорт родителя'
  43: 'diplom_copy', // 'Копия диплома'
  //44, // 'Копия документа, подтверждающего смену фамилии (Обязательно при смене фамилии)'
  //45, // 'Копия ИНН'
  46: 'snils_copy', // 'Копия СНИЛС'
  //47, // 'Копия удостоверения, подтверждающего, что вы являетесь государственным служащим'
  48: 'activity_field', // 'Направление профессиональной деятельности'
  //49, // 'Направлении подготовки (области компетенций, планируемые к развитию)'
  50: 'diploma_form_number', // 'Номер бланка диплома'
  //51, // 'Номер ИНН'
  //52, // 'Номер паспорта родителя'
  53: 'snils', // 'Номер СНИЛС (указывать БЕЗ пробелов и тире "-")'
  //54, // 'Образование'
  //55, // 'Образовательная организация'
  57: 'company', // 'Организация (Место вашей работы)'
  58: 'patronymic', // 'Отчество'
  59: 'sex', // 'Пол'
  //60, // 'Предыдущий документ об образовании'
  //61, // 'прикрепить скан - доверенность на получение документа об образовании и (или) о квалификации'
  //62, // 'Приложение к диплому'
  63: 'diploma_registration_number', // 'Регистрационный номер диплома'
  //64, // 'Серия и номер паспорта (для иностранных граждан)'
  //65, // 'Серия паспорта родителя'
  //66, // 'Специальность/направление подготовки по диплому'
  //67, // 'Справка с места учёбы'
  //68, // 'Укажите данные желаемого вида связи'
  69: 'surname', // 'Фамилия'
  //70, // 'ФИО (Заказчика)'
  73: 'phone_num', // Телефон
  74: 'structural_division', // Структурное подразделение
  75: 'employee_category', // Категория сотрудника
  76: 'position_gazprom', // 'Должность'
  77: 'field77',
  78: 'field78',
  79: 'field79'
};

export type IUpdateDataType = string | ISuggestion | IAddress;
export interface IUpdateObject {
  [index: string]: IUpdateDataType;
}

//* Lms.UseCases.Interfaces.Attributes.AttributeGroups.AttributeGroupDto
// Группа атрибутов
export interface IAttributesGroupItem {
  id: Id;
  name?: string;
  system: boolean;
  sequence: number;
}

//* Lms.UseCases.Interfaces.Sys.AttributeGroups.GetAttributeGroupsQueryResult
// Список аттрибутов без самих аттрибутов
// todo: upd
export interface IAttributesGroups {
  attributeGroups: IAttributesGroupItem[];
}

//* Lms.Entities.Auth.Principals.AttributeUsage
// Варианты использования атрибута
export const enum AttributeUsageEnum {
  Undefined = 0,
  Required = 1,
  Optional = 2,
  Additional = 3,
  MethodologistCorrespondence = 4
}

export type ChainResultAttributeId = AttributesField['id'];

//* Lms.UseCases.Interfaces.ChainAttributes.Profiles.ChainResultAttribute
// Информация об атрибуте Результате
export type ChainResultAttribute = {
  chainResultAttributeId: ChainResultAttributeId;
  usage: AttributeUsageEnum;
  readonly: boolean;
  isCommonAttribute: boolean;
  hasHigherUsagePrioCommonAttribute: boolean;
};

export type ChainConditionAttributeId = AttributesField['id'];
export type ChainConditionTriggerValue = Id; // значение словаря

//* Lms.UseCases.Interfaces.ChainAttributes.Profiles.GetUserChainAttributesWithResultsQueryResult
// Модель связи атрибута Условия с атрибутами Результатов
export type ChainConditionResultAttributeRelationship = {
  chainConditionAttributeId: ChainConditionAttributeId;
  chainConditionTriggerValue?: ChainConditionTriggerValue;
  chainResultAttributes?: ChainResultAttribute[];
};
