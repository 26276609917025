import { FC } from 'react';
import { CoverDescriptionLayout, Header } from 'containers/_new';
import { HtmlContent } from 'common/ui/_new';
import { useDefaultMediaQueries } from 'common/hooks';
import { MetaData } from './MetaData';
import { CourseDescriptionProps } from './types';
import styles from './styles.module.scss';

export const CourseDescription: FC<CourseDescriptionProps> = ({
  picture,
  label,
  description,
  group,
  begin,
  end,
  alwaysAvailableByDates,
  open,
  close,
  footer
}) => {
  const { isMobile } = useDefaultMediaQueries();

  return (
    <CoverDescriptionLayout
      cover={picture}
      header={
        <Header level={2} ellipsis={{ rows: 2, tooltip: label }} className={styles.title}>
          {label}
        </Header>
      }
      footer={footer}
    >
      {description && <HtmlContent content={description} ellipsis={isMobile} />}
      <MetaData {...{ group, begin, end, alwaysAvailableByDates, open, close }} />
    </CoverDescriptionLayout>
  );
};
