import { FC, useEffect } from 'react';
import { ISearchProps, Search } from 'common/ui/_new';
import { useDebouncedState } from 'common/hooks';

export const DebouncedSearch: FC<ISearchProps> = (props) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { onChange, value, ...restProps } = props;
  const [searchQuery, setSearchQuery] = useDebouncedState();

  const onSearchChange: ISearchProps['onChange'] = (e) => {
    setSearchQuery(e.target.value);
  };

  useEffect(() => {
    // @ts-ignore
    onChange?.(searchQuery);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchQuery]);

  return <Search onChange={onSearchChange} {...restProps} />;
};
