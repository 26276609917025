import { ComponentProps } from 'react';
import { useTranslation } from 'react-i18next';
import { Table as AntTable } from 'antd';
import cn from 'classnames';
import { Empty, Pagination } from 'common/ui/_new';
import { getColumns } from './helper';
import { IInternalTable, ITable } from './types';
import styles from './styles.module.scss';

export const InternalTable: IInternalTable = (props) => {
  const { t } = useTranslation('common', { keyPrefix: 'ui.table' });
  const { columns, loading, className, ...restProps } = props;

  const innerProps: ComponentProps<typeof AntTable> = {
    //* overridable:
    tableLayout: 'auto',
    locale: {
      emptyText: <Empty description={t('empty.description')} />
    },
    scroll: {
      x: true
    },
    //* outer:
    ...restProps,
    //* unoverridable:
    columns: columns && getColumns(columns),
    loading: loading && { size: 'large' },
    pagination: false,
    className: cn(styles.table, className)
  };

  if (props.pagination) {
    return (
      <div className={styles.tableContainer}>
        <AntTable {...innerProps} />
        <Pagination {...props.pagination} type='horizontal' className={styles.pagination} />
      </div>
    );
  }

  return <AntTable {...innerProps} />;
};

export const Table: ITable = Object.assign(InternalTable, {});
