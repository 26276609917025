import { Id } from 'types/common';

export type ITaskId = Id;

//*	Lms.Infrastructure.Interfaces.RabbitMessageSenders.QueueTaskTypes
export const enum QueueTaskType {
  INTEGRATE_WITH_CRM = 1,
  CLEAR_QUEUE = 2,
  DOWNLOAD_BY_LEARNING_PROGRESS = 3,
  RECALCULATE_COURSE_END = 4,
  SYNC_USERS_WITH_ONEID = 5,
  COPY_DICTIONARY = 6,
  GENERATE_CERTIFICATE = 7,
  INCLUDE_TEACHER_IN_TRAINING = 8,
  INCLUDE_STUDENT_IN_TRAINING = 9,
  DOWNLOAD_BY_VISIT_JOURNAL = 10,
  DOWNLOAD_DATA_ON_MAILING_LISTS = 11,
  DOWNLOAD_INSPECTORS = 12,
  RECALCULATE_EXERCISES_AVAILABILITY = 13,
  RECALCULATE_EXERCISE_GRADES = 14,
  REMOVE_TEACHER_FROM_TRAINING = 15,
  DOWNLOAD_ALL_STUDENTS = 16,
  SEND_CERTIFICATES_TO_1C = 17,
  GET_ANALYTICS_FROM_KINESCOPE = 18,
  DOWNLOAD_BY_VIDEO_CONTENT_VIEWING_DEPTH = 19
}

export type IQueueTaskTypeId = QueueTaskType;

export type IQueueTaskStatusTypeId = number;

export type ITask = {
  id: ITaskId;
  queueTaskTypeId: IQueueTaskTypeId;
  queueTaskTypeName: string;
  queueTaskStatusTypeId: IQueueTaskStatusTypeId;
  queueTaskStatusTypeName: string;
  created: string;
  completed: string;
  percentComplete: number;
  name: string;
  accountId: string;
  account: string;
};

type IQueueTaskError = {
  id: string;
  errorText: string;
  queueTaskId: string;
};

type IQueueTaskFile = {
  fileId: string;
  fileType: string;
  fileName: string;
  path: string;
  fileTypeId: number;
};

export interface ITaskInfo extends ITask {
  queueTaskErrors: IQueueTaskError[];
  queueTaskFiles: IQueueTaskFile[];
}

export type ITaskType = {
  id: QueueTaskType;
  label: string;
};

export type ITaskStatus = {
  id: string;
  label: string;
};
