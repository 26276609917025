import { useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { ArgsProps } from 'antd/es/notification/interface';
import { NotificationContext } from 'containers/_new';
import { getPropsByType } from './helper';
import { ArgsPropsWithOptionalMessage, useNotificationReturnType } from './types';

export const useNotification = (): useNotificationReturnType => {
  const { notificationApi } = useContext(NotificationContext);
  const { t } = useTranslation('common', { keyPrefix: 'ui.notification' });

  const openInfoNotification = useCallback(
    (props: ArgsProps) => {
      notificationApi.info(getPropsByType({ type: 'info', props }));
    },
    [notificationApi]
  );
  const openWarningNotification = useCallback(
    (props: ArgsProps) => {
      notificationApi.warning(getPropsByType({ type: 'warning', props }));
    },
    [notificationApi]
  );
  const openSuccessNotification = useCallback(
    (props?: ArgsPropsWithOptionalMessage) => {
      notificationApi.success(getPropsByType({ type: 'success', props }, t));
    },
    [notificationApi, t]
  );
  const openErrorNotification = useCallback(
    (props?: ArgsPropsWithOptionalMessage) => {
      notificationApi.error(getPropsByType({ type: 'error', props }, t));
    },
    [notificationApi, t]
  );

  return {
    ...notificationApi,
    info: openInfoNotification,
    inDevelopment: () => openInfoNotification({ message: t('in-development.default.message') }),
    warning: openWarningNotification,
    success: openSuccessNotification,
    error: openErrorNotification
  };
};
