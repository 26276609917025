import {
  AccountCircled,
  Arrow,
  ArrowChevron,
  ArrowChevronDouble,
  ArrowDiagonal,
  ArrowsControl,
  ArrowTriangle,
  ArrowTriangleSmooth,
  Attach,
  Avatar,
  BookmarkChecked,
  BookmarkMortarboard,
  BookOpen,
  BookOpenSmooth,
  BurgerMenu,
  BxEraser,
  Calendar,
  Camera,
  Certificate,
  ChartPie,
  Chat,
  Check,
  CheckSolid,
  Clock,
  ClockBackward,
  ClockForward,
  Comment,
  Computer,
  CopySmooth,
  CrossBig,
  CrossSmall,
  Dashboard,
  DecorAccept,
  DecorCalendar,
  DecorChampionshipCup,
  DecorDownloadToFolder,
  DecorError,
  DecorInfo,
  DecorMarker,
  DecorTrace,
  DecorTrash,
  DecorUnlock,
  Delete,
  Dot,
  Download,
  DownloadFolder,
  DownloadStorage,
  EditorAlign,
  EditorAlignCenter,
  EditorBold,
  EditorCode,
  EditorColors,
  EditorImg,
  EditorItalic,
  EditorLink,
  EditorList,
  EditorListOrdered,
  EditorMaximize,
  EditorMinimize,
  EditorQuestion,
  EditorTable,
  EditorUnderline,
  EditorVideo,
  Education,
  ExerciseCheck,
  EyeHide,
  EyeShow,
  File,
  FileArchive,
  FileAudio,
  FileDoc,
  FileDocDownloadSmooth,
  FileDocSmooth,
  FileDownload,
  FileDownloadSmooth,
  FileImg,
  FileReplace,
  FileVideo,
  Filter,
  FilterPlus,
  FilterSolid,
  FingerPrint,
  Folder,
  Hash,
  Home,
  IdCard,
  Import,
  ImportApplication,
  Info,
  InProgress,
  Insert,
  Kebab,
  Key,
  Layers,
  Layout,
  Lecture,
  Lightbulb,
  Link,
  LinkExternal,
  Location,
  Lock,
  LockAdmin,
  LockOpen,
  LogIn,
  LogOut,
  Mail,
  MailSend,
  MailSolidSmooth,
  MasksDrama,
  Medal,
  MessageProcessing,
  MessageProcessingCenterSmooth,
  MessageProcessingSmooth,
  MessageProcessingSolidSmooth,
  MessageRounded,
  Minus,
  More,
  Mortarboard,
  MortarboardSmooth,
  Mouse,
  Move,
  MoveVertically,
  Note,
  NotesSmooth,
  Notification,
  NotificationSmooth,
  NotificationYes,
  Pencil,
  Phone,
  PlayMedia,
  Plus,
  Price,
  Question,
  Recording,
  Refresh,
  Search,
  Send,
  Settings,
  Slide,
  SmTelegramSolid,
  Star,
  Support,
  SwitchVertical,
  TextCheck,
  Timer,
  Upload,
  UploadCloud,
  UploadOop,
  UploadStorage,
  User,
  UserAdd,
  UserLock,
  UserProfile,
  Users,
  Users2,
  UsersAdd,
  UsersDelete,
  UserSearch,
  Warning,
  Web,
  ZoomIn,
  ZoomOut
} from './assets';
import { IGetInitialProps, IIconProps, IIcons, IIconStyle, IIconType } from './types';

export const icons: IIcons<IIconType> = {
  ['24-hours']: { icon: ClockForward },
  ['apply']: { icon: Refresh },
  ['avatar']: { icon: Avatar, iconStyles: ['primary'] },
  ['camera']: { icon: Camera, iconStyles: ['primary'] },
  ['support']: { icon: Support },
  ['arrow-chevron-double-down']: { icon: ArrowChevronDouble, iconStyles: ['rotated180'] },
  ['arrow-chevron-double-left']: { icon: ArrowChevronDouble, iconStyles: ['rotated270'] },
  ['arrow-chevron-double-right']: { icon: ArrowChevronDouble, iconStyles: ['rotated90'] },
  ['arrow-chevron-double-up']: { icon: ArrowChevronDouble },
  ['arrow-chevron-down']: { icon: ArrowChevron, iconStyles: ['rotated180'] },
  ['arrow-chevron-left']: { icon: ArrowChevron, iconStyles: ['rotated270'] },
  ['arrow-chevron-right']: { icon: ArrowChevron, iconStyles: ['rotated90'] },
  ['arrow-chevron-up']: { icon: ArrowChevron },
  ['arrow-down']: { icon: Arrow, iconStyles: ['rotated180'] },
  ['arrow-left']: { icon: Arrow, iconStyles: ['rotated270'] },
  ['arrow-left-down']: { icon: ArrowDiagonal, iconStyles: ['rotated180'] },
  ['arrow-left-up']: { icon: ArrowDiagonal, iconStyles: ['rotated270'] },
  ['arrow-right']: { icon: Arrow, iconStyles: ['rotated90'] },
  ['arrow-right-down']: { icon: ArrowDiagonal, iconStyles: ['rotated90'] },
  ['arrow-right-up']: { icon: ArrowDiagonal },
  ['arrow-triangle-down']: { icon: ArrowTriangle, iconStyles: ['rotated180'] },
  ['arrow-triangle-left']: { icon: ArrowTriangle, iconStyles: ['rotated270'] },
  ['arrow-triangle-right']: { icon: ArrowTriangle, iconStyles: ['rotated90'] },
  ['arrow-triangle-smooth-down']: { icon: ArrowTriangleSmooth, iconStyles: ['rotated180'] },
  ['arrow-triangle-smooth-left']: { icon: ArrowTriangleSmooth, iconStyles: ['rotated270'] },
  ['arrow-triangle-smooth-right']: { icon: ArrowTriangleSmooth, iconStyles: ['rotated90'] },
  ['arrow-triangle-smooth-up']: { icon: ArrowTriangleSmooth },
  ['arrow-triangle-up']: { icon: ArrowTriangle },
  ['arrow-up']: { icon: Arrow },
  ['arrows-control']: { icon: ArrowsControl },
  ['attach']: { icon: Attach },
  ['back']: { icon: Arrow, iconStyles: ['rotated270'] },
  ['book-open']: { icon: BookOpen },
  ['bookmark']: { icon: Education },
  ['burger-menu']: { icon: BurgerMenu },
  ['calendar']: { icon: Calendar },
  ['certificate']: { icon: Certificate },
  ['chart-pie']: { icon: ChartPie },
  ['chat']: { icon: Chat },
  ['chat-start']: { icon: MessageProcessingSmooth },
  ['chat-start-center']: { icon: MessageProcessingCenterSmooth },
  ['chat-start-solid']: { icon: MessageProcessingSolidSmooth },
  ['check']: { icon: Check },
  ['check-solid']: { icon: CheckSolid },
  ['clear']: { icon: BxEraser },
  ['clock']: { icon: Clock },
  ['close']: { icon: CrossBig },
  ['comment']: { icon: Comment },
  ['competencies']: { icon: Mortarboard },
  ['composite']: { icon: Layers },
  ['computer']: { icon: Computer },
  ['control']: { icon: Mouse },
  ['copy']: { icon: CopySmooth },
  ['cross-small']: { icon: CrossSmall },
  ['dashboard']: { icon: Dashboard },
  ['decor-accept']: { icon: DecorAccept, iconStyles: ['lg', 'success'] },
  ['decor-calendar']: { icon: DecorCalendar, iconStyles: ['lg', 'primary'] },
  ['decor-championship-cup']: { icon: DecorChampionshipCup, iconStyles: ['lg', 'primary'] },
  ['decor-download-to-folder']: { icon: DecorDownloadToFolder, iconStyles: ['lg', 'primary'] },
  ['decor-error']: { icon: DecorError, iconStyles: ['lg', 'error'] },
  ['decor-info']: { icon: DecorInfo, iconStyles: ['lg', 'primary'] },
  ['decor-marker']: { icon: DecorMarker, iconStyles: ['lg', 'primary'] },
  ['decor-trash']: { icon: DecorTrash, iconStyles: ['lg', 'error'] },
  ['decor-trace']: { icon: DecorTrace, iconStyles: ['lg', 'primary'] },
  ['decor-unlock']: { icon: DecorUnlock, iconStyles: ['lg', 'primary'] },
  ['delete']: { icon: Delete },
  ['digital-footprint']: { icon: FingerPrint },
  ['document']: { icon: FileDocSmooth },
  ['done-solid']: { icon: CheckSolid, iconStyles: ['md', 'round', 'success'] },
  ['dot']: { icon: Dot },
  ['download']: { icon: Download },
  ['download-document']: { icon: FileDocDownloadSmooth },
  ['download-folder']: { icon: DownloadFolder },
  ['download-storage']: { icon: DownloadStorage },
  ['edit']: { icon: Pencil },
  ['editor-align-center']: { icon: EditorAlignCenter },
  ['editor-align-left']: { icon: EditorAlign },
  ['editor-align-right']: { icon: EditorAlign, iconStyles: ['flipped'] },
  ['editor-bold']: { icon: EditorBold },
  ['editor-code']: { icon: EditorCode },
  ['editor-colors']: { icon: EditorColors },
  ['editor-img']: { icon: EditorImg },
  ['editor-italic']: { icon: EditorItalic },
  ['editor-link']: { icon: EditorLink },
  ['editor-list']: { icon: EditorList },
  ['editor-list-ordered']: { icon: EditorListOrdered },
  ['editor-maximize']: { icon: EditorMaximize },
  ['editor-minimize']: { icon: EditorMinimize },
  ['editor-question']: { icon: EditorQuestion },
  ['editor-table']: { icon: EditorTable },
  ['editor-underline']: { icon: EditorUnderline },
  ['editor-video']: { icon: EditorVideo },
  ['education']: { icon: Education },
  ['exercise-check']: { icon: ExerciseCheck },
  ['feedback']: { icon: MessageRounded },
  ['file']: { icon: File },
  ['file-archive']: { icon: FileArchive },
  ['file-audio']: { icon: FileAudio },
  ['file-doc']: { icon: FileDoc },
  ['file-download']: { icon: FileDownload },
  ['file-download-smooth']: { icon: FileDownloadSmooth },
  ['file-img']: { icon: FileImg },
  ['file-video']: { icon: FileVideo },
  ['filter']: { icon: Filter },
  ['filter-plus']: { icon: FilterPlus },
  ['filter-solid']: { icon: FilterSolid },
  ['finished']: { icon: Medal },
  ['folder']: { icon: Folder },
  ['forward']: { icon: Arrow, iconStyles: ['rotated90'] },
  ['go-to-details']: { icon: ArrowChevron, iconStyles: ['rotated90'] },
  ['graduate']: { icon: FileReplace },
  ['hash']: { icon: Hash },
  ['hidden']: { icon: EyeHide },
  ['hide']: { icon: EyeHide },
  ['history']: { icon: ClockBackward },
  ['home']: { icon: Home },
  ['id-card']: { icon: IdCard },
  ['import']: { icon: Import },
  ['import-application']: { icon: ImportApplication },
  ['in-progress']: { icon: InProgress, iconStyles: ['md', 'round', 'warning'] },
  ['independent-work']: { icon: FileDocSmooth },
  ['info']: { icon: Info },
  ['insert']: { icon: Insert },
  ['journal']: { icon: BookmarkMortarboard },
  ['kebab']: { icon: Kebab },
  ['key']: { icon: Key },
  ['language']: { icon: Web },
  ['layout']: { icon: Layout },
  ['lecture']: { icon: Lecture },
  ['lightbulb']: { icon: Lightbulb },
  ['link']: { icon: Link },
  ['link-external']: { icon: LinkExternal },
  ['location']: { icon: Location },
  ['locked']: { icon: Lock },
  ['lock-admin']: { icon: LockAdmin },
  ['unlocked']: { icon: LockOpen },
  ['log-in']: { icon: LogIn },
  ['log-in-as']: { icon: MasksDrama },
  ['log-out']: { icon: LogOut },
  ['mail']: { icon: Mail },
  ['mail-send']: { icon: MailSend },
  ['mail-solid']: { icon: MailSolidSmooth },
  ['message-processing']: { icon: MessageProcessing },
  ['minus']: { icon: Minus },
  ['more']: { icon: More },
  ['mortarboard-smooth']: { icon: MortarboardSmooth },
  ['move']: { icon: Move },
  ['next']: { icon: ArrowChevron, iconStyles: ['rotated90'] },
  ['note']: { icon: Note },
  ['notes-smooth']: { icon: NotesSmooth },
  ['notification']: { icon: Notification, iconStyles: ['primary'] },
  ['notification-smooth']: { icon: NotificationSmooth },
  ['notification-yes']: { icon: NotificationYes, iconStyles: ['primary'] },
  ['on-review']: { icon: Recording, iconStyles: ['warning'] },
  ['open-book']: { icon: BookOpenSmooth },
  ['phone']: { icon: Phone },
  ['play']: { icon: PlayMedia },
  ['plus']: { icon: Plus },
  ['preview']: { icon: Search },
  ['previous']: { icon: ArrowChevron, iconStyles: ['rotated270'] },
  ['price']: { icon: Price },
  ['refresh']: { icon: Refresh },
  ['reorder']: { icon: MoveVertically },
  ['search']: { icon: Search },
  ['send']: { icon: Send },
  ['session-log']: { icon: BookmarkChecked },
  ['settings']: { icon: Settings },
  ['show']: { icon: EyeShow },
  ['slide']: { icon: Slide },
  ['sm-telegram']: { icon: SmTelegramSolid },
  ['sort']: { icon: SwitchVertical },
  ['star']: { icon: Star },
  ['students']: { icon: Users, iconStyles: ['flipped'] },
  ['question']: { icon: Question },
  ['taxonomy']: { icon: Mortarboard },
  ['teacher']: { icon: MortarboardSmooth },
  ['testing']: { icon: TextCheck },
  ['timer']: { icon: Timer },
  ['upload']: { icon: Upload },
  ['upload-cloud']: { icon: UploadCloud },
  ['upload-oop']: { icon: UploadOop },
  ['upload-storage']: { icon: UploadStorage },
  ['user']: { icon: User },
  ['user-add']: { icon: UserAdd },
  ['user-circled']: { icon: AccountCircled },
  ['user-profile']: { icon: UserProfile },
  ['user-rights']: { icon: UserLock },
  ['user-search']: { icon: UserSearch },
  ['users']: { icon: Users },
  ['hierarchy']: { icon: Users2 },
  ['users-add']: { icon: UsersAdd },
  ['users-delete']: { icon: UsersDelete },
  ['visible']: { icon: EyeShow },
  ['warning']: { icon: Warning },
  ['web']: { icon: Web },
  ['zoom-in']: { icon: ZoomIn },
  ['zoom-out']: { icon: ZoomOut }
};

const DEFAULT_ICON = ArrowDiagonal;

export const getInitialProps: IGetInitialProps = ({ type, styles }) => {
  const { icon = DEFAULT_ICON, iconStyles = [] } = icons[type] ?? {};

  const initialTypeStyleClassNames: IIconProps['className'][] = [styles.icon];

  if (iconStyles.length > 0) {
    const iconTypeStyles: IIconProps['className'][] = iconStyles.map((iconStyle: IIconStyle) => styles[`icon--${iconStyle}`]);
    initialTypeStyleClassNames.push(...iconTypeStyles);
  }

  return {
    component: icon,
    initialTypeStyleClassNames
  };
};
