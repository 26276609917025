import type { IEditVideoSlide, ISaveVideoSlide, IVideoSlide } from 'types/requests/instructor/slides.slide_video.api';
import { slidesApi } from '../slides.api';

const slideVideoApi = slidesApi.injectEndpoints({
  endpoints: ({ query, mutation }) => ({
    // API Создание страницы типа Video.
    saveVideoSlide: mutation<ISaveVideoSlide.Response, ISaveVideoSlide.Params>({
      query: ({ exerciseId, body }) => ({
        url: `/api/instructors/Exercises/${exerciseId}/Pages/Video`,
        method: 'POST',
        body
      }),
      invalidatesTags: ['Slides', 'Video']
    }),
    // API информация страницы типа Video
    getVideoSlide: query<IVideoSlide.Response, IVideoSlide.Params>({
      query: ({ exerciseId, pageId }) => `/api/instructors/Exercises/${exerciseId}/Pages/${pageId}/Video`,
      providesTags: ['Video']
    }),
    // API Редактирование страницы типа Video.
    editVideoSlide: mutation<IEditVideoSlide.Response, IEditVideoSlide.Params>({
      query: ({ exerciseId, pageId, body }) => ({
        url: `/api/instructors/Exercises/${exerciseId}/Pages/${pageId}/Video`,
        method: 'PUT',
        body
      }),
      invalidatesTags: ['Slides', 'Video']
    })
  })
});

export const { useEditVideoSlideMutation, useGetVideoSlideQuery, useSaveVideoSlideMutation } = slideVideoApi;
