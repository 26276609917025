import { FEATURE_FLAGS, FeatureFlagName } from 'common/constants';
import { ApplicationMode } from 'types/common';
import { IROLE } from 'types/roles';
import { validateRole } from './validation';

export const isFeatureAvailable = (featureName: FeatureFlagName, user: IROLE.RoleObject) => {
  const rules = FEATURE_FLAGS[featureName];

  if (typeof rules === 'boolean') {
    return rules;
  }

  return rules.some((rule) => {
    const requirements = [validateRole(rule.users, user)];
    if (rule.modes !== undefined) {
      const mode = getApplicationMode();
      requirements.push(rule.modes.includes(mode));
    }

    return requirements.every((requirement) => requirement === true);
  });
};

export const getApplicationMode = () => {
  switch (process.env.REACT_APP_OLD) {
    case 'https://ooc-lms-app-test.geocode.tech':
      return ApplicationMode.DEV;
    case 'https://learn.unionepro.ru':
      return ApplicationMode.PROD;
    default:
      throw new Error('Wrong value of the environment variable!');
  }
};
