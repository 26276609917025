import { IROLE } from 'types/roles';

export const enum ApplicationMode {
  DEV,
  PROD
}

export type FeatureFlagRule = {
  users: IROLE.RoleObject[];
  modes?: ApplicationMode[];
};
