import { FC } from 'react';
import { ContentLayout, Header, IContentLayoutProps, IHeaderProps } from 'containers/_new';
import styles from './styles.module.scss';

type SiderContentLayoutProps = {
  header: IHeaderProps['children'];
  children: IContentLayoutProps['children'];
};

export const SiderContentLayout: FC<SiderContentLayoutProps> = ({ header, children }) => {
  return (
    <ContentLayout
      header={
        <Header level={3} className={styles.header}>
          {header}
        </Header>
      }
      headerSeparated
      gap='md'
      tile
    >
      {children}
    </ContentLayout>
  );
};
