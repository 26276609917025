import { ComponentProps, FC } from 'react';
import { Dropdown as AntDropdown } from 'antd';
import cn from 'classnames';
import { getPermittedDropdownItems } from './Dropdown.helper';
import { IDropdownProps } from './types';
import styles from './styles.module.scss';

const staticOverridableProps: ComponentProps<typeof AntDropdown> = {
  overlayClassName: styles.overlay
};

export const Dropdown: FC<IDropdownProps> = ({ className, children, menu, ...outerProps }) => {
  const overridableProps: ComponentProps<typeof AntDropdown> = {
    ...staticOverridableProps
  };

  const unoverridableProps: ComponentProps<typeof AntDropdown> = {
    menu: { ...menu, items: menu?.items && getPermittedDropdownItems(menu.items) },
    className: cn(styles.dropdown, className)
  };

  return (
    <AntDropdown {...overridableProps} {...outerProps} {...unoverridableProps}>
      {children}
    </AntDropdown>
  );
};
