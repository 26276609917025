import { ChangeEvent, FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Flex, Input, Upload } from 'antd';
import { UploadFile } from 'antd/lib/upload/interface';
import { Button } from 'common/ui/_new';
import { IFormFieldProps } from 'types/entities';

interface IFileInputProps {
  field: IFormFieldProps;
  readOnly?: boolean;
  accept?: string;
  input?: string;
  onInput?: (value: string) => void;
  onChange: (uploadFile: UploadFile) => void;
  loading?: boolean;
}

export const FileInput: FC<IFileInputProps> = ({ onChange, input, onInput, readOnly, accept = '*', loading }) => {
  const { t } = useTranslation('common', { keyPrefix: 'ui.file-input' });
  const [inputValue, setInputValue] = useState('');

  useEffect(() => {
    if (typeof input === 'string') {
      setInputValue(input);
    }
  }, [input]);

  const onInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
    onInput && onInput(e.target.value);
  };

  return (
    <Flex gap={20}>
      <Input accept={accept} readOnly={readOnly} value={inputValue} onChange={onInputChange} placeholder={t('placeholder')} />
      <Upload
        maxCount={1}
        showUploadList={false}
        beforeUpload={(file) => {
          onChange(file);
          setInputValue(file.name);
          return false;
        }}
        onChange={() => false}
      >
        <Button icon='upload' loading={loading}>
          {t('upload-button.label')}
        </Button>
      </Upload>
    </Flex>
  );
};
