import { ComponentPropsWithRef, forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Input as AntInput } from 'antd';
import cn from 'classnames';
import { ClearButton } from 'common/ui/_new';
import { Password } from './Password';
import { Search } from './Search';
import { TextArea } from './TextArea';
import { IInput, IInternalInput } from './types';
import styles from './styles.module.scss';

const InternalInput: IInternalInput = forwardRef(function InternalInput(props, ref) {
  const { t } = useTranslation('common', { keyPrefix: 'ui.input' });
  const { size, allowClear, ...restProps } = props;

  const innerProps: ComponentPropsWithRef<typeof AntInput> = {
    //* overridable:
    placeholder: t('placeholder'),
    //* outer:
    ...restProps,
    //* unoverridable:
    ref,
    size,
    allowClear: false,
    className: cn(styles.input, props.className)
  };

  if (!props.readOnly && allowClear) {
    return (
      <div className={styles.inputWrapperWithClearBtn}>
        <AntInput {...innerProps} />
        <ClearButton onInputChange={props.onChange} disabled={!props.value} size={size} />
      </div>
    );
  }

  return <AntInput {...innerProps} />;
});

export const Input: IInput = Object.assign(InternalInput, {
  // Group: AntInput.Group,
  Search,
  TextArea,
  Password
});

/*
todo: TextArea - стилизовать полосу-прокрутку
todo: исправить схлопывание Select без defaultValue (будучи в addon)
*/
