import { Id } from 'types/common';
import { QuestionTypeEnum } from 'types/entities';

export type EvaluateTypeText = string;

export enum EvaluateTypeEnum {
  NO_RATING,
  AUTO_CHECK,
  MANUAL_CHECK
}

// Объект получения страниц для упражнения.
export interface IExerciseSlide {
  id: Id;
  sequence: number;
  label: string;
  type: string;
  question: boolean;
  skills: boolean;
  hide: boolean;
  isHiddenAsResourse: boolean;
  evaluateType: EvaluateTypeEnum;
  supportedEvaluateTypes: { evaluateType: EvaluateTypeEnum; evaluateTypeText: EvaluateTypeText }[];
  questionType: QuestionTypeEnum;
}
