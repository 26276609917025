import { createApi } from '@reduxjs/toolkit/query/react';
import { getBaseQuery } from 'common/helpers';
import { ICoursesByCatalogFilters } from 'types/requests/guest/catalog.api';

export const catalogApi = createApi({
  reducerPath: 'catalogApi',
  baseQuery: getBaseQuery(),
  endpoints: ({ query }) => ({
    getCoursesByCatalogFilters: query<ICoursesByCatalogFilters.Response, ICoursesByCatalogFilters.Params>({
      query: ({ take, skip, body }) => ({
        method: 'post',
        url: `/api/catalog?take=${take}&skip=${skip}`,
        body
      }),
      keepUnusedDataFor: 5
    })
  })
});

export const { useGetCoursesByCatalogFiltersQuery, useLazyGetCoursesByCatalogFiltersQuery } = catalogApi;
