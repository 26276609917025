import { ComponentProps, FC } from 'react';
import { Empty as AntEmpty } from 'antd';
import cn from 'classnames';
import { NoData } from './assets';
import styles from './styles.module.scss';

type IEmptyProps = ComponentProps<typeof AntEmpty> & {
  size?: 'small' | 'default' | 'large';
};

export const Empty: FC<IEmptyProps> = (props) => {
  const { size = 'default', className, ...restProps } = props;

  const innerProps: ComponentProps<typeof AntEmpty> = {
    //* overridable:
    image: NoData,
    //* outer:
    ...restProps,
    //* unoverridable:
    className: cn(styles.empty, { [styles.small]: size === 'small', [styles.large]: size === 'large' }, className)
  };

  return <AntEmpty {...innerProps} />;
};
