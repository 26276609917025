import type { IEditWebSlide, ISaveWebSlide, IWebSlide } from 'types/requests/instructor/slides.slide_web-page.api';
import { slidesApi } from '../slides.api';

const slideWebApi = slidesApi.injectEndpoints({
  endpoints: ({ query, mutation }) => ({
    // API Создание страницы типа Web.
    saveWebSlide: mutation<ISaveWebSlide.Response, ISaveWebSlide.Params>({
      query: ({ exerciseId, body }) => ({
        url: `/api/instructors/Exercises/${exerciseId}/Pages/Web`,
        method: 'POST',
        body
      }),
      invalidatesTags: ['Slides', 'Web-page']
    }),
    // API информация страницы типа Web.
    getWebSlide: query<IWebSlide.Response, IWebSlide.Params>({
      query: ({ exerciseId, pageId }) => `/api/instructors/Exercises/${exerciseId}/Pages/${pageId}/Web`,
      providesTags: ['Web-page']
    }),
    // API Редактирование страницы типа Web.
    editWebSlide: mutation<IEditWebSlide.Response, IEditWebSlide.Params>({
      query: ({ exerciseId, pageId, body }) => ({
        url: `/api/instructors/Exercises/${exerciseId}/Pages/${pageId}/Web`,
        method: 'PUT',
        body
      }),
      invalidatesTags: ['Slides', 'Web-page']
    })
  })
});

export const { useEditWebSlideMutation, useGetWebSlideQuery, useSaveWebSlideMutation } = slideWebApi;
