import { createApi } from '@reduxjs/toolkit/query/react';
import { getBaseQuery } from 'common/helpers';
import { GetInstructorCoursesAndGroups, ITrainingsExercises, ITrainingsOptions } from 'types/requests/instructor/trainings.api';

export const instructorTrainingsApi = createApi({
  reducerPath: 'instructorTrainingsApi',
  baseQuery: getBaseQuery(),
  tagTypes: ['Training'],
  endpoints: ({ query }) => ({
    // 🔽 Список курсов для выпадающего списка - список курсов, которые доступны текущему преподу (в которые он включен как препод)
    getInstructorTrainings: query<ITrainingsOptions.Response, ITrainingsOptions.Params>({
      query: () => ({
        url: '/api/instructors/trainings'
      })
    }),
    // 🔽 Список курсов для выпадающего списка - полный список
    getAllInstructorTrainings: query<ITrainingsOptions.Response, ITrainingsOptions.Params>({
      query: () => 'api/instructors/trainings/all'
    }),
    // 📄 Список курсов для страницы отображения списка курсов.
    getInstructorCoursesAndGroups: query<GetInstructorCoursesAndGroups.Response, GetInstructorCoursesAndGroups.Params>({
      query: ({ dateFilter, search }) => {
        const params = new URLSearchParams();
        if (dateFilter) {
          params.append('dateFilter', String(dateFilter));
        }
        if (search) {
          params.append('search', search);
        }

        return {
          url: 'api/Instructors/Trainings/List',
          params
        };
      },
      providesTags: ['Training']
    }),
    getTrainingsExercises: query<ITrainingsExercises.Response, ITrainingsExercises.Params>({
      //Получение уроков и упражнений
      query: ({ courseId }) => ({
        url: `/api/instructors/trainings/${courseId}/progress-filter/exercises`
      })
    })
  })
});

export const {
  useGetInstructorTrainingsQuery,
  useGetAllInstructorTrainingsQuery,
  useGetInstructorCoursesAndGroupsQuery,
  usePrefetch,
  useGetTrainingsExercisesQuery
} = instructorTrainingsApi;
