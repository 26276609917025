import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ISelectProps, Select } from 'common/ui/_new';
import { useGetDictionaryQuery } from 'api/student/dictionary/dictionary.api';
import { sortByZone, timeZoneCodeByIdMap, timeZoneDictionaryId } from './helper';

export const TimeZonesDictionarySelect: FC<ISelectProps> = (props) => {
  const { t } = useTranslation('common', { keyPrefix: 'components.time-zones-select' });

  const getDictionaryQuery = useGetDictionaryQuery(timeZoneDictionaryId);

  const options: ISelectProps['options'] = useMemo(
    () =>
      getDictionaryQuery.data
        ?.map(({ id }) => {
          const timeZoneCode = timeZoneCodeByIdMap[id];
          const label = t(`options.${timeZoneCode}`);
          return { label, value: id };
        })
        .sort((a, b) => sortByZone(a.label, b.label)),
    [getDictionaryQuery.data, t]
  );

  return (
    <Select {...props} options={options} optionFilterProp='label' loading={getDictionaryQuery.isFetching || getDictionaryQuery.isLoading} />
  );
};

//! individual component for time zones dictionary is temporal
