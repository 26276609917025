import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import AntImgCrop, { ImgCropProps as AntImgCropProps } from 'antd-img-crop';
import cn from 'classnames';
import { ImgCropProps } from './types';
import styles from './styles.module.scss';

export const ImgCrop: FC<ImgCropProps> = ({ children, modalClassName, ...restProps }) => {
  const { t } = useTranslation('common', { keyPrefix: 'ui.img-crop' });

  const innerProps: Omit<AntImgCropProps, 'children'> = {
    //* overridable
    modalTitle: t('modal.title'),
    modalWidth: 1000,
    //* outer
    ...restProps,
    //* unoverridable
    modalClassName: cn(styles.imgCrop, modalClassName)
  };

  return <AntImgCrop {...innerProps}>{children}</AntImgCrop>;
};
