import { createApi } from '@reduxjs/toolkit/query/react';
import { getBaseQuery } from 'common/helpers';
import { ExportInstructorEstimates } from 'types/requests/instructor/reports.api';
import { instructorTaskQueueApi } from '../taskQueue/taskQueue.api';

export const reportsApi = createApi({
  reducerPath: 'reportsApi',
  baseQuery: getBaseQuery(),
  endpoints: ({ mutation }) => ({
    exportInstructorEstimates: mutation<ExportInstructorEstimates.Response, ExportInstructorEstimates.Params>({
      query: ({ courseId, body }) => ({
        url: `/api/instructors/trainings/${courseId}/instructorestimates/export`,
        method: 'POST',
        body
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        await queryFulfilled;
        dispatch(instructorTaskQueueApi.util.invalidateTags(['Tasks']));
      }
    })
  })
});

export const { useExportInstructorEstimatesMutation } = reportsApi;
