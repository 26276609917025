import { FC } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { QueryResponseBoundary } from 'containers/_new';
import { PdfViewer } from 'common/ui/_new';
import { useGetPdfSlideQuery } from 'api/instructor/slides/endpoints/slide_pdf.endpoint';
import { InstructorParams } from 'types/routes/instructor';

export const PdfPreviewModal: FC = () => {
  const { exerciseId = '' } = useParams<InstructorParams.Exercise>();
  const [searchParams] = useSearchParams();

  const pdfQuery = useGetPdfSlideQuery({ exerciseId, pageId: searchParams.get('slideId') || '' });

  return (
    <QueryResponseBoundary query={pdfQuery}>
      <PdfViewer url={pdfQuery.data?.address} />
    </QueryResponseBoundary>
  );
};
