import { ComponentProps, FC } from 'react';
import { Tooltip as AntTooltip } from 'antd';
import cn from 'classnames';
import { ITooltipProps } from './types';
import styles from './styles.module.scss';

export const Tooltip: FC<ITooltipProps> = (props) => {
  const { className, overlayClassName, ...restProps } = props;

  const innerProps: ComponentProps<typeof AntTooltip> = {
    //* overridable:
    //* outer:
    ...restProps,
    //* unoverridable:
    className: cn(styles.tooltip, className),
    overlayClassName: cn(styles.overlay, overlayClassName)
  };

  return <AntTooltip {...innerProps} />;
};
