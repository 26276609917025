import { theme, ThemeConfig } from 'antd';
import styleVar from 'common/styles/vars.scss';

export const enum ColorScheme {
  LIGHT,
  DARK
}

const TRANSPARENT = 'rgba(255, 255, 255, 0)';
const BOX_SHADOW_SECONDARY = {
  DARK: '4px 4px 15px 0px rgba(255, 255, 255, 0.07)'
};

export const themeConfigsMapByColorScheme: Record<ColorScheme, ThemeConfig> = {
  [ColorScheme.LIGHT]: {
    token: {
      colorPrimaryBg: styleVar.colorPrimary10,
      colorPrimaryHover: styleVar.colorPrimary100,
      colorPrimary: styleVar.colorPrimary,
      colorPrimaryActive: styleVar.colorPrimary300,
      colorInfo: styleVar.colorPrimary,
      colorError: styleVar.colorRed200,
      colorWarning: styleVar.colorYellow200,
      colorSuccess: styleVar.colorGreen200,
      colorLinkHover: styleVar.colorPrimary100,
      colorLinkActive: styleVar.colorPrimary300,
      colorTextBase: styleVar.colorBlack,
      colorText: styleVar.colorBlack,
      colorTextHeading: styleVar.colorBlack,
      colorTextSecondary: styleVar.colorGrey500,
      colorTextTertiary: styleVar.colorGrey500,
      colorTextQuaternary: styleVar.colorGrey500,
      colorBorder: styleVar.colorGrey300,
      colorBorderSecondary: styleVar.colorGrey200,
      colorBgContainerDisabled: styleVar.colorGrey100,
      colorTextDisabled: styleVar.colorGrey400
    },
    components: {
      Alert: {
        colorInfoBg: styleVar.colorPrimary10,
        colorInfo: styleVar.colorPrimary100,
        colorSuccessBg: styleVar.colorGreen10,
        colorSuccess: styleVar.colorGreen100,
        colorErrorBg: styleVar.colorRed10,
        colorError: styleVar.colorRed100,
        colorWarningBg: styleVar.colorYellow10,
        colorWarning: styleVar.colorYellow100,
        colorTextHeading: styleVar.colorBlack,
        colorText: styleVar.colorDarkGrey400
      },
      Avatar: {
        colorTextPlaceholder: styleVar.colorPrimary
      },
      Button: {
        colorBgContainerDisabled: styleVar.colorGrey300,
        colorTextDisabled: styleVar.colorGrey500
      },
      DatePicker: {
        activeBorderColor: styleVar.colorPrimary,
        cellHoverBg: styleVar.colorPrimary50,
        colorIcon: styleVar.colorDarkGrey300,
        colorIconHover: styleVar.colorPrimary,
        colorSplit: styleVar.colorGrey300,
        colorText: styleVar.colorDarkGrey400,
        colorTextHeading: styleVar.colorDarkGrey300,
        hoverBorderColor: styleVar.colorPrimary50
      },
      Dropdown: {
        colorText: styleVar.colorBlack,
        colorTextDisabled: styleVar.colorGrey500,
        controlItemBgHover: TRANSPARENT,
        controlItemBgActive: styleVar.colorPrimary10,
        controlItemBgActiveHover: styleVar.colorPrimary10
      },
      Form: {
        labelRequiredMarkColor: styleVar.colorRed,
        labelColor: styleVar.colorDarkGrey200
      },
      Input: {
        hoverBorderColor: styleVar.colorPrimary50,
        activeBorderColor: styleVar.colorPrimary
      },
      InputNumber: {
        hoverBorderColor: styleVar.colorPrimary50,
        activeBorderColor: styleVar.colorPrimary
      },
      Menu: {
        itemColor: styleVar.colorBlack,
        itemSelectedColor: styleVar.colorBlack,
        itemSelectedBg: styleVar.colorPrimary10,
        subMenuItemBg: TRANSPARENT,
        darkSubMenuItemBg: TRANSPARENT,
        darkItemBg: TRANSPARENT
      },
      Modal: {
        titleColor: styleVar.colorBlack
      },
      Notification: {
        colorBgTextActive: TRANSPARENT,
        colorBgTextHover: TRANSPARENT,
        colorIcon: styleVar.colorDarkGrey300,
        colorIconHover: styleVar.colorDarkGrey100,
        colorText: styleVar.colorDarkGrey400,
        colorTextHeading: styleVar.colorBlack
      },
      Pagination: {
        itemActiveBg: styleVar.colorPrimary10,
        colorBorder: styleVar.colorGrey300,
        colorText: styleVar.colorGrey500
      },
      Progress: {
        defaultColor: styleVar.colorAccentGreen100,
        remainingColor: styleVar.colorGrey300,
        circleTextColor: styleVar.colorAccentGreen100
      },
      Select: {
        colorBgContainer: styleVar.colorWhite,
        colorPrimaryHover: styleVar.colorPrimary50,
        colorTextQuaternary: styleVar.colorDarkGrey300,
        optionSelectedColor: styleVar.colorPrimary
      },
      Switch: {
        colorTextQuaternary: styleVar.colorGrey400,
        colorTextTertiary: styleVar.colorGrey300,
        handleShadow: '0 .125em .2em 0 rgba(0, 35, 11, 0.2)'
      },
      Table: {
        borderColor: styleVar.colorGrey300,
        headerSplitColor: styleVar.colorGrey300,
        headerBg: styleVar.colorGrey100,
        headerColor: styleVar.colorDarkGrey400,
        colorText: styleVar.colorDarkGrey400
      },
      Tabs: {
        inkBarColor: styleVar.colorPrimary,
        itemActiveColor: styleVar.colorPrimary300,
        itemColor: styleVar.colorDarkGrey400,
        itemHoverColor: styleVar.colorPrimary,
        itemSelectedColor: styleVar.colorPrimary,
        colorBorderSecondary: styleVar.colorGrey300
      },
      Tooltip: {
        colorBgSpotlight: styleVar.colorDarkGrey400,
        colorTextLightSolid: styleVar.colorWhite
      }
    }
  },
  [ColorScheme.DARK]: {
    algorithm: theme.darkAlgorithm,
    token: {},
    components: {
      DatePicker: {
        colorBgContainer: styleVar.colorDarkGrey500,
        colorBgContainerDisabled: styleVar.colorBlack,
        colorBorder: styleVar.colorDarkGrey300,
        colorText: styleVar.colorWhite
      },
      Dropdown: {
        boxShadowSecondary: BOX_SHADOW_SECONDARY.DARK,
        colorBgContainer: styleVar.colorDarkGrey500,
        colorBgContainerDisabled: styleVar.colorBlack,
        colorBgElevated: styleVar.colorDarkGrey500,
        colorText: styleVar.colorWhite,
        colorTextBase: styleVar.colorWhite,
        controlItemBgHover: TRANSPARENT
      },
      Input: {
        colorBgContainer: styleVar.colorDarkGrey500,
        colorBgContainerDisabled: styleVar.colorBlack,
        colorBorder: styleVar.colorDarkGrey300,
        colorText: styleVar.colorWhite
      },
      InputNumber: {
        colorBgContainer: styleVar.colorDarkGrey500,
        colorBgContainerDisabled: styleVar.colorBlack,
        colorBorder: styleVar.colorDarkGrey300,
        colorText: styleVar.colorWhite
      },
      Pagination: {
        itemActiveBg: styleVar.colorPrimary10,
        colorText: styleVar.colorGrey500
      },
      Popover: {
        boxShadowSecondary: BOX_SHADOW_SECONDARY.DARK,
        colorBgElevated: styleVar.colorDarkGrey500
      },
      Select: {
        colorBgContainer: styleVar.colorDarkGrey500,
        colorBgContainerDisabled: styleVar.colorBlack,
        colorBgElevated: styleVar.colorDarkGrey500,
        colorBorder: styleVar.colorDarkGrey300,
        colorText: styleVar.colorWhite
      },
      Tabs: {
        boxShadowSecondary: BOX_SHADOW_SECONDARY.DARK,
        colorBgContainer: styleVar.colorDarkGrey500,
        colorBorderSecondary: styleVar.colorDarkGrey300,
        inkBarColor: styleVar.colorPrimary,
        itemActiveColor: styleVar.colorPrimary100,
        itemHoverColor: styleVar.colorPrimary,
        itemSelectedColor: styleVar.colorPrimary
      },
      Typography: {
        colorTextHeading: styleVar.colorWhite
      }
    }
  }
};
