import { UseTranslationResponse } from 'react-i18next';
import { IResultProps } from 'common/ui/_new';
import { UserRoleUnionType } from 'types/roles/userRoles';
import { InDevelopment, NoAccess, NoConnection as Error, NoData } from './assets';
import { IResultPageProps } from './types';

export const getPropsByStatus = (
  status: IResultPageProps['status'],
  role: UserRoleUnionType,
  t: UseTranslationResponse<'common', 'components.result-page'>['t']
): IResultProps => {
  switch (status) {
    case 403:
    case 'no-access':
      return {
        icon: <NoAccess />,
        ...(role === 'Instructors'
          ? {
              title: t('no-access-instructor.title'),
              subTitle: t('no-access-instructor.subtitle')
            }
          : {
              title: t('no-access.title'),
              subTitle: t('no-access.subtitle')
            })
      };
    case 'no-data':
      return {
        icon: <NoData />,
        title: t('no-data.title')
      };
    case 'in-development':
      return {
        icon: <InDevelopment />,
        title: t('in-development.title')
      };
    case 404:
    case 'not-found':
      return {
        icon: <Error />,
        title: 404,
        subTitle: t('not-found.sub-title')
      };
    default:
      return {
        icon: <Error />
      };
  }
};
