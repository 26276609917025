import { UseTranslationResponse } from 'react-i18next';
import { MetaDataListItemProps } from 'common/components';
import { formatDateTimeRangeByLocale } from 'common/hooks';
import { BeginDate, EndDate, MetaDataProps } from './types';

const getAvailabilityDatesItem = (
  isOpenDatesRange: boolean,
  beginDate: BeginDate | undefined,
  endDate: EndDate | undefined,
  {
    t,
    language
  }: {
    t: UseTranslationResponse<'common', 'components.course-description.meta'>['t'];
    language: string;
  }
): MetaDataListItemProps | undefined => {
  if (!beginDate && !endDate) {
    return;
  }

  const label = t('availability-dates.label');

  if (isOpenDatesRange) {
    return {
      label,
      children: t('availability-dates.always-available')
    };
  }

  return {
    label,
    children: formatDateTimeRangeByLocale(language, beginDate, endDate)
  };
};

const getDatesItem = (
  beginDate: BeginDate,
  endDate: EndDate,
  {
    t,
    language
  }: {
    t: UseTranslationResponse<'common', 'components.course-description.meta'>['t'];
    language: string;
  }
): MetaDataListItemProps | undefined => {
  if (beginDate && endDate) {
    return {
      label: t('dates.range'),
      children: formatDateTimeRangeByLocale(language, beginDate, endDate)
    };
  }
  if (beginDate) {
    return {
      label: t('dates.begin'),
      children: formatDateTimeRangeByLocale(language, beginDate)
    };
  }
  if (endDate) {
    return {
      label: t('dates.end'),
      children: formatDateTimeRangeByLocale(language, endDate)
    };
  }
};

export const getMetaDataItems = (
  {
    group,
    begin,
    end,
    alwaysAvailableByDates,
    open,
    close
  }: Pick<MetaDataProps, 'group' | 'begin' | 'end' | 'alwaysAvailableByDates' | 'open' | 'close'>,
  { t, i18n: { language } }: Pick<UseTranslationResponse<'common', 'components.course-description.meta'>, 't' | 'i18n'>
): MetaDataListItemProps[] => {
  const items: MetaDataListItemProps[] = [];

  if (group) {
    items.push({
      label: t('group'),
      children: group
    });
  }

  const datesItem = getDatesItem(begin, end, { t, language });
  if (datesItem) {
    items.push(datesItem);
  }

  const availabilityDatesItem = getAvailabilityDatesItem(Boolean(alwaysAvailableByDates), open, close, { t, language });
  if (availabilityDatesItem) {
    items.push(availabilityDatesItem);
  }

  return items;
};
