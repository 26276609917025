import { useCallback, useState } from 'react';
import { TablePaginationConfig } from 'antd';

interface IAdvancedTablePaginationConfig extends TablePaginationConfig {
  startIndex: number;
}

type IUpdatePaginationFunction = (current?: number, pageSize?: number, total?: number) => void;

type IUsePaginationFunction = (props?: IAdvancedTablePaginationConfig) => [IAdvancedTablePaginationConfig, IUpdatePaginationFunction];

export const usePagination: IUsePaginationFunction = (
  props = {
    current: 1,
    pageSize: 20,
    total: 0,
    startIndex: 0
  }
) => {
  const [pagination, setPagination] = useState<IAdvancedTablePaginationConfig>(props);

  const updatePagination: IUpdatePaginationFunction = useCallback((current = 1, pageSize, total) => {
    setPagination((oldValue) => ({
      ...oldValue,
      pageSize: pageSize || oldValue.pageSize,
      current,
      total: total ?? oldValue.total,
      startIndex: ((current || 1) - 1) * (pageSize || oldValue.pageSize || 10)
    }));
  }, []);

  return [pagination, updatePagination];
};

//! Требуется пересмотреть логику хука.
//! 1) При несовпадении pageSize на странице во время первого рендера происходит двойной вызов метода updatePagination.
//! 2) Использовать один и тот же объект для инициализации хука и для параметров updatePagination
