import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import type { Dayjs } from 'dayjs';
import { QueryResponseBoundary } from 'containers/_new';
import { getLocalizedDate } from 'common/helpers';
import { useGetEventsByDateQuery } from 'api/student/calendar/calendar.api';
import { Event } from './Event';
import styles from './styles.module.scss';

type EventsProps = {
  chosenDate: Dayjs;
};

export const Events: FC<EventsProps> = ({ chosenDate }) => {
  const { t, i18n } = useTranslation('common', { keyPrefix: 'components.events-calendar' });

  const eventsByDate = useGetEventsByDateQuery({ chosenDate: chosenDate.format('YYYY-MM-DD') });
  const formattedDate = getLocalizedDate(chosenDate, 'LL', i18n.language);

  return (
    <div className={styles.eventsWrapper}>
      <span className={styles.today}>{formattedDate}</span>
      <div className={styles.events}>
        <QueryResponseBoundary query={eventsByDate} empty={{ description: t('events.empty.description') }}>
          {eventsByDate.data?.map((event, i) => (
            <Event key={i} event={event} />
          ))}
        </QueryResponseBoundary>
      </div>
    </div>
  );
};
