import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { Icon, Tooltip } from 'common/ui/_new';
import { CourseStatusEnum } from 'types/entities';
import { getPropsByStatusMap } from './helper';
import { ExerciseStatusIconProps } from './types';
import styles from './styles.module.scss';

export const ExerciseStatusIcon: FC<ExerciseStatusIconProps> = ({ status }) => {
  const { t } = useTranslation('Students', { keyPrefix: 'pages.course.lessons.lesson.exercises.exercise.status' });

  if (status === undefined || status === null || status === CourseStatusEnum.NOT_STARTED) {
    return null;
  }

  const { tooltipProps, iconProps } = getPropsByStatusMap(status, t) ?? {};

  return (
    <Tooltip {...tooltipProps}>
      <Icon {...iconProps} className={cn(styles.icon, iconProps?.className)} />
    </Tooltip>
  );
};
