import { FC } from 'react';
import { Icon, IIconType, ITitleProps, Title } from 'common/ui/_new';
import styles from './styles.module.scss';

export type HeaderProps = {
  title?: string;
  subtitle?: ITitleProps['children'];
  icon?: IIconType;
};

export const Header: FC<HeaderProps> = ({ title, subtitle, icon }) => {
  return (
    <div className={styles.header}>
      {icon && <Icon type={icon} className={styles.icon} />}
      {title && (
        <Title level={3} className={styles.title}>
          {title}
        </Title>
      )}
      {subtitle && (
        <div className={styles.subtitleWrapper}>
          <Title level={5} className={styles.subtitle}>
            {subtitle}
          </Title>
        </div>
      )}
    </div>
  );
};
