import { FC, useState } from 'react';
import { Layout } from 'containers';
import { IResultPageProps, ResultPage } from 'common/components/_new';
import { PageErrorHandlerContext_old } from './helper';
import { PageError_old, PageErrorBoundaryProps_old } from './types';

/**
 * @deprecated
 */
export const PageErrorBoundary_old: FC<PageErrorBoundaryProps_old> = ({ showBreadcrumbs = true, children, responseConfig }) => {
  const [pageError, setPageError] = useState<PageError_old>();

  if (pageError) {
    const { status, ...restPageErrorProps } = pageError;

    const resultProps: IResultPageProps = {
      ...(status in responseConfig && responseConfig[status]),
      ...restPageErrorProps,
      status
    };

    return (
      <Layout showBreadcrumbs={showBreadcrumbs} centered style={{ justifyContent: 'center' }}>
        <ResultPage {...resultProps} />
      </Layout>
    );
  }

  return <PageErrorHandlerContext_old.Provider value={{ setPageError }}>{children}</PageErrorHandlerContext_old.Provider>;
};
