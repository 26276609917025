import { createApi } from '@reduxjs/toolkit/query/react';
import { getBaseQuery } from 'common/helpers';
import { IGetHierarchyItem, IGetHierarchyValues } from 'types/requests/student/hierarchies.api';

export const hierarchiesApi = createApi({
  reducerPath: 'hierarchiesApi',
  baseQuery: getBaseQuery(),
  endpoints: ({ query }) => ({
    getHierarchy: query<IGetHierarchyItem.Response, IGetHierarchyItem.Params>({
      query: ({ id, node }) => ({
        url: `/api/hierarchies/${id}/nodes/children`,
        params: { node }
      })
    }),
    getHierarchyValues: query<IGetHierarchyValues.Response, IGetHierarchyValues.Params>({
      query: ({ id, value }) => ({
        url: `/api/hierarchies/${id}/last-level-value/${value}/tree`
      })
    })
  })
});

export const { useGetHierarchyQuery, useLazyGetHierarchyQuery, useGetHierarchyValuesQuery, useLazyGetHierarchyValuesQuery } =
  hierarchiesApi;
