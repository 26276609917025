import { FC } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Flex } from 'antd';
import { Empty, Spin } from 'common/ui/_new';
import { FileViewer } from 'common/ui/_new/SingleFileUpload/FileViewer';
import { useGetCredentialFileQuery } from 'api/instructor/credential/credential.api';
import { CertificateId, CourseId } from 'types/entities';
import { InstructorParams } from 'types/routes/instructor';

type CertificateViewProps = {
  courseIdProp?: CourseId;
  certificateId?: CertificateId;
};

export const CertificateView: FC<CertificateViewProps> = ({ courseIdProp = '', certificateId = '' }) => {
  const { t } = useTranslation('common');
  const { courseId = courseIdProp } = useParams<InstructorParams.Course>();
  const certificateQuery = useGetCredentialFileQuery({ courseId, certificateId });
  if (certificateQuery.isLoading) {
    return (
      <Flex justify='center'>
        <Spin />
      </Flex>
    );
  }
  return (
    <>
      {certificateQuery.error && 'status' in certificateQuery.error && certificateQuery.error?.status === 404 ? (
        <Empty description={t('components.certificate-view.error')} />
      ) : (
        <FileViewer name='Файл' src={certificateQuery.data?.link || ''} />
      )}
    </>
  );
};
