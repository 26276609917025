import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Flex } from 'antd';
import { Button, DocViewer, PdfViewer, Text } from 'common/ui/_new';
import { extractFileType } from './helper';
import { Image } from './Image';

type FileViewerProps = {
  src: string;
  name: string;
  mimeType?: string;
};

export const FileViewer: FC<FileViewerProps> = ({ src, name, mimeType }) => {
  const { t } = useTranslation('common', { keyPrefix: 'ui.single-file-upload.preview-modal.file-viewer' });

  if (mimeType) {
    const [type, subtype] = mimeType.split('/');
    if (type === 'image') {
      return <Image src={src} />;
    }
    if (type === 'application') {
      if (subtype === 'pdf') {
        return <PdfViewer url={src} className='fixed-modal-height' />;
      }
    }
  }

  const type = extractFileType(src);
  if (~['jpg', 'jpeg', 'png', 'bmp'].indexOf(type)) {
    return <Image src={src} />;
  }
  if (type === 'pdf') {
    return <PdfViewer url={src} className='fixed-modal-height' />;
  }
  if (type === 'doc' || type === 'docx') {
    return <DocViewer url={src} className='fixed-modal-height' style={{ height: undefined }} />;
  }

  return (
    <Flex gap='16px' align='center' wrap='wrap' justify='center'>
      <Text>{t('unavailable.title')}</Text>
      <Button href={src} download>
        {t('unavailable.download-button.label', { name })}
      </Button>
    </Flex>
  );
};
