import { FC } from 'react';
import cn from 'classnames';
import { Tooltip } from 'common/ui/_new';
import { AbbrProps, HTMLAbbrProps } from './types';
import styles from './styles.module.scss';

export const Abbr: FC<AbbrProps> = (props) => {
  const { title, children, className, ...restProps } = props;

  const innerProps: HTMLAbbrProps = {
    //* overridable
    //* outer
    ...restProps,
    //* unoverridable
    className: cn(styles.abbr, className)
  };

  return (
    <Tooltip title={title}>
      <abbr {...innerProps}>{children}</abbr>
    </Tooltip>
  );
};
