import { FC } from 'react';
import cn from 'classnames';
import { ITitleProps, Title } from 'common/ui/_new';
import { isPropString } from './helper';
import styles from './styles.module.scss';

export const PageHeadingTitle: FC<ITitleProps> = ({ className, children }) => {
  if (isPropString(children)) {
    return <Title className={cn(styles.pageHeadingTitle, className)}>{children}</Title>;
  }

  return <div className={cn(styles.pageHeadingTitle, className)}>{children}</div>;
};
