import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Avatar, Button, IButtonProps, ImgCrop, RcFile, Spin, Upload, useNotification } from 'common/ui/_new';
import { useAppSelector } from 'common/hooks';
import { useRemoveProfileImageMutation, useSetProfileImageMutation } from 'api/student/profile/profile.api';
import { profileSelectors } from 'api/student/profile/profile.selectors';
import styles from './styles.module.scss';

const defaultAvatar = '/public/images/default-avatar.png';
const allowedFileTypes = ['image/png', 'image/jpeg'];

export const AvatarUpload: FC = () => {
  const { t } = useTranslation('common', { keyPrefix: 'ui.avatar-upload' });

  const [loading, setLoading] = useState<boolean>(false);

  const { picture } = useAppSelector(profileSelectors.profile);

  const notify = useNotification();

  const [setProfileImage, setProfileImageMutation] = useSetProfileImageMutation();
  const onAvatarUpload = (file: RcFile) => {
    const form = new FormData();
    form.append('file', file);
    setProfileImage(form);
    return '';
  };
  useEffect(() => {
    if (setProfileImageMutation.isError) {
      setLoading(false);
      const { error } = setProfileImageMutation;
      if (error) {
        if ('status' in error && error.status == 400) {
          if ('data' in error && !!error.data && typeof error.data === 'object') {
            if ('ErrorCodeMessage' in error.data && Array.isArray(error.data.ErrorCodeMessage)) {
              notify.error({ description: error.data.ErrorCodeMessage.join() });
              return;
            }
          }
        }

        if ('status' in error && error.status == 500) {
          notify.error({ description: t('on-response.error.500') });
          return;
        }

        notify.error({ description: t('on-response.error.default') });
        return;
      }
    }

    if (setProfileImageMutation.isSuccess) {
      setLoading(false);
      notify.success();
      return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setProfileImageMutation]);

  const [removeProfileImage, removeProfileImageMutation] = useRemoveProfileImageMutation();
  const onAvatarDelete: IButtonProps['onClick'] = () => {
    removeProfileImage();
  };
  useEffect(() => {
    if (removeProfileImageMutation.isError) {
      setLoading(false);
      const { error } = removeProfileImageMutation;
      if (error) {
        if ('status' in error && error.status == 500) {
          notify.error({ description: t('on-response.error.500') });
          return;
        }

        notify.error({ description: t('on-response.error.default') });
        return;
      }
    }

    if (removeProfileImageMutation.isSuccess) {
      setLoading(false);
      notify.success();
      return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [removeProfileImageMutation]);

  return (
    <Spin spinning={loading || setProfileImageMutation.isLoading || removeProfileImageMutation.isLoading}>
      <div className={styles.avatarUpload}>
        <Avatar src={picture} icon='camera' size={{ xs: 120, sm: 136, md: 152, lg: 168, xl: 184, xxl: 200 }} className={styles.avatar} />
        <div className={styles.footer}>
          {picture && picture !== defaultAvatar ? (
            <Button onClick={onAvatarDelete} type='link' icon='delete' danger>
              {t('delete-button.label')}
            </Button>
          ) : (
            <>
              <ImgCrop
                cropShape='round'
                beforeCrop={() => {
                  setLoading(true);
                  return true;
                }}
                onModalCancel={() => {
                  setLoading(false);
                }}
                onModalOk={() => {
                  setLoading(false);
                }}
              >
                <Upload accept={allowedFileTypes.join()} maxCount={1} showUploadList={false} action={onAvatarUpload}>
                  <Button type='link' icon='upload'>
                    {t('upload-button.label')}
                  </Button>
                </Upload>
              </ImgCrop>
              <span className={styles.help}>{t('help.label')}</span>
            </>
          )}
        </div>
      </div>
    </Spin>
  );
};

// todo: display server response errors
