import { createApi } from '@reduxjs/toolkit/query/react';
import { getBaseQuery } from 'common/helpers';
import { IDictionary } from 'types/requests/student/dictionary.api';

export const dictionaryApi = createApi({
  reducerPath: 'dictionaryApi',
  baseQuery: getBaseQuery(),
  endpoints: ({ query }) => ({
    getDictionary: query<IDictionary.Response, IDictionary.Params>({
      query: (id) => `/api/dictionaries/${id}`
    })
  })
});

export const { useGetDictionaryQuery, useLazyGetDictionaryQuery } = dictionaryApi;
