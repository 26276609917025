import { ComponentProps } from 'react';
import { Checkbox as AntCheckbox } from 'antd';
import cn from 'classnames';
import { ICheckbox, IInternalCheckbox } from './types';
import styles from './styles.module.scss';

const InternalCheckbox: IInternalCheckbox = (props) => {
  const { size = 'middle', className, children, ...restProps } = props;

  const innerProps: ComponentProps<typeof AntCheckbox> = {
    //* overridable:
    //* outer:
    ...restProps,
    //* unoverridable:
    className: cn(styles.checkbox, { [styles.checkbox_small]: size === 'small' }, className)
  };

  return <AntCheckbox {...innerProps}>{children}</AntCheckbox>;
};

export const Checkbox: ICheckbox = Object.assign(InternalCheckbox, {
  Group: AntCheckbox.Group
});

/*
todo: свойство danger
*/
