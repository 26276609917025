import { createApi } from '@reduxjs/toolkit/query/react';
import { getBaseQuery, isOpenDatesRange } from 'common/helpers';
import { GetCoursePreview, GetCoursePreviewByInviteCode, JoinCourse } from 'types/requests/guest/inivitations.api';

export const invitationsApi = createApi({
  reducerPath: 'invitationsApi',
  baseQuery: getBaseQuery(),
  tagTypes: ['Apply'],
  endpoints: ({ query, mutation }) => ({
    // Получить обучение по коду приглашения
    getCoursePreviewByInviteCode: query<GetCoursePreviewByInviteCode.TransformedResponse, GetCoursePreviewByInviteCode.Params>({
      query: ({ inviteId }) => ({
        url: `/api/Invite/${inviteId}/View`
      }),
      transformResponse: (data: GetCoursePreviewByInviteCode.Response): GetCoursePreviewByInviteCode.TransformedResponse => {
        if (isOpenDatesRange(data.open, data.close)) {
          return {
            ...data,
            alwaysAvailableByDates: true
          };
        }
        return data;
      },
      providesTags: ['Apply']
    }),
    // Получить обучение для приглашения по id
    getCoursePreview: query<GetCoursePreview.TransformedResponse, GetCoursePreview.Params>({
      query: ({ courseId }) => ({
        url: `/api/Invite/Trainings/${courseId}/View`
      }),
      transformResponse: (data: GetCoursePreview.Response): GetCoursePreview.TransformedResponse => {
        if (isOpenDatesRange(data.open, data.close)) {
          return {
            ...data,
            alwaysAvailableByDates: true
          };
        }
        return data;
      },
      providesTags: ['Apply']
    }),
    // Присоединение к курсу
    joinCourse: mutation<JoinCourse.Response, JoinCourse.Params>({
      query: ({ courseId }) => ({
        method: 'POST',
        url: `/api/Invite/Trainings/${courseId}/Join`
      }),
      invalidatesTags: ['Apply']
    })
  })
});
export const { useGetCoursePreviewByInviteCodeQuery, useGetCoursePreviewQuery, useJoinCourseMutation } = invitationsApi;
