import { createApi } from '@reduxjs/toolkit/query/react';
import { getBaseQuery } from 'common/helpers';
import { GetRefinedAttributes } from 'types/requests/student/refine.api';

export const refineApi = createApi({
  reducerPath: 'refineApi',
  baseQuery: getBaseQuery(),
  endpoints: ({ query }) => ({
    getRefinedAttributes: query<GetRefinedAttributes.Response, GetRefinedAttributes.Params>({
      query: () => `/api/refine/attributes`
    })
  })
});

export const { useGetRefinedAttributesQuery } = refineApi;
