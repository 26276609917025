import { useEffect, useState } from 'react';

type UseLocalStorageOptions = {
  useStorage: 'localStorage' | 'sessionStorage';
};

const methods = {
  localStorage: {
    getItem: (id: string) => localStorage.getItem(id),
    setItem: (id: string, value: string) => localStorage.setItem(id, value),
    removeItem: (id: string) => localStorage.removeItem(id)
  },
  sessionStorage: {
    getItem: (id: string) => sessionStorage.getItem(id),
    setItem: (id: string, value: string) => sessionStorage.setItem(id, value),
    removeItem: (id: string) => sessionStorage.removeItem(id)
  }
};

function useBrowserStorage<T>(
  id: string,
  { useStorage, initialValue }: UseLocalStorageOptions & { initialValue?: T } = { useStorage: 'localStorage' }
): [T, React.Dispatch<React.SetStateAction<T>>] {
  const { getItem, setItem, removeItem } = methods[useStorage];
  const [value, setValue] = useState<T>(() => {
    const rawData = getItem(id);
    if (!rawData) {
      return initialValue;
    }
    try {
      return JSON.parse(rawData);
    } catch {
      return rawData;
    }
  });

  useEffect(() => {
    if (value === undefined) {
      return removeItem(id);
    }
    setItem(id, JSON.stringify(value));
  }, [id, removeItem, setItem, value]);

  return [value, setValue];
}

export { useBrowserStorage };
