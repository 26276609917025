import { FC, useContext } from 'react';
import { Button, IButtonProps } from 'common/ui/_new';
import { MobileDrawerContext } from '../helper';

type ShowButton = {
  icon?: IButtonProps['icon'];
};

export const ShowButton: FC<ShowButton> = ({ icon = 'layout' }) => {
  const { setIsMobileDrawerOpen } = useContext(MobileDrawerContext);

  return <Button onClick={() => setIsMobileDrawerOpen(true)} type='stroke-primary-square' icon={icon} />;
};
