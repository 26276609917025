import { Icon } from 'common/ui/_new';
import { IGetItemRender } from './types';

export const getItemRender: IGetItemRender = (page, type, originalElement) => {
  if (type === 'prev') {
    return <Icon type='previous' />;
  }

  if (type === 'next') {
    return <Icon type='next' />;
  }

  return originalElement;
};
