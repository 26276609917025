import { ComponentType, FC, PropsWithChildren, useState } from 'react';
import { PageErrorHandlerContext } from './helper';
import { PageErrorNode } from './types';

export const PageErrorBoundary: FC<PropsWithChildren> = ({ children }) => {
  const [pageErrorNode, setPageErrorNode] = useState<PageErrorNode>();
  return <PageErrorHandlerContext.Provider value={{ setPageErrorNode }}>{pageErrorNode ?? children}</PageErrorHandlerContext.Provider>;
};

export function withPageErrorBoundary<T extends JSX.IntrinsicAttributes>(WrappedComponent: ComponentType<T>) {
  const displayName = WrappedComponent.displayName || WrappedComponent.name || 'Component';

  const ComponentWithPageErrorBoundary = (props: T) => {
    return (
      <PageErrorBoundary>
        <WrappedComponent {...props} />
      </PageErrorBoundary>
    );
  };

  ComponentWithPageErrorBoundary.displayName = `withPageErrorBoundary(${displayName})`;

  return ComponentWithPageErrorBoundary;
}
