import { DateTime, Id } from 'types/common';
import { CourseGroupId, CourseId, ProgramId } from 'types/entities';
import { ICourse } from './';

//*	CQRS.Trainings.GetTrainingsAndSets.EducationCategoryItemType
export enum EducationCategoryItemType {
  UNKNOWN = 0,
  COURSE = 1,
  COURSE_GROUP = 2
}

//* CQRS.Sets.GetContentBySet.FilteredTrainingsSetsResult
// Содержимое группы курсов
export type FilteredTrainingsSetsResult<T = EducationCategoryItemType> = {
  type: T;
  label?: string;
  begin?: DateTime;
  end?: DateTime;
  picture?: string;
  order?: string;
} & (T extends EducationCategoryItemType.COURSE
  ? {
      id: CourseId;
      courseId?: ProgramId;
      group?: CourseGroupId;
    }
  : T extends EducationCategoryItemType.COURSE_GROUP
  ? {
      id: CourseGroupId;
      description?: string;
    }
  : unknown);

//* CQRS.Sets.GetContentBySet.GetContentBySetIdQueryResult
export type GetContentBySetIdQueryResult = {
  id: CourseGroupId;
  label?: string;
  description?: string;
  picture?: string;
  begin?: DateTime;
  end?: DateTime;
  data?: FilteredTrainingsSetsResult[];
};

//* CQRS.Trainings.GetTrainingsAndSets.TrainingsSetsResult
export type TrainingsSetsResult<T = EducationCategoryItemType> = T extends EducationCategoryItemType.COURSE
  ? {
      type: EducationCategoryItemType.COURSE;
      id: CourseId;
      label?: string;
      begin?: DateTime;
      end?: DateTime;
      picture?: string;
      courseId?: ProgramId;
      group?: CourseGroupId;
    }
  : T extends EducationCategoryItemType.COURSE_GROUP
  ? {
      type: EducationCategoryItemType.COURSE_GROUP;
      id: CourseGroupId;
      label?: string;
      begin?: DateTime;
      end?: DateTime;
      picture?: string;
      description?: string;
    }
  : {
      type: EducationCategoryItemType.UNKNOWN;
    };

//* CQRS.Trainings.GetTrainingsAndSets.GetTrainingsAndSetsQueryResult
// Список курсов и программ обучения
export type GetTrainingsAndSetsQueryResult = {
  data?: TrainingsSetsResult[];
  dataWithEmptyDates?: TrainingsSetsResult[];
};

interface ICourseGroupPicture {
  picture: string;
  pictureId: string;
}

export interface ICourseGroup {
  id: CourseGroupId;
  label: string;
  begin?: DateTime;
  end?: DateTime;
  description?: string;
}

export interface ICourseGroupOption extends Pick<ICourseGroup, 'id' | 'label'> {
  code?: string;
  data?: Record<string, string>;
}

export interface ICourseGroupCreateProps extends Omit<ICourseGroup, 'id'> {
  expulsionId?: Id;
  maxModulesAmountCalc?: number; // int32, [0, 1000]
  pictureFile?: File; // binary
}

export type IChildCourseGroupCreateProps = Omit<ICourseGroupCreateProps, 'begin' | 'end' | 'expulsionId'>;

export interface ICourseGroupEditProps extends ICourseGroupCreateProps {
  fileReset?: boolean;
  parentId?: ICourseGroup['id'];
}

export interface ICourseGroupInfo extends ICourseGroupEditProps, ICourseGroupPicture {
  operationId: Id;
  isParent: boolean;
  hasCourse: boolean;
  icon?: string;
  file?: string; // binary
  parentSet: ICourseGroup['id'];
}

export type ICourseGroupForParentOption = Pick<ICourseGroup, 'id' | 'label'>;

export interface ICourseGroupHierarchy {
  id: Id;
  parent?: ICourseGroup['id'];
  text: ICourseGroup['label'] | ICourse['label'];
  state: unknown;
  nodes?: ICourseGroupHierarchy[];
}

type OrderId = Id;

type ReorderChild<T = EducationCategoryItemType> = (T extends EducationCategoryItemType.COURSE
  ? Pick<FilteredTrainingsSetsResult<EducationCategoryItemType.COURSE>, 'type' | 'id' | 'group' | 'label' | 'begin' | 'end' | 'order'>
  : T extends EducationCategoryItemType.COURSE_GROUP
  ? Pick<FilteredTrainingsSetsResult<EducationCategoryItemType.COURSE_GROUP>, 'type' | 'id' | 'label' | 'order'>
  : never) & {
  orderId: OrderId;
  keywords?: string;
};

export type ICourseGroupReorderList = {
  id: ICourseGroup['id'];
  label: ICourseGroup['label'];
  description: ICourseGroup['description'];
  picture: ICourseGroupPicture['picture'];
  current: ReorderChild[];
};
