import { DetailedHTMLProps, FC, HTMLAttributes, PropsWithChildren } from 'react';
import styles from './styles.module.scss';

export type ItemProps = PropsWithChildren & {
  label: DetailedHTMLProps<HTMLAttributes<HTMLSpanElement>, HTMLSpanElement>['children'];
};

export const Item: FC<ItemProps> = ({ label, children }) => {
  return (
    <div className={styles.item}>
      <span className={styles.label}>{label}:</span> {children}
    </div>
  );
};
