import { FC, Fragment } from 'react';
import { Divider, Empty } from 'common/ui/_new';
import { Exercise } from './Exercise';
import { ExercisesProps } from './types';
import styles from './styles.module.scss';

export const Exercises: FC<ExercisesProps> = ({ items }) => {
  if (!items?.length) {
    return <Empty />;
  }

  return (
    <div className={styles.exercises}>
      {items.map(({ id, ...restExerciseProps }) => (
        <Fragment key={id}>
          <Exercise {...restExerciseProps} />
          <Divider className={styles.divider} />
        </Fragment>
      ))}
    </div>
  );
};
