import { ComponentProps, FC } from 'react';
import { Alert as AntAlert } from 'antd';
import cn from 'classnames';
import { getIconByType, getPropsByType } from './helper';
import { IAlertProps } from './types';
import styles from './styles.module.scss';

export const Alert: FC<IAlertProps> = ({ type = 'default', message, icon, className, ...restProps }) => {
  const { innerType, innerTypeClassName } = getPropsByType(type);

  const innerProps: ComponentProps<typeof AntAlert> = {
    //* overridable:
    showIcon: true,
    //* outer:
    ...restProps,
    //* unoverridable:
    type: innerType,
    message,
    icon: getIconByType({ type: innerType, icon }),
    className: cn(styles.alert, innerTypeClassName, className)
  };

  return (
    <div className={styles.container}>
      <AntAlert {...innerProps} />
    </div>
  );
};
