import { Id } from 'types/common';
import { AttributesField, AttributeTypeEnum } from 'types/entities';

//* Lms.UseCases.Interfaces.Instructors.ChainAttributes.Trainings.UpdateTrainingAttributesCommand
// 	Модель обновления общих атрибутов
export type UpdateTrainingAttributes = {
  required?: AttributesField['id'][];
  info?: AttributesField['id'][];
  private?: AttributesField['id'][];
  readonly?: AttributesField['id'][];
  methodist?: AttributesField['id'][];
};

export type ChainAttributeTemplateId = Id;
// Объект шаблонов условий.
export interface IChainAttributeTemplate {
  id: ChainAttributeTemplateId;
  label?: string;
}

//* Interfaces.DtoModels.AttributeDto
// Модель атрибута
export interface AttributeDto {
  id: Id;
  label: string;
  type: AttributeTypeEnum;
  options?: string;
  regex?: string;
}

export interface IChainAttributeTransform {
  value: AttributeDto['id'];
  label: AttributeDto['label'];
  type: Required<AttributeDto['options']>;
}

//* Lms.UseCases.Interfaces.Common.ChainAttributes.ChainAttributeResultUsage
// Тип использования результата цепочки атрибутов
export enum ChainAttributeResultUsageEnum {
  REQUIRED = 1,
  OPTIONAL = 2,
  ADDITIONAL = 3,
  READONLY = 10
}

//* Lms.UseCases.Interfaces.Common.ChainAttributes.ChainAttributeResultDto
// 	Атрибут-результат
export interface IChainAttributeResult {
  attributeId: AttributesField['id'];
  usage: ChainAttributeResultUsageEnum;
}

//* Lms.UseCases.Interfaces.Common.ChainAttributes.TemplateChainAttributeConditionDto
// Цепочка атрибута - для шаблона
export interface IChainAttribute {
  attributeId: AttributesField['id'];
  conditionValue?: string;
  resultAttributeCollection: IChainAttributeResult[];
}

//* Lms.UseCases.Interfaces.Instructors.ChainAttributes.Templates.GetChainAttributeTemplateResponse
// Модель шаблона цепочек атрибутов
export interface IChainsAttributesTemplates {
  template: IChainAttributeTemplate;
  chainAttributes: IChainAttribute[];
}

export type ChainAttributesUsage = {
  id: ChainAttributeResultUsageEnum;
  label?: string;
};
