import { CatalogImg, CollaborationImg, NewsImg, ProfileImg } from './assets';
import { IGetPresetProps, IPreset } from './types';

const presetsMap: Record<IPreset, string> = {
  profile: ProfileImg,
  news: NewsImg,
  collaboration: CollaborationImg,
  catalog: CatalogImg
};

export const getPresetProps: IGetPresetProps = ({ preset, image }) => {
  if (!preset) {
    return {
      imgSrc: image
    };
  }

  return {
    imgSrc: presetsMap[preset] ?? image
  };
};
