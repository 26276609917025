import { initReactI18next } from 'react-i18next';
import EnGuestPages from '../i18n/en/guest/pages.json';
import EnInstructorPages from '../i18n/en/instructor/pages.json';
import EnStudentPages from '../i18n/en/student/pages.json';
import RuGuestPages from '../i18n/ru/guest/pages.json';
import RuInstructorPages from '../i18n/ru/instructor/pages.json';
import RuStudentPages from '../i18n/ru/student/pages.json';
import { UserRoleUnionType } from 'types/roles/userRoles';
import EnCommon from '../i18n/en/common.json';
import EnGuestCommon from '../i18n/en/guest/common.json';
import EnStudentCommon from '../i18n/en/student/common.json';
import RuCommon from '../i18n/ru/common.json';
import RuGuestCommon from '../i18n/ru/guest/common.json';
import RuStudentCommon from '../i18n/ru/student/common.json';
import { LANGUAGES, NAMESPACES } from './constants';

import { createInstance, i18n as i18nInstance } from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

export const defaultNS = NAMESPACES.common;

interface CustomTypeOptions {
  resources:
    | Record<UserRoleUnionType, Partial<Record<keyof typeof NAMESPACES, object>>> & {
        common: object;
      };
}

export const resources: Record<keyof typeof LANGUAGES, CustomTypeOptions['resources']> = {
  en: {
    common: EnCommon,
    Guest: {
      common: EnGuestCommon,
      pages: EnGuestPages
    },
    Students: {
      common: EnStudentCommon,
      pages: EnStudentPages
    },
    Instructors: {
      pages: EnInstructorPages
    },
    Root: {}
  },
  ru: {
    common: RuCommon,
    Guest: {
      common: RuGuestCommon,
      pages: RuGuestPages
    },
    Students: {
      common: RuStudentCommon,
      pages: RuStudentPages
    },
    Instructors: {
      pages: RuInstructorPages
    },
    Root: {}
  }
};

const createI18n = (language: keyof typeof LANGUAGES): i18nInstance => {
  const i18n = createInstance().use(initReactI18next).use(LanguageDetector);

  i18n.init({
    debug: true,
    supportedLngs: Object.values(LANGUAGES),
    fallbackLng: language,
    ns: [NAMESPACES.common, NAMESPACES.pages],
    defaultNS,
    resources
  });

  return i18n;
};

export const i18n = createI18n(LANGUAGES.en);
