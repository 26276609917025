import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { ISelectProps, Select } from 'common/ui/_new';
import { Label } from './Label';
import { CompanySelectViewOption, CompanySelectViewProps, CompanySelectViewValue } from './types';

export const CompanySelectView: FC<CompanySelectViewProps> = ({ value, ...restProps }) => {
  const { t } = useTranslation('common', { keyPrefix: 'components.company-select-view' });

  const options: ISelectProps<CompanySelectViewValue, CompanySelectViewOption>['options'] = [
    {
      value: value.value,
      label: (
        <Label>
          <div>{value.value}</div>
        </Label>
      )
    }
  ];

  return <Select {...restProps} options={options} value={options[0]} placeholder={t('placeholder')} />;
};
