import { Icon } from '../../../Icon';
import { isButtonIconType } from './helper';
import { IButtonIconProps, ISuffixButtonIconProps } from './types';

export const ButtonIcon: React.FC<IButtonIconProps> = ({ icon }) => {
  return <>{isButtonIconType(icon) ? <Icon type={icon} /> : icon}</>;
};

export const SuffixButtonIcon: React.FC<ISuffixButtonIconProps> = ({ icon }) => {
  return <span className='ant-btn-icon'>{isButtonIconType(icon) ? <Icon type={icon} /> : icon}</span>;
};
