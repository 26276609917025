import { Button, IButtonProps } from 'common/ui/_new';
import { IClearButton } from './types';
import styles from './styles.module.scss';

export const ClearButton: IClearButton = (props) => {
  const { onInputChange, ...restProps } = props;

  const innerProps: IButtonProps = {
    //* overridable:
    type: 'stroke-secondary-square',
    icon: 'clear',
    onClick: () => onInputChange?.(undefined),
    //* outer:
    ...restProps,
    //* unoverridable:
    className: styles.clearBtn
  };

  return <Button {...innerProps} />;
};
