import { Id } from 'types/common';
import { CourseId, ExerciseId, LessonId } from 'types/entities';

export enum IExerciseConstraintsEnum {
  EMPTY = -1,
  All = 0,
  One = 1
}

// Объект отображения перечня ограничений для упражнения.
export interface IExerciseConstraintById {
  course: string;
  exercise: string;
  label: string;
  union: IExerciseConstraintsEnum.One | IExerciseConstraintsEnum.All;
}

// Объект получения ограничений упражнения.
export interface IExerciseConstraint {
  attribute: string;
  label: string;
  value: string;
  editable: true;
}

// Объект получения ограничений для урока.
export interface IExerciseTrainingConstraint {
  id: Id;
  trainingId: CourseId;
  lessonId: LessonId;
  exerciseId: ExerciseId;
  label: string;
  group: string;
  labelGroup: string;
}

// Объект получения атрибутов для фильтрации.
export interface IExerciseConstraintAttributes {
  id: Id;
  label: string;
  code: string;
  data: {
    [key: string]: string;
  };
}

export type ConstraintType = 1 | 2 | 3 | 4 | 5 | 10 | 15 | 20 | 30 | 50 | 51 | 60 | 80;

export interface ITrainingConstraint {
  id: Id;
  trainingId: CourseId;
  lessonId: LessonId;
  exerciseId: ExerciseId;
  label: string;
  group: string;
  labelGroup: string;
}

export interface IConstraintAttribute {
  course: string;
  exercise: string;
  attribute: string;
  type: ConstraintType;
  value: string[];
}

//Объект получения ограничения для упражнения по обучению.
export interface IExerciseTrainingConstraints {
  trainingIds: string[];
}
