import { createContext, type ReactNode, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ExpressModal } from 'common/ui/_new';
import { ConfirmContext, ConfirmOptions, Nullable } from './confirm.types';

export const ConfirmCtx = createContext<Nullable<ConfirmContext>>(null);

interface Props {
  children: ReactNode;
}

export const ConfirmProvider = ({ children }: Props) => {
  const { t } = useTranslation('common', { keyPrefix: 'helpers.confirm.confirm-provider' });
  const [confirm, setConfirm] = useState<Nullable<ConfirmOptions>>(null);

  const [open, toggle] = useState(false);

  const show = useCallback(
    (confirmOptions: Nullable<ConfirmOptions>) => {
      setConfirm(confirmOptions);
      toggle(true);
    },
    [toggle]
  );

  const onConfirm = () => {
    confirm?.onConfirm?.();
    toggle(false);
  };

  const onCancel = () => {
    confirm?.onCancel?.();
    toggle(false);
  };

  const value = useMemo(() => ({ show }), [show]);

  return (
    <ConfirmCtx.Provider value={value}>
      {open && (
        <ExpressModal
          open
          title={t('modal.title')}
          subtitle={t('modal.subtitle')}
          onCancel={onCancel}
          onOk={onConfirm}
          okText={t('modal.ok-text')}
        />
      )}
      {children}
    </ConfirmCtx.Provider>
  );
};
