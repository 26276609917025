import { ComponentProps } from 'react';
import { Radio as AntRadio } from 'antd';
import cn from 'classnames';
import { Group } from './Group';
import { IInternalRadio, IRadio } from './types';
import styles from './styles.module.scss';

const InternalRadio: IInternalRadio = ({ className, children, ...outerProps }) => {
  const overridableProps: ComponentProps<typeof AntRadio> = {};

  const unoverridableProps: ComponentProps<typeof AntRadio> = {
    className: cn(styles.radio, className)
  };

  return (
    <AntRadio {...overridableProps} {...outerProps} {...unoverridableProps}>
      {children}
    </AntRadio>
  );
};

export const Radio: IRadio = Object.assign(InternalRadio, {
  Button: AntRadio.Button,
  Group: Group
});
