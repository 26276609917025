import { RootState } from '../../index';

const profile = (state: RootState) => state.profile;
const isLogged = (state: RootState) => !!state.profile.accountId;
const userFullName = (state: RootState) => `${state.profile.firstname} ${state.profile.surname}`;
const profileIsLoading = (state: RootState) => state.profile.isLoading;
const role = (state: RootState) => state.profile.role;
const avatar = (state: RootState) => state.profile.picture;
const unfilled = (state: RootState) => state.profile.unfilled;

export const profileSelectors = {
  profile,
  isLogged,
  userFullName,
  profileIsLoading,
  role,
  avatar,
  unfilled
};
