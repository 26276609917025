import { FC, HTMLAttributes, PropsWithChildren, useEffect, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import cn from 'classnames';
import { ILinkProps, Spin } from 'common/ui/_new';
import { createEventListener } from './helper';
import styles from './styles.module.scss';

export type CardProps<T = (() => void) | undefined> = (T extends undefined
  ? {
      onClick?: undefined;
      onAuxClick?: undefined;
      to: ILinkProps['to'];
    }
  : {
      onClick: T;
      onAuxClick?: T;
      to?: ILinkProps['to'];
    }) & {
  title?: ILinkProps['title'];
  loading?: boolean;
  disabled?: boolean;
} & Pick<HTMLAttributes<HTMLElement>, 'className'> &
  PropsWithChildren;

export const Card: FC<CardProps> = ({ to = '', onClick, onAuxClick, title, loading = false, disabled = false, className, children }) => {
  const cardRef = useRef<HTMLElement>(null);
  const navigate = useNavigate();

  useEffect(() => {
    const cardElement = cardRef.current;
    if (!disabled) {
      if (cardElement) {
        if (to) {
          createEventListener(cardElement, () => navigate(to));
        }
        if (onClick) {
          createEventListener(cardElement, onClick);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Spin spinning={loading}>
      <article ref={cardRef} className={cn(styles.card, { [styles.disabled]: disabled }, className)}>
        {children}
        {!disabled && <Link to={to} onClick={onClick} onAuxClick={onAuxClick} title={title} className={styles.link} />}
      </article>
    </Spin>
  );
};
