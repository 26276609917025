import { ComponentPropsWithRef, ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { Select as AntSelect } from 'antd';
import cn from 'classnames';
import { ClearButton, Icon } from 'common/ui/_new';
import { SHOWSEARCH_OPTIONS_MIN } from './helper';
import { BaseOptionType, DefaultOptionType, ISelectProps } from './types';
import styles from './styles.module.scss';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const InternalSelect = <ValueType = any, OptionType extends BaseOptionType | DefaultOptionType = DefaultOptionType>(
  props: ISelectProps<ValueType, OptionType>
): ReactElement => {
  const { t } = useTranslation('common', { keyPrefix: 'ui.select' });
  const { readOnly, allowClear, className, ...restProps } = props;

  const innerProps: ComponentPropsWithRef<typeof AntSelect<ValueType, OptionType>> = {
    //* overridable:
    placeholder: t('placeholder'),
    removeIcon: <Icon type='close' />,
    showSearch: props.options && props.options.length > SHOWSEARCH_OPTIONS_MIN,
    optionFilterProp: 'label',
    //* outer:
    ...restProps,
    //* unoverridable:
    ...(readOnly && {
      open: false,
      removeIcon: null,
      showSearch: false,
      onSearch: undefined,
      onChange: undefined
    }),
    allowClear: false,
    className: cn(styles.select, className, { 'select-readonly': readOnly }),
    popupClassName: styles.popup
  };

  const isClearButtonDisabled = props.mode
    ? // @ts-ignore // todo: надо добавить проверку на массив
      (props.defaultValue ?? props.value ?? []).length === 0
    : (props.defaultValue ?? props.value) === undefined;

  if (!readOnly && allowClear) {
    return (
      <div className={styles.selectWrapperWithClearBtn}>
        <AntSelect {...innerProps} />
        <ClearButton
          onInputChange={(value, option) => {
            props.onChange?.(value, option);
            props.onSearch?.(value);
            props.onDeselect?.(value, option);
            props.onClear?.();
          }}
          disabled={isClearButtonDisabled}
          size={props.size}
        />
      </div>
    );
  }

  return <AntSelect {...innerProps} />;
};

type ISelect = typeof InternalSelect & {
  Option: typeof AntSelect.Option;
  OptGroup: typeof AntSelect.OptGroup;
};

export const Select: ISelect = Object.assign(InternalSelect, {
  Option: AntSelect.Option,
  OptGroup: AntSelect.OptGroup
});

/* 
todo: кастомизировать иконки, suffixIcon заменяет также иконку лупы
*/
