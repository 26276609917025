import { ComponentProps, FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Input as AntInput } from 'antd';
import cn from 'classnames';
import { ITextAreaProps } from './types';
import styles from './styles.module.scss';

export const TextArea: FC<ITextAreaProps> = (props) => {
  const { t } = useTranslation('common', { keyPrefix: 'ui.input-textarea' });
  const { className, ...restProps } = props;

  const innerProps: ComponentProps<typeof AntInput.TextArea> = {
    //* overridable
    placeholder: t('placeholder'),
    autoSize: { minRows: 2, maxRows: 6 },
    //* outer
    ...restProps,
    //* unoverridable
    className: cn(styles.textarea, className)
  };

  return <AntInput.TextArea {...innerProps} />;
};
