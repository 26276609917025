import { FC } from 'react';
import { Space } from 'antd';
import cn from 'classnames';
import { Button, Dropdown, IDropdownProps, Link, Tooltip } from 'common/ui/_new';
import { isLink } from './helper';
import { ActionsPanelItemProps, IActionsPanelProps } from './types';
import styles from './styles.module.scss';

export const ActionsPanel: FC<IActionsPanelProps> = (props) => {
  const { items, className } = props;

  return (
    <Space.Compact className={cn(styles.actionsPanel, className)}>
      {items.map(({ dropdownItems, onDropdownClick, ...item }, i) => {
        const innerProps: ActionsPanelItemProps = {
          //* outer
          ...item,
          //* unoverridable
          type: 'stroke-rect-small'
        };

        if (dropdownItems || onDropdownClick || innerProps.dropdownProps) {
          const innerDropdownProps: IDropdownProps = {
            //* outer
            menu: {
              items: dropdownItems,
              onClick: onDropdownClick
            },
            ...innerProps.dropdownProps,
            //* unoverridable
            trigger: ['click']
          };

          return (
            <Dropdown key={i} {...innerDropdownProps}>
              {isLink(innerProps) ? <Link {...innerProps} /> : <Button {...innerProps} />}
            </Dropdown>
          );
        }

        if (isLink(innerProps)) {
          if (innerProps.tooltip) {
            return (
              <Tooltip key={i} title={innerProps.tooltip}>
                <Link {...innerProps} />
              </Tooltip>
            );
          }

          return <Link key={i} {...innerProps} />;
        }

        return <Button key={i} {...innerProps} />;
      })}
    </Space.Compact>
  );
};
