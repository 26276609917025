import { ComponentPropsWithRef, FC } from 'react';
import { Tabs as AntTabs } from 'antd';
import cn from 'classnames';
import { getDefaultKey, handleTabsChange } from './helper';
import { ITabsProps } from './types';
import styles from './styles.module.scss';

export const Tabs: FC<ITabsProps> = ({ id, items, defaultActiveKey, onChange, className, ...restProps }) => {
  const innerProps: ComponentPropsWithRef<typeof AntTabs> = {
    //* overridable
    size: 'large',
    animated: { inkBar: true, tabPane: true },
    defaultActiveKey: getDefaultKey({ defaultActiveKey, id, items }),
    //* outer
    ...restProps,
    //* unoverridable
    items,
    onChange: (activeKey) => handleTabsChange({ activeKey, onChange, id }),
    className: cn(styles.tabs, className)
  };

  return <AntTabs {...innerProps} />;
};
