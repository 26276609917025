import { SlideId } from 'types/entities';

export enum IVideoEnum {
  Default = 0,
  One = 1,
  Two = 2
}

//* Robocode.Models.Instructors.Pages.Edit.Video
export interface IVideoSlide {
  id: SlideId;
  duration: number;
  evaluate: boolean;
  label: string;
  address: string;
  player: number;
  play: boolean;
  downloadPermission: boolean;
}
