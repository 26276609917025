import { ComponentProps, FC } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import cn from 'classnames';
import { LinkAlert, LinkButton, LinkIcon } from './components';
import { isLinkAsAlert, isLinkAsButton } from './helper';
import { ILinkProps } from './types';
import styles from './styles.module.scss';

export const Link: FC<ILinkProps> = (props) => {
  if (isLinkAsButton(props)) {
    return <LinkButton {...props} />;
  } else if (isLinkAsAlert(props)) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { type, className, children, ...restProps } = props;
    const innerProps: ComponentProps<typeof RouterLink> = {
      //* overridable:
      //* outer:
      ...restProps,
      //* unoverridable:
      className: cn(styles.linkAlertLink, className)
    };

    return <LinkAlert type='info' icon='link' message={<RouterLink {...innerProps}>{children}</RouterLink>} />;
  } else {
    const { icon, suffixIcon, className, children, ...restProps } = props;
    const innerProps: ComponentProps<typeof RouterLink> = {
      //* overridable:
      //* outer:
      ...restProps,
      //* unoverridable:
      className: cn(styles.linkRouterLink, className)
    };

    return (
      <RouterLink {...innerProps}>
        {icon && <LinkIcon icon={icon} />}
        {children}
        {suffixIcon && <LinkIcon icon={suffixIcon} className={styles.linkSuffixIcon} />}
      </RouterLink>
    );
  }
};

/* 
todo: настроить обработку to и href для возможных значений (http(s):, file:, с/без слэша), особенно при указании to
todo: сделать ссылку фокусируемой (href='#' не подойдет, т.к. дублируется история перехода по страницам)
todo: стили для disabled
*/
