import { attributesSlice } from './attributes/attributes.slice';
import { sidebarSlice } from './calendar/calendar.slice';
import { dictionarySlice } from './dictionary/dictionary.slice';
import { profileSlice } from './profile/profile.slice';

export const studentSlices = {
  [sidebarSlice.name]: sidebarSlice.reducer,
  [profileSlice.name]: profileSlice.reducer,
  [dictionarySlice.name]: dictionarySlice.reducer,
  [attributesSlice.name]: attributesSlice.reducer
};
