import { Layout } from 'containers';
import { IResultPageProps, ResultPage } from 'common/components/_new';
import { INoDataPage } from './types';

export const NoDataPage: INoDataPage = (props) => {
  const { ...restProps } = props;

  const innerProps: IResultPageProps = {
    //* overridable:
    //* outer:
    ...restProps,
    //* unoverridable:
    status: 'no-data',
    className: props.className
  };

  return (
    <Layout centered style={{ justifyContent: 'center', flexGrow: 1 }}>
      <ResultPage {...innerProps} />
    </Layout>
  );
};

//! page should be in pages folder
