import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Text } from 'common/ui/_new';
import { useAppSelector } from 'common/hooks';
import { profileSelectors } from 'api/student/profile/profile.selectors';
import styles from './styles.module.scss';

export const Username: FC = () => {
  const { t } = useTranslation('common', { keyPrefix: 'components.header.user-name' });
  const userFullName = useAppSelector(profileSelectors.userFullName);
  const [firstName, lastName] = userFullName.replace(/[ ]+/g, ' ').split(' ');
  const username = `${firstName} ${lastName[0]}.`;

  return (
    <>
      <div className={styles.current}>{t('current-account')}</div>
      <Text ellipsis={{ tooltip: username }} className={styles.userName}>
        {username}
      </Text>
    </>
  );
};
