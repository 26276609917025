import { FC } from 'react';
import cn from 'classnames';
import { ILinkProps, Link, Text } from 'common/ui/_new';
import { AttachmentProps } from './types';
import styles from './styles.module.scss';

export const Attachment: FC<AttachmentProps> = ({ className, children, ...restProps }) => {
  const innerProps: ILinkProps = {
    //* overridable
    //* outer
    ...restProps,
    //* unoverridable
    download: true,
    target: '_blank',
    rel: 'noreferrer',
    icon: 'attach',
    className: cn(styles.attachment, className)
  };

  return (
    <Link {...innerProps}>
      <Text ellipsis={{ tooltip: children }} className={styles.text}>
        {children}
      </Text>
    </Link>
  );
};
