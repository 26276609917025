import { createApi } from '@reduxjs/toolkit/query/react';
import { getBaseQuery } from 'common/helpers';
import { IInstructorProfileRights } from 'types/requests/instructor/profileRights.api';

export const instructorProfileRightsApi = createApi({
  reducerPath: 'instructorProfileRightsApi',
  baseQuery: getBaseQuery(),
  endpoints: ({ query }) => ({
    getInstructorProfileRights: query<IInstructorProfileRights.Response, IInstructorProfileRights.Params>({
      query: ({ accountId }) => ({
        url: `/api/Instructors/Support/Profile/${accountId}/Rights`
      })
    })
  })
});

export const { useGetInstructorProfileRightsQuery } = instructorProfileRightsApi;
