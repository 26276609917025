import { FC, PropsWithChildren } from 'react';
import { isFeatureAvailable } from 'common/helpers';
import { useAppSelector } from 'common/hooks';
import { FeatureFlagName } from 'common/constants';
import { profileSelectors } from 'api/student/profile/profile.selectors';
import { IROLE } from 'types/roles';

type FeatureEnabledProps = PropsWithChildren & {
  name: FeatureFlagName;
};

export const FeatureEnabled: FC<FeatureEnabledProps> = ({ name, children }) => {
  const user: IROLE.RoleObject = useAppSelector(profileSelectors.role);

  if (isFeatureAvailable(name, user)) {
    return children;
  }

  return null;
};
