import { createApi } from '@reduxjs/toolkit/query/react';
import { getBaseQuery } from 'common/helpers';
import { IExpulsionEdit, IExpulsionRemove, IExpulsionsList } from 'types/requests/student/expulsion.api';

export const expulsionApi = createApi({
  reducerPath: 'expulsionApi',
  baseQuery: getBaseQuery(),
  tagTypes: ['Expulsion'],
  endpoints: ({ query, mutation }) => ({
    getExpulsions: query<IExpulsionsList.Response, IExpulsionsList.Params>({
      query: () => '/api/students/expulsion/applications',
      providesTags: ['Expulsion']
    }),
    removeExpulsion: mutation<IExpulsionRemove.Response, IExpulsionRemove.Params>({
      query: ({ id }) => ({
        url: `/api/students/expulsion/application/${id}/withdraw`,
        method: 'POST'
      }),
      invalidatesTags: ['Expulsion']
    }),
    editExpulsion: mutation<IExpulsionEdit.Response, IExpulsionEdit.Params>({
      query: ({ id, file }) => ({
        url: `/api/students/expulsion/application/${id}/edit`,
        method: 'POST',
        body: file
      }),
      invalidatesTags: ['Expulsion']
    })
  })
});

export const { useGetExpulsionsQuery, useRemoveExpulsionMutation, useEditExpulsionMutation } = expulsionApi;
