import { FC, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Input, InputProps, optionalInputBlocked, Switch } from 'common/ui/_new';
import { OptionalInputProps } from './types';

export const OptionalInput: FC<OptionalInputProps> = (props) => {
  const { t } = useTranslation('common', { keyPrefix: 'ui.optional-input' });
  const { toggleProps, ...restProps } = props;
  const [isChecked, setIsChecked] = useState(toggleProps?.defaultChecked);
  const previousValue = useRef(props.value);

  const inputProps: InputProps = {
    //* overridable
    //* outer
    ...restProps,
    //* unoverridable
    ...(isChecked && { disabled: true, placeholder: t('placeholder') }),
    ...(props.readOnly && { disabled: false, placeholder: '', title: t('placeholder') })
  };

  return (
    <>
      <Input {...inputProps} />
      {!(props.disabled || props.readOnly) && (
        <Switch
          {...toggleProps}
          checked={isChecked}
          onChange={(checked) => {
            setIsChecked(checked);
            if (checked) {
              previousValue.current = props.value;
              // @ts-ignore: т.к. ожидает event
              props.onChange?.(optionalInputBlocked);
            } else {
              if (previousValue.current === optionalInputBlocked) {
                // @ts-ignore: т.к. ожидает event
                props.onChange?.(undefined);
              } else {
                // @ts-ignore: т.к. ожидает event
                props.onChange?.(previousValue.current);
              }
            }
          }}
        />
      )}
    </>
  );
};
