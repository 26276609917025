import { Flex } from 'antd';
import { Button } from 'common/ui/_new';
import { IButtonsGroup } from './types';

export const ButtonsGroup: IButtonsGroup = (props) => {
  const { type = 'stroke-primary-circle', items } = props;

  return (
    <Flex gap={8}>
      {items.map((item, i) => (
        <Button key={i} type={type} {...item} />
      ))}
    </Flex>
  );
};
