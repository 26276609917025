import { useGetChainConditionAttributesQuery } from 'api/student/attributes/attributes.api';
import {
  AccountId,
  AttributesField,
  AttributeUsageEnum,
  ChainConditionAttributeId,
  ChainResultAttribute,
  ChainResultAttributeId
} from 'types/entities';
import { ChainConditionAttributesMap, ChainsMap } from './types';

export const useGetChainConditionAttributes = (
  accountId: AccountId
): {
  data: ChainConditionAttributesMap;
} => {
  const getChainConditionAttributesQuery = useGetChainConditionAttributesQuery(accountId);
  const { data: chainConditionAttributes } = getChainConditionAttributesQuery;

  if (!chainConditionAttributes) {
    return {
      data: {}
    };
  }

  const chainConditionAttributesMap = chainConditionAttributes.reduce(
    (acc: ChainConditionAttributesMap, { chainConditionAttributeId, chainConditionTriggerValue, chainResultAttributes }) => {
      acc[chainConditionAttributeId] ??= {};

      if (chainConditionTriggerValue) {
        acc[chainConditionAttributeId][chainConditionTriggerValue] ??= {};

        if (chainResultAttributes) {
          chainResultAttributes.forEach(({ chainResultAttributeId, ...newProps }) => {
            if (acc[chainConditionAttributeId][chainConditionTriggerValue][chainResultAttributeId] === undefined) {
              acc[chainConditionAttributeId][chainConditionTriggerValue][chainResultAttributeId] = newProps;
            } else if (newProps.usage !== AttributeUsageEnum.Undefined) {
              const hasHigherUsagePriority =
                newProps.usage < acc[chainConditionAttributeId][chainConditionTriggerValue][chainResultAttributeId].usage;
              if (hasHigherUsagePriority) {
                acc[chainConditionAttributeId][chainConditionTriggerValue][chainResultAttributeId] = newProps;
              }
            }
          });
        }
      }

      return acc;
    },
    {}
  );

  return {
    data: chainConditionAttributesMap
  };
};

export const extendResultAttributesWithChainProps = (
  chainResultAttributes: AttributesField[],
  chainResultAttributeProps: { [key: ChainResultAttributeId]: Omit<ChainResultAttribute, 'chainResultAttributeId'> }
) => {
  return chainResultAttributes.map((attributeProps) => ({
    ...attributeProps,
    ...chainResultAttributeProps[attributeProps.id]
  }));
};

export const addResultAttributesToChainInMap = (
  chainsMap: ChainsMap,
  chainConditionAttributeId: ChainConditionAttributeId,
  chainResultAttributes: (AttributesField & Omit<ChainResultAttribute, 'chainResultAttributeId'>)[]
): ChainsMap => ({
  ...chainsMap,
  [chainConditionAttributeId]: chainResultAttributes
});

export const deleteResultAttributesFromChainInMap = (
  chainsMap: ChainsMap,
  chainConditionAttributeId: ChainConditionAttributeId
): ChainsMap => ({
  ...chainsMap,
  [chainConditionAttributeId]: undefined
});

export const updateCurrentAttributes = (chainsMap: ChainsMap, initialAttributes: AttributesField[]) => {
  const currentAttributesMap = initialAttributes.reduce(
    (acc: { [id: AttributesField['id']]: AttributesField }, chainConditionAttributeField) => {
      acc[chainConditionAttributeField.id] ??= chainConditionAttributeField;

      const resultAttributes = chainsMap[chainConditionAttributeField.id];
      if (resultAttributes) {
        resultAttributes.forEach(({ usage, isCommonAttribute, hasHigherUsagePrioCommonAttribute, ...resultAttributeProps }) => {
          if (isCommonAttribute) {
            if (!hasHigherUsagePrioCommonAttribute) {
              acc[resultAttributeProps.id] = resultAttributeProps;
            }
          } else {
            const currentAttribute = acc[resultAttributeProps.id];
            if (currentAttribute) {
              if (usage === AttributeUsageEnum.Required || (usage === AttributeUsageEnum.Optional && !currentAttribute.required)) {
                acc[resultAttributeProps.id] = resultAttributeProps;
              }
            } else {
              acc[resultAttributeProps.id] = resultAttributeProps;
            }
          }
        });
      }

      return acc;
    },
    {}
  );

  return Object.values(currentAttributesMap);
};
