import { ComponentProps } from 'react';
import { Spin as AntSpin } from 'antd';
import AntIcon from '@ant-design/icons';
import cn from 'classnames';
import { Ellipse } from './assets';
import { ISpin } from './types';
import styles from './styles.module.scss';

AntSpin.setDefaultIndicator(<AntIcon component={Ellipse} spin />);

export const Spin: ISpin = (props) => {
  const { className, ...restProps } = props;

  const innerProps: ComponentProps<typeof AntSpin> = {
    //* overridable:

    //* outer:
    ...restProps,
    //* overridable:
    className: cn(styles.spin, className)
  };

  return <AntSpin {...innerProps} />;
};
