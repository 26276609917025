import { ComponentPropsWithRef, FC } from 'react';
import { Rate as AntRate } from 'antd';
import cn from 'classnames';
import { Icon } from 'common/ui/_new';
import { RateProps } from './types';
import styles from './styles.module.scss';

export const Rate: FC<RateProps> = ({ className, ...restProps }) => {
  const innerProps: ComponentPropsWithRef<typeof AntRate> = {
    //* overridable:
    //* outer:
    ...restProps,
    //* overridable:
    character: <Icon type='star' />,
    className: cn(styles.rate, className)
  };

  return <AntRate {...innerProps} />;
};
