import { FC, useMemo } from 'react';
import { ISelectProps, Select, Tooltip } from 'common/ui/_new';
import { useGetDictionaryQuery } from 'api/student/dictionary/dictionary.api';
import { IDictionarySelectProps } from './types';

export const DictionarySelect: FC<IDictionarySelectProps> = (props) => {
  const { dictionaryId = '', ...restProps } = props;

  const getDictionaryQuery = useGetDictionaryQuery(dictionaryId, { skip: !dictionaryId });

  const options: ISelectProps['options'] = useMemo(
    () => getDictionaryQuery.data?.map(({ label, id }) => ({ label: <Tooltip title={label}>{label}</Tooltip>, value: id })),
    [getDictionaryQuery.data]
  );

  return <Select {...restProps} options={options} loading={getDictionaryQuery.isFetching || getDictionaryQuery.isLoading} />;
};
