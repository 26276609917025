import { FC } from 'react';
import { Popover as AntPopover, PopoverProps as AntPopoverProps } from 'antd';
import cn from 'classnames';
import { PopoverProps } from './types';
import styles from './styles.module.scss';

export const Popover: FC<PopoverProps> = ({ className, children, ...restProps }) => {
  const innerProps: AntPopoverProps = {
    //* overridable
    //* outer
    ...restProps,
    //* unoverridable
    className: cn(styles.popover, className)
  };

  return <AntPopover {...innerProps}>{children}</AntPopover>;
};
