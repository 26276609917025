import { FC, useState } from 'react';
import { ContentLayout, Header, IContentLayoutProps, IHeaderProps } from 'containers/_new';
import { Button } from 'common/ui/_new';
import styles from './styles.module.scss';

export type TileCollapseProps = {
  title: IHeaderProps['children'];
  headerProps?: Omit<IHeaderProps, 'endGroup' | 'children'>;
} & IContentLayoutProps;

export const TileCollapse: FC<TileCollapseProps> = ({ title, headerProps, children, ...restProps }) => {
  const [isExpanded, setIsExpanded] = useState(true);

  return (
    <ContentLayout
      gap='md'
      {...restProps}
      tile
      header={
        <Header
          level={3}
          {...headerProps}
          endGroup={
            <Button
              type='stroke-secondary-circle'
              icon={isExpanded ? 'arrow-chevron-up' : 'arrow-chevron-down'}
              onClick={() => setIsExpanded((prev) => !prev)}
              size='small'
              className={styles.linksButton}
            />
          }
        >
          {title}
        </Header>
      }
      headerSeparated={isExpanded}
    >
      {isExpanded && children}
    </ContentLayout>
  );
};
