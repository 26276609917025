import { ComponentProps, FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Input as AntInput } from 'antd';
import cn from 'classnames';
import { Icon } from 'common/ui/_new/Icon';
import { IPasswordProps } from './types';
import styles from './styles.module.scss';

export const Password: FC<IPasswordProps> = (props) => {
  const { t } = useTranslation('common', { keyPrefix: 'ui.input-password' });
  const { className, ...restProps } = props;

  const innerProps: ComponentProps<typeof AntInput.Password> = {
    //* overridable:
    placeholder: t('placeholder'),
    iconRender: (visible) =>
      visible ? <Icon type='hide' title={t('hide-icon.title')} /> : <Icon type='show' title={t('show-icon.title')} />,
    //* outer:
    ...restProps,
    //* unoverridable:
    className: cn(styles.password, className)
  };

  return <AntInput.Password {...innerProps} />;
};
