import { Id, PaginationApiMetadata } from 'types/common';
import { AccountId, ExerciseId, LessonId } from 'types/entities';
import { ChainAttributeTemplateId, IExercises } from './';

type CourseAge = 0 | 1 | 2 | 3 | 4;

export const COURSE_AGES = ['Любой', 'Дети', 'Школьники', 'Студенты', 'Взрослые'];

// Объект списка курсов для инструктора
export interface ICourse {
  id: Id;
  label: string;
  picture: string;
  age: CourseAge;
  ready: boolean;
}

//* Robocode.Models.Instructors.Courses.View.Lesson
export type LessonView = {
  id: LessonId;
  label?: string;
  description?: string;
  constraints: boolean;
  n: number;
};

interface IAuthor {
  id: Id;
  picture: string;
  first: string;
  last: string;
  eMail: string;
  phone: string;
}

// Объект курса для инструктора
export interface ICourseById {
  id: Id;
  label: string;
  description: string;
  file: string;
  icon: string;
  picture: string;
  pictureId: string;
  trace: boolean;
  age: CourseAge;
  lessons: LessonView[];
  lectures: number;
  tasks: number;
  tests: number;
  authors: IAuthor[];
  min: number;
  max: number;
  done: boolean;
}

// Тело объетка курса
export interface ICourseBody {
  id: Id;
  label: string;
  description: string;
  pictureId?: string;
  age: CourseAge;
  done: boolean;
}

// Обьект Групп обучения
export interface ITrainingGroup {
  id: string;
  label: string;
  title: string;
}

//* CQRS.Models.ExerciseInfoResult
// todo: update
export type ExerciseInfo = {
  labelExercise: string;
  isDisabled: boolean; //индикация параметра активности упражнения по фильтр-статусу
  index: number;
  percents: number;
  hasFiles: boolean;
  available: boolean;
  done: boolean;
  status: number;
  averageMark: number;
  fileInfo: {
    exercise: ExerciseId;
    student: AccountId;
  };
};

export interface IJournalTable {
  data: IJournal[];
  filterMetadataStudent: PaginationApiMetadata;
}

export interface IJournal {
  studentId: string;
  initials: string;
  groupName?: string;
  email: string;
  done: number;
  evaluated?: number;
  exercises: ExerciseInfo[];
  duration?: number;
  result?: number;
}

export interface IVisitJournalTable {
  accountId: string;
  email: string;
  initials: string;
  isHasVisit: boolean;
}

export interface IVisitJournalTableData {
  data: IVisitJournalTable[];
  draw: number;
  recordsFiltered: number;
  recordsTotal: number;
}

export interface IVisitJournalLesson {
  lessonId: string;
  lessonName: string;
  sequence: number;
}

export interface IVisitJournalExercise {
  exerciseId: string;
  exerciseName: string;
}

export enum ICourseStudyStatusesEnum {
  Study = 1,
  Expelled = 2,
  Dropped = 3
}

//*Lms.Entities.Entities.Training.TrainingDatesVisibility
export enum TrainingDatesVisibilityEnum {
  Both, // Отображение обоих дат.
  StartOnly, // Отображение только даты начала курса.
  EndOnly, // Отображение только даты окончания курса.
  None // Не отображать даты курса.
}

//*Lms.Entities.Entities.Training.TrainingTypes
export enum TrainingTypesEnum {
  Synchronous,
  Asynchronous
}

//*Lms.UseCases.Interfaces.Trainings.ModifyTrainingUseCases.TrainingCreateUseCase.CreateTrainingCommand
export interface ICreateCourseSettings {
  pictureId: string;
  operationId: string;
  pay: boolean;
  label: string;
  description: string;
  set: string;
  course: string;
  available: boolean;
  enroll: string;
  group: string;
  attributes_Private: string[];
  attributes_Required: string[];
  attributes_Info: string[];
  attributes_Readonly: string[];
  attributes_Instructors: string[];
  attributes_Methodist: string[];
  public: boolean;
  approve: boolean;
  verified: boolean;
  lid: boolean;
  dates: TrainingDatesVisibilityEnum;
  grade: string;
  tutor: string;
  tags: string[];
  category: string;
  template: string;
  isExistTraceAdditionalFields: boolean;
  parentCourseId: string;
  flowNum: string;
  flowId: string;
  project: string;
  projectId: string;
  isIncludedIntoCalc: boolean;
  allowWithoutAttributes: boolean;
  expulsionId: string;
  chainAttributesTemplateId?: ChainAttributeTemplateId;
  revaluationDuringMonth: boolean;
  assesment: string;
  trainingType: TrainingTypesEnum;
  landingLink: string;
  sendCertsToId: string;
  trainingIdCerts1C: string;
}

export type ILessonsExercises = { label?: string; id?: LessonId; code?: string; nodes?: IExercises[] };
