import { ComponentProps, forwardRef } from 'react';
import { Button as AntButton } from 'antd';
import cn from 'classnames';
import { ButtonIcon, ButtonTooltip, isTooltipReactNode, SuffixButtonIcon } from './components';
import { getInitialProps } from './helper';
import { IButton, IInternalButton, IInternalButtonWithTooltip } from './types';
import styles from './styles.module.scss';

const InternalButton: IInternalButton = forwardRef(function InternalButton(
  { type, icon, suffixIcon, className, children, ...restProps },
  ref
) {
  const { initialTypeStyleProps, initialTypeStyleClassNames } = getInitialProps({ type, styles });

  const innerProps: ComponentProps<typeof AntButton> = {
    //* overridable:
    ...initialTypeStyleProps,
    icon: icon && <ButtonIcon icon={icon} />,
    //* outer:
    ...restProps,
    //* unoverridable:
    ref,
    className: cn(...initialTypeStyleClassNames, className)
  };

  return (
    <AntButton {...innerProps}>
      {children}
      {suffixIcon && <SuffixButtonIcon icon={suffixIcon} />}
    </AntButton>
  );
});

const InternalButtonWithTooltip: IInternalButtonWithTooltip = forwardRef(function InternalButtonWithTooltip(
  { tooltip, ...outerProps },
  ref
) {
  if (tooltip) {
    const tooltipProps = isTooltipReactNode(tooltip) || typeof tooltip === 'string' ? { title: tooltip } : tooltip;
    return (
      <ButtonTooltip {...tooltipProps}>
        <InternalButton ref={ref} {...outerProps} />
      </ButtonTooltip>
    );
  }

  return <InternalButton ref={ref} {...outerProps} />;
});

export const Button: IButton = Object.assign(InternalButtonWithTooltip, { Group: AntButton.Group });

/* 
todo: выделить отдельный компонент кнопка-иконка
*/
