import { FC } from 'react';
import cn from 'classnames';
import { Item } from './Item';
import { MetaDataListProps } from './types';
import styles from './styles.module.scss';

export const MetaDataList: FC<MetaDataListProps> = ({ items, className }) => {
  return (
    <div className={cn(styles.metaData, className)}>
      {items.map((itemProps, i) => (
        <Item key={i} {...itemProps} />
      ))}
    </div>
  );
};
