import { ComponentProps } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { Progress, Text } from 'common/ui/_new';
import { getInnerPropsByData } from './helper';
import { IStepsProgress } from './types';
import styles from './styles.module.scss';

const staticOverridableProps: ComponentProps<typeof Progress> = {
  type: 'line',
  showInfo: false,
  size: [0, 4]
};

export const StepsProgress: IStepsProgress = ({ complete, total, className, ...outerProps }) => {
  const { t } = useTranslation('common', { keyPrefix: 'components.steps-progress' });
  const { percent, steps, textIndicatorProps } = getInnerPropsByData({ complete, total }, t);

  const overridableProps: ComponentProps<typeof Progress> = {
    ...staticOverridableProps
  };

  const unoverridableProps: ComponentProps<typeof Progress> = {
    percent,
    steps,
    className: cn(styles.stepsProgress, className)
  };

  return (
    <div className={styles.wrapper}>
      <Progress {...overridableProps} {...outerProps} {...unoverridableProps} />
      <Text {...textIndicatorProps} className={styles.textIndicator} />
    </div>
  );
};
