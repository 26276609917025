import { createApi } from '@reduxjs/toolkit/query/react';
import { getBaseQuery } from 'common/helpers';
import {
  ICheckAttributeForChainResults,
  IGetAttributeGroups,
  IGetAttributeItem,
  IGetAttributes,
  IGetChainConditionAttributes,
  IGetUnfilledAttributeList,
  ISetQuestionAttributeFields,
  ISetQuestionAttributeFiles
} from 'types/requests/student/attributes.api';

export const attributesApi = createApi({
  reducerPath: 'attributesApi',
  baseQuery: getBaseQuery(),
  tagTypes: ['Attributes', 'Unfilled', 'AttributeGroupItems'],
  endpoints: ({ query, mutation }) => ({
    getAttributes: query<IGetAttributes.Response, IGetAttributes.Params>({
      query: (list) => ({
        url: '/api/attribute/listByCategories',
        method: 'post',
        body: list
      }),
      providesTags: ['Attributes']
    }),
    getAttributeGroups: query<IGetAttributeGroups.Response, IGetAttributeGroups.Params>({
      query: () => '/api/Attribute/Groups'
    }),
    getAttributeItem: query<IGetAttributeItem.Response, IGetAttributeItem.Params>({
      query: ({ id }) => `/api/Attribute/Groups/${id}`,
      providesTags: ['AttributeGroupItems']
    }),
    getUnfilledAttributeList: query<IGetUnfilledAttributeList.Response, IGetUnfilledAttributeList.Params>({
      query: () => ({
        url: `/api/Attribute/unfilled`
      }),
      providesTags: ['Unfilled']
    }),
    setQuestionAttributeFields: mutation<ISetQuestionAttributeFields.Response, ISetQuestionAttributeFields.Params>({
      query: ({ id, body }) => ({
        url: `/api/questions/${id}/fields`,
        method: 'POST',
        body
      }),
      invalidatesTags: ['Unfilled', 'AttributeGroupItems']
    }),
    setQuestionAttributeFiles: mutation<ISetQuestionAttributeFiles.Response, ISetQuestionAttributeFiles.Params>({
      query: ({ id, body }) => ({
        url: `/api/questions/${id}/files`,
        method: 'POST',
        body
      }),
      invalidatesTags: ['Unfilled', 'AttributeGroupItems']
    }),
    checkAttributeForChainResults: mutation<ICheckAttributeForChainResults.Response, ICheckAttributeForChainResults.Params>({
      query: (body) => ({
        url: '/api/Attribute/chain-results/check',
        method: 'POST',
        body
      })
    }),
    getChainConditionAttributes: query<IGetChainConditionAttributes.Response, IGetChainConditionAttributes.Params>({
      query: (accountId) => {
        const params = new URLSearchParams({ accountId });
        return {
          url: '/api/Attribute/chain-conditions',
          params
        };
      }
    })
  })
});

export const {
  useGetAttributesQuery,
  useGetAttributeGroupsQuery,
  useGetAttributeItemQuery,
  useLazyGetAttributeItemQuery,
  useGetUnfilledAttributeListQuery,
  useLazyGetUnfilledAttributeListQuery,
  useSetQuestionAttributeFieldsMutation,
  useSetQuestionAttributeFilesMutation,
  useCheckAttributeForChainResultsMutation,
  useGetChainConditionAttributesQuery
} = attributesApi;
