import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IChainAttribute } from 'types/entities';

export type IChainAttributesState = {
  chainAttributes: IChainAttribute[];
  error: boolean | undefined;
};

const initialState: IChainAttributesState = {
  chainAttributes: [],
  error: undefined
};

export const chainAttributesSlice = createSlice({
  name: 'chainAttributes',
  initialState,
  reducers: {
    setError: (state, action: PayloadAction<boolean>) => {
      state.error = action.payload;
    }
  }
});

export const { setError } = chainAttributesSlice.actions;
export default chainAttributesSlice.reducer;
