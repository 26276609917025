import { FC, PropsWithChildren, ReactNode } from 'react';
import cn from 'classnames';
import { Card } from 'common/ui';
import { IImageProps, ILinkProps, Image, ITitleProps, Title } from 'common/ui/_new';
import styles from './styles.module.scss';

export type CoverCardProps = {
  title?: ITitleProps['children'];
  tag?: ReactNode;
  action?: ReactNode;
  footer?: ReactNode;
  to: ILinkProps['to'];
  cover: IImageProps['src'];
  disabled?: boolean;
} & PropsWithChildren;

export const CoverCard: FC<CoverCardProps> = ({ title, tag, action, footer, to, cover, disabled, children }) => {
  return (
    <div className={styles.cardContainer}>
      <Card to={to} className={cn(styles.coverCard, { [styles.disabled]: disabled })}>
        <div className={styles.main}>
          <section className={styles.cover}>
            <Image src={cover} alt='' fallback='structure' className={styles.cover_img} />
          </section>
          <div className={styles.content}>
            <div className={styles.header}>
              {tag && <div className={styles.tag}>{tag}</div>}
              {title && (
                <Title level={5} ellipsis={{ tooltip: title }}>
                  {title}
                </Title>
              )}
            </div>
            {children && <section className={styles.children}>{children}</section>}
          </div>
          {action && <div className={styles.action}>{action}</div>}
        </div>
        {footer && <footer>{footer}</footer>}
      </Card>
    </div>
  );
};
