import { FC } from 'react';
import { useSearchParams } from 'react-router-dom';
import { QueryResponseBoundary } from 'containers/_new';
import { useGetKinescopeSlideQuery } from 'api/instructor/slides/endpoints/slide_kinescope.endpoint';
import styles from './styles.module.scss';

export const KinescopePreviewModal: FC = () => {
  const [searchParams] = useSearchParams();

  const getKinescopeSlideQuery = useGetKinescopeSlideQuery({ pageId: searchParams.get('slideId') || '' });

  return (
    <QueryResponseBoundary query={getKinescopeSlideQuery}>
      <iframe
        className={styles.iframe}
        src={`https://kinescope.io/embed/${getKinescopeSlideQuery.data?.videoId || ''}`}
        allow='autoplay; fullscreen; picture-in-picture; encrypted-media;'
        allowFullScreen
      ></iframe>
    </QueryResponseBoundary>
  );
};
